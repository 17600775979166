import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Explore from "./explore";
import CardList from "./CardList";
import SmallList from "./SmallList";
import Select from "./Select";

const Index = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/explore`} component={Explore} />
      <Route path="/">
        <Explore />
      </Route>
      <Redirect from="*" to="/" />
    </Switch>
  );
};

Index.List = SmallList;
Index.CardList = CardList;
Index.Select = Select;

export default Index;
