import React from "react";
import List from "./List";
import { Redirect, Route, Switch } from "react-router-dom";
import ID from "./@id";
import Container from "@mui/material/Container";

const Index = ({ match }) => {
  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        px: { lg: "100px", md: "50px", mobile: "10px" },
        mb: "50px",
        mt: "50px",
      }}
    >
      <Switch>
        <Route path={`${match.url}/listing/:listingId`} component={ID} />
        <Route path="/">
          <List />
        </Route>
        <Redirect from="*" to="/" />
      </Switch>
    </Container>
  );
};

export default Index;
