import Gateway from "../@components/gateway";

const useService = () => {
  const gateway = Gateway.useGateway();

  const redeem = async (data) => {
    const response = await gateway.post(
      `/qr_ticket_scan/${data.qrTicketId}/utility/${data.utilityId}/redeem/`
    );
    return response.data;
  };

  const info = async (id, salt) => {
    const response = await gateway.get(`/qr_ticket_scan/${id}/info/${salt}/`);
    return response.data;
  };

  return {
    redeem,
    info,
  };
};

export default useService;
