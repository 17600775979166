import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  label: {
    color: "rgb(255 255 255 / 30%) !important",
  },
}));

const MaterialCheckbox = React.forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      sx={{
        mr: { mobile: "0px", md: props?.nomargin === "true" ? "0px" : "16px" },
      }}
      classes={{ disabled: classes.label }}
      control={
        <Checkbox
          checked={props.value || false}
          {...props}
          ref={ref}
          sx={{
            color: "#944DFF",
            "&.Mui-disabled": {
              color: "rgb(148 77 255 / 30%) !important",
            },
            "&.Mui-checked": {
              color: "#944DFF",
            },
          }}
        />
      }
      label={props.label}
    />
  );
});

export default MaterialCheckbox;
