import Gateway from "../@components/gateway";

const useService = () => {
  const gateway = Gateway.useGateway();

  const list = async () => {
    const response = await gateway.get("/collection/?page_size=100");
    return response.data.results;
  };

  const featured = async () => {
    const response = await gateway.get("/collection/featured/");
    return response.data;
  };

  return {
    list,
    featured,
  };
};

export default useService;
