import React from "react";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import Badge from "@mui/material/Badge";
import MaterialDialog from "@mui/material/Dialog";
import PreviewSwiper from "./PreviewSwiper";
import { Box } from "@material-ui/core";
import playIcon from "../../../../../static/play-media.mp4";

const GradientCircularProgress = (props) => {
  return (
    <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        size={45}
        disableShrink
        sx={{ "svg circle": { stroke: "url(#my_gradient)" }, ...props.sx }}
      />
    </React.Fragment>
  );
};

const StoryDialog = ({ media, open, onClose }) => {
  return (
    <MaterialDialog
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          background: "transparent",
          boxShadow: "none",
          maxWidth: "min-content",
          width: "100%",
          height: "auto",
          margin: "10px",
        },
      }}
      PaperProps={{
        maxWidth: "1200px",
      }}
      open={open}
      onClose={onClose}
    >
      <PreviewSwiper items={media} />
    </MaterialDialog>
  );
};

const StoryMedia = ({ avatar, media }) => {
  const [showStoryDialog, setShowStoryDialog] = React.useState(false);
  return (
    <>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <Avatar
            sx={{
              width: 22,
              height: 22,
            }}
            alt="Remy Sharp"
            src={avatar}
          />
        }
      >
        <Box
          component={"video"}
          src={playIcon}
          alt={"avatar"}
          playsInline
          muted
          loop
          autoPlay={true}
          onClick={() => setShowStoryDialog(true)}
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: { md: "50%", mobile: "100%" },
            cursor: "pointer",
            border: "1px solid #944DFF",
            boxShadow: "0px 0px 15px 0px #944DFF",
          }}
        />
        <GradientCircularProgress
          sx={{
            color: "#b185f2",
            position: "absolute",

            top: -2.9,
            left: -3,
            zIndex: -1,
          }}
        />
      </Badge>

      <StoryDialog
        open={showStoryDialog}
        onClose={() => setShowStoryDialog(false)}
        media={media}
      />
    </>
  );
};

export default StoryMedia;
