import React from "react";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import InfoIcon from "@mui/icons-material/Info";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import GradingIcon from "@mui/icons-material/Grading";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 18,
    left: 'calc(-50% + 18px)',
    right: 'calc(50% + 18px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.colors.primary,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.colors.primary,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.bgTransparent.secondary,
    borderRadius: 10,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.bgTransparent.secondary,
  zIndex: 1,
  color: theme.palette.text.tertiary,
  width: 36,
  height: 36,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    background: theme.palette.colors.primary,
    boxShadow: "0px 0px 30px rgb(138 38 255 / 20%)",
    color: "white",
  }),
  ...(ownerState.completed && {
    background: theme.palette.colors.primary,
    color: "white",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <InfoIcon sx={{ fontSize: "18px" }}/>,
    2: <LocalActivityIcon sx={{ fontSize: "18px" }} />,
    3: <PlaylistAddIcon sx={{ fontSize: "18px" }} />,
    4: <GradingIcon sx={{ fontSize: "18px" }}/>,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const FormStepper = ({ steps, activeStep }) => {
  const classes = useStyles();
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            classes={{ label: classes.stepLabel }}
            StepIconComponent={ColorlibStepIcon}
          >
            <Typography
              variant={"subtitle3"}
              sx={{ fontSize: { md: "12px !important", mobile: "11px !important" }, color: (theme) => theme.palette.text.primary }}
            >
              {label}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default FormStepper;
