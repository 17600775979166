import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const PerkItem = ({ perk, numOfPerks }) => {
  return (
    <Typography variant={"h2"} sx={{ fontSize: { mobile:"16px", md: "18px" } }}>
      {`${numOfPerks}x ${perk?.title}`}
    </Typography>
  );
};

const Perk = ({ collection, perkName, numOfPerks }) => {
  const { t } = useTranslation();
  const perks = {
    digital_content: {
      title: t("brands.collection.collectionPage.success.perkItem.digitalContent"),
      description:
        "Suspendisse blandit ante a nulla venenatis aliquam. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      button: {
        title: "Got to Feed",
        link: `/brand/${collection?.dao_id}/feed`,
      },
    },
    ticket: {
      title: t("brands.collection.collectionPage.success.perkItem.ticket"),
      description:
        "Suspendisse blandit ante a nulla venenatis aliquam. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      button: { title: "QR Code", link: `/brand/${collection?.dao_id}/feed` },
    },
    service: {
      title: t("brands.collection.collectionPage.success.perkItem.service"),
      description:
        "Suspendisse blandit ante a nulla venenatis aliquam. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      button: { title: "QR Code", link: `/brand/${collection?.dao_id}/feed` },
    },
    product: {
      title: t("brands.collection.collectionPage.success.perkItem.product"),
      description:
        "Suspendisse blandit ante a nulla venenatis aliquam. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      button: {
        title: "Go to Collection",
        link: `/brand/${collection?.dao_id}/collection/${collection?.id}`,
      },
    },
    merch: {
      title: t("brands.collection.collectionPage.success.perkItem.merch"),
      description:
        "Suspendisse blandit ante a nulla venenatis aliquam. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      button: {
        title: "Go to Collection",
        link: `/brand/${collection?.dao_id}/collection/${collection?.id}`,
      },
    },
    discord_access: {
      title: t("brands.collection.collectionPage.success.perkItem.discordAccess"),
      description:
        "Suspendisse blandit ante a nulla venenatis aliquam. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      button: {
        title: "Go to Collection",
        link: `/profile`,
      },
    },
    custom: {
      title: t("brands.collection.collectionPage.success.perkItem.custom"),
      description:
        "Suspendisse blandit ante a nulla venenatis aliquam. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
      button: {
        title: "Go to Collection",
        link: `/brand/${collection?.dao_id}/collection/${collection?.id}`,
      },
    },
  };

  return <PerkItem perk={perks[perkName]} numOfPerks={numOfPerks} />;
};

export default Perk;
