import React from "react";
import Box from "@mui/material/Box";
import { ethers } from "ethers";
import marketplacABI from "../../abis/contracts/marketplace/marketplace.json";
import customHooks from "../../@components/hooks";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import erc721Artifact from "../../abis/contracts/modules/collection/Collection.sol/Collection.json";
import UI from "../../@components/UI";
import MaterialDialog from "@mui/material/Dialog";
import {
  useSendUserOperation,
  useSmartAccountClient,
} from "@alchemy/aa-alchemy/react";
import { encodeFunctionData } from "viem";
import MenuItem from "@mui/material/MenuItem";

const marketplaceAddress = "0xA6576936A91924486204F8D642a555Dcf5fe7590";
const qvrsAddress = "0x89d148eFEA769134F6302B88803c49f0F3bbb6C4";

const SellDialog = ({
  open,
  daoId,
  collectionId,
  tokenId,
  tokenAddress,
  brandName,
  onSuccess,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [price, setPrice] = React.useState(0);
  const publicProvider = customHooks.useEthersProvider();
  const [isListing, setIsListing] = React.useState(false);
  const [currency, setCurrency] = React.useState("MATIC");

  const { client: smartAccountClient } = useSmartAccountClient({
    type: "MultiOwnerModularAccount",
    gasManagerConfig: {
      policyId:
        window.env.CHAIN === "AMOY"
          ? "2a7bc98c-5422-4d90-a8b1-91de6fdadc0d"
          : "9e29758c-dcb6-43a1-85f5-2acfd5a0c060",
    },
    opts: {
      txMaxRetries: 20,
    },
  });

  const { sendUserOperation } = useSendUserOperation({
    client: smartAccountClient,
    onSuccess: ({ hash, request }) => {
      enqueueSnackbar("NFT listed for sale", { variant: "success" });
      onSuccess();
      setIsListing(false);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const list = async () => {
    setIsListing(true);

    const nftContract = new ethers.Contract(
      tokenAddress,
      erc721Artifact.abi,
      publicProvider
    );

    let userOperations = [];
    const approvedAddress = await nftContract.getApproved(tokenId);

    if (approvedAddress.toLowerCase() !== marketplaceAddress.toLowerCase()) {
      userOperations.push({
        target: nftContract.target,
        data: encodeFunctionData({
          abi: erc721Artifact.abi,
          functionName: "setApprovalForAll",
          args: [marketplaceAddress, true],
        }),
      });
    }

    const params = {
      tokenAddress: tokenAddress,
      tokenId: tokenId,
      brandId: daoId,
      collectionId: collectionId,
      noOfTokensToSell: 1,
      pricePerToken: ethers.parseUnits(price, "ether"),
      paymentToken: currency === "MATIC" ? ethers.ZeroAddress : qvrsAddress, // 0x0 address for MATIC
      tokenType: 1, // 1 for ERC721
    };
    console.log("params", params);

    userOperations.push({
      target: marketplaceAddress,
      data: encodeFunctionData({
        abi: marketplacABI,
        functionName: "listNFT",
        args: [params],
      }),
    });
    sendUserOperation({ uo: userOperations });
  };

  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          padding: { md: "40px 30px", mobile: "40px 10px" },
          width: "700px",
          margin: { mobile: "0px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={open}
      onClose={onClose}
    >
      <CloseIcon
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 15,
          top: 15,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <DialogContent sx={{ p: "0px" }}>
        <Typography
          variant={"h5"}
          sx={{ fontWeight: "600", textAlign: "center" }}
        >
          LIST ON THE MARKETPLACE
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            justifyContent: "center",
            mt: "40px",
            width: "80%",
            margin: "40px auto",
          }}
        >
          <Box
            sx={{
              width: { md: "100%", xs: "100%" },
              mb: { xs: "10px", md: "0px" },
            }}
          >
            <TextField
              sx={{
                ".MuiOutlinedInput-root": { pr: "0px" },
                mr: { md: "20px", xs: "0px" },
                width: "100%",
              }}
              placeholder={"Price per item"}
              value={price}
              onChange={(event) => {
                setPrice(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <UI.Select
                      value={currency}
                      onChange={(event) => setCurrency(event.target.value)}
                    >
                      <MenuItem value="MATIC">MATIC</MenuItem>
                      <MenuItem value="QVRS">QVRS</MenuItem>
                    </UI.Select>
                  </InputAdornment>
                ),
              }}
            >
              <InputAdornment position="start">
                <Typography variant={"h6"} sx={{ fontWeight: "600" }}>
                  Price
                </Typography>
              </InputAdornment>
            </TextField>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant={"subtitle1"}
            sx={{
              fontSize: "11px !important",
              mt: "4.3px",
            }}
          >
            {`${brandName} fee: 3%`}
          </Typography>
          <Typography
            variant={"subtitle1"}
            sx={{
              fontSize: "11px !important",
              mt: "4.3px",
            }}
          >
            {"QVRSE fee: 5%"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            mb: "10px",
          }}
        >
          <UI.SmartAccountActionButton
            title={"Confirm"}
            isLoading={isListing}
            onClick={async () => {
              await list();
            }}
          />
        </Box>
      </DialogContent>
    </MaterialDialog>
  );
};

const Action = ({
  element: Component,
  onConfirm,
  daoId,
  collectionId,
  tokenId,
  tokenAddress,
  onSuccess,
}) => {
  const [open, setOpen] = React.useState(null);

  return (
    <>
      <Box
        component={"span"}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {Component}
      </Box>

      <SellDialog
        open={open}
        daoId={daoId}
        collectionId={collectionId}
        tokenId={tokenId}
        tokenAddress={tokenAddress}
        onSuccess={onSuccess}
        onClose={() => setOpen(false)}
        onConfirm={() => {
          onConfirm();
          setOpen(false);
        }}
      />
    </>
  );
};

SellDialog.Action = Action;

export default SellDialog;
