import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../../@components/UI";
import Paper from "@mui/material/Paper";
import { Controller, useFieldArray } from "react-hook-form";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import { useFormContext } from "react-hook-form";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AdditionalInfo from "./AdditionalInfo";
import CollectInfo from "./CollectInfo";
import CustomActionButton from "../CustomActionDialog";
import AddField from "../AddField";
import LinkIcon from "@mui/icons-material/Link";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  inputOuter: {
    marginBottom: "25px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Form = ({ data: utility, index: utilityIndex, onRemove }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [customActionButton, setCustomActionButton] = React.useState(null);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { append } = useFieldArray({
    control: control,
    name: `extra_utilities.${utilityIndex}.custom_info_fields`,
  });

  return (
    <Paper
      sx={{
        width: "100%",
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
        px: { md: "50px", mobile: "10px" },
        py: { md: "50px", mobile: "50px" },
      }}
    >
      <Box
        sx={{ mb: "50px", display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant={"h2"}>{utility.displayName}</Typography>
        <DeleteForeverIcon
          sx={{
            cursor: "pointer",
            color: (theme) => theme.palette.colors.darkRed,
            opacity: "0.8",
            "&:hover": {
              opacity: 1,
            },
          }}
          onClick={() => onRemove(utilityIndex)}
        />
      </Box>
      <Box className={classes.inputOuter}>
        <Box sx={{ display: "flex", mb: "10px" }}>
          <Typography variant={"h4"}>
            {t("brands.collection.import.extraUtilities.form.name.title")}
          </Typography>
        </Box>

        <Controller
          name={`extra_utilities.${utilityIndex}.name`}
          control={control}
          rules={{ required: true }}
          render={({ field }) => <UI.TextField {...field} />}
        />
        {Object.keys(errors).length > 0 &&
          errors?.extra_utilities[utilityIndex]?.name?.type === "required" && (
            <Box sx={{ mt: "15px" }}>
              <Alert
                variant="outlined"
                severity="error"
              >
                {t("brands.collection.import.extraUtilities.form.name.error.required")}
              </Alert>
            </Box>
          )}
      </Box>
      <Box className={classes.inputOuter}>
        <Box sx={{ display: "flex", mb: "10px" }}>
          <Typography variant={"h4"}>
            {t(
              "brands.collection.import.extraUtilities.form.description.title",
              { utilityName: utility?.displayName }
            )}
          </Typography>
        </Box>

        <Controller
          name={`extra_utilities.${utilityIndex}.description`}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <UI.TextField multiline rows={4} {...field} placeholder={""} />
          )}
        />
        <Box sx={{ mt: "15px" }}>
          {Object.keys(errors).length > 0 &&
            errors?.extra_utilities[utilityIndex]?.description?.type ===
              "required" && (
              <Alert
                variant="outlined"
                severity="error"
              >
                {t( "brands.collection.import.extraUtilities.form.description.error.required")}
              </Alert>
            )}
        </Box>
      </Box>
      <Box sx={{ mb: "20px", mt: "20px" }}>
        <AdditionalInfo control={control} utilityIndex={utilityIndex} />
      </Box>
      <Box sx={{ mt: "10px" }}>
        <CollectInfo control={control} utilityIndex={utilityIndex} />
      </Box>
      {customActionButton == null && (
        <Box sx={{ mt: "10px" }}>
          <Box
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <CustomActionButton.Action
              title={t(
                "brands.collection.import.extraUtilities.form.customLink.title"
              )}
              onAdd={(customField) => {
                setCustomActionButton(customField);
                append(customField);
              }}
              element={
                <AddField
                  title={t(
                    "brands.collection.import.extraUtilities.form.customLink.action.title"
                  )}
                  subtitle={t(
                    "brands.collection.import.extraUtilities.form.customLink.action.subtitle"
                  )}
                />
              }
            />
          </Box>
        </Box>
      )}
      {customActionButton != null && (
        <Box sx={{ mb: "10px", mt: "15px" }}>
          <Typography variant={"h4"} sx={{ mb: "9px" }}>
            {t(
              "brands.collection.import.extraUtilities.form.customLink.action.title"
            )}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.open(customActionButton.value, "_blank");
              }}
            >
              <Tooltip title={customActionButton.name} arrow>
                <Box
                  sx={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    backgroundColor: "rgba(148, 77, 255, 1);",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LinkIcon />
                </Box>
              </Tooltip>
            </Box>
            <Box
              onClick={() => setCustomActionButton(null)}
              sx={{
                cursor: "pointer",
                ml: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <DeleteForeverIcon
                sx={{
                  color: (theme) => theme.palette.colors.darkRed,
                  opacity: "0.8",
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default Form;
