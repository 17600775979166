import React from "react";
import Box from "@mui/material/Box";
import UI from "../../../../../../@components/UI";
import { useDrop } from "react-dnd";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const Card = ({ item, onDrop }) => {
  const { t } = useTranslation();

  const spaceIsConfirmed = item.album_card.length !== 0;
  const isLocked = item.is_locked;

  const collectionId =
    item &&
    item.collections !== null &&
    item.collections.length > 0 &&
    item?.collections[0].id;

  const collectionType =
    (item &&
      item.collections !== null &&
      item.collections.length > 0 &&
      item?.collections[0].nft_file_type) ||
    "image/";

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: collectionId.toString(),
    drop: (data, nft) => {
      onDrop({ droppedItem: data, spaceItem: item });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;
  let display = "none";
  if (isActive) {
    display = "flex";
  } else if (canDrop && !isLocked) {
    display = "flex";
  }

  return (
    <Box
      ref={drop}
      key={item.id}
      sx={{
        borderRadius: (theme) => theme.borderRadius.secondary,
        backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
        position: "relative",
        cursor: "pointer",
        width: { md: "155px", mobile: "102px" },
        height: { md: "232px", mobile: "153px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          backgroundColor: (theme) => theme.palette.bgTransparent.secondary,
          transform: "scale(1.02)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: { md: "50px", mobile: "32px" },
        }}
      >
        <Typography
          variant={"h9"}
          sx={{
            p: "8px",
            mt: "3px",
            maxWidth: { md: "135px", mobile: "90px" },
            display: "flex",
            alignItems: "center",
            fontSize: { md: "14px", mobile: "10px" },
          }}
        >
          <UI.OverflowTip>{item?.collections[0]?.name}</UI.OverflowTip>
        </Typography>
      </Box>

      <Box
        sx={{
          position: "absolute",
          display: display,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 2000,
          borderRadius: (theme) => theme.borderRadius.secondary,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          border: "2px solid #2fd729",
          boxShadow: "#2fd729 0px 0px 10px 0px",
        }}
      >
        <Typography variant={"h8"} sx={{ color: "#ffffff" }}>
          {t("brands.albums.cards.dropHere")}
        </Typography>
      </Box>

      {!spaceIsConfirmed && !isLocked && !isOver && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 1000,
            borderRadius: (theme) => theme.borderRadius.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "2px solid #944dff",
            boxShadow: "#944dff 0px 0px 5px 0px",
            animation: "pulse 3s infinite",
            "@keyframes pulse": {
              "0%": {
                transform: "scale(1)",
              },
              "50%": {
                transform: "scale(1.02)",
                backgroundColor: "rgba(177,133,242,0.3)",
                transition: "all ease-in-out",
              },
              "100%": {
                transform: "scale(1)",
              },
            },
          }}
        ></Box>
      )}

      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: { md: "100%", mobile: "100%" },
          height: { md: "145px", mobile: "98px" },
          overflow: "hidden",
        }}
      >
        {collectionType.startsWith("image/") && item && item?.collections && (
          <UI.ImageWithSkeleton
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
              transition: "all 0.3s ease-in-out",
            }}
            src={
              (item?.collections.length !== 0 &&
                item?.collections[0]?.nft_image_url) ||
              null
            }
          />
        )}
        {collectionType.startsWith("video/") && item && item?.collections && (
          <Box>
            <video
              style={{ width: "100%" }}
              autoPlay="autoplay"
              playsInline
              loop
              muted
            >
              <source
                src={
                  (item?.collections.length !== 0 &&
                    item?.collections[0]?.nft_image_url) ||
                  null
                }
              />
            </video>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const Action = ({ element: Component, item, onSelect }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const collectionId =
    item &&
    item.collections !== null &&
    item.collections.length > 0 &&
    item?.collections[0].id;
  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          !isMobile && onSelect([]);
        }}
      >
        <Box
          sx={{
            transition: "all 0.3s ease-in-out",
            cursor: "pointer",
          }}
          component={"span"}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onSelect([collectionId]);
          }}
        >
          {Component}
        </Box>
      </ClickAwayListener>
    </>
  );
};

Card.Action = Action;

export default Card;
