import React from "react";
import { Helmet } from "react-helmet";

const HeaderTags = ({ title, description, imageUrl, siteUrl }) => {
  return (
    <Helmet>
      {/*Essential META Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:type" content="article" />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={siteUrl} />
      <meta name="twitter:card" content="logo" />

      {/*Non-Essential, But Recommended */}
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="QVRSE" />
      {/*<meta name="twitter:image:alt" content="Alt text for image" />*/}

      {/*Non-Essential, But Required for Analytics */}
      {/*<meta property="fb:app_id" content="your_app_id" />*/}
      {/*<meta name="twitter:site" content="@website-username" />*/}
    </Helmet>
  );
};

export default HeaderTags;
