import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Ticket from "./Ticket";
import useService from "./useService";
import UI from "../@components/UI";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Utilities from "./Utilities";
import CustomHooks from "../@components/hooks";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id, salt } = useParams();
  const service = useService();
  const [mainUtility, setMainUtility] = React.useState(null);

  const {
    data: ticket,
    isLoading,
    refetch,
  } = CustomHooks.useFetch(["qr-scan-ticket"], () => service.info(id, salt), {
    onSuccess: async (response) => {
      setMainUtility(
        response.collection?.utilities.find(
          (utility) => utility.is_main_utility
        )
      );
    },
  });

  return (
    <Container maxWidth={"lg"} sx={{ px: { mobile: "0px", md: "unset" } }}>
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle1"}>
          {t("profile.qrTicketScan.loading")}
        </Typography>
        <Typography variant={"subtitle1"}>
          {t("profile.qrTicketScan.loading2")}
        </Typography>
      </UI.Busy.FullscreenIndicator>
      {!isLoading && (
        <Paper
          elevation={0}
          sx={{ width: "100%", pb: "100px", background: "transparent" }}
        >
          <Box sx={{ padding: { md: "10px 70px", mobile: "0px 10px" } }}>
            <Box>
              <Grid container>
                <Grid item mobile={12} md={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { md: "row", mobile: "column-reverse" },
                    }}
                  >
                    <Box
                      onClick={() =>
                        history.push(
                          `/brand/${ticket.collection.dao_id}/collection/${ticket.collection.id}`
                        )
                      }
                      sx={{
                        margin: { mobile: "35px auto", md: "unset" },
                      }}
                    >
                      <Ticket
                        brandName={ticket?.dao?.name}
                        brandAvatar={ticket?.dao?.avatar_url}
                        name={ticket?.collection.name}
                        file_url={ticket?.collection?.nft_image_url}
                        fileType={ticket?.collection?.nft_file_type}
                        mainUtility={mainUtility}
                      />
                    </Box>
                    <Box
                      sx={{ ml: { md: "70px", mobile: 0 }, width: "100%" }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Box sx={{ mb: "30px" }}>
                        <Typography variant={"h2"}>
                          {t("profile.qrTicketScan.perks")}
                        </Typography>
                      </Box>
                      <Utilities
                        collection={ticket.collection}
                        hasPermissionToRedeem={
                          ticket.memberRole === "ADMIN" ||
                          ticket.memberRole === "MODERATOR" ||
                          ticket.memberRole === "TICKET_REDEEMER"
                        }
                        utilities={ticket.ticket_qr.utility_redeems}
                        onRedeem={() => refetch()}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      )}
    </Container>
  );
};

export default Index;
