import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../@components/UI";
import { useHistory } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import CardSkeletonList from "../collections/CardSkeletonList";
import { useTranslation } from "react-i18next";
import { ethers } from "ethers";

const CardList = ({ items, busy }) => {
  return (
    <Grid container spacing={2} columns={20}>
      {busy && <CardSkeletonList />}
      {items?.length > 0 &&
        items?.map((item, index) => (
          <Item item={item} key={"collection_" + index} />
        ))}
      {!busy && items?.length === 0 && (
        <Box
          sx={{ display: "flex", justifyContent: "center", margin: "0 auto" }}
        >
          <UI.NoResults text={"No NFTs found :("} />
        </Box>
      )}
    </Grid>
  );
};

const Item = ({ item }) => {
  return (
    <Grid
      item
      mobile={20}
      md={6.66}
      lg={5}
      xxl={4}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <UI.FlipCard
        frontComponent={<FrontSide item={item} />}
        backComponent={<BackSide item={item} />}
      />
    </Grid>
  );
};

const BackSide = ({ item }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  return (
    <Box
      sx={{
        cursor: "pointer",
        textAlign: "left",
        margin: "0 auto",
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          borderBottom: "none",
          padding: "14px 20px 10px",
          height: "70px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", alignItems: "center", mr: "12px" }}>
            <Avatar src={item?.dao_info?.avatar_url} alt={"avatar"} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mt: "2px" }}>
            <Box sx={{ textAlign: "left" }}>
              {item?.dao_info?.name ? (
                <Typography
                  variant={"subtitle4"}
                  sx={{
                    maxWidth: "195px",
                  }}
                >
                  <UI.OverflowTip>{`${item?.dao_info?.name}`}</UI.OverflowTip>
                </Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"130px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {item?.collection_info?.name ? (
              <Typography
                variant={"h2"}
                sx={{
                  fontSize: "15px !important",
                  maxWidth: "195px",
                }}
              >
                <UI.OverflowTip>
                  {item?.collection_info?.name_slo !== null &&
                  item?.collection_info?.name_slo !== "" &&
                  i18n.language === "sl"
                    ? item?.collection_info?.name_slo
                    : item?.collection_info?.name || ""}
                </UI.OverflowTip>
              </Typography>
            ) : (
              <Skeleton
                height={"30px"}
                width={"130px"}
                variant={"rectangular"}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          height: "290px",
          flexDirection: "column",
          p: "0px 20px 10px",
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography
              variant={"subtitle4"}
              sx={{
                mr: "10px",
              }}
            >
              {t("commons.price")}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {item?.free_nft_gas_fee_payed_by_system === false && (
                <Box sx={{ width: "18px", height: "18px", mr: "7px" }}>
                  <UI.PaymentIcon currency={"matic"} />
                </Box>
              )}
              {item?.price !== "0.00" && (
                <Typography variant={"h8"} sx={{ lineHeight: "1 !important" }}>
                  {`${ethers.formatEther(item?.price)}`}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", mt: "17px", maxHeight: "141px" }}>
          <Typography
            variant={"subtitle3"}
            sx={{
              maxHeight: "141px",
              lineHeight: "1.4",
              overflow: "hidden",
              whiteSpace: "normal",
              textAlign: "justify",
              textOverflow: "ellipsis",
            }}
          >
            {item?.collection_info?.description_slo !== null &&
            item?.collection_info?.description_slo !== "" &&
            i18n.language === "sl"
              ? item?.collection_info?.description_slo.length > 240
                ? item?.collection_info?.description_slo.slice(0, 240) + " ..."
                : item?.collection_info?.description_slo
              : item?.collection_info?.description.length > 240
              ? item?.collection_info?.description.slice(0, 240) + " ..."
              : item?.collection_info?.description}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "70px",
          p: "5px 10px",
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
        }}
      >
        <Box sx={{ width: "180px" }}>
          <UI.Button
            sx={{ height: "40px" }}
            type={"primary"}
            title={t("buttons.view")}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push({
                pathname: `/marketplace/listing/${item?.listing_id}`,
                state: { scrollToTop: true },
              });
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const FrontSide = ({ item }) => {
  const { i18n } = useTranslation();
  return (
    <Box
      sx={{
        textAlign: "left",
        cursor: "pointer",
        margin: "0 auto",
        width: "290px",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          borderBottom: "none",
          padding: "14px 20px 10px",
          height: "70px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", alignItems: "center", mr: "12px" }}>
            <Avatar src={item?.dao_info?.avatar_url} alt={"avatar"} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mt: "2px" }}>
            <Box>
              {item?.dao_id ? (
                <Typography
                  variant={"subtitle4"}
                  sx={{
                    maxWidth: "195px",
                  }}
                >
                  <UI.OverflowTip>{`${item?.dao_info?.name}`}</UI.OverflowTip>
                </Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"130px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {item?.collection_info?.name ? (
              <Typography
                variant={"h2"}
                sx={{
                  fontSize: "15px !important",
                  maxWidth: "195px",
                }}
              >
                <UI.OverflowTip>
                  {item?.collection_info?.name_slo !== null &&
                  item?.collection_info?.name_slo !== "" &&
                  i18n.language === "sl"
                    ? item?.collection_info?.name_slo
                    : item?.collection_info?.name || ""}
                </UI.OverflowTip>
              </Typography>
            ) : (
              <Skeleton
                height={"30px"}
                width={"130px"}
                variant={"rectangular"}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: "290px",
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          component={"span"}
          sx={{
            boxSizing: "border-box",
            display: "block",
            overflow: "hidden",
            width: "initial",
            height: "initial",
            background: "none",
            opacity: 1,
            border: "0px",
            margin: "0px",
            padding: "0px",
            position: "absolute",
            inset: 0,
            lineHeight: "0",
            fontSize: "0",
          }}
        >
          {item ? (
            item.collection_info?.collection_nft_file_type?.startsWith(
              "video"
            ) ? (
              <video
                width="100%"
                height={"auto"}
                autoPlay="autoplay"
                playsInline
                loop
                muted
              >
                <source src={item.collection_info?.nft_image_url} />
              </video>
            ) : (
              <UI.ImageWithSkeleton
                sx={{
                  position: "absolute",
                  inset: 0,
                  boxSizing: "border-box",
                  padding: 0,
                  border: "none",
                  margin: "auto",
                  display: "block",
                  width: 0,
                  height: 0,
                  minWidth: "100%",
                  maxWidth: "100%",
                  minHeight: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
                src={item.collection_info?.nft_image_url}
                width={"100%"}
                alt={"slider-item"}
              />
            )
          ) : (
            <Skeleton
              height={"300px"}
              width={"300px"}
              variant={"rectangular"}
            />
          )}
        </Box>
      </Box>

      {item?.is_imported ? (
        <Box
          sx={{
            height: "70px",
            backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          }}
        ></Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: "20px",
            backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", height: "70px" }}>
            <Box sx={{ width: "18px", height: "18px", mr: "7px" }}>
              <UI.PaymentIcon currency={"matic"} />
            </Box>
            <Typography variant={"h8"}>
              {`${ethers.formatEther(item?.price)}`}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CardList;
