import React from "react";
import UI from "../UI";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import { useBlockContext } from "../contextAPI";
import governorArtifact from "../../abis/contracts/qtech/governor/Governor.sol/QtechGovernor.json";
import moment from "moment";
import Alert from "@mui/material/Alert";
import { useUserContext } from "../userContext";
import useBlockchainState from "../hooks/useBlockchainState";
import voteWalletIcon from "../../static/voteWallet.png";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import customHooks from "../hooks";
import { useTranslation } from "react-i18next";

const Option = ({ disabled, title, onClick, votes, totalStakedAmount }) => {
  const percentage = (votes / ethers.formatEther(totalStakedAmount)) * 100;
  const { t, i18n } = useTranslation();
  return (
    <Box
      onClick={() => !disabled && onClick()}
      sx={{
        position: "relative",
        cursor: disabled ? "normal" : "pointer",
        border: "1px solid transparent",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          transform: "scale(1)",
          border: disabled ? "1px solid transparent" : "1px solid #944dff",
          backgroundColor: (theme) => theme.palette.bgTransparent.secondary,
        },
        display: "flex",
        height: "48px",
        mb: { md: "11px", mobile: "9px" },
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
        borderRadius: (theme) => theme.borderRadius.primary,
      }}
    >
      <Box
        sx={{
          transition: "all 0.5s ease-in-out",
          backgroundColor: `rgba(148, 77, 255, 1)`,
          width: `${percentage || 0}%`,
        }}
      ></Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant={"h8"}
          sx={{
            position: "absolute",
            left: "17px",
            top: "50%",
            transform: "translateY(-50%)",
            ml: "0px",
          }}
        >
          {title}
        </Typography>
        {!disabled && (
          <Typography
            variant={"h8"}
            sx={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translateY(-50%) translateX(-50%)",
              opacity: "0.3",
              ml: "0px",
            }}
          >
            Click to Vote
          </Typography>
        )}

        {votes && i18n.language === "en" && (
          <Typography
            variant={"subtitle3"}
            sx={{
              position: "absolute",
              right: "17px",
              top: "50%",
              transform: `translateY(-50%) translateX(0)`,
              ml: "17px",
              transition: "transform 0.4s ease-in-out",
              visibility: "visible",
            }}
          >
            {`${votes} votes`}
          </Typography>
        )}
        {votes && i18n.language === "sl" && (
          <Typography
            variant={"subtitle3"}
            sx={{
              position: "absolute",
              right: "17px",
              top: "50%",
              transform: `translateY(-50%) translateX(0)`,
              ml: "17px",
              transition: "transform 0.4s ease-in-out",
              visibility: "visible",
            }}
          >
            {`${t("commons.votes")}: ${votes}`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const VotingEstimates = ({
  proposalState,
  DAO,
  proposal,
  setExecutionDateTime,
}) => {
  const { t } = useTranslation();
  const provider = customHooks.useEthersProvider();
  const [voteEnd, setVoteEnd] = React.useState("0");
  const [voteStart, setVoteStart] = React.useState(null);
  const [timelockEta, setTimelockEta] = React.useState(null);
  const { isCalculatingBlockTimes, getFutureBlockDateTime } = useBlockContext();

  React.useEffect(() => {
    const fetchData = async () => {
      if (!isCalculatingBlockTimes && proposal && DAO) {
        const governor = new ethers.Contract(
          DAO?.governor_contract?.address,
          governorArtifact.abi,
          provider
        );

        const proposalEtaTimestampData = await governor.proposalEta(
          proposal?.proposal_hash_id
        );
        const proposalEta = Number(proposalEtaTimestampData).toString();

        setTimelockEta(moment(proposalEta * 1000).format("DD MMM YYYY HH:mm"));
        setExecutionDateTime(
          moment(proposalEta * 1000)
            .add(1, "minutes")
            .format("DD MMM YYYY HH:mm")
        );
        const voteStartBlockData = await governor.proposalSnapshot(
          proposal?.proposal_hash_id
        );
        const voteStartBlock = Number(voteStartBlockData).toString();
        const voteEndBlockData = await governor.proposalDeadline(
          proposal?.proposal_hash_id
        );
        const voteEndBlock = Number(voteEndBlockData).toString();

        const voteStartDateTime = await getFutureBlockDateTime(
          parseInt(voteStartBlock)
        );

        const voteEndDateTime = await getFutureBlockDateTime(
          parseInt(voteEndBlock)
        );

        setVoteStart(moment(voteStartDateTime).format("DD MMM YYYY HH:mm"));
        setVoteEnd(moment(voteEndDateTime).format("DD MMM YYYY HH:mm"));
      }
    };
    DAO && proposal && fetchData();
    // eslint-disable-next-line
  }, [isCalculatingBlockTimes, proposal, DAO]);

  return (
    <Box>
      {proposalState && proposalState.toLowerCase() === "pending" && (
        <Alert variant="outlined" severity="info">
          {voteStart
            ? `${t("brands.vote.votingResultsPanel.votingWillStart", {
                date: voteStart,
              })}`
            : `${t("brands.vote.votingResultsPanel.estimating")}`}
        </Alert>
      )}
      {proposalState && proposalState.toLowerCase() === "active" && (
        <Alert variant="outlined" severity="info">
          {voteStart
            ? `${t("brands.vote.votingResultsPanel.votingWillEnd", {
                date: voteEnd,
              })}`
            : `${t("brands.vote.votingResultsPanel.estimating")}`}
        </Alert>
      )}
      {proposalState && proposalState.toLowerCase() === "queued" && (
        <Alert variant="outlined" severity="info">
          <Box sx={{ display: "flex" }}>
            {voteStart
              ? `${t(
                  "brands.vote.votingResultsPanel.voteWillBeReadyToExecute",
                  {
                    date: timelockEta,
                  }
                )}`
              : `${t("brands.vote.votingResultsPanel.estimating")}`}
          </Box>
        </Alert>
      )}
    </Box>
  );
};

const VotingPoll = ({
  DAO,
  proposal,
  onVote,
  onQueue,
  onExecute,
  hideQueue,
  hideExecute,
}) => {
  const { t, i18n } = useTranslation();
  const { user: loggedInUser } = useUserContext();
  const { isConnected } = useAccount();
  const [executionDateTime, setExecutionDateTime] = React.useState(null);
  const [canUserVote, setCanUserVote] = React.useState(false);
  const { openConnectModal } = useConnectModal();
  const {
    proposalState,
    currentVotes,
    accountHasVoted,
    votingPower,
    totalStakedAmount,
  } = useBlockchainState(DAO, proposal);

  const getStatus = (status) => {
    switch (status) {
      case "Executed":
        return t("brands.vote.status.executed");
      case "Queued":
        return t("brands.vote.status.queued");
      case "Succeeded":
        return t("brands.vote.status.succeeded");
      case "Defeated":
        return t("brands.vote.status.defeated");
      case "Pending":
        return t("brands.vote.status.pending");
      case "Active":
        return t("brands.vote.status.active");
      default:
        return t("brands.vote.status.pending");
    }
  };

  React.useEffect(() => {
    if (
      proposalState === "Succeeded" ||
      proposalState === "Pending" ||
      proposalState === "Defeated" ||
      accountHasVoted ||
      !isConnected ||
      DAO?.memberRole !== "ADMIN" ||
      DAO?.isMember === false ||
      !loggedInUser?.email_verified
    ) {
      setCanUserVote(false);
    } else {
      setCanUserVote(true);
    }
  }, [proposalState, accountHasVoted, isConnected, DAO, loggedInUser]);

  return (
    <Box
      sx={{
        p: { md: "18px 30px 12px 30px", mobile: "15px 15px 0px 15px" },
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant={"h5"}
          sx={{
            mb: "16px",
            maxWidth: "420px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <UI.OverflowTip>
              {t("brands.vote.votingResultsPanel.title")}
            </UI.OverflowTip>
          </Box>
        </Typography>

        <UI.Tag
          sx={{
            mt: "4px",
            ml: "10px",
            backgroundColor:
              proposalState === "Executed"
                ? (theme) => theme.palette.tag.green
                : proposalState === "Defeated"
                ? (theme) => theme.palette.tag.red
              : (theme) => theme.palette.tag.yellow,
                
          }}
        >
          {proposalState === "Queued"
            ? getStatus("Pending")
              : getStatus(proposalState)}
        </UI.Tag>

      </Box>
      {currentVotes && (
        <Box>
          <Option
            disabled={!canUserVote}
            onClick={() => onVote("yes")}
            accountHasVoted={accountHasVoted}
            totalStakedAmount={totalStakedAmount}
            votingPower={votingPower}
            title={t("brands.vote.votingResultsPanel.options.yes")}
            votes={ethers.formatEther(currentVotes?.forVotes).toString()}
          />
          <Option
            disabled={!canUserVote}
            onClick={() => onVote("no")}
            accountHasVoted={accountHasVoted}
            totalStakedAmount={totalStakedAmount}
            votingPower={votingPower}
            title={t("brands.vote.votingResultsPanel.options.no")}
            votes={ethers.formatEther(currentVotes?.againstVotes).toString()}
          />
          <Option
            disabled={!canUserVote}
            onClick={() => onVote("abstain")}
            accountHasVoted={accountHasVoted}
            totalStakedAmount={totalStakedAmount}
            votingPower={votingPower}
            title={t("brands.vote.votingResultsPanel.options.abstain")}
            votes={ethers.formatEther(currentVotes?.abstainVotes).toString()}
          />
        </Box>
      )}
      <Box
        sx={{
          display: { md: "flex", mobile: "row" },
          justifyContent: "space-between",
        }}
      >
        <VotingEstimates
          setExecutionDateTime={setExecutionDateTime}
          proposalState={proposalState}
          DAO={DAO}
          proposal={proposal}
        />
        {!accountHasVoted &&
          proposalState !== "Executed" &&
          proposalState !== "Defeated" && (
            <Box>
              {i18n.language === "en" && (
                <Typography
                  variant={"subtitle3"}
                  sx={{
                    mt: { md: "0px", mobile: "10px" },
                  }}
                >
                  You have {votingPower}{" "}
                  {votingPower.toString() === "1.0"
                    ? t("commons.vote")
                    : t("commons.votes")}
                </Typography>
              )}
              {i18n.language === "sl" && (
                <Typography
                  variant={"subtitle3"}
                  sx={{
                    mt: { md: "0px", mobile: "10px" },
                  }}
                >
                  {t("brands.vote.votingResultsPanel.youHaveVotingPower", {
                    votingPower,
                  })}
                </Typography>
              )}
            </Box>
          )}
        {accountHasVoted &&
          proposalState !== "Executed" &&
          proposalState !== "Defeated" && (
            <Typography
              variant={"subtitle3"}
              sx={{
                mt: { md: "0px", mobile: "10px" },
              }}
            >
              {t("brands.vote.votingResultsPanel.alreadyVoted")}
            </Typography>
          )}
        {proposalState === "Executed" && (
          <Typography
            variant={"subtitle3"}
          >
            {t("brands.vote.votingResultsPanel.voteExecutedOn", {
              date: moment(proposal?.datetime_completion).format(
                "DD MMM YYYY HH:mm"
              ),
            })}
          </Typography>
        )}
        {proposalState === "Defeated" && (
          <Typography
            variant={"subtitle3"}
          >
            {t("brands.vote.votingResultsPanel.voteExpiredOn", {
              date: moment(proposal?.datetime_completion).format(
                "DD MMM YYYY HH:mm"
              ),
            })}
          </Typography>
        )}
      </Box>
      {proposalState === "Succeeded" && !hideQueue && (
        <Box
          sx={{
            mt: "0px",
            display: "flex",
            justifyContent: "center",
            mb: "28px",
          }}
        >
          <UI.Button
            sx={{ width: "250px", mt: { md: "10px", mobile: "24px" } }}
            onClick={async () => onQueue()}
            disabled={!isConnected || DAO?.isMember === false}
            title={t("buttons.addToQueue")}
            type={"primary"}
            startIcon={
              <Box
                component={"img"}
                src={voteWalletIcon}
                sx={{ width: "43px", height: "19px", mr: "8px" }}
              />
            }
          />
        </Box>
      )}
      {proposalState === "Queued" && !hideExecute && (
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <UI.Button
              sx={{ width: "250px", mt: "15px" }}
              onClick={async () => onExecute()}
              disabled={
                !moment().isAfter(executionDateTime) ||
                !isConnected ||
                DAO?.isMember === false
              }
              title={t("buttons.execute")}
              type={"primary"}
              startIcon={
                <Box
                  component={"img"}
                  src={voteWalletIcon}
                  sx={{ width: "43px", height: "19px", mr: "8px" }}
                />
              }
            />
          </Box>
        </Box>
      )}
      <Typography
        sx={{
          mt: { md: "10px", mobile: "24px" },
          mb: { md: "13px", mobile: "22px" },
        }}
      >
        {!isConnected && loggedInUser && (
          <Alert variant="outlined" severity="info">
            {t("brands.vote.votingResultsPanel.connectToVote")}
            <Box
              sx={{
                color: (theme) => theme.palette.text.linkBlue, 
                textDecoration: "underline",
                textDecorationColor: (theme) => theme.palette.text.linkBlue,
                cursor: "pointer",
                fontWeight: "bold",
                ml: "5px",
                "&:hover": {
                  color: (theme) => theme.palette.text.linkBlueHover,  
                  textDecorationColor: (theme) => theme.palette.text.linkBlueHover, 
                  transition: "all 0.3s ease-in-out",
                },
              }}
              onClick={() => openConnectModal()}
              component={"span"}
            >
              {t("brands.vote.votingResultsPanel.connectWallet")}
            </Box>
          </Alert>
        )}
      </Typography>
    </Box>
  );
};

export default VotingPoll;
