import Gateway from "../../../../@components/gateway";

const useService = (daoId) => {
  const gateway = Gateway.useGateway();

  const save = (data) => {
    return gateway.post(`dao/${daoId}/info/edit_payment/`, data);
  };

  return {
    save,
  };
};

export default useService;
