import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
    },
  },
  categoryItem: {
    width: "130px",
    height: "130px",
    display: "flex",
    flexDirection: "column", // Adjusted to column direction
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    background: "transparent",
    border: "0px solid transparent",
    borderRadius: theme.borderRadius.secondary,
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    position: "relative",
    "&:hover": {
      opacity: "0.6",
    },
  },
  textBox: {
    width: "100%",
    backgroundColor: theme.palette.bgTransparent.quaternary,
    padding: "8px",
    textAlign: "center",
    borderRadius: "5px",
    lineHeight: "1",
  },
}));

const Item = ({
  collection,
  contentType,
  selected,
  contentData,
  isFirstItem,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const classes = useStyles({ selected, isMobile, isFirstItem });
  const history = useHistory();
  return (
    <Box
      sx={{
        position: "relative",
        borderRadius: (theme) => theme.borderRadius.primary,
        cursor: "pointer",
      }}
      className={classes.categoryItem}
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/profile/nft/${collection?.id}`);
      }}
    >
      {contentType?.startsWith("video") && (
        <Box sx={{
          width: "100%",
          height: "100%",
          borderRadius: "5px",
        }}>
          <video playsInline width="100%" height={"100%"} autoPlay loop muted>
            <source src={contentData} />
          </video>
        </Box>
      )}
      {contentType?.startsWith("image") && (
        <Box
          component={"img"}
          src={contentData}
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "5px",
          }}
        />
      )}
    </Box>
  );
};

export default Item;
