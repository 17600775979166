import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Notifications from "../notifications";
import { useTranslation } from "react-i18next";

const NotificationsMenu = ({ anchorEl, handleClose }) => {
  const { t } = useTranslation();
  //const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 360,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            pt: "4px",
            pb: "6px",
            backgroundColor: (theme) => theme.palette.dialog.background,
            backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
            "& .MuiAvatar-root": {
              width: 56,
              height: 56,
              ml: "8px",
              mr: "15px",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ p: "20px 20px 20px 24px", fontSize: "18px" }}>
            {t("profile.dialogs.notificationsDialog.title")}
          </Typography>
          <MoreVertIcon sx={{ mr: "16px", fontSize: "20px !important", cursor: "pointer", mb: "4px" }} />
        </Box>
        <Box
          sx={{
            maxHeight: "calc(100vh - 150px)",
            overflowY: "auto",
            scrollbarWidth: "none", // Firefox
            "&::-webkit-scrollbar": {
              display: "none", // WebKit browsers
            },
            pb: "20px",
            px: "20px",
          }}
        >
          <Notifications />
        </Box>
      </Menu>
    </>
  );
};

export default NotificationsMenu;
