import React from "react";
import UI from "../../../../../@components/UI";
import useVote from "../useVote";
import useQueue from "../useQueue";
import useExecute from "../useExecute";
import { useSnackbar } from "notistack";
import useProposalService from "../../../proposal/useService";
import { useMenu } from "../../../context";
import { ethers } from "ethers";
import governorArtifact from "../../../../../abis/contracts/modules/governor/Governor.sol/DaoGovernor.json";
import timelockArtifact from "../../../../../abis/contracts/modules/timelock/Timelock.sol/Timelock.json";
import { handleVote } from "../../../../../qvrse/vote/@id/utils";
import customHooks from "../../../../../@components/hooks";
import { useTranslation } from "react-i18next";

const VotingPanel = ({ DAO, proposal, onChange }) => {
  const { t } = useTranslation();
  const signer = customHooks.useEthersSigner();
  const { enqueueSnackbar } = useSnackbar();
  const { updateMenu } = useMenu();
  const proposalService = useProposalService(DAO?.id);
  const [isLoading, setIsLoading] = React.useState(false);

  const { votingPower } = customHooks.useBlockchainState(DAO, proposal);
  const { vote } = useVote(DAO);
  const { queue } = useQueue(DAO);
  const { execute } = useExecute(DAO);

  const updateProposalStatus = async () => {
    await proposalService
      .setProposalExecuted(proposal.id)
      .then(() => {
        updateMenu();
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(error?.message, { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addEventListeners = () => {
    const governor = new ethers.Contract(
      DAO?.governor_contract?.address,
      governorArtifact.abi,
      signer
    );

    const timelock = new ethers.Contract(
      DAO?.timelock_contract?.address,
      timelockArtifact.abi,
      signer
    );
    proposal?.data?.events?.forEach((data) => {
      if (data.sourceContract === "governor") {
        governor.on(data.event, async (response) => {
          enqueueSnackbar(data.event, {
            variant: "success",
          });
          await updateProposalStatus();
          removeEventListeners();
        });
      } else if (data.sourceContract === "timelock") {
        timelock.on(data.event, async (response) => {
          enqueueSnackbar(data.event, {
            variant: "success",
          });
          await updateProposalStatus();
          removeEventListeners();
        });
      }
    });
  };

  const removeEventListeners = () => {
    const governor = new ethers.Contract(
      DAO?.governor_contract?.address,
      governorArtifact.abi,
      signer
    );

    const timelock = new ethers.Contract(
      DAO?.timelock_contract?.address,
      timelockArtifact.abi,
      signer
    );
    proposal?.data?.events?.forEach((data) => {
      if (data.sourceContract === "governor") {
        governor.removeAllListeners();
      } else if (data.sourceContract === "timelock") {
        timelock.removeAllListeners();
      }
    });
  };

  React.useEffect(() => {
    return () => {
      removeEventListeners();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <UI.Busy.FullscreenIndicator show={isLoading} />
      <UI.VotingPoll
        DAO={DAO}
        proposal={proposal}
        onVote={async (option) => {
          setIsLoading(true);
          await handleVote(option, { voteFunc: vote, proposal, votingPower })
            .then(async (response) => {
              setIsLoading(false);
              enqueueSnackbar(t("brands.vote.votingResultsPanel.snackbar"), {
                variant: "success",
              });
              onChange(response);
            })
            .catch((reason) => {
              setIsLoading(false);
            });
        }}
        onQueue={async () => {
          setIsLoading(true);
          queue(proposal)
            .then((response) => {
              onChange(response);
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        }}
        onExecute={async () => {
          addEventListeners();
          setIsLoading(true);
          execute(proposal)
            .then((response) => {
              onChange(response);
            })
            .catch(() => {
              removeEventListeners();
              setIsLoading(false);
            });
        }}
      />
    </React.Fragment>
  );
};

export default VotingPanel;
