import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import UI from "../../../@components/UI";
import { useHistory, useLocation } from "react-router-dom";

import List from "./List";
import VotingPower from "./VotingPower";
import Add from "./Add";
import { useAccount } from "wagmi";
import { useUserContext } from "../../../@components/userContext";
import CustomHooks from "../../../@components/hooks";
import { useUserRole } from "../context";
import { useTranslation } from "react-i18next";

const Index = ({ DAO }) => {
  const { t } = useTranslation();
  const { user: loggedInUser } = useUserContext();
  const { isBrandMember } = useUserRole();
  const location = useLocation();
  const history = useHistory();
  const { isConnected } = useAccount();

  CustomHooks.useProtectedRoute(DAO, isBrandMember);

  let query = CustomHooks.useQuery();

  const [emailRedirectDialogText, setEmailRedirectDialogText] =
    React.useState(null);

  React.useEffect(() => {
    const email_redirect = query.get("email_redirect");
    if (email_redirect) setEmailRedirectDialogText(email_redirect);
  }, [query]);

  return (
    <>
      {emailRedirectDialogText && (
        <UI.InfoDialog
          contentText={emailRedirectDialogText}
          onClose={() => {
            setEmailRedirectDialogText(null);
            history.push(`${location.pathname}`);
          }}
          onConfirm={() => {
            setEmailRedirectDialogText(null);
            history.push(`${location.pathname}`);
          }}
        />
      )}
      {DAO && (
        <Paper elevation={0} sx={{ width: "100%", background: "transparent !important" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              px: { md: "0px", mobile: "10px" },
              pb: "50px",
              pt: { md: "40px", mobile: "40px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: { mobile: "center", md: "center" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "178px",
                }}
              >
                <UI.Button
                  disabled={
                    !isConnected ||
                    !loggedInUser?.email_verified ||
                    !isBrandMember
                  }
                  onClick={() => history.push(`${location.pathname}/add`)}
                  title={t("buttons.addMember")}
                  type={"primary"}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: { md: "0px 0px", mobile: "0px 0px" },
            }}
          >
            <List DAO={DAO} />
          </Box>
        </Paper>
      )}
    </>
  );
};

Index.List = List;
Index.VotingPower = VotingPower;
Index.Add = Add;

export default Index;
