import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import "./styles.css";
import { EffectCards } from "swiper/modules";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../index.js";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const Item = ({ disableClick, theme, item }) => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  item?.nft_file_type === "" && (item.nft_file_type = "image/png");
  return (
    <Box
      key={item.id}
      onClick={() => {
        if (disableClick) {
          return;
        }
        history.push({
          pathname: `/brand/${item?.dao_id}/collection/${item?.id}`,
          state: { scrollToTop: true },
        });
      }}
      sx={{
        background: theme ? theme.card_background_color : "",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          borderTop: "2px solid #944DFF",
          borderLeft: "2px solid #944DFF",
          borderRight: "2px solid #944DFF",
          borderRadius: "8px 8px 0 0",
          height: "48px",
          backgroundColor: (theme) => theme.palette.colors.lightGray,
          alignItems: "center",
        }}
      >
        <Typography
          variant={"h9"}
          sx={{
            color: theme ? theme.card_text_color : "",
            p: "8px",
            maxWidth: "185.4px",
          }}
        >
          <UI.OverflowTip>
            {item?.name_slo !== null &&
            item?.name_slo !== "" &&
            i18n.language === "sl"
              ? item?.name_slo
              : item?.name || ""}
          </UI.OverflowTip>
        </Typography>
      </Box>
      {item.nft_file_type.startsWith("image") && (
        <Box
          sx={{
            position: "relative",
            height: "200px",
            width: "auto",
            maxHeight: "100%",
            maxWidth: "100%",
            overflow: "hidden",
            background:
              "linear-gradient(180deg, rgb(8 10 25 / 0%) 54.17%, rgb(8 10 25 / 68%) 100%)",
            borderLeft: "2px solid #944DFF",
            borderRight: "2px solid #944DFF",
          }}
        >
          <UI.ImageWithSkeleton
            sx={{
              inset: "0px",
              boxSizing: "border-box",
              padding: "0px",
              border: "none",
              margin: "auto",
              display: "block",
              width: "0px",
              height: "0px",
              minWidth: "100%",
              maxWidth: "100%",
              minHeight: "100%",
              maxHeight: "100%",
              objectFit: "cover",
              transition: "all 0.3s ease-in-out",
            }}
            src={item.nft_image_url}
          />
        </Box>
      )}
      {item.nft_file_type.startsWith("video") && (
        <Box
          sx={{
            position: "relative",
            height: "200px",
            width: "auto",
            maxHeight: "100%",
            maxWidth: "100%",
            overflow: "hidden",
            borderRadius: "0rem",
            background:
              "linear-gradient(180deg, rgb(8 10 25 / 0%) 54.17%, rgb(8 10 25 / 68%) 100%)",
            borderLeft: "2px solid #944DFF",
            borderRight: "2px solid #944DFF",
          }}
        >
          <Box
            sx={{
              inset: "0px",
              boxSizing: "border-box",
              padding: "0px",
              border: "none",
              margin: "auto",
              display: "block",
              width: "0px",
              height: "0px",
              minWidth: "100%",
              maxWidth: "100%",
              minHeight: "100%",
              maxHeight: "100%",
              objectFit: "cover",
              transition: "all 0.3s ease-in-out",
            }}
          >
            <video
              playsInline
              width={isMobile ? "fit-content" : "100%"}
              height={isMobile ? "100%" : "auto"}
              autoplay="autoplay"
              loop
              muted
            >
              <source src={item.nft_image_url} />
            </video>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          borderLeft: "2px solid #944DFF",
          borderRight: "2px solid #944DFF",
          borderBottom: "2px solid #944DFF",
          borderRadius: "0 0 8px 8px",
          height: "48px",
          backgroundColor: (theme) => theme.palette.colors.lightGray,
          alignItems: "center",
        }}
      >
        {item.nft_amount_limit < 999999 && (
          <Typography
            variant={"h9"}
            sx={{
              color: theme ? theme.card_text_color : "",
              p: "8px",
              maxWidth: "185.4px",
            }}
          >
            <UI.OverflowTip>{`${item.nft_amount_minted} / ${item.nft_amount_limit}`}</UI.OverflowTip>
          </Typography>
        )}
        {item.nft_amount_limit >= 999999 && (
          <Typography
            variant={"h9"}
            sx={{
              color: theme ? theme.card_text_color : "",
              p: "8px",
              maxWidth: "185.4px",
            }}
          >
            <AllInclusiveIcon />
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default function App({
  disableClick,
  theme,
  onSlideChange,
  items,
  from = 0,
  to = 5,
}) {
  return (
    <>
      <Swiper
        onSlideChange={onSlideChange}
        effect={"cards"}
        grabCursor={true}
        preventClicks={false}
        preventClicksPropagation={false}
        modules={[EffectCards]}
        className="mobileSwiper"
      >
        {items?.slice(from, to).map((item, index) => (
          <SwiperSlide key={"mobile_swiper_" + index}>
            <Item disableClick={disableClick} theme={theme} item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
