import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import UI from "../../../@components/UI";
import { useHistory, useLocation } from "react-router-dom";
import Explore from "./explore";
import Select from "./Select";
import Create from "./Create";
import Import from "./import";
import { useUserContext } from "../../../@components/userContext";
import { useUserRole } from "../context";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const Index = ({ DAO }) => {
  const { t } = useTranslation();
  const { user: loggedInUser } = useUserContext();
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { isBrandMember } = useUserRole();

  return (
    <Paper elevation={0} sx={{ width: "100%", background: "transparent" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          px: { md: "0px", mobile: "10px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          {isBrandMember && isMobile && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                px: { md: "0px", mobile: "10px" },
                pb: { md: "35px", mobile: "22px" },
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "270px" }}>
                  <UI.Button
                    onClick={() =>
                      history.push({
                        pathname: `${location.pathname}/new`,
                        state: { scrollToTop: true },
                      })
                    }
                    title={t("brands.collection.createButton")}
                    type={"primary"}
                  />
                </Box>
              </Box>
            </Box>
          )}
          {isBrandMember && (
            <>
              <Box
                sx={{
                  width: "230px",
                  display: { md: "flex", mobile: "none" },
                  mt: { md: "0px", mobile: "20px" },
                  mr: { md: "15px", mobile: "0px" },
                }}
              >
                <UI.Button
                  disabled={!loggedInUser?.email_verified}
                  onClick={() =>
                    history.push({
                      pathname: `${location.pathname}/new`,
                      state: { scrollToTop: true },
                    })
                  }
                  title={t("brands.collection.createButton")}
                  type={"primary"}
                />
              </Box>
              <Box
                sx={{
                  width: "130px",
                  display: { md: "flex", mobile: "none" },
                  mt: { md: "0px", mobile: "20px" },
                }}
              >
                <UI.Button
                  disabled={!loggedInUser?.email_verified}
                  onClick={() =>
                    history.push({
                      pathname: `${location.pathname}/import`,
                      state: { scrollToTop: true },
                    })
                  }
                  title={t("commons.import")}
                  type={"secondary"}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
      {DAO && (
        <Box
          sx={{
            width: "100%",
            padding: { md: "0px 0px", mobile: "0px 0px" },
          }}
        >
          <Explore DAO={DAO} />
        </Box>
      )}
    </Paper>
  );
};

Index.Create = Create;
Index.Import = Import;
Index.Select = Select;

export default Index;
