import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UI from "../../../../../../../@components/UI";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import { useUserContext } from "../../../../../../../@components/userContext";
import useService from "../../../../useService";
import CustomHooks from "../../../../../../../@components/hooks";
import Alert from "@mui/material/Alert";

const Animation = ({ DAO }) => {
  const { albumId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();
  const { user: loggedInUser } = useUserContext();
  const service = useService(DAO?.id);

  const { mutate: createAnimation } = CustomHooks.usePost(service.newCard, {
    onSuccess: () => {
      enqueueSnackbar(t("brands.albums.cards.snackbar"), {
        variant: "success",
      });
      history.push({
        pathname: `/brand/${DAO?.id}/album/${albumId}/edit`,
        state: { scrollToTop: true },
      });
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      preview_image: "",
      animation_url: "",
    },
  });

  const onSubmit = async (form) => {
    const data = {
      name: "Animation",
      album_space_type: "Animation",
      data_json: {
        preview_image_url: form.preview_image.ipfs,
        animation_url: form.animation_url,
      },
    };

    await createAnimation({ albumId: albumId, data: data });
  };
  return (
    <Paper
      sx={{ width: "100%", backgroundColor: (theme) => theme.palette.bgTransparent.primary, }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: "100%",
            px: { md: "80px", mobile: "10px" },
            pb: "35px",
            pt: { md: "55px", mobile: "30px" },
          }}
        >
          <UI.BackButton
            title={t("brands.albums.backToAlbum")}
            onClick={() => history.go(-1)}
          />

          <Typography
            variant={"h3"}
            sx={{
              mb: "25px",
              mt: "30px",
            }}
          >
            {t("brands.albums.createCard.animation.createAnimationCard")}
          </Typography>
          <Controller
            name={"preview_image"}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <UI.Upload
                noHeight
                noPadding
                {...field}
                id={"images"}
                title={t("brands.albums.createCard.animation.upload.title")}
                subtitle={t(
                  "brands.collection.create.basicInfo.uploadPhoto.subtitle"
                )}
                acceptFiles={"image/jpg, image/jpeg,image/gif,image/png"}
              />
            )}
          />
          <Box sx={{ mt: "15px" }}>
            {errors.images?.type === "required" && (
              <Alert
                variant="outlined"
                severity="error"
              >
                {t("brands.albums.createCard.error.image")}
              </Alert>
            )}
          </Box>
          <Box
            sx={{
              marginBottom: "15px",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Box sx={{ display: "flex", mb: "6px", pt: "0px" }}>
              <Typography variant={"h6"}>
                {t("brands.albums.createCard.animation.animationUrl")}
              </Typography>
            </Box>
            <Controller
              name={"animation_url"}
              rules={{ required: true }}
              control={control}
              render={({ field }) => <UI.TextField {...field} />}
            />
            <Box sx={{ mt: "15px" }}>
              {errors.animation_url?.type === "required" && (
                <Alert
                  variant="outlined"
                  severity="error"
                >
                  {t("brands.albums.createCard.error.animationUrl")}
                </Alert>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            mb: "60px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "210px" }}>
            <UI.Button
              disabled={
                !!Object.keys(errors).length || !loggedInUser?.email_verified
              }
              title={t("brands.albums.createCard.button.createCard")}
              type={"primary"}
            />
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default Animation;
