import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import moment from "moment";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const AccordionItem = (props) => {
  const type = props?.type;
  let displayValue = props?.value;
  console.log("displayValue: ", displayValue);
  if (type === "date") {
    displayValue = moment(props?.value).format("DD MMM YYYY");
  } else if (type === "time") {
    displayValue = moment(props?.value).format("HH:mm");
  }
  return (
    <Box sx={{ display: "flex", mb: "10px" }}>
      <KeyboardArrowRightIcon
        sx={{
          display: "flex",
          mt: "-1px",
          mr: { md: "4px", mobile: "1px" },
          color: "#944DFF",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <Typography variant={"subtitle2"}>{`${props?.title}${
            displayValue !== "" ? ":" : ""
          } `}</Typography>
        </Box>
        {
          <Box>
            <Typography
              variant={"h7"}
              sx={{
                overflowWrap: "anywhere",
                textTransform: "unset",
              }}
            >
              {displayValue}
            </Typography>
          </Box>
        }
      </Box>
    </Box>
  );
};

export default AccordionItem;
