import Billing from "./billing";
import General from "./general";
import Governor from "./governor";
import Payment from "./payment";
import Plugin from "./plugin";

const Settings = {
  Billing,
  General,
  Governor,
  Payment,
  Plugin,
};

export default Settings;
