import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import List from "./List";
import { useUserRole } from "../context";
import CustomHooks from "../../../@components/hooks";
import WarningIcon from "@mui/icons-material/Warning";
import Typography from "@mui/material/Typography";
import useService from "../proposal/useService";
import { useTranslation } from "react-i18next";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: "34px" }}>{children}</Box>}
    </div>
  );
}

const Index = ({ DAO }) => {
  const { t } = useTranslation();
  const service = useService(DAO?.id);
  const [value, setValue] = React.useState(2);
  const { isBrandMember } = useUserRole();
  const [hasPendingItems, setPendingItems] = React.useState(false);
  CustomHooks.useProtectedRoute(DAO, isBrandMember);

  React.useEffect(() => {
    async function fetchData() {
      await service.proposals(["Succeeded", "Queued"]).then((response) => {
        let items = response.data.results;
        if (items.length > 0) {
          setPendingItems(true);
        }
      });
    }

    DAO && fetchData();
    // eslint-disable-next-line
  }, [DAO]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Paper elevation={0} sx={{ width: "100%", background: "transparent" }}>
      <Box
        sx={{
          width: "100%",
          padding: { md: "40px 70px", mobile: "40px 15px" },
        }}
      >
        <Box sx={{ display: { md: "block", mobile: "none" } }}>
          <Tabs
            TabIndicatorProps={{
              sx: {
                display: "none",
              },
            }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs"
          >
            <Tab
              label={t("brands.vote.tabs.all")}
              sx={{
                borderRadius: "100px",
                height: "34px !important",
                minHeight: "34px",
                fontSize: { mobile: "12px !important", md: "13px !important" },
                padding: { md: "12px 20px", mobile: "10px 14px" },
                mr: { md: "20px", mobile: "14px" },
                "&.MuiTab-root.Mui-selected": {
                  backgroundColor: (theme) => theme.palette.colors.primary,
                },
              }}
            />
            <Tab
              label={t("brands.vote.tabs.preVote")}
              sx={{
                borderRadius: "100px",
                height: "34px !important",
                minHeight: "34px",
                fontSize: { mobile: "12px !important", md: "13px !important" },
                padding: { md: "12px 20px", mobile: "10px 14px" },
                mr: { md: "20px", mobile: "14px" },
                "&.MuiTab-root.Mui-selected": {
                  backgroundColor: (theme) => theme.palette.colors.primary,
                },
              }}
            />
            <Tab
              label={t("brands.vote.tabs.active")}
              sx={{
                borderRadius: "100px",
                height: "34px !important",
                minHeight: "34px",
                fontSize: { mobile: "12px !important", md: "13px !important" },
                padding: { md: "12px 20px", mobile: "10px 14px" },
                mr: { md: "20px", mobile: "14px" },
                "&.MuiTab-root.Mui-selected": {
                  backgroundColor: (theme) => theme.palette.colors.primary,
                },
              }}
            />
            <Tab
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant={"h2"}
                    sx={{
                      fontSize: { mobile: "12px !important", md: "13px !important" },
                      lineHeight: "14px !important",
                    }}
                  >
                    {t("brands.vote.tabs.pending")}
                  </Typography>{" "}
                  {hasPendingItems && (
                    <WarningIcon
                      sx={{
                        ml: 1,
                        mb: "0px",
                        fontSize: "18px",
                        color: "#fac245",
                      }}
                    />
                  )}
                </Box>
              }
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "100px",
                height: "34px !important",
                minHeight: "34px",
                fontSize: { mobile: "12px !important", md: "13px !important" },
                padding: { md: "12px 20px", mobile: "10px 14px" },
                mr: { md: "20px", mobile: "14px" },
                width: "fit-content", // makes sure the width fits the content
                "&.MuiTab-root.Mui-selected": {
                  backgroundColor: (theme) => theme.palette.colors.primary,
                },
              }}
            />
            <Tab
              label={t("brands.vote.tabs.finished")}
              sx={{
                borderRadius: "100px",
                height: "34px !important",
                minHeight: "34px",
                fontSize: { mobile: "12px !important", md: "13px !important" },
                padding: { md: "12px 20px", mobile: "10px 14px" },
                "&.MuiTab-root.Mui-selected": {
                  backgroundColor: (theme) => theme.palette.colors.primary,
                },
              }}
            />
          </Tabs>
        </Box>
        <Box sx={{ display: { md: "none", mobile: "block" } }}>
          <Tabs
            TabScrollButtonProps={{
              sx: {
                display: "none !important",
              },
            }}
            TabIndicatorProps={{
              sx: {
                display: "none",
              },
            }}
            variant="scrollable"
            scrollButtons="auto"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs"
          >
            <Tab
              label={t("brands.vote.tabs.all")}
              sx={{
                borderRadius: "100px",
                height: "34px !important",
                minHeight: "34px",
                fontSize: { mobile: "12px !important", md: "13px !important" },
                padding: { md: "12px 20px", mobile: "10px 14px" },
                mr: { md: "20px", mobile: "14px" },
                "&.MuiTab-root.Mui-selected": {
                  backgroundColor: (theme) => theme.palette.colors.primary,
                },
              }}
            />
            <Tab
              label={t("brands.vote.tabs.preVote")}
              sx={{
                borderRadius: "100px",
                height: "34px !important",
                minHeight: "34px",
                fontSize: { mobile: "12px !important", md: "13px !important" },
                padding: { md: "12px 20px", mobile: "10px 14px" },
                mr: { md: "20px", mobile: "14px" },
                "&.MuiTab-root.Mui-selected": {
                  backgroundColor: (theme) => theme.palette.colors.primary,
                },
              }}
            />
            <Tab
              label={t("brands.vote.tabs.mobile.active")}
              sx={{
                borderRadius: "100px",
                height: "34px !important",
                minHeight: "34px",
                fontSize: { mobile: "12px !important", md: "13px !important" },
                padding: { md: "12px 20px", mobile: "10px 14px" },
                mr: { md: "20px", mobile: "14px" },
                "&.MuiTab-root.Mui-selected": {
                  backgroundColor: (theme) => theme.palette.colors.primary,
                },
              }}
            />
            <Tab
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant={"h2"}
                    sx={{
                      fontSize: { mobile: "12px !important", md: "13px !important" },
                      lineHeight: "14px !important",
                    }}
                  >
                    {t("brands.vote.tabs.mobile.pending")}
                  </Typography>{" "}
                  {hasPendingItems && (
                    <WarningIcon
                      sx={{
                        ml: 1,
                        mb: "0px",
                        fontSize: "18px",
                        color: "#fac245",
                      }}
                    />
                  )}
                </Box>
              }
              sx={{
                borderRadius: "100px",
                height: "34px !important",
                minHeight: "34px",
                fontSize: { mobile: "12px !important", md: "13px !important" },
                padding: { md: "12px 20px", mobile: "10px 14px" },
                mr: { md: "20px", mobile: "14px" },
                "&.MuiTab-root.Mui-selected": {
                  backgroundColor: (theme) => theme.palette.colors.primary,
                },
              }}
            />
            <Tab
              label={t("brands.vote.tabs.mobile.finished")}
              sx={{
                borderRadius: "100px",
                height: "34px !important",
                minHeight: "34px",
                fontSize: { mobile: "12px !important", md: "13px !important" },
                padding: { md: "12px 20px", mobile: "10px 14px" },
                mr: { md: "20px", mobile: "14px" },
                "&.MuiTab-root.Mui-selected": {
                  backgroundColor: (theme) => theme.palette.colors.primary,
                },
              }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <List DAO={DAO} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <List DAO={DAO} filter={["Pending"]} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <List DAO={DAO} filter={["Active"]} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <List DAO={DAO} filter={["Succeeded", "Queued"]} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <List
            DAO={DAO}
            filter={["Expired", "Executed", "Defeated", "Canceled"]}
          />
        </TabPanel>
      </Box>
    </Paper>
  );
};

Index.List = List;

export default Index;
