import React from "react";
import UI from "../../../../@components/UI";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import Collection from "../../collection";
import { useUserContext } from "../../../../@components/userContext";
import PublicIcon from '@mui/icons-material/Public';
import collectionIcon from "../../../../static/collection-icon.png";
import collectionIconDark from "../../../../static/collection-icon-dark.png";
import photoIcon from "../../../../static/select-photo-icon.png";
import videoIcon from "../../../../static/select-video-icon.png";
import pollIcon from "../../../../static/select-poll-icon.png";

import { useSnackbar } from "notistack";
import useService from "../useService";
import CustomHooks from "../../../../@components/hooks";
import { useUserRole } from "../../context";
import Poll from "./Poll";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';


const useStyles = makeStyles(() => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "0px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Create = ({ DAO }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const service = useService(DAO?.id);
  const { user: loggedInUser } = useUserContext();
  const classes = useStyles();
  const [visibility, setVisibility] = React.useState("public");
  const { isBrandMember } = useUserRole();
  CustomHooks.useProtectedRoute(DAO, isBrandMember);

  const [selectedMedia, setSelectedMedia] = React.useState(() => "photo");
  const [selectedPoll, setSelectedPoll] = React.useState(false);

  const handleMedia = (event, newSelectedMedia) => {
    setSelectedMedia(newSelectedMedia);
  };

  const { mutate: createPost, isLoading } = CustomHooks.usePost(service.save, {
    onSuccess: () => {
      enqueueSnackbar(t("brands.feed.create.snackbar"), {
        variant: "success",
      });
      history.push({
        pathname: `/brand/${DAO?.id}/feed`,
        state: { scrollToTop: true },
      });
    },
  });

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      description: "",
      files: [],
      content: {},
      is_public: true,
      collections: [],
    },
  });

  const onSubmit = async (form) => {
    let data = {};
    if (visibility === "public") {
      data.is_public = true;
    } else {
      data.is_public = false;
    }

    if (selectedMedia === "iframe") {
      data.iframe_url = form.iframe_url;
    }

    data.files = form.files;
    data.description = form.description;
    data.collections = form.collections.map((collection) => collection.id);
    data.content = form.content;
    createPost(data);
  };

  return (
    <Paper
      sx={{ width: "100%", backgroundColor: (theme) => theme.palette.bgTransparent.primary }}
    >
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle1"}>Posting to the feed ...</Typography>
        <Typography variant={"subtitle1"}>
          Please do not close this window.
        </Typography>
      </UI.Busy.FullscreenIndicator>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { md: "70px", mobile: "0px" },
            pb: "20px",
            pt: { md: "55px", mobile: "30px" },
          }}
        >
          <UI.BackButton
            title={t("brands.feed.create.backButton")}
            onClick={() =>
              history.push({
                pathname: `/brand/${DAO?.id}/feed`,
                state: { scrollToTop: true },
              })
            }
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            px: { md: "80px", mobile: "10px" },
            pb: "75px",
          }}
        >
          <Typography
            variant={"h3"}
            sx={{
              mb: "25px",
              mt: "10px",
            }}
          >
            {t("brands.feed.create.title")}
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px", pt: "0px" }}>
                <Typography variant={"h4"}>
                    {t("brands.feed.create.description.title")}
                  </Typography>
                </Box>

                <Controller
                  name={"description"}
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <UI.TextField multiline rows={5} {...field} />
                  )}
                />
                <Box sx={{ mt: "15px" }}>
                  {errors.description?.type === "required" && (
                    <Alert
                      variant="outlined"
                      severity="error"
                    >
                      {t("brands.feed.create.description.error.required")}
                    </Alert>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px", mt: "0" }}>
                <Typography variant={"h4"}>
                    {t("brands.feed.create.addToThePost.title")}
                  </Typography>
                  <UI.Tooltip>
                    {t("brands.feed.create.addToThePost.tooltip")}
                  </UI.Tooltip>
                </Box>
                <Box
                  sx={{
                    mt: "10px",
                    mb: "0px",
                    display: "flex",
                    width: { mobile: "100%", md: "unset" },
                  }}
                >
                  <ToggleButtonGroup
                    sx={{ flexGrow: { md: "unset", mobile: 2 } }}
                    value={selectedMedia}
                    exclusive
                    onChange={handleMedia}
                    aria-label="select media"
                  >
                    <ToggleButton
                      sx={{
                        padding: "0px",
                        height: "72px",
                        border: "0px",
                        flexGrow: { mobile: 1, md: "unset" },
                        justifyContent: "center",
                      }}
                      value="photo"
                      aria-label="bold"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { md: "row", mobile: "column" },
                          alignItems: "center",
                          width: { md: "160px", mobile: "auto" },
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          component={"img"}
                          sx={{
                            width: "30px",
                            height: "30px",
                            mr: { md: "9px", mobile: "0px" },
                            lineHeight: 1.5,
                          }}
                          alt={"media upload"}
                          src={photoIcon}
                        />
                        <Typography
                          variant={"h1"}
                          sx={{
                            fontSize: {
                              md: "16px !important",
                              mobile: "12px !important",
                            },
                            lineHeight: {
                              md: "unset",
                              mobile: "24px !important",
                            },
                            display: "block",
                          }}
                        >
                          {t("brands.feed.create.addToThePost.photo")}
                        </Typography>
                      </Box>
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        padding: "0px",
                        height: "72px",
                        border: "0px",
                        flexGrow: { mobile: 1, md: "unset" },
                        justifyContent: "center",
                      }}
                      value="video"
                      aria-label="italic"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { md: "row", mobile: "column" },
                          alignItems: "center",
                          width: { md: "160px", mobile: "auto" },

                          justifyContent: "center",
                        }}
                      >
                        <Box
                          component={"img"}
                          sx={{
                            width: "30px",
                            height: "30px",
                            mr: { md: "9px", mobile: "0px" },
                          }}
                          alt={"media upload"}
                          src={videoIcon}
                        />
                        <Typography
                          variant={"h1"}
                          sx={{
                            fontSize: {
                              md: "16px !important",
                              mobile: "12px !important",
                            },
                            lineHeight: {
                              md: "unset",
                              mobile: "24px !important",
                            },
                            display: "block",
                          }}
                        >
                          {t("brands.feed.create.addToThePost.video")}
                        </Typography>
                      </Box>
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        padding: "0px",
                        height: "72px",
                        border: "0px",
                        flexGrow: { mobile: 1, md: "unset" },
                        justifyContent: "center",
                      }}
                      value="iframe"
                      aria-label="italic"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { md: "row", mobile: "column" },
                          alignItems: "center",
                          width: { md: "160px", mobile: "auto" },

                          justifyContent: "center",
                        }}
                      >
                        <Box
                          component={"img"}
                          sx={{
                            width: "30px",
                            height: "25px",
                            mr: { md: "8px", mobile: "0px" },
                          }}
                          alt={"iframe content"}
                          src={videoIcon}
                        />
                        <Typography
                          variant={"h1"}
                          sx={{
                            fontSize: {
                              md: "16px !important",
                              mobile: "12px !important",
                            },
                            lineHeight: {
                              md: "unset",
                              mobile: "24px !important",
                            },
                            display: "block",
                          }}
                        >
                          {"IFRAME"}
                        </Typography>
                      </Box>
                    </ToggleButton>
                  </ToggleButtonGroup>

                  <ToggleButton
                    sx={{
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      padding: "0px",
                      height: "72px",
                      border: "0px",
                      flexGrow: { mobile: 1, md: "unset" },
                      justifyContent: "center",
                    }}
                    selected={selectedPoll}
                    onChange={() => {
                      setSelectedPoll(!selectedPoll);
                      if (selectedPoll) {
                        setValue("content", {});
                      }
                    }}
                    aria-label="underlined"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { md: "row", mobile: "column" },
                        alignItems: "center",
                        width: { md: "160px", mobile: "auto" },
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        component={"img"}
                        sx={{
                          width: "30px",
                          height: "30px",
                          mr: { md: "9px", mobile: "0px" },
                        }}
                        alt={"poll"}
                        src={pollIcon}
                      />
                      <Typography
                        variant={"h1"}
                        sx={{
                          fontSize: {
                            md: "16px !important",
                            mobile: "12px !important",
                          },
                          lineHeight: {
                            md: "unset",
                            mobile: "24px !important",
                          },
                          display: "block",
                        }}
                      >
                        {t("brands.feed.create.addToThePost.poll")}
                      </Typography>
                    </Box>
                  </ToggleButton>
                </Box>
                {selectedMedia && (
                  <Box sx={{ mt: { mb: "40px", mobile: "30px" } }}>
                    {selectedMedia === "iframe" && (
                      <>
                        <Controller
                          name={"iframe_url"}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <UI.TextField
                              placeholder={"Iframe URL"}
                              {...field}
                            />
                          )}
                        />
                      </>
                    )}
                    {selectedMedia === "photo" && (
                      <>
                        <Controller
                          name={"files"}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <UI.Dropzone
                              {...field}
                              id={"files"}
                              title={t(
                                "brands.feed.create.addToThePost.photoUpload.title"
                              )}
                              subtitle={t(
                                "brands.feed.create.addToThePost.photoUpload.subtitle"
                              )}
                              acceptFiles={"image/*"}
                            />
                          )}
                        />
                      </>
                    )}
                    {selectedMedia === "video" && (
                      <>
                        <Controller
                          name={"files"}
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => (
                            <UI.DropzoneVideo
                              {...field}
                              id={"files"}
                              title={t(
                                "brands.feed.create.addToThePost.videoUpload.title"
                              )}
                              subtitle={t(
                                "brands.feed.create.addToThePost.videoUpload.subtitle"
                              )}
                              acceptFiles={"video/*"}
                            />
                          )}
                        />
                      </>
                    )}
                  </Box>
                )}
                {selectedPoll && (
                  <Box sx={{ mt: "0px" }}>
                    <Poll
                      control={control}
                      errors={errors}
                      setValue={setValue}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px", mt: "0" }}>
                <Typography variant={"h4"}>
                    {t("commons.visibility")}
                  </Typography>
                  <UI.Tooltip>
                    <Typography
                      variant={"subtitle3"}
                    >
                      {t("brands.feed.create.visibility.public")}
                    </Typography>
                    <Typography
                      variant={"subtitle3"}
                      sx={{ mt: "8px" }}
                    >
                      {t("brands.feed.create.visibility.collections")}
                    </Typography>
                  </UI.Tooltip>
                </Box>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="visibility"
                    name="visibility"
                    value={visibility}
                    onChange={(event) => setVisibility(event.target.value)}
                  >
                    <FormControlLabel
                      value="public"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: (theme) => theme.palette.colors.primary,
                            },
                          }}
                        />
                      }
                      label={
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <PublicIcon sx={{ color: (theme) => theme.palette.text.secondary, fontSize: "20px", marginRight: "7px", }}></PublicIcon>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant={"subtitle1"}>
                              {t("commons.public")}
                            </Typography>
                          </Box>
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value="collections"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: (theme) => theme.palette.colors.primary,
                            },
                          }}
                        />
                      }
                      label={
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                             {theme.palette.type === "dark" ? 
                               <Box
                                  component={"img"}
                                  alt={"collections only visibility"}
                                  sx={{
                                    width: "22px",
                                    marginRight: "7px",
                                    opacity: "0.7",
                                  }}
                                  src={collectionIcon}
                                /> : 
                                <Box
                                  component={"img"}
                                  alt={"collections only visibility"}
                                  sx={{
                                    width: "25px",
                                    height: "18px",
                                    marginRight: "7px",
                                    opacity: "0.7",
                                  }}
                                  src={collectionIconDark}
                                />
                              }
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant={"subtitle1"}>
                              {t("commons.collections")}
                            </Typography>
                          </Box>
                        </Box>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
            {visibility === "collections" && (
              <Grid item md={6} mobile={12}>
                <Box sx={{ mt: "0px" }} className={classes.inputOuter}>
                  <Controller
                    name={"collections"}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Collection.Select {...field} DAO={DAO} />
                    )}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
          <Box
            sx={{
              width: "100%",
              mt: { mb: "50px", mobile: "30px" },
              display: "flex",
              justifyContent: "center",
              pb: { md: "0px", mobile: "0px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: "210px" }}>
                <UI.Button
                  disabled={!isBrandMember || !loggedInUser?.email_verified}
                  sx={{
                    filter:
                      "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
                  }}
                  title={t("buttons.createPost")}
                  type={"primary"}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default Create;
