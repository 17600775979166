import React from "react";
import Box from "@mui/material/Box";
import UI from "../../../@components/UI";
import Typography from "@mui/material/Typography";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import useService from "./useService";
import { useSnackbar } from "notistack";
import DeleteDialog from "./DeleteDialog";
import SkeletonList from "./CollectionSkeletonList";
import CustomHooks from "../../../@components/hooks";
import draftIcon from "../../../static/draft-icon.png";
import { useTranslation } from "react-i18next";

const Item = ({ item, index, onRemove, onClick }) => {
  return (
    <Box
      onClick={() => onClick(item, index + 1)}
      sx={{
        width: "140px",
        borderRadius: (theme) => theme.borderRadius.primary,
        mr: { md: "30px", mobile: "20px" },
        mt: "30px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        transition: "all  0.3s ease-in-out",
        "&:hover": {
          transform: "scale(1.1)",
        },
        "&:hover .removeIcon": {
          display: "block",
          transition: "all 0.1s ease 0s",
        },
      }}
    >
      <HighlightOffIcon
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          onRemove(item?.id);
        }}
        className={"removeIcon"}
        sx={{
          display: "none",
          position: "absolute",
          top: "-20px",
          right: "-20px",
          zIndex: 999,
          color: "white",
          cursor: "pointer",
          "&:hover": {
            color: (theme) => theme.palette.colors.darkRed,
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <Box
        component={"img"}
        src={draftIcon}
        sx={{
          position: "absolute",
          bottom: "70px",
          right: "5px",
          width: "35px",
          height: "35px",
          zIndex: 2,
        }}
      />
      <Box
        sx={{
          cursor: "pointer",
        }}
        onClick={() => onClick(item, index + 1)}
      >
        <Box
          sx={{
            position: "relative",
            width: "140px",
            borderRadius: (theme) => theme.borderRadius.primary,
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            height: "140px",
            overflow: "hidden",
            border: "2px solid transparent",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              boxShadow: "#944dff 0px 0px 15px 0px",
              border: "2px solid #944dff",
              transform: "scale(1.05)",
              transition: "all 0.3s ease-in-out",
            },
          }}
        >
          {item?.data?.basic_info?.picture_url?.file?.type?.startsWith(
            "image"
          ) && (
            <UI.ImageWithSkeleton
              sx={{
                position: "absolute",
                width: "140px",
                borderRadius: (theme) => theme.borderRadius.primary,
                height: "140px",
                textAlign: "center",
                objectFit: "cover",
              }}
              width={140}
              src={item?.data?.basic_info?.picture_url?.ipfs || null}
              alt={"dao avatar"}
            />
          )}
          {item?.data?.basic_info?.picture_url?.file?.type?.startsWith(
            "video"
          ) && (
            <Box
              sx={{
                width: "140px",
                height: "140px",
                borderRadius: (theme) => theme.borderRadius.primary,
                overflow: "hidden",
              }}
            >
              <video
                playsInline
                style={{
                  width: "100%",
                  height: "100%",
                  textAlign: "center",
                  objectFit: "cover",
                  color: "transparent",
                  textIndent: "10000px",
                }}
                autoplay="autoplay"
                loop
                muted
              >
                <source
                  src={item?.data?.basic_info?.picture_url?.ipfs || null}
                />
              </video>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            mt: "12px",
            justifyContent: "center",
            textAlign: "center",
            height: "50px",
            overflow: "hidden",
          }}
        >
          <Typography
            variant={"h8"}
            sx={{
              margin: "0 auto",
              maxWidth: "320px",
              whiteSpace: "wrap",
            }}
          >
            {item?.data?.basic_info?.name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const CollectionList = ({ draftType = "applyForDao", onClick }) => {
  const { t } = useTranslation();
  const service = useService();
  const { enqueueSnackbar } = useSnackbar();
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const {
    data: drafts,
    isLoading,
    refetch: refetchDrafts,
  } = CustomHooks.useFetch(["drafts"], service.list, {
    select: (response) => {
      return response.filter((item) => item.draft_type.startsWith(draftType));
    },
  });

  const { mutate: removeDraft } = CustomHooks.usePost(service.remove, {
    onSuccess: () => {
      enqueueSnackbar("Draft was successfully deleted", {
        variant: "success",
      });
      refetchDrafts();
    },
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: { mobile: "center", md: "left" },
          flexWrap: { md: "wrap", mobile: "wrap" },
        }}
      >
        {isLoading && <SkeletonList />}
        {!isLoading &&
          drafts?.length > 0 &&
          drafts.map((item, index) => (
            <Item
              key={item.id}
              index={index}
              item={item}
              onRemove={(draftId) => setShowDeleteDialog(draftId)}
              onClick={(item, index) => onClick(item, index)}
            />
          ))}
      </Box>
      {drafts?.length === 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <UI.NoResults text={t("drafts.notFound")} />
          </Box>
        </Box>
      )}
      {showDeleteDialog && (
        <DeleteDialog
          onConfirm={() => {
            removeDraft(showDeleteDialog);
            setShowDeleteDialog(false);
          }}
          onClose={() => setShowDeleteDialog(false)}
        />
      )}
    </>
  );
};

export default CollectionList;
