import React from "react";
import Box from "@mui/material/Box";
import backArrow from "../../static/back-arrow.png";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import UI from "./index";
import truncateEthAddress from "truncate-eth-address";

const useStyles = makeStyles((theme) => ({
  padding: {
    display: "flex",
    justifyContent: "flex-start",
  },
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },
}));

const BackButton = (props) => {
  const { title, onClick, address, fontSize, ...other } = props;
  const classes = useStyles();
  return (
    <Box className={classes.padding} {...other}>
      <Box className={classes.backButtonOuter} sx={{ display: "flex" }}>
        <Box
          onClick={onClick}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            ml: "10px",
          }}
        >
          <img
            className={"backArrow"}
            style={{ transition: "0.4s ease-in" }}
            src={backArrow}
            alt={"back-arrow"}
            width={"13px"}
            height={"13px"}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: { md: "row", mobile: "column" },
        }}
      >
        <Box sx={{ display: { md: "flex", mobile: "none" }, alignItems: "center" }}>
          <Typography
            variant={"h9"}
            sx={{
              ml: "8px",
              mt: "2px",
              fontSize: "13px !important",
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box sx={{ display: { md: "none", mobile: "block" } }}>
          <Typography
              variant={"h9"}
              sx={{
                ml: "8px",
                mt: "2px",
                fontSize: "13px !important",
                color: (theme) => theme.palette.text.secondary,
              }}
            >
            {title}
          </Typography>
        </Box>

        {address && (
          <Box
            sx={{
              ml: { md: "16px", mobile: "15px" },
              display: "flex",
              alignItems: "center",
            }}
          >
            <UI.CopyToClipboard
              label={truncateEthAddress(address)}
              value={address}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BackButton;
