import Gateway from "../../@components/gateway";

const useService = () => {
  const gateway = Gateway.useGateway();

  const save = (data) => {
    return gateway.post(
      `dao/${window.env.QVRSE_DAO_ID}/stake/submit_stake/`,
      data
    );
  };

  const closeStake = async (id) => {
    return gateway.post(
      `dao/${window.env.QVRSE_DAO_ID}/stake/${id}/close_stake/`
    );
  };

  const stake = async (id) => {
    return gateway.get(`dao/${window.env.QVRSE_DAO_ID}/stake/${id}/`);
  };

  const list = async () => {
    const response = await gateway.get(`dao/${window.env.QVRSE_DAO_ID}/stake/`);
    return response.data.results;
  };

  return {
    save,
    closeStake,
    stake,
    list,
  };
};

export default useService;
