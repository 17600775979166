import Tooltip from "@mui/material/Tooltip";
import InfoIcon from '@mui/icons-material/InfoTwoTone';
import Typography from "@mui/material/Typography";
import React from "react";

const QvrseTooltip = ({ children }) => {
  return (
    <Tooltip
      sx={{
        cursor: "pointer",
        fontSize: "16px",
        mt: "1px",
        ml: "7px",
        color: (theme) => theme.palette.text.tertiary,
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          color: (theme) => theme.palette.text.secondary,
        }
      }}
      title={
        <Typography variant={"subtitle4"}>
          {children}
        </Typography>
      }
      placement="top"
      arrow
    >
      <InfoIcon />
    </Tooltip>
  );
};

export default QvrseTooltip;
