import Gateway from "../@components/gateway";

const useService = () => {
  const gateway = Gateway.useGateway();

  const marketplace = async (data) => {
    const response = await gateway.get(`listings/`);
    return response.data;
  };

  const listing = async (listingId) => {
    const response = await gateway.get(`/listings/${listingId}/by_listing_id/`);
    return response.data;
  };

  return {
    marketplace,
    listing,
  };
};

export default useService;
