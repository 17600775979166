import React from "react";
import Box from "@mui/material/Box";
import useCollectionService from "../../collection/useService";
import CollectionSlider from "./CollectionSlider";
import UI from "../../../../@components/UI";
import CustomHooks from "../../../../@components/hooks";
import Busy from "../../../../@components/UI/busy";
import { useUserRole } from "../../context";
import { useTranslation } from "react-i18next";

const Index = ({ DAO }) => {
  const { t } = useTranslation();
  const { isBrandMember } = useUserRole();

  const collectionService = useCollectionService(DAO?.id);

  const {
    data: items,
    isLoading: collectionsLoading,
    refetch: fetchCollections,
  } = CustomHooks.useFetch(
    ["collections", { id: DAO?.id }],
    collectionService.list,
    {
      enabled: false,
      select: (response) => {
        // Filter the items based on the visibility condition
        const filteredItems = response.filter((item) => {
          // Return all items if user has ADMIN or MODERATOR role
          if (isBrandMember) {
            return true;
          }
          // Filter out items that have item.visible = false
          return item.visible !== false;
        });

        return filteredItems;
      },
    }
  );

  React.useEffect(() => {
    if (DAO) {
      fetchCollections();
    }
  }, [DAO, fetchCollections]);

  return (
    <>
      <Box sx={{ mt: { md: "10px", mobile: "0px" } }}>
        <>
          {!collectionsLoading && items && items.length > 0 && (
            <CollectionSlider items={items} busy={collectionsLoading} />
          )}
          {collectionsLoading && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Busy.Indicator />
            </Box>
          )}
          {!collectionsLoading && items && items.length === 0 && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <UI.NoResults text={t("infoMessages.collectionNotFound")} />
            </Box>
          )}
        </>
        {/*{isMobile && (*/}
        {/*  <>*/}
        {/*    {items && items.length !== 0 && <UI.SwiperMobile items={items} />}*/}
        {/*    {collectionsLoading && (*/}
        {/*      <Box sx={{ display: "flex", justifyContent: "center" }}>*/}
        {/*        <Busy.Indicator />*/}
        {/*      </Box>*/}
        {/*    )}*/}
        {/*    {!collectionsLoading && items && items.length === 0 && (*/}
        {/*      <Box sx={{ display: "flex", justifyContent: "center" }}>*/}
        {/*        <UI.NoResults text={t("infoMessages.collectionNotFound")} />*/}
        {/*      </Box>*/}
        {/*    )}*/}
        {/*  </>*/}
        {/*)}*/}
      </Box>
    </>
  );
};

export default Index;
