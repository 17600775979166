import React from "react";
import Box from "@mui/material/Box";
import MaterialDialog from "@mui/material/Dialog";
import {useParams} from "react-router-dom";
import useService from "../../../useService";
import CustomHooks from "../../../../../../@components/hooks";
import Lottie from "lottie-react";
import confettiAnimation from "../../../../../../static/confetti-animation.json";
import confettiAnimation2 from "../../../../../../static/confetti2.json";

const Dialog = ({albumSpace, DAO, open, onClose, data, refetch}) => {
  const {albumId} = useParams();
  const service = useService(DAO?.id);
  const confettiRef = React.useRef(null);
  const confettiRef2 = React.useRef(null);

  const {mutate: submitCard} = CustomHooks.usePost(service.submitCard, {
    onSuccess: async () => {
      await refetch();
      triggerConfetti();
    },
  });

  const triggerConfetti = () => {
    confettiRef.current?.goToAndPlay(0);
    confettiRef2.current?.goToAndPlay(0);
  };

  React.useEffect(() => {
    if (open && albumSpace.album_card.length === 0) {
      submitCard({
        album_id: albumId,
        album_space_type: albumSpace.album_space_type,
        space_id: data.id,
      });
    }

    // eslint-disable-next-line
  }, [open]);
  return (
    <>
      <MaterialDialog
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            background: "transparent",
            boxShadow: "none",
            maxWidth: "max-content",
          },
        }}
        PaperProps={{
          maxWidth: "1200px",
        }}
        open={open}
        onClose={onClose}
      >
        <Box
          component={"video"}
          controls
          autoPlay
          sx={{
            maxHeight: {md: "600px", mobile: "auto"},
            width: {md: "auto", mobile: "100%"},
            margin: "0 auto",
          }}
        >
          <source src={data.data_json.video_url}/>
        </Box>
      </MaterialDialog>
      <Lottie
        lottieRef={confettiRef}
        autoplay={false}
        loop={false}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          pointerEvents: "none",
        }}
        resizeMode="cover"
        animationData={confettiAnimation}
      />
      <Lottie
        lottieRef={confettiRef2}
        autoplay={false}
        loop={false}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          pointerEvents: "none",
        }}
        resizeMode="cover"
        animationData={confettiAnimation2}
      />
    </>
  );
};

const Action = ({element: Component, data, DAO, albumSpace, refetch}) => {
  const [open, setOpen] = React.useState(null);

  return (
    <>
      <Box
        sx={{cursor: "pointer"}}
        component={"span"}
        onClick={() => setOpen(!open)}
      >
        {Component}
      </Box>

      <Dialog
        refetch={refetch}
        DAO={DAO}
        open={open}
        albumSpace={albumSpace}
        onClose={() => setOpen(false)}
        data={data}
      />
    </>
  );
};

Dialog.Action = Action;

export default Dialog;
