import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Collections from "./collections";
import Cards from "./cards";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/BorderColor";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Edit from "./edit";
import useService from "../useService";
import CustomHooks from "../../../../@components/hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { MultiBackend, Preview, DndProvider } from "react-dnd-multi-backend";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Skeleton from "@mui/material/Skeleton";
import { useUserRole } from "../../context";
import HomeIcon from "@mui/icons-material/Home";
import { Breadcrumbs } from "@mui/material";

const IndexComponent = ({ DAO }) => {
  const { albumId } = useParams();
  const service = useService(DAO?.id);
  const { isBrandMember } = useUserRole();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [collections, setCollections] = useState([]);
  const [isSticky, setIsSticky] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [page, setPage] = useState(0);
  const [showMore, setShowMore] = useState(false);

  const [setIsLoading] = useState(true);

  const maxItems = isMobile ? 4 : 10;

  // Effect to handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      const sticky = window.scrollY > 0;
      setIsSticky(sticky);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { data: album, refetch: fetchAlbum } = CustomHooks.useFetch(
    ["album"],
    () => service.album(albumId),
    {
      enabled: false,
      onSuccess: () => setIsLoading(false),
      cacheTime: 0,
    }
  );

  const {
    mutate: fetchMissingCollections,
    isLoading: loadingMissingCollections,
  } = CustomHooks.usePost(service.missingCollections, {
    onSuccess: (response) => {
      //map every collection id to collection_id and add property not_owned=true
      response = response.map((collection) => {
        return {
          ...collection,
          collection_id: collection.id,
          not_owned: true,
        };
      });
      setCollections((prev) => [...prev, ...response]);
    },
  });

  const {
    data: allFetchedCollections,
    refetch,
    isLoading: loadingOwnedCollections,
  } = CustomHooks.useFetch(["collection"], () => service.collections(albumId), {
    enabled: false,
  });

  useEffect(() => {
    if (DAO) {
      refetch(albumId);
      fetchAlbum(albumId);
    }
    // eslint-disable-next-line
  }, [DAO]);

  const filterCollections = async (collectionIds) => {
    // Filter collections that are present in allFetchedCollections
    const filteredCollections = allFetchedCollections.filter((collection) =>
      collectionIds.includes(collection.collection_id)
    );

    // Find the collectionIds that are not present in allFetchedCollections
    const missingCollectionIds = collectionIds.filter(
      (id) =>
        !allFetchedCollections.some(
          (collection) => collection.collection_id === id
        )
    );

    if (missingCollectionIds.length > 0) {
      await fetchMissingCollections({
        ids: missingCollectionIds,
      });
    }

    // Combine the filtered and fetched collections
    const combinedCollections = [...filteredCollections];
    // Update the state with combined collections
    if (filteredCollections.length > 0) {
      setCollections(combinedCollections);
    } else {
      setCollections([]);
    }
  };

  const generatePreview = ({ item, style }) => {
    const { index, nft: collection } = item;

    return (
      <Box style={{ ...style, zIndex: 999 }}>
        <Collections.Slider.Item
          cardType={collection.collection_id}
          selected={true}
          onSelect={() => { }}
          collection={collection}
          contentType={collection.nft_file_type}
          contentData={collection.nft_image_url}
          index={index}
        />
      </Box>
    );
  };

  const renderDescription = () => {
    if (!album?.description) return null;
    const description = album.description;
    if (!isMobile) {
      if (description.length > 300 && !showMore) {
        return description.slice(0, 250) + "...";
      }
      return description;
    }
    if (description.length > 90 && !showMore) {
      return description.slice(0, 90) + "...";
    }
    return description;
  };

  return (
    <Box
      sx={{
        width: "100%",
        p: { mobile: "0px 10px", md: "0px" },
        mt: { md: "50px", mobile: "0px" },
      }}
    >
      <Box sx={{ mb: "20px", display: { md: "block", mobile: "none" } }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                cursor: "pointer",
                color: (theme) => theme.palette.text.primary,
              },
            }}
            onClick={() => {
              history.push(`/brand/${DAO.id}`);
            }}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Box>
          <Box
            underline="hover"
            sx={{
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                cursor: "pointer",
                color: (theme) => theme.palette.text.primary,
              },
            }}
            onClick={() => {
              history.push(`/brand/${DAO.id}/album`);
            }}
          >
            Albums
          </Box>
          {album ? (
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              {album?.name}
            </Typography>
          ) : (
            <Skeleton variant="text" width={100} />
          )}
        </Breadcrumbs>
      </Box>
      <Preview>{generatePreview}</Preview>

      <Box
        component={"img"}
        sx={{
          position: "absolute",
          top: {md: "-80px", mobile: "60px"},
          zIndex: "-1",
          left: "0",
          maxHeight: "600px",
          minHeight: "180px",
          filter: "blur(100px)",
          width: "100%",
          opacity: {md: "0.5", mobile: "0.75"},
          backgroundImage: album?.avatar_url
            ? `url(${album.avatar_url})`
            : "none",
        }}
      />

      <Box
        sx={{
          width: "100%",
          height: { mobile: "200px", md: "350px" },
          backgroundImage: album?.avatar_url
            ? `url(${album.avatar_url})`
            : "none",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mb: { mobile: "18px", md: "22px" },
          borderRadius: (theme) => theme.borderRadius.primary,
          position: "relative",
          "::before": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: "100%",
            background: "rgba(0, 0, 0, 0.35)",
            zIndex: 1, // ensure it's below the Typography
            borderRadius: (theme) => theme.borderRadius.primary,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          {album != null ? (
            <Typography variant={"h3"} sx={{ color: "#fff", zIndex: 1 }}>
              {album?.name}
            </Typography>
          ) : (
            <Skeleton
              height={"30px"}
              width={"200px"}
              variant={"rectangular"}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderRadius: (theme) => theme.borderRadius.primary,
              }}
            />
          )}
          {isBrandMember && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                width: { md: "36px", mobile: "32px" },
                height: { md: "36px", mobile: "32px" },
                ml: { md: "12px", mobile: "10px" },
                borderRadius: "50%",
                backgroundColor: "rgba(255, 255, 255, 0.3)",
                "&:hover": {
                  transform: "scale(1.05)",
                  backgroundColor: "rgba(255, 255, 255, 0.4)",
                },
                zIndex: 1,
              }}
            >
              <Tooltip title={t("actions.edit")} arrow>
                <EditIcon
                  onClick={() => {
                    history.push(`${location?.pathname}/edit`);
                  }}
                  sx={{
                    fontSize: { md: "18px", mobile: "16px" },
                    color: "#fff",
                  }}
                />
              </Tooltip>
            </Box>
          )}
        </Box>

        <Typography
          variant={"subtitle2"}
          sx={{
            color: "#fff",
            mt: 2,
            textAlign: "center",
            zIndex: 1,
            px: "80px",
            display: { md: "block", mobile: "none" },
          }}
        >
          {renderDescription()}
          {album?.description?.length > 250 && (
            <Typography
              variant={"subtitle2"}
              onClick={() => setShowMore(!showMore)}
              sx={{
                color: "#fff",
                cursor: "pointer",
                fontWeight: "bold",
                mt: "8px",
              }}
            >
              {showMore ? t("commons.showLess") : t("commons.showMore")}
            </Typography>
          )}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant={"subtitle2"}
          sx={{
            color: (theme) => theme.palette.text.primary,
            mt: 2,
            textAlign: "center",
            zIndex: 1,
            px: "20px",
            display: { md: "none", mobile: "block" },
            mb: "5px",
          }}
        >
          {renderDescription()}
        </Typography>
        {isMobile && album?.description?.length > 90 && (
          <Typography
            variant={"subtitle2"}
            onClick={() => setShowMore(!showMore)}
            sx={{
              color: (theme) => theme.palette.text.primary,
              cursor: "pointer",
              fontWeight: "bold",
              mt: "4px",
              display: "flex",
              justifyContent: "center",
              mb: "10px"
            }}
          >
            {showMore ? t("commons.showLess") : t("commons.showMore")}
          </Typography>
        )}
      </Box>
      {DAO && collections && (
        <Box
          sx={{
            mb: { mobile: "30px", md: "40px" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              px: { md: "26px", mobile: "16px" },
              py: "30px",
              mt: { md: "0px", mobile: "10px" },
              height: { md: "130px", mobile: "110px" },
              borderRadius: (theme) => theme.borderRadius.primary,
              position: isSticky ? "sticky" : "relative",
              top: isSticky ? (!isMobile ? 30 : 20) : "auto",
              zIndex: isSticky ? 1001 : 1, // Ensure it's above other content
              backgroundColor: (theme) => theme.palette.bgTransparent.secondary,
              backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
            }}
          >
            <Box
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (page >= maxItems) {
                  setPage(page - maxItems);
                }
              }}
              sx={{
                display:
                  collections?.length > maxItems && page > 0 ? "block" : "none",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "#944dff",
                position: "absolute",
                zIndex: 99999,
                left: "0px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              <ArrowBackIosIcon
                sx={{ position: "absolute", top: "8px", left: "13px" }}
              />
            </Box>
            <Collections.Slider
              page={page}
              maxItems={maxItems}
              collections={collections}
              DAO={DAO}
              isLoading={loadingMissingCollections && loadingOwnedCollections}
              onSelect={() => console.log("selected")}
            />
            <Box
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                console.log("page: ", page);
                console.log("maxItems: ", maxItems);
                if (page + maxItems < collections.length) {
                  setPage(page + maxItems);
                }
              }}
              sx={{
                display:
                  collections?.length > maxItems &&
                    page + maxItems <= collections?.length - 1
                    ? "block"
                    : "none",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "#944dff",
                position: "absolute",
                right: "0px",
                zIndex: 99999,
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              <ArrowForwardIosIcon
                sx={{ position: "absolute", top: "8px", left: "10px" }}
              />
            </Box>
          </Box>
          <Box sx={{ mt: { md: "22px", mobile: "10px" } }}>
            <Cards
              DAO={DAO}
              onFilterCollections={(collectionIds) => {
                setPage(0);
                filterCollections(collectionIds);
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
const Index = ({ DAO }) => {
  return (
    <>
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        <IndexComponent DAO={DAO} />
      </DndProvider>
    </>
  );
};

Index.Edit = Edit;
export default Index;
