import React, { useEffect, createContext, useContext } from "react";
import moment from "moment";
import { toDaysMinutesSeconds } from "./utils";
import customHooks from "../hooks";

export const BlockContext = createContext();

export default function BlockProvider({ children }) {
  const provider = customHooks.useEthersProvider();

  const [avgBlockTime] = React.useState(0);
  const [blocksPerMinute] = React.useState(0);
  const [blocksPerHour] = React.useState(0);
  const [blocksPerDay] = React.useState(0);
  const [isCalculatingAvgBlockTimes, setIsCalculatingAvgBlockTimes] =
    React.useState(false);
  const [isCalculatingBlockTimes] = React.useState(false);

  const getCurrentBlock = async () => {
    return await provider.getBlockNumber();
  };

  const getNumOfBlocksAvgTime = async (blocks) => {
    const avgBlockTime = await getBlockAverageTime();
    const avgTimeForNumOfBlocks = blocks * avgBlockTime;
    return avgTimeForNumOfBlocks;
  };

  const getAverageBlockTimeForBlocks = async (numOfBlocks) => {
    setIsCalculatingAvgBlockTimes(true);
    const avgTime = await getNumOfBlocksAvgTime(numOfBlocks);
    const formattedTime = toDaysMinutesSeconds(avgTime);

    setIsCalculatingAvgBlockTimes(false);
    return formattedTime;
  };

  const getPastBlockDateTime = async (blockNumber) => {
    const block = await provider.getBlock(blockNumber);
    const timestamp = block.timestamp;
    const dateTime = new Date(timestamp * 1000);
    return dateTime;
  };

  const getFutureBlockDateTime = async (futureBlockNumber) => {
    const currentBlockNumber = await provider.getBlockNumber();

    if (futureBlockNumber > currentBlockNumber) {
      const futureNumOfBlocks = futureBlockNumber - currentBlockNumber;
      const futureNumOfBlocksAvgTime = futureNumOfBlocks * avgBlockTime;
      const currentDateTime = await getPastBlockDateTime(currentBlockNumber);
      const futureDateTime = moment(currentDateTime).add(
        futureNumOfBlocksAvgTime,
        "seconds"
      );

      return futureDateTime;
    } else {
      return getPastBlockDateTime(futureBlockNumber);
    }
  };

  const getBlockAverageTime = async (span = 2) => {
    const times = [];
    const currentBigIntNumber = await provider.getBlockNumber();
    const currentNumber = Number(currentBigIntNumber);
    const firstBlock = await provider.getBlock(currentNumber - span);
    let prevTimestamp = firstBlock.timestamp;

    for (let i = currentNumber - span + 1; i <= currentNumber; i++) {
      const block = await provider.getBlock(i);

      let time = Number(block.timestamp) - Number(prevTimestamp);
      prevTimestamp = Number(block.timestamp);
      times.push(time);
    }

    return Math.round(times.reduce((a, b) => a + b) / times.length);
  };

  useEffect(() => {
    // const fetchData = async () => {
    //   setIsCalculatingBlockTimes(true);
    //   const avgBlockTime = await getBlockAverageTime();
    //   setAvgBlockTime(avgBlockTime);
    //   const blocksPerMinute = 60 / avgBlockTime;
    //   setBlocksPerMinute(blocksPerMinute);
    //   const blocksPerHour = blocksPerMinute * 60;
    //   setBlocksPerHour(blocksPerHour);
    //   setBlocksPerDay(blocksPerHour * 24);
    //   setIsCalculatingBlockTimes(false);
    // };
    //fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <BlockContext.Provider
      value={{
        avgBlockTime,
        blocksPerMinute,
        blocksPerHour,
        blocksPerDay,
        isCalculatingBlockTimes,
        isCalculatingAvgBlockTimes,
        getFutureBlockDateTime,
        getCurrentBlock,
        getNumOfBlocksAvgTime,
        getBlockAverageTime,
        getAverageBlockTimeForBlocks,
      }}
    >
      {children}
    </BlockContext.Provider>
  );
}

export const useBlockContext = () => useContext(BlockContext);
