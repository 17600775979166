import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";
import useService from "./useService";
import CustomHooks from "../../../../@components/hooks";
import { useSnackbar } from "notistack";
import UI from "../../../../@components/UI";
import { useTranslation } from "react-i18next";

const Poll = ({ data, daoId, postId, refetch }) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const service = useService(daoId, postId);
  const [userHasVoted, setUserHasVoted] = React.useState(false);
  const [isPollExpired, setIsPollExpired] = React.useState(false);

  const { mutate: vote } = CustomHooks.usePost(service.vote, {
    onSuccess: () => {
      enqueueSnackbar(t("brands.feed.create.poll.snackbar"), {
        variant: "success",
      });
      refetch();
    },
  });

  React.useEffect(() => {
    if (data) {
      const userHasVoted = data.options.some((option) => option.has_voted);
      setUserHasVoted(userHasVoted);

      const isPollExpired = moment().isAfter(data.datetime_expire);
      setIsPollExpired(isPollExpired);
    }
  }, [data]);

  return (
    <Box
      sx={{
        pt: "18px",
        px: { md: "20px", mobile: "10px" },
        pb: "14px",
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
        borderRadius: "0 0 10px 10px,"
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant={"h8"}
          sx={{
            overflowWrap: "anywhere",
            mb: "9px",
          }}
        >
          {data.title}
        </Typography>
        <UI.Tag
          sx={{
            ml: "10px",
            mt: "-2px",
            backgroundColor: 
            isPollExpired ?
              (theme) => theme.palette.tag.yellow
              : (theme) => theme.palette.tag.green
          }}
        >
          {isPollExpired
            ? t("brands.feed.create.poll.expired")
            : t("brands.feed.create.poll.active")}
        </UI.Tag>
      </Box>
      <Box>
        {data.options.map((option) => {
          const percentage = (option.votes / data.total_votes) * 100;

          return (
            <Box
              onClick={() => isPollExpired === false && vote(option?.id)}
              sx={{
                position: "relative",
                cursor: isPollExpired ? "normal" : "pointer",
                backgroundColor: option?.has_voted
                  ? "rgba(255, 255, 255, 0.06)"
                  : "rgba(255, 255, 255, 0.12)",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  transform: isPollExpired ? "scale(1)" : "scale(1.02)",
                  backgroundColor: isPollExpired
                    ? "rgba(255, 255, 255, 0.06)"
                    : "rgba(255, 255, 255, 0.12)",
                },
                display: "flex",
                height: "48px",
                mb: { md: "8px", mobile: "9px" },
                borderRadius: (theme) => theme.borderRadius.primary,
              }}
            >
              <Box
                sx={{
                  transition: "all 0.5s ease-in-out",
                  backgroundColor: `rgba(148, 77, 255, 1)`,
                  borderRadius: (theme) => theme.borderRadius.primary,
                  width: `${userHasVoted || isPollExpired ? percentage : 0}%`,
                }}
              ></Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant={"subtitle3"}
                  sx={{
                    position: "absolute",
                    left: { md: "17px", mobile: "11px" },
                    top: "50%",
                    transform: "translateY(-50%)",
                    ml: "0px",
                    fontWeight: option?.has_voted ? "bold" : "normal",
                    color: option?.has_voted ? "#fff" : "#ffffffb3",
                    maxWidth: { md: "360px", mobile: "270px" },
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <UI.OverflowTip> {option.title}</UI.OverflowTip>
                </Typography>

                <Typography
                  variant={"subtitle3"}
                  sx={{
                    position: "absolute",
                    right: { md: "17px", mobile: "11px" },
                    top: "50%",
                    transform: `translateY(-50%) translateX(${
                      userHasVoted || isPollExpired ? "0" : "100%"
                    })`,
                    ml: "17px",
                    fontWeight: option?.has_voted ? "bold" : "normal",
                    color: option?.has_voted ? "#fff" : "#ffffffb3",
                    transition: "transform 0.4s ease-in-out",
                    display: userHasVoted || isPollExpired ? "block" : "none",
                  }}
                >
                  {i18n.language === "en" && (
                    <>
                      {option.votes} {option.votes === 1 ? "vote" : "votes"}
                    </>
                  )}
                  {i18n.language === "sl" && <>{`glasovi: ${option.votes}`}</>}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "12px",
          mb: "2px",
        }}
      >
        <Box>
          <Typography
            variant={"subtitle4"}
            sx={{
              pb: { md: "3px", mobile: "10px" },
            }}
          >
            {isPollExpired ? (
              <>
                {t("brands.feed.create.poll.expiredOn")}{" "}
                {moment(data.datetime_expire).format("DD MMM YYYY HH:mm")}
              </>
            ) : (
              <>
                {t("brands.feed.create.poll.activeUntil")}{" "}
                {moment(data.datetime_expire).format("DD MMM YYYY HH:mm")}
              </>
            )}
          </Typography>
        </Box>
        {i18n.language === "en" && (
          <Box>
            <Typography
              variant={"subtitle4"}
              sx={{
                pb: { md: "3px", mobile: "10px" },
              }}
            >
              {data.total_votes} {data.total_votes === 1 ? "voter" : "voters"}
            </Typography>
          </Box>
        )}
        {i18n.language === "sl" && (
          <Box>
            <Typography
              variant={"subtitle4"}
              sx={{
                pb: { md: "3px", mobile: "10px" },
              }}
            >
              {`glasovalci: ${data.total_votes}`}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Poll;
