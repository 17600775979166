import React from "react";
import { Box } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import { useUserContext } from "../../../userContext";
import Typography from "@mui/material/Typography";
import SettingsIcon from "@mui/icons-material/Settings";
import { useHistory } from "react-router-dom";

const User = () => {
  const { user: loggedInUser } = useUserContext();
  const history = useHistory();
  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{
              mr: "10px",
              transition: "box-shadow 0.3s",
              width: "34px",
              height: "34px",
              boxShadow:
                loggedInUser?.email_verified &&
                loggedInUser?.smart_address !== null
                  ? "unset"
                  : "0 0 0 2px #d32f2f",
              "&:hover": {
                boxShadow:
                  loggedInUser?.email_verified &&
                  loggedInUser?.smart_address !== null
                    ? "0 0 0 2px #944DFF"
                    : "0 0 0 2px #d32f2f",
              },
            }}
            src={loggedInUser?.avatar_url}
          />
          <Typography
            variant={"subtitle2"}
            sx={{
              fontWeight: "700",
              color: (theme) => theme.palette.text.primary,
            }}
          >
            {loggedInUser?.username || ""}
          </Typography>
        </Box>
        <Box
          onClick={() =>
            history.push({
              pathname: "/profile/settings",
              state: { scrollToTop: true },
            })
          }
          sx={{ display: "flex", alignItems: "center" }}
        >
          <SettingsIcon sx={{ mr: "10px" }} />
        </Box>
      </Box>
    </Box>
  );
};

export default User;
