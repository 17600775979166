import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Form from "./Form";
import Select from "./Select";
import { useForm, FormProvider } from "react-hook-form";
import { useFormData } from "../context";
import UI from "../../../../../@components/UI";
import { useUserContext } from "../../../../../@components/userContext";
import { useTranslation } from "react-i18next";
import HomeIcon from "@mui/icons-material/Home";
import { Breadcrumbs } from "@mui/material";
import { useHistory } from "react-router-dom";

const Index = ({ draft, nextStep, previouseSteps, onSave, DAO }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { data, setFormValues } = useFormData();
  const { user: loggedInUser } = useUserContext();
  const [utilityFormInputs, setUtilityFormInputs] = React.useState(null);

  const methods = useForm({
    defaultValues: {
      main_utility: {
        is_main_utility: true,
        ...data.main_utility,
      },
    },
    mode: "all",
  });

  // Reset form with draft data.
  React.useEffect(() => {
    if (draft) {
      setFormValues(draft.data);
      methods.reset({
        main_utility: { ...draft.data.main_utility, is_main_utility: true },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft]);

  // On change of main utility, reset form with utility data.
  React.useEffect(() => {
    utilityFormInputs &&
      methods.reset({
        main_utility: { ...utilityFormInputs, is_main_utility: true },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [utilityFormInputs]);

  const onSubmit = async (values, action) => {
    switch (action) {
      case "save_and_continue": {
        await onSave(values, false);
        nextStep();
        break;
      }
      case "save": {
        await onSave(values, true);
        break;
      }
      default: {
        await onSave(values, true);
        break;
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onKeyDown={handleKeyDown}
        onSubmit={methods.handleSubmit((values) =>
          onSubmit(values, "save_and_continue")
        )}
      >
        <Box
          sx={{
            mt: { md: "15px", mobile: "20px" },
            p: { md: "40px 65px", mobile: "0px 10px" },
            mb: "10px",
          }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  cursor: "pointer",
                  color: (theme) => theme.palette.text.primary,
                },
              }}
              onClick={() => {
                history.push(`/brand/${DAO.id}`);
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Home
            </Box>
            <Box
              underline="hover"
              sx={{
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  cursor: "pointer",
                  color: (theme) => theme.palette.text.primary,
                },
              }}
              onClick={() => {
                history.push(`/brand/${DAO.id}/collection`);
              }}
            >
              Collections
            </Box>
            <Box
              underline="hover"
              sx={{
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  cursor: "pointer",
                  color: (theme) => theme.palette.text.primary,
                },
              }}
              onClick={() => previouseSteps(1)}
            >
              Basic Info
            </Box>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              Main Utility
            </Typography>
          </Breadcrumbs>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              <Box
                sx={{
                  mb: { mobile: "20px", md: "40px" },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant={"h3"}>
                  {t("brands.collection.create.mainUtility.title")}
                </Typography>
              </Box>

              <Box sx={{ mb: { mobile: "20px", md: "40px" } }}>
                <Select
                  draft={draft}
                  onSelect={(selectedUtilityFormInputs) =>
                    setUtilityFormInputs(selectedUtilityFormInputs)
                  }
                />
              </Box>

              <Form />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: "100%",
            mt: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "210px" }}>
              <UI.Button
                disabled={
                  !!Object.keys(methods?.formState?.errors).length ||
                  !loggedInUser?.email_verified
                }
                title={t("buttons.saveAndContinue")}
                type={"primary"}
              />
              <UI.Button
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const allValues = methods.getValues();
                  await onSubmit(allValues, "save");
                }}
                disabled={!loggedInUser?.email_verified}
                sx={{
                  mt: "10px",
                  filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
                }}
                title={t("buttons.saveForLater")}
                type={"tertiary"}
              />
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default Index;
