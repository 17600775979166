import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Brands from "../home/brands";
import { useTranslation } from "react-i18next";

const Index = ({ user }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{
        padding: { md: "10px 70px 20px", mobile: "0 0 20px" },
      }}
      >
        <Grid container>
          <Grid
            item
            mobile={12}
            md={12}
            sx={{
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                mb: { md: "40px", mobile: "30px" },
                textAlign: "left",
              }}
              variant={"h3"}
            >
              {t("profile.home.myBrands")}
            </Typography>
            <Brands />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Index;
