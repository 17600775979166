import React from "react";
import UI from "../../../../../@components/UI";
import useBlockchainState from "../useBlockchainState";
import useVote from "../useVote";
import useQueue from "../useQueue";
import useExecute from "../useExecute";
import { useSnackbar } from "notistack";
import { prepareTransactionData } from "../utils";
import { useParams } from "react-router-dom";
import useService from "../../../tresuary/transactions/useService";
import { useMenu } from "../../../context";
import { ethers } from "ethers";
import treasuryArtifact from "../../../../../abis/contracts/modules/treasury/Treasury.sol/Treasury.json";
import { handleVote } from "../../../../../qvrse/vote/@id/utils";
import customHooks from "../../../../../@components/hooks";
import { useTranslation } from "react-i18next";

const VotingPanel = ({ DAO, proposal, onChange }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const signer = customHooks.useEthersSigner();
  const transactionService = useService(DAO?.id);
  const { updateMenu } = useMenu();
  const { enqueueSnackbar } = useSnackbar();
  const [eventListeners, setEventListeners] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const { votingPower } = useBlockchainState(DAO, proposal);
  const { vote } = useVote(DAO);
  const { queue } = useQueue(DAO);
  const { execute } = useExecute(DAO);

  const saveTransactionToDatabase = async (contractData) => {
    const data = prepareTransactionData(id, proposal, contractData);
    await transactionService
      .save(data)
      .then(() => {
        enqueueSnackbar("Token transfer was successful", {
          variant: "success",
        });
        setIsLoading(false);
        updateMenu();
      })
      .catch((error) => {
        enqueueSnackbar(error?.message, { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addEventListeners = () => {
    if (eventListeners.length === 0) {
      const treasury = new ethers.Contract(
        DAO?.treasury_contract?.address,
        treasuryArtifact.abi,
        signer
      );
      if (proposal?.data?.token.token_type === "NATIVE") {
        treasury.on(
          "NativeTokenSuccessfullySent",
          async (to, amount, isFee) => {
            await saveTransactionToDatabase({
              from: DAO?.treasury_contract?.address,
              to,
              amount,
              isFee,
            });
            removeEventListeners();
          }
        );
        setEventListeners((prevValue) => [
          ...prevValue,
          "NativeTokenSuccessfullySent",
        ]);
      } else {
        treasury.on(
          "TokenSuccessfullySent",
          async (tokenAddress, from, to, amount, isFee) => {
            await saveTransactionToDatabase({
              tokenAddress,
              from,
              to,
              amount,
              isFee,
            });
            removeEventListeners();
          }
        );
        setEventListeners((prevValue) => [
          ...prevValue,
          "TokenSuccessfullySent",
        ]);
      }
    }
  };

  const removeEventListeners = () => {
    const treasury = new ethers.Contract(
      DAO?.treasury_contract?.address,
      treasuryArtifact.abi,
      signer
    );
    treasury.removeAllListeners();
  };

  React.useEffect(() => {
    return () => {
      removeEventListeners();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <UI.Busy.FullscreenIndicator show={isLoading} />
      <UI.VotingPoll
        DAO={DAO}
        proposal={proposal}
        onVote={async (option) => {
          setIsLoading(true);
          await handleVote(option, { voteFunc: vote, proposal, votingPower })
            .then(async (response) => {
              setIsLoading(false);
              enqueueSnackbar(t("brands.vote.votingResultsPanel.snackbar"), {
                variant: "success",
              });
              onChange(response);
            })
            .catch((reason) => {
              setIsLoading(false);
            });
        }}
        onQueue={async () => {
          setIsLoading(true);
          queue(proposal)
            .then((response) => {
              onChange(response);
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        }}
        onExecute={async () => {
          addEventListeners();
          setIsLoading(true);
          execute(proposal)
            .then((response) => {
              onChange(response);
            })
            .catch(() => {
              removeEventListeners();
              setIsLoading(false);
            });
        }}
      />
    </React.Fragment>
  );
};

export default VotingPanel;
