import React from "react";
import { useTranslation } from "react-i18next";
import useService from "../../../../profile/created/draft/useService";
import { useSnackbar } from "notistack";
import CustomHooks from "../../../../@components/hooks";
import Box from "@mui/material/Box";
import UI from "../../../../@components/UI";
import DeleteDialog from "../../../../profile/created/draft/DeleteDialog";
import CloseIcon from "@mui/icons-material/Close";
import MaterialDialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

const DraftDialog = ({ DAO, open, onClose, drafts }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const service = useService();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const { mutate: removeDraft } = CustomHooks.usePost(service.remove, {
    onSuccess: () => {
      enqueueSnackbar("Draft was successfully deleted", {
        variant: "success",
      });
      //refetchDrafts();
    },
  });
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          p: { md: "30px 24px", mobile: "30px 24px" },
          width: "550px",
          height: "auto",
          margin: { mobile: "15px", md: "0px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={open}
      onClose={onClose}
    >
      {!isMobile && (
        <CloseIcon
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 30,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
      )}

      <Typography
        variant="h4"
        sx={{ fontSize: { md: "20px", mobile: "18px" } }}
      >
        {t("profile.dialogs.draftDialog.title")}
      </Typography>
      <Box
        sx={{
          mt: { md: "12px", mobile: "10px" },
          display: "flex",
          justifyContent: { mobile: "center", md: "left" },
          flexWrap: { md: "wrap", mobile: "wrap" },
        }}
      >
        {drafts?.length > 0 &&
          drafts.slice(0, 5).map((item, index) => (
            <Box
              key={index}
              onClick={() => {
                onClose();
                history.push({
                  pathname: `/brand/${item?.data?.dao_id}/collection/new/draft/${item?.id}`,
                  state: {
                    scrollToTop: true,
                    draft: item.data,
                    draft_id: item?.id,
                    draft_display_index: index,
                  },
                });
              }}
              sx={{
                p: "0",
                display: "flex",
                flexDirection: "column",
                mx: "5px",
                mt: "10px",
                width: { md: "90px", mobile: "80px" },
                height: "auto",
                position: "relative",
                maxHeight: "120px",
                background: (theme) => theme.palette.bgTransparent.primary,
                cursor: "pointer",
                transition: "all  0.3s ease-in-out",
                borderRadius: "5px",
                "&:hover": {
                  background: (theme) => theme.palette.bgTransparent.secondary,
                },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "0",

                  paddingBottom: "100%", // This ensures a square aspect ratio
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                {item?.data?.basic_info?.picture_url?.file?.type.startsWith(
                  "image"
                ) && (
                  <Box
                    component="img"
                    src={item?.data?.basic_info?.picture_url?.ipfs}
                    alt={item?.data?.basic_info?.name}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      width: "100%",
                      height: "100%",
                      borderRadius: "5px 5px 0 0",
                      transform: "translate(-50%, -50%)",
                      objectFit: "cover", // Ensure the image covers the box
                    }}
                  />
                )}
                {item?.data?.basic_info?.picture_url?.file?.type.startsWith(
                  "video"
                ) && (
                  <video
                    playsInline
                    autoPlay="autoplay"
                    style={{
                      objectFit: "cover",

                      height: "90px",
                      width: "90px",
                    }}
                    loop
                    muted
                  >
                    <source src={item?.data?.basic_info?.picture_url?.ipfs} />
                  </video>
                )}
              </Box>
              <Typography
                variant="subtitle4"
                sx={{
                  mt: "10px",
                  mb: "7px",
                  fontWeight: "bold",
                  color: (theme) => theme.palette.text.secondary,
                  textAlign: "center",
                  lineHeight: "1 !important",
                }}
              >
                {moment(item?.data?.datetime_created).format("DD MMM HH:mm")}
              </Typography>
            </Box>
          ))}
      </Box>

      {showDeleteDialog && (
        <DeleteDialog
          onConfirm={() => {
            removeDraft(showDeleteDialog);
            setShowDeleteDialog(false);
          }}
          onClose={() => setShowDeleteDialog(false)}
        />
      )}
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <UI.Button
          title={t("profile.dialogs.draftDialog.button")}
          type={"secondary"}
          onClick={() => onClose()}
          sx={{
            width: { md: "280px", mobile: "90%" },
            mt: "40px",
          }}
        />
      </Box>
    </MaterialDialog>
  );
};

export default DraftDialog;
