import React from "react";
import Drawer from "@mui/material/Drawer";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Notifications from "../../notifications";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "100%",
    borderRadius: "0",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.dialog.background
        : "rgba(255, 255, 255, 0.7) !important",
    backdropFilter:
      theme.palette.type === "dark"
        ? theme.palette.dialog.backdropFilter
        : "blur(20px)",
  },
}));

const Index = ({ open, onClose }) => {
  const classes = useStyles();
  const location = useLocation();

  React.useEffect(() => {
    onClose();
    // eslint-disable-next-line
  }, [location]);

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor={"right"}
      open={open}
      onClose={onClose}
      transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
    >
      <DialogTitle
        className="drawerTitle"
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4" sx={{ p: "16px 16px 16px 10px", fontSize: "18px !important" }}>
          Notifications
        </Typography>
        <IconButton onClick={() => onClose()}>
          <CloseIcon
            sx={{
              color: (theme) => theme.palette.text.primary,
              fontSize: "24px",
              "&:hover": {
                transition: "all 0.1s ease-in-out",
                transform: "scale(1.1) rotate(-90deg)",
              },
            }}
          />
        </IconButton>
      </DialogTitle>
      <Box
        sx={{
          px: "24px",
          width: "auto",
          zIndex: 0,
          maxHeight: "calc(100vh - 150px)",
          overflowY: "auto",
          scrollbarWidth: "none", // Firefox
          "&::-webkit-scrollbar": {
            display: "none", // WebKit browsers
          },
        }}
        role="presentation"
      >
        <Notifications />
      </Box>
    </Drawer>
  );
};

export default Index;
