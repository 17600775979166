import { useTranslation } from "react-i18next";

export const useTranslatedFormInputs = () => {
  const { t } = useTranslation();

  const ticket = {
    type: "ticket",
    displayName: t(
      "brands.collection.import.mainUtility.form.formInputs.ticket.displayName"
    ),
    name: "",
    price: 0,
    description: "",
    is_main_utility: false,
    custom_info_fields: [
      {
        name: t(
          "brands.collection.import.mainUtility.form.formInputs.ticket.custom_info_fields.date"
        ),
        value: null,
        type: "date",
      },
      {
        name: t(
          "brands.collection.import.mainUtility.form.formInputs.ticket.custom_info_fields.time"
        ),
        value: null,
        type: "time",
      },
      {
        name: t(
          "brands.collection.import.mainUtility.form.formInputs.ticket.custom_info_fields.location"
        ),
        value: null,
        type: "text",
      },
    ],
    request_info_fields: [],
  };

  const service = {
    type: "service",
    displayName: t(
      "brands.collection.import.mainUtility.form.formInputs.service.displayName"
    ),
    name: "",
    price: 0,
    description: "",
    is_main_utility: false,
    custom_info_fields: [
      {
        name: t(
          "brands.collection.import.mainUtility.form.formInputs.service.custom_info_fields.typeOfService"
        ),
        value: null,
        type: "text",
      },
    ],
    request_info_fields: [],
  };

  const product = {
    type: "product",
    displayName: t(
      "brands.collection.import.mainUtility.form.formInputs.product.displayName"
    ),
    name: "",
    price: 0,
    description: "",
    is_main_utility: false,
    custom_info_fields: [
      {
        name: t(
          "brands.collection.import.mainUtility.form.formInputs.product.custom_info_fields.typeOfProduct"
        ),
        value: null,
        type: "text",
      },
    ],
    request_info_fields: [],
  };

  const merch = {
    type: "merch",
    displayName: t(
      "brands.collection.import.mainUtility.form.formInputs.merch.displayName"
    ),
    name: "",
    price: 0,
    description: "",
    is_main_utility: false,
    custom_info_fields: [
      {
        name: t(
          "brands.collection.import.mainUtility.form.formInputs.merch.custom_info_fields.typeOfMerch"
        ),
        value: null,
        type: "text",
      },
    ],
    request_info_fields: [],
  };

  const digitalContent = {
    type: "digital_content",
    displayName: t(
      "brands.collection.import.mainUtility.form.formInputs.digitalContent.displayName"
    ),
    name: "",
    price: 0,
    description: "",
    is_main_utility: false,
    custom_info_fields: [],
    request_info_fields: [],
  };

  const custom = {
    type: "custom",
    displayName: t(
      "brands.collection.import.mainUtility.form.formInputs.custom.displayName"
    ),
    name: "",
    price: 0,
    description: "",
    is_main_utility: false,
    custom_info_fields: [],
    request_info_fields: [],
  };

  return {
    ticket,
    service,
    product,
    merch,
    digitalContent,
    custom,
  };
};
