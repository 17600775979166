import React from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";

const VertMenu = ({ children, color, fontSize }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleMenuOpen} sx={{ p: "0px" }} aria-label="more">
        <MoreVertIcon
          sx={{
            color: color ? color : (theme) => theme.palette.text.tertiary,
            transition: "all 0.2s ease",
            fontSize: fontSize ? fontSize : "1.8rem",
            "&:hover": {
              color: (theme) => theme.palette.text.primary,
              transform: "rotate(-90deg)",
            },
          }}
        />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: (theme) => theme.palette.colors.darkGray,
          }
        }}
      >
        {children}
      </Menu>
    </React.Fragment>
  );
};

export default VertMenu;
