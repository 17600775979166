import { ConnectButton } from "@rainbow-me/rainbowkit";

import Box from "@mui/material/Box";
import React from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: { mobile: "0 auto", md: "unset" },
    cursor: "pointer",
    display: "flex",
    width: "286px",
    justifyContent: "center",
    borderRadius: "5px",
    height: "46px",
    padding: "10px 26px",
    alignItems: "center",
    backgroundColor: theme.palette.bgTransparent.primary,
    border: "1px solid transparent",
    "&:hover": {
      border: "1px solid transparent",
      filter: "drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.10))",
      transition: "all 0.2s ease-in-out",
      backgroundColor: theme.palette.bgTransparent.secondary,
    },
  },
}));

const Button = ({ title }) => {
  const classes = useStyles();
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted;
        const connected = ready && account && chain;

        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Box onClick={openConnectModal} className={classes.button}>
                    <AccountBalanceWalletIcon sx={{ color: (theme) => theme.palette.text.primary }}/>
                    <Typography variant={"h8"} sx={{ ml: "15px" }}>
                      {title}
                    </Typography>
                  </Box>
                );
              }

              if (chain.unsupported) {
                return (
                  <button onClick={openChainModal} type="button">
                    Wrong network
                  </button>
                );
              }

              return (
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 12 }}
                >
                  <Box onClick={openChainModal} className={classes.button}>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "14px !important",
                        fontWeight: 700,
                        fontTransform: "uppercase",
                      }}
                    >
                      {chain.hasIcon && (
                        <div
                          style={{
                            background: chain.iconBackground,
                            width: 12,
                            height: 12,
                            borderRadius: 999,
                            overflow: "hidden",
                            marginRight: 4,
                          }}
                        >
                          {chain.iconUrl && (
                            <img
                              alt={chain.name ?? "Chain icon"}
                              src={chain.iconUrl}
                              style={{
                                width: 12,
                                height: 12,
                                position: "absolute",
                              }}
                            />
                          )}
                        </div>
                      )}
                      {chain.name}
                    </Box>
                  </Box>

                  <Box onClick={openAccountModal} className={classes.button}>
                    <Typography
                      variant={"h8"}
                      sx={{
                        justifyContent: "center",
                      }}
                    >
                      {account.displayName}
                      {account.displayBalance
                        ? ` (${account.displayBalance})`
                        : ""}
                    </Typography>
                  </Box>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default Button;
