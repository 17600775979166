import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import UI from "../../../../@components/UI";
import Skeleton from "@mui/material/Skeleton";

const CardItem = ({ item }) => {
  return (
    <Box
      sx={{
        width: "113px",
        mb: "5px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        flexDirection: "column",
        position: "relative",
        transition: "all  0.3s ease-in-out",
        "&:hover": {
          transform: "scale(1.05)",
        },
        "&:hover .removeIcon": {
          display: "block",
          transition: "all 0.1s ease 0s",
        },
      }}
    >
      <Box
        sx={{
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            position: "relative",
            borderRadius: (theme) => theme.borderRadius.primary,
            width: "100px",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            height: "100px",
            overflow: "hidden",
            border: "2px solid transparent",
            transition: "all 0.3s ease-in-out",
            zIndex: 1,
            "&:hover": {
              boxShadow: "#944dff 0px 0px 15px 0px",
              border: "2px solid #944dff",
              transform: "scale(1.05)",
              transition: "all 0.3s ease-in-out",
            },
          }}
        >
          {item ? (
            <UI.ImageWithSkeleton
              sx={{
                position: "absolute",
                inset: 0,
                boxSizing: "border-box",
                padding: 0,
                border: "none",
                margin: "auto",
                display: "block",
                width: 0,
                height: 0,
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: "100%",
                maxHeight: "100%",
                objectFit: "cover",
              }}
              src={item.image_url}
              width={"100%"}
              alt={"slider-item"}
            />
          ) : (
            <Skeleton
              height={"300px"}
              width={"300px"}
              variant={"rectangular"}
            />
          )}
        </Box>
        <Box
          sx={{
            mt: { md: "12px", mobile: "4px" },
            justifyContent: "center",
            textAlign: "center",
            height: "25px",
            overflow: "hidden",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              variant={"h3"}
              sx={{
                m: "0 auto",
                maxWidth: "300px",
                mt: { mobile: "1px", md: "0" },
                fontSize: { md: "12px !important", mobile: "11px" },
                whiteSpace: "wrap",
                lineHeight: "1.5 !important",
              }}
            >
              {item?.name.length > 17
                ? item?.name.slice(0, 17) + "... "
                : item?.name + " "}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SupriseBag = ({ collection }) => {
  const { t } = useTranslation();
  return (
    <Grid
      item
      md={12}
      mobile={12}
      sx={{
        mt: { md: "12px", mobile: "36px" },
        mb: { md: "10px", mobile: "12px" },
        pt: "0 !important",
      }}
    >
      <Box
        sx={{
          height: "42px",
          minHeight: "42px !important",
          p: "0",
        }}
      >
        {" "}
        <Typography
          variant={"h4"}
          sx={{
            display: "flex",
            alignItems: "end",
          }}
        >
          {t("brands.collection.collectionPage.sneakPeak")}
          <Box>
            <Typography sx={{ textTransform: "none", mb: "1px", ml: "6px" }}>
              {t("brands.collection.collectionPage.sneakPeak2")}
            </Typography>
          </Box>
        </Typography>
      </Box>
      <Box sx={{ p: "0", mt: "0" }}>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {collection?.collection_images.map((item, index) => (
            <CardItem item={item} />
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default SupriseBag;
