import React from "react";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useHistory, useLocation } from "react-router-dom";

import { useMenu } from "./context";
import { useAccount } from "wagmi";
import { useUserContext } from "../@components/userContext";
import { useTranslation } from "react-i18next";

const Menu = ({ DAO, currentUrl }) => {
  const { t } = useTranslation();
  const { activeVotes } = useMenu();
  const { user: loggedInUser } = useUserContext();
  const { address, isConnected } = useAccount();
  let location = useLocation();
  const history = useHistory();
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (DAO && location.pathname) {
      if (location.pathname.startsWith(`/qvrse/tresuary`)) {
        setValue(1);
      } else if (location.pathname.startsWith(`/qvrse/vote`)) {
        setValue(2);
      } else if (location.pathname.startsWith(`/qvrse/membership`)) {
        setValue(3);
      } else {
        setValue(0);
      }
    }
    // eslint-disable-next-line
  }, [DAO, location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        margin: "35px 0px",
        display: {
          md: "block",
          mobile: "none",
        },
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Tabs
          TabIndicatorProps={{
            sx: {
              backgroundColor: (theme) =>
                !location.pathname.startsWith(`/qvrse/settings`)
                  ? theme.palette.colors.primary
                  : "transparent",
            },
          }}
          scrollButtons
          allowScrollButtonsMobile
          value={value}
          onChange={handleChange}
          centered
        >
          <Tab
            sx={{
              "&.MuiTab-root": {
                transition: "all 0.3s ease-in-out",
                width: "140px",
                fontSize: "14px",
                color: location.pathname.startsWith(`/qvrse/settings`)
                  ? "#ffffffb3 !important"
                  : "#fff",
                backgroundColor: (theme) => location.pathname.startsWith(`/qvrse/settings`)
                  ? theme.palette.bgTransparent.tertiary + "!important"
                  : "transparent",
              },
            }}
            onClick={() => history.push(`${currentUrl}`)}
            label={t("qvrseDAO.menu.home")}
          />
          <Tab
            sx={{
              display:
                DAO?.maintainer_address === address ||
                (DAO?.isMember === true &&
                  isConnected &&
                  loggedInUser?.email_verified)
                  ? "block"
                  : "none",
              "&.MuiTab-root": {
                transition: "all 0.3s ease-in-out",
                width: "140px",
                fontSize: "14px",
                color: location.pathname.startsWith(`/qvrse/settings`)
                  ? "#ffffffb3 !important"
                  : "#fff",
                backgroundColor: (theme) => location.pathname.startsWith(`/qvrse/settings`)
                  ? theme.palette.bgTransparent.tertiary + "!important"
                  : "transparent",
              },
            }}
            onClick={() => history.push(`${currentUrl}/tresuary`)}
            label={t("qvrseDAO.menu.treasury")}
          />
          <Tab
            sx={{
              "&.MuiTab-root": {
                transition: "all 0.3s ease-in-out",
                width: "140px",
                fontSize: "14px",
                color: location.pathname.startsWith(`/qvrse/settings`)
                  ? "#ffffffb3 !important"
                  : "#fff",
                backgroundColor: (theme) => location.pathname.startsWith(`/qvrse/settings`)
                  ? theme.palette.bgTransparent.tertiary + "!important"
                  : "transparent",
              },
            }}
            onClick={() => history.push(`${currentUrl}/vote`)}
            label={
              <Badge
                sx={{
                  "& span": {
                    top: "2px",
                    right: "-8px",
                  },
                }}
                badgeContent={activeVotes}
                color={"badgePrimary"}
                invisible={activeVotes === 0}
              >
                {t("qvrseDAO.menu.vote")}
              </Badge>
            }
          />
          <Tab
            sx={{
              "&.MuiTab-root": {
                transition: "all 0.3s ease-in-out",
                width: "140px",
                fontSize: "14px",
                color: location.pathname.startsWith(`/qvrse/settings`)
                  ? "#ffffffb3 !important"
                  : "#fff",
                backgroundColor: (theme) => location.pathname.startsWith(`/qvrse/settings`)
                  ? theme.palette.bgTransparent.tertiary + "!important"
                  : "transparent",
              },
            }}
            onClick={() => history.push(`${currentUrl}/membership`)}
            label={t("qvrseDAO.menu.membership")}
          />
        </Tabs>
      </Box>
    </Box>
  );
};

export default Menu;
