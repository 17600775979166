import ApplyForDao from "./applyForDAO";
import Withdraw from "./withdraw";
import Settings from "./settings";

const Components = {
  Withdraw,
  Settings,
  ApplyForDao,
};

export default Components;
