import React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  roller: {
    height: '4.125rem',
    lineHeight: '4rem',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#1D3557',
    [theme.breakpoints.down('sm')]: {
      height: '2.6rem',
      lineHeight: '2.125rem',
    },
  },
  rolltext: {
    position: 'absolute',
    top: 0,
    animation: `$slide 6s infinite`,
    [theme.breakpoints.down('sm')]: {
      animation: `$slideMobile 6s infinite`,
    },
  },
  '@keyframes slide': {
    '0%': {
      top: 0,
    },
    '25%': {
      top: '-4rem',
    },
    '50%': {
      top: '-8rem',
    },
    '72.5%': {
      top: '-12.25rem',
    },
  },
  '@keyframes slideMobile': {
    '0%': {
      top: 0,
    },
    '25%': {
      top: '-2.125rem',
    },
    '50%': {
      top: '-4.25rem',
    },
    '72.5%': {
      top: '-6.375rem',
    },
  },
}));


const RollerAnimation = ({ imageSrc }) => {
  const classes = useStyles();
  return (
    <Box
      component="div"
      className={classes.roller}
      sx={{
        height: '4.125rem',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        color: '#1D3557',
      }}
    >
      <Box
        component="img"
        className={classes.rolltext}
        src={imageSrc}
        alt="qvrse-logo"
        sx={{
          width: '66px', // Adjust as per your image size
          position: 'absolute',
          top: '0', // Adjust as per your layout
        }}
      />
    </Box>
  );
};

export default RollerAnimation;
