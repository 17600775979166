import Gateway from "../../@components/gateway";

const useService = () => {
  const gateway = Gateway.useGateway();
  const save = (data) => {
    return gateway.post(`dao/${window.env.QVRSE_DAO_ID}/info/edit/`, data);
  };

  const info = () => {
    return gateway.get(`/dao/${window.env.QVRSE_DAO_ID}/`);
  };

  return {
    save,
    info,
  };
};

export default useService;
