import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import UI from "../../../@components/UI";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Controller, useForm, useWatch } from "react-hook-form";
import { ethers } from "ethers";
import useService from "../useService";
import { useSnackbar } from "notistack";
import { useProposal } from "./utils";
import useHandleErrors from "../../../@components/hooks/useHandleErrors";
import Alert from "@mui/material/Alert";
import { useAccount } from "wagmi";
import governorArtifact from "../../../abis/contracts/qtech/governor/Governor.sol/QtechGovernor.json";
import timelockArtifact from "../../../abis/contracts/qtech/timelock/TimeLock.sol/QtechTimelock.json";
import { useMenu } from "../../context";
import InputAdornment from "@mui/material/InputAdornment";
import { useBlockContext } from "../../../@components/contextAPI";
import { uuid } from "uuidv4";
import customHooks from "../../../@components/hooks";

const useStyles = makeStyles(() => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: { md: "20px", mobile: "0px" },
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Index = ({ DAO }) => {
  const classes = useStyles();
  const history = useHistory();
  const { updateMenu } = useMenu();
  const { getProposalData } = useProposal(DAO);
  const { enqueueSnackbar } = useSnackbar();

  const { isConnected } = useAccount();
  const signer = customHooks.useEthersSigner();
  const provider = customHooks.useEthersProvider();
  const { handleApiError, handleMetamaskError } = useHandleErrors();
  const voteService = useService();
  const [quorum, setQuorum] = React.useState(null);
  const [votingDelay, setVotingDelay] = React.useState(null);
  const [votingPeriod, setVotingPeriod] = React.useState(null);
  const [timelockDelay, setTimelockDelay] = React.useState(null);
  const [proposalThreshold, setProposalThreshold] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const [isLoadingVotingDelayAvgTime, setIsLoadingVotingDelayAvgTime] =
    React.useState(false);
  const [isLoadingVotingPeriodAvgTime, setIsLoadingVotingPeriodAvgTime] =
    React.useState(false);
  const [votingDelayBlocksAvgTime, setVotingDelayBlocksAvgTime] =
    React.useState(0);
  const [votingPeriodBlocksAvgTime, setVotingPeriodBlocksAvgTime] =
    React.useState(0);

  const {
    avgBlockTime,
    isCalculatingBlockTimes,
    getAverageBlockTimeForBlocks,
  } = useBlockContext();

  React.useEffect(() => {
    const fetchData = async () => {
      const governor = new ethers.Contract(
        DAO?.governor_contract?.address,
        governorArtifact.abi,
        provider
      );

      const timelock = new ethers.Contract(
        DAO?.timelock_contract?.address,
        timelockArtifact.abi,
        provider
      );
      const quorum = await governor.quorumNumerator();
      setQuorum(Number(quorum));

      const votingDelay = await governor.votingDelay();
      setVotingDelay(Number(votingDelay));

      const votingPeriod = await governor.votingPeriod();
      setVotingPeriod(Number(votingPeriod));

      const proposalThreshold = await governor.proposalThreshold();
      setProposalThreshold(Number(proposalThreshold));

      const timelockDelay = await timelock.getMinDelay();
      setTimelockDelay(Number(timelockDelay));
    };
    DAO && fetchData();
  }, [DAO, provider]);

  useEffect(() => {
    // reset form with data

    if (
      quorum !== null &&
      votingDelay !== null &&
      votingPeriod !== null &&
      timelockDelay !== null &&
      proposalThreshold !== null
    ) {
      reset({
        quorum: quorum,
        voting_delay: votingDelay,
        voting_period: votingPeriod,
        proposal_treshold: ethers.formatEther(proposalThreshold.toString()),
        timelock_delay: timelockDelay,
      });

      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [quorum, votingDelay, votingPeriod, timelockDelay, proposalThreshold]);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: {
      support: "",
      quorum: "",
      voting_delay: "",
      voting_period: "",
      proposal_treshold: "",
      timelock_delay: "",
    },
    mode: "all",
  });

  const quorumSliderValue = useWatch({ control, name: "quorum" });
  const votingDelayValue = useWatch({ control, name: "voting_delay" });
  const votingPeriodValue = useWatch({ control, name: "voting_period" });

  const fetchVotingDelayAvgTime = async (numOfBlocks) => {
    setIsLoadingVotingDelayAvgTime(true);
    const avgTime = await getAverageBlockTimeForBlocks(numOfBlocks);
    setVotingDelayBlocksAvgTime(avgTime);
    setIsLoadingVotingDelayAvgTime(false);
  };

  const fetchVotingPeriodAvgTime = async (numOfBlocks) => {
    setIsLoadingVotingPeriodAvgTime(true);
    const avgTime = await getAverageBlockTimeForBlocks(numOfBlocks);
    setVotingPeriodBlocksAvgTime(avgTime);
    setIsLoadingVotingPeriodAvgTime(false);
  };

  React.useEffect(() => {
    votingDelayValue && fetchVotingDelayAvgTime(votingDelayValue);
    // eslint-disable-next-line
  }, [votingDelayValue]);

  React.useEffect(() => {
    votingPeriodValue && fetchVotingPeriodAvgTime(votingPeriodValue);
    // eslint-disable-next-line
  }, [votingPeriodValue]);

  const onSubmit = async (form) => {
    const { targets, values, calldatas, events } = getProposalData(
      form,
      dirtyFields
    );

    const changedFields = Object.keys(dirtyFields).map((fieldName) => {
      return {
        fieldName: fieldName,
        value: form[fieldName],
      };
    });

    try {
      setIsLoading(true);
      const proposalData = {
        targets: targets,
        values: values,
        calldatas: calldatas,
        description: `Change governor settings: ${changedFields.map(
          (field) => `${field.fieldName}: ${field.value}`
        )} ID: ${uuid()}`,
      };

      const governor = new ethers.Contract(
        DAO?.governor_contract?.address,
        governorArtifact.abi,
        signer
      );

      const proposeTx = await governor.propose(
        proposalData.targets,
        proposalData.values,
        proposalData.calldatas,
        proposalData.description
      );

      const proposeReceipt = await proposeTx.wait(1);

      const proposalId = proposeReceipt.logs[0].args.proposalId.toString();

      // Save proposal to BE.
      voteService
        .save({
          proposal_type: "settings",
          proposal_hash_id: proposalId,
          calldatas: [],
          signatures: [],
          targets: [],
          start_block: Number(
            proposeReceipt.logs[0].args.startBlock
          ).toString(),
          end_block: Number(proposeReceipt.logs[0].args.endBlock).toString(),
          status: "Active",
          progress: 0,
          data: {
            proposal_data: proposalData,
            ...form,
            changedFields,
            events,
          },
        })
        .then((response) => {
          enqueueSnackbar("Proposal succesfully sent.", { variant: "success" });
          updateMenu();
          setIsLoading(false);
          history.push({
            pathname: `/qvrse/vote/${response?.data?.id}/settings`,
            state: { scrollToTop: true },
          });
        })
        .catch((error) => {
          handleApiError(error);
          setIsLoading(false);
        });
    } catch (error) {
      handleMetamaskError(error);
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <UI.Busy.FullscreenIndicator show={isLoading} />
      <Paper elevation={0} sx={{ width: "100%", background: "transparent" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: { md: "70px", mobile: "10px" },
            pb: "20px",
            pt: { md: "30px", mobile: "30px" },
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography variant={"h1"}>GOVERNOR SETTINGS</Typography>
          </Box>
        </Box>

        <Box sx={{ width: "100%" }}>
          {quorum !== null &&
          votingDelay !== null &&
          votingPeriod !== null &&
          timelockDelay !== null &&
          proposalThreshold !== null ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ padding: { md: "10px 70px", mobile: "0px 10px" } }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item mobile={12} md={12}>
                    <Box
                      sx={{
                        mt: "30px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant={"h4"}>Quorum</Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            width: { md: "80%", mobile: "70%" },
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Controller
                            name={"quorum"}
                            control={control}
                            render={({ field }) => (
                              <UI.MaterialSlider {...field} value={quorum} />
                            )}
                          />
                        </Box>

                        <Box sx={{ width: { md: "10%", mobile: "20%" } }}>
                          <UI.TextField value={`${quorumSliderValue}%`} />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item mobile={12} md={12}>
                    <Box className={classes.inputOuter}>
                      <Box sx={{ display: "flex", mb: "10px" }}>
                        <Typography variant={"h4"}>Voting Delay</Typography>
                        <UI.Tooltip>
                          How many blocks must pass before voting starts
                        </UI.Tooltip>
                      </Box>
                      <Controller
                        name={"voting_delay"}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <UI.TextField
                            endAdornment={
                              <InputAdornment position="start">
                                blocks
                              </InputAdornment>
                            }
                            {...field}
                          />
                        )}
                      />
                      {errors.voting_delay == null && (
                        <Box
                          sx={{
                            display: "flex",
                          }}
                        >
                          <Box sx={{ mt: "15px" }}>
                            <Alert variant="outlined" severity="info">
                              {!isLoadingVotingDelayAvgTime &&
                                `${votingDelayValue} ${
                                  parseInt(votingDelayValue) === 1
                                    ? "block"
                                    : "blocks"
                                } = ${votingDelayBlocksAvgTime}`}
                              {isLoadingVotingDelayAvgTime &&
                                `Calculating estimated time...`}
                            </Alert>
                          </Box>
                          <Box
                            sx={{
                              mt: "15px",
                              ml: "20px",
                              display: { md: "block", mobile: "none" },
                            }}
                          >
                            <Alert variant="outlined" severity="info">
                              {!isCalculatingBlockTimes &&
                                `Average block time is ${avgBlockTime} seconds.`}
                              {isCalculatingBlockTimes &&
                                `Calculating average block time...`}
                            </Alert>
                          </Box>
                        </Box>
                      )}
                      <Box sx={{ mt: "15px" }}>
                        {errors.voting_delay?.type === "required" && (
                          <Alert variant="outlined" severity="error">
                            Voting delay is required
                          </Alert>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item mobile={12} md={12}>
                    <Box className={classes.inputOuter}>
                      <Box sx={{ display: "flex", mb: "10px" }}>
                        <Typography variant={"h4"}>Voting Period</Typography>
                        <UI.Tooltip>
                          How many blocks must pass before voting ends
                        </UI.Tooltip>
                      </Box>
                      <Controller
                        name={"voting_period"}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <UI.TextField
                            endAdornment={
                              <InputAdornment position="start">
                                blocks
                              </InputAdornment>
                            }
                            {...field}
                          />
                        )}
                      />
                      {errors.voting_period == null && (
                        <Box
                          sx={{
                            display: "flex",
                          }}
                        >
                          <Box sx={{ mt: "15px" }}>
                            <Alert variant="outlined" severity="info">
                              {!isLoadingVotingPeriodAvgTime &&
                                `${votingPeriodValue} ${
                                  parseInt(votingPeriodValue) === 1
                                    ? "block"
                                    : "blocks"
                                } = ${votingPeriodBlocksAvgTime}`}
                              {isLoadingVotingPeriodAvgTime &&
                                `Calculating estimated time...`}
                            </Alert>
                          </Box>
                          <Box
                            sx={{
                              mt: "15px",
                              ml: "20px",
                              display: { md: "block", mobile: "none" },
                            }}
                          >
                            <Alert variant="outlined" severity="info">
                              {!isCalculatingBlockTimes &&
                                `Average block time is ${avgBlockTime} seconds.`}
                              {isCalculatingBlockTimes &&
                                `Calculating average block time...`}
                            </Alert>
                          </Box>
                        </Box>
                      )}
                      <Box sx={{ mt: "15px" }}>
                        {errors.voting_period?.type === "required" && (
                          <Alert variant="outlined" severity="error">
                            Voting period is required
                          </Alert>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item mobile={12} md={12}>
                    <Box className={classes.inputOuter}>
                      <Box sx={{ display: "flex", mb: "10px" }}>
                        <Typography variant={"h4"}>
                          Proposal Threshold
                        </Typography>
                        <UI.Tooltip>
                          Amount of tokens user must lock to be able to create
                          new proposals.
                        </UI.Tooltip>
                      </Box>
                      <Controller
                        name={"proposal_treshold"}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <UI.TextField
                            endAdornment={
                              <InputAdornment position="start">
                                tokens
                              </InputAdornment>
                            }
                            {...field}
                          />
                        )}
                      />
                      <Box sx={{ mt: "15px" }}>
                        {errors.proposal_treshold?.type === "required" && (
                          <Alert variant="outlined" severity="error">
                            Proposal threshold is required
                          </Alert>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item mobile={12} md={12}>
                    <Box className={classes.inputOuter}>
                      <Box sx={{ display: "flex", mb: "10px" }}>
                        <Typography variant={"h4"}>Timelock Delay</Typography>
                        <UI.Tooltip>
                          How long until proposal can be executed.
                        </UI.Tooltip>
                      </Box>
                      <Controller
                        name={"timelock_delay"}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <UI.TextField
                            endAdornment={
                              <InputAdornment position="start">
                                seconds
                              </InputAdornment>
                            }
                            {...field}
                            placeholder={3600}
                          />
                        )}
                      />
                      <Box sx={{ mt: "15px" }}>
                        {errors.timelock_delay?.type === "required" && (
                          <Alert variant="outlined" severity="error">
                            Timelock delay is required
                          </Alert>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    width: "100%",
                    mt: "100px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box sx={{ width: "210px" }}>
                      <UI.Button
                        disabled={
                          isLoading ||
                          !isConnected ||
                          Object.keys(dirtyFields).length === 0
                        }
                        title={"Change ExtraButtons"}
                        type={"primary"}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          ) : (
            <Box sx={{ padding: { md: "35px 70px", mobile: "45px 15px" } }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item mobile={12} md={12}>
                  <UI.Busy.Indicator />
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default Index;
