import React from "react";
import UI from "../../../../../@components/UI";
import AddField from "../AddField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { useFormData } from "../context";
import { useTranslatedFormInputs } from "../formInputs";
import { useTranslation } from "react-i18next";

const Select = ({ onSelect }) => {
  const formInputs = useTranslatedFormInputs();
  const { t } = useTranslation();
  const [showSelectInput, setShowSelectInput] = React.useState(false);
  const { data } = useFormData();
  return (
    <>
      {!showSelectInput && (
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <AddField
            title={t("brands.collection.create.extraUtilities.select.add", {
              another:
                data?.extra_utilities.length > 0 ? t("commons.another") : "",
            })}
            onClick={() => setShowSelectInput(true)}
          />
        </Box>
      )}
      {showSelectInput && (
        <UI.Select
          defaultOpen
          defaultValue={""}
          onClose={() => setShowSelectInput(false)}
          onChange={(e) => {
            setShowSelectInput(false);
            onSelect(e.target.value);
          }}
        >
          <MenuItem value={formInputs.digitalContent}>
            {t("brands.collection.create.extraUtilities.select.item1")}
          </MenuItem>
          <MenuItem value={formInputs.product}>
            {t("brands.collection.create.extraUtilities.select.item2")}
          </MenuItem>

          <MenuItem value={formInputs.merch}>
            {t("brands.collection.create.extraUtilities.select.item3")}
          </MenuItem>
          <MenuItem value={formInputs.discord}>
            {t("brands.collection.create.extraUtilities.select.item4")}
          </MenuItem>
          <MenuItem value={formInputs.service}>
            {t("brands.collection.create.extraUtilities.select.item5")}
          </MenuItem>
          <MenuItem value={formInputs.ticket}>
            {t("brands.collection.create.extraUtilities.select.item6")}
          </MenuItem>
          <MenuItem value={formInputs.postcard}>
            {t("brands.collection.create.extraUtilities.select.item8")}
          </MenuItem>
          <MenuItem value={formInputs.custom}>
            {t("brands.collection.create.extraUtilities.select.item7")}
          </MenuItem>
        </UI.Select>
      )}
    </>
  );
};

export default Select;
