import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import SeatPicker from "react-seat-picker";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import UI from "../../../../../@components/UI";
import handleRedirectToPayments from "../handleRedirectToPayments";
import { useUserContext } from "../../../../../@components/userContext";
import useEventVenue from "../../../../../@components/hooks/venues/useEventVenue";

const Reservation = ({ collection, reservedSeats }) => {
  const { t, i18n } = useTranslation();
  const { user: loggedInUser } = useUserContext();
  const [selected, setSelected] = useState([]);
  const [rows, setRows] = useState([]);
  const location = useLocation();
  const { getVenueSeatsById } = useEventVenue();

  //get seat_reservation_venue from collection.utilities where utility.type === "ticket"
  const venueId = collection?.utilities?.find(
    (utility) => utility.type === "ticket"
  )?.seat_reservation_venue;

  React.useEffect(() => {
    if (!venueId) return;

    const rows = getVenueSeatsById(venueId);

    //if reservedSeats is not empty, then find which item has number === reservedSeat and to this object isReserved=true
    if (reservedSeats && rows) {
      rows.forEach((row) => {
        row.forEach((seat) => {
          //skip when seat===null
          if (seat === null) return;

          if (reservedSeats.includes(seat.number)) {
            seat.isReserved = true;
          }
        });
      });
      setRows(rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservedSeats, venueId]);

  //const rows = [];
  const price = collection?.total_price_with_tax || 0;
  const totalprice = price * selected.length;

  const addSeatCallback = ({ row, number, id }, addCb) => {
    setSelected((prevItems) => [...prevItems, number]);
    addCb(row, number, id);
  };

  const removeSeatCallback = ({ row, number, id }, removeCb) => {
    setSelected((list) => list.filter((item) => item !== number));
    removeCb(row, number);
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.bgTransparent.primary,
          borderRadius: (theme) => theme.borderRadius.primary,
          py: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: { md: "40%", mobile: "90%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: "24px",
            backgroundColor: (theme) => theme.palette.bgTransparent.tertiary,
            padding: "28px 140px",
            borderRadius: "5px 5px 80px 80px",
          }}
        >
          {/* <Typography variant={"h4"}>{t("commons.stage")}</Typography> */}
        </Box>
        {/*<Typography variant={"h2"}>*/}
        {/*  {collection?.name_slo !== null &&*/}
        {/*  collection?.name_slo !== "" &&*/}
        {/*  i18n.language === "sl"*/}
        {/*    ? collection?.name_slo*/}
        {/*    : collection?.name || ""}*/}
        {/*</Typography>*/}
        <Box sx={{ mt: "10px", width: "100%" }}>
          {rows?.length > 0 && (
            <SeatPicker
              addSeatCallback={addSeatCallback}
              removeSeatCallback={removeSeatCallback}
              rows={rows}
              maxReservableSeats={1}
              visible
            />
          )}
        </Box>
        {selected.length !== 0 ? (
          <>
            <Box
              sx={{
                marginTop: "40px",
                display: "flex",
                width: "80%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography variant={"h5"}>
                  {`${t("commons.seat")}: ${selected.toString()}`}
                </Typography>
              </Box>
              <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: "20px", height: "20px" }}>
                    <UI.PaymentIcon currency={collection?.currency} />
                  </Box>
                  <Typography
                    variant={"h8"}
                    sx={{
                      ml: "10px",
                      lineHeight: "unset !important",
                    }}
                  >
                    {`${totalprice} ${collection?.currency}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        ) : null}
        <Box
          sx={{
            width: "210px",
            mt: { md: "60px", mobile: "40px" },
            mb: "10px",
          }}
        >
          <UI.Button
            disabled={selected.length === 0}
            title={t("commons.continue")}
            type={"primary"}
            onClick={() => {
              handleRedirectToPayments(
                collection,
                { id: location?.state?.daoId },
                null,
                loggedInUser,
                i18n,
                selected
              );
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Reservation;
