import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../../@components/UI";
import Paper from "@mui/material/Paper";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import AddRequestInfoDialog from "../AdditionalInfoDialog";
import CollectInfoDialog from "../CollectInfoDialog";
import CustomActionButton from "../CustomActionDialog";
import AddField from "../AddField";
import Grid from "@mui/material/Grid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LinkIcon from "@mui/icons-material/Link";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import Media from "./Media";
import MenuItem from "@mui/material/MenuItem";
import useEventVenue from "../../../../../@components/hooks/venues/useEventVenue";

const useStyles = makeStyles(() => ({
  inputOuter: {
    marginBottom: "25px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Form = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [customActionButton, setCustomActionButton] = React.useState(null);
  const { getSupportedVenues } = useEventVenue();
  const {
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const seatReservationEnabled = watch("main_utility.seat_reservation_enabled");

  const displayName = getValues("main_utility.displayName");
  const descriptionDisplayName = getValues(
    "main_utility.descriptionDisplayName"
  );
  const utilityType = getValues("main_utility.type");
  const {
    fields: customInfoFields,
    append: appendCustomInfoFields,
    remove: removeCustomInfoFields,
  } = useFieldArray({
    control,
    name: "main_utility.custom_info_fields",
  });

  React.useEffect(() => {
    //setCustomActionButton
    const button = customInfoFields.find((item) => item?.type === "button");
    if (button) {
      setCustomActionButton(button);
    }
  }, [customInfoFields]);

  const removeCustomActionButton = () => {
    const index = customInfoFields.findIndex((item) => item?.type === "button");
    if (index > -1) {
      removeCustomInfoFields(index);
    }
  };

  const {
    fields: requestInfoFields,
    append: appendRequestInfoFields,
    remove: removeRequestInfoFields,
  } = useFieldArray({
    control,
    name: "main_utility.request_info_fields",
  });

  return (
    <Paper
      sx={{
        width: "100%",
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
        px: { md: "50px", mobile: "10px" },
        py: { md: "50px", mobile: "20px" },
      }}
    >
      <Box sx={{ display: "flex", mb: "30px", alignItems: "center" }}>
        <UI.UtilityTag
          sx={{ height: { md: "23px", mobile: "21px" } }}
          utilityType={utilityType}
        />
        <Typography variant={"h3"} sx={{ ml: "15px" }}>
          {displayName}
        </Typography>
      </Box>
      <Box className={classes.inputOuter}>
        <Box sx={{ display: "flex", mb: "10px" }}>
          <Typography variant={"h6"}>
            {" "}
            {t("brands.collection.create.mainUtility.form.name.title")}
          </Typography>
        </Box>

        <Controller
          name={"main_utility.name"}
          control={control}
          rules={{ required: true }}
          render={({ field }) => <UI.TextField aiButtonEnabled {...field} />}
        />
        {errors.main_utility?.name?.type === "required" && (
          <Box sx={{ mt: "15px" }}>
            <Alert variant="outlined" severity="error">
              {t(
                "brands.collection.create.mainUtility.form.name.error.required"
              )}
            </Alert>
          </Box>
        )}
      </Box>
      <Box className={classes.inputOuter}>
        <Box sx={{ display: "flex", mb: "10px" }}>
          <Typography variant={"h6"}>
            {t("brands.collection.create.mainUtility.form.description.title", {
              displayName: descriptionDisplayName,
            })}
          </Typography>
        </Box>

        <Controller
          name={"main_utility.description"}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <UI.TextField
              aiButtonEnabled
              multiline
              rows={4}
              {...field}
              placeholder={""}
            />
          )}
        />
        {errors.main_utility?.description?.type === "required" && (
          <Box sx={{ mt: "15px" }}>
            <Alert variant="outlined" severity="error">
              {t(
                "brands.collection.create.mainUtility.form.description.error.required"
              )}
            </Alert>
          </Box>
        )}
      </Box>
      <Media control={control} />

      {customInfoFields.length > 0 &&
        customInfoFields?.map((item, index) => {
          if (item?.type === "button") {
            //setCustomActionButton(item);
            // Skip the rest of this iteration if type is 'button'
            return null;
          }

          return (
            <Box className={classes.inputOuter}>
              <Box sx={{ display: "flex", mb: "10px" }}>
                <Typography variant={"h6"}>{item?.name}</Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Controller
                  name={`main_utility.custom_info_fields.${index}.value`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.Inputs.GetInputByType
                      type={item?.type}
                      {...field}
                      placeholder={item?.placeholder}
                    />
                  )}
                />

                <Box
                  onClick={() => removeCustomInfoFields(index)}
                  sx={{
                    cursor: "pointer",
                    ml: "8px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <DeleteForeverIcon
                    sx={{
                      color: (theme) => theme.palette.colors.darkRed,
                      opacity: "0.8",
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                  />
                </Box>
              </Box>
              {Object.keys(errors).length > 0 &&
                errors?.main_utility?.custom_info_fields &&
                errors?.main_utility?.custom_info_fields[index]?.value?.type ===
                  "required" && (
                  <Box sx={{ mt: "15px" }}>
                    <Alert variant="outlined" severity="error">
                      {t(
                        "brands.collection.create.mainUtility.form.customInfoField.error.required",
                        {
                          name: item?.name,
                        }
                      )}
                    </Alert>
                  </Box>
                )}
            </Box>
          );
        })}
      {utilityType === "ticket" && (
        <Box className={classes.inputOuter}>
          <Box sx={{ display: "flex", mb: "5px", mt: "15px" }}>
            <Typography variant={"h6"}>
              {t("brands.collection.create.mainUtility.enableSeatReservation")}
            </Typography>
            <UI.Tooltip>
              {t(
                "brands.collection.create.mainUtility.seatReservation.tooltip"
              )}
            </UI.Tooltip>
          </Box>

          <UI.Checkbox
            onClick={() => {
              setValue("main_utility.seat_reservation_enabled", false, {
                shouldDirty: true,
              });
            }}
            defaultChecked={true}
            value={seatReservationEnabled === false ? true : false}
            nomargin={"true"}
            label={
              <Box
                component={"span"}
                sx={{
                  fontWeight: "regular",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {t("commons.disable")}
              </Box>
            }
          />
          <UI.Checkbox
            onClick={() => {
              setValue("main_utility.seat_reservation_enabled", true, {
                shouldDirty: true,
              });
            }}
            value={seatReservationEnabled === true}
            nomargin={"true"}
            label={
              <Box
                component={"span"}
                sx={{
                  fontWeight: "regular",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {t("commons.enable")}
              </Box>
            }
          />
          {seatReservationEnabled && (
            <Box sx={{ mt: "40px" }}>
              <Controller
                name={"main_utility.seat_reservation_venue"}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <UI.Select
                    onChange={(e) => {
                      setValue(
                        "main_utility.seat_reservation_venue",
                        e.target.value
                      );
                    }}
                    {...field}
                  >
                    {getSupportedVenues().map((venue) => (
                      <MenuItem key={venue.id} value={venue.id}>
                        {venue.name}
                      </MenuItem>
                    ))}
                  </UI.Select>
                )}
              />
              {errors.main_utility?.seat_reservation_venue?.type ===
                "required" && (
                <Box sx={{ mt: "15px" }}>
                  <Alert variant="outlined" severity="error">
                    {t(
                      "brands.collection.create.basicInfo.enable.error.required"
                    )}
                  </Alert>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
      <Box>
        <Box
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <AddRequestInfoDialog.Action
            title={t(
              "brands.collection.create.mainUtility.form.additionalInfoField.title"
            )}
            onAdd={(customField) => {
              appendCustomInfoFields(customField);
            }}
            element={
              <AddField
                title={t(
                  "brands.collection.create.mainUtility.form.additionalInfoField.action.title"
                )}
                subtitle={t(
                  "brands.collection.create.mainUtility.form.additionalInfoField.action.subtitle"
                )}
              />
            }
          />
        </Box>
      </Box>
      {requestInfoFields.map((item, index) => (
        <Grid item md={12} mobile={12} key={index}>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h6"}>{item?.title}</Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Controller
                name={`main_utility.request_info_fields.${index}.value`}
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <UI.TextField {...field} placeholder={item?.placeholder} />
                )}
              />
              <Box
                onClick={() => removeRequestInfoFields(index)}
                sx={{
                  cursor: "pointer",
                  ml: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <DeleteForeverIcon
                  sx={{
                    color: (theme) => theme.palette.colors.darkRed,
                    opacity: "0.8",
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
      <Box sx={{ mt: "10px" }}>
        <Box
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <CollectInfoDialog.Action
            title={t(
              "brands.collection.create.mainUtility.form.collectInformation.title"
            )}
            onAdd={(customField) => {
              appendRequestInfoFields(customField);
            }}
            element={
              <AddField
                title={t(
                  "brands.collection.create.mainUtility.form.collectInformation.action.title"
                )}
                subtitle={t(
                  "brands.collection.create.mainUtility.form.collectInformation.action.subtitle"
                )}
              />
            }
          />
        </Box>
      </Box>
      {customActionButton == null && (
        <Box sx={{ mt: "10px" }}>
          <Box
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <CustomActionButton.Action
              title={t(
                "brands.collection.create.mainUtility.form.customLink.title"
              )}
              onAdd={(customField) => {
                setCustomActionButton(customField);
                appendCustomInfoFields(customField);
              }}
              element={
                <AddField
                  title={t(
                    "brands.collection.create.mainUtility.form.customLink.action.title"
                  )}
                  subtitle={t(
                    "brands.collection.create.mainUtility.form.customLink.action.subtitle"
                  )}
                />
              }
            />
          </Box>
        </Box>
      )}
      {customActionButton != null && (
        <Box sx={{ mb: "10px", mt: "15px" }}>
          <Typography variant={"h6"} sx={{ mb: "9px" }}>
            {" "}
            {t(
              "brands.collection.create.mainUtility.form.customLink.action.title"
            )}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.open(customActionButton.value, "_blank");
              }}
            >
              <Tooltip title={customActionButton.name} arrow>
                <Box
                  sx={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    backgroundColor: "rgba(148, 77, 255, 1);",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LinkIcon />
                </Box>
              </Tooltip>
            </Box>
            <Box
              onClick={() => {
                setCustomActionButton(null);
                removeCustomActionButton();
              }}
              sx={{
                cursor: "pointer",
                ml: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <DeleteForeverIcon
                sx={{
                  color: (theme) => theme.palette.colors.darkRed,
                  opacity: "0.8",
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default Form;
