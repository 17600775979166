import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Collections from "./collections";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

const Index = ({ user }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ pb: "30px" }}>
        <Grid container>
          <Grid
            item
            mobile={12}
            md={12}
            sx={{
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "left",
                padding: { md: "10px 70px 30px 30px", mobile: "0 0 30px" },
              }}
              variant={"h3"}
            >
              {t("profile.home.myNFTs")}
            </Typography>
            <Collections />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Index;
