import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const Item = () => {
  return (
    <Paper
      sx={{
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
        mb: "25px",
        width: "100%",
        position: "relative",
        "&:hover .removeIcon": {
          display: "block",
          transition: "all 0.1s ease 0s",
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            p: "20px 20px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                mr: { md: "20px", mobile: "10px" },
              }}
            >
              <Skeleton variant={"circular"} width={"40px"} height={"40px"} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {/*<Typography variant={"subtitle1"}>*/}
              {/*  <Skeleton*/}
              {/*    variant={"rectangular"}*/}
              {/*    width={"180px"}*/}
              {/*    height={"30px"}*/}
              {/*  />*/}
              {/*</Typography>*/}
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mr: "15px",
              }}
            >
              <Typography
                variant={"subtitle4"}
                sx={{
                  opacity: "1 !important",
                }}
              >
                <Skeleton
                  variant={"rectangular"}
                  width={"180px"}
                  height={"30px"}
                />
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Skeleton
                variant={"rectangular"}
                width={"30px"}
                height={"30px"}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ mt: "30px", px: "20px", pb: "20px" }}>
          <Skeleton variant={"rectangular"} width={"100%"} height={"130px"} />
        </Box>
        {/*{data.files !== null && data.files.length > 0 && (*/}
        {/*  <PreviewSwiper items={data.files} />*/}
        {/*)}*/}
      </Box>
    </Paper>
  );
};

const SkeletonList = () => {
  const items = [1, 2, 3, 4];
  return (
    <Box>
      {items.map((index) => (
        <Item key={index} />
      ))}
    </Box>
  );
};

export default SkeletonList;
