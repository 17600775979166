import React from "react";
import MaterialTextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  cssOutlinedInput: {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important",
    },
  },
  notchedOutline: {
    borderColor: "transparent !important",
    borderRadius: "5px !important"
  },
  disabled: {
    "-webkit-text-fill-color": "rgb(255 255 255 / 30%) !important",
  },
}));

const PasswordField = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <MaterialTextField
      size={isMobile ? "small" : "large"}
      {...props}
      ref={ref}
      type={showPassword ? "text" : "password"}
      fullwidth
      InputProps={{
        startAdornment: props.startAdornment,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              sx={{ color: (theme) => theme.palette.text.primary, fontSize: "20px" }}
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
        classes: {
          root: classes.cssOutlinedInput,
          notchedOutline: classes.notchedOutline,
          disabled: classes.disabled,
        },
      }}
      sx={{
        width: "100%",
        backgroundColor: props?.backgroundcolor
          ? props?.backgroundcolor
          : (theme) => theme.palette.bgTransparent.primary,
          borderRadius: (theme) => theme.borderRadius.secondary,
      }}
      variant="outlined"
      value={props.value || ""}
    />
  );
});

export default PasswordField;
