import React, { useState } from "react";
import MaterialTextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import aiIcon from "../../../static/ai-icon.png";
import { Box } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useSnackbar } from "notistack";
import UI from "../index";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

const useStyles = makeStyles((theme) => ({
  notchedOutline: {
    borderColor: "transparent !important",
  },
  disabled: {
    "-webkit-text-fill-color": theme.palette.text.disabled,
  },
}));

const AIButton = ({ onClick }) => (
  <InputAdornment position="end">
    <IconButton
      sx={{
        color: "white",
        fontSize: "20px",
        padding: { md: "8px", mobile: "6px" },
        background: (theme) => theme.palette.colors.primary,
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          background: "#b185f2 !important",
        },
        marginRight: { md: "-2px", mobile: "-10px" },
      }}
      aria-label="ai button"
      onClick={onClick}
      edge="end"
    >
      <Box
        component={"img"}
        src={aiIcon}
        alt="ai icon"
        sx={{
          filter: "invert(1)",
          width: "20px",
        }}
      />
    </IconButton>
  </InputAdornment>
);

const TextField = React.forwardRef((props, ref) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [isPromptActive] = useState(false);
  const [internalValue, setInternalValue] = useState(props.value || ""); // Initialize with props.value
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setInternalValue(props.value || ""); // Update internal state when props.value changes
  }, [props.value]);

  const fetchAIResult = async (prompt) => {
    setIsLoading(true);
    const response = await axios
      .post(`https://backend.qvrse.io/ai_text_prompt/${prompt}/`)
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    return response.data;
  };

  const fetchAI = async (type) => {
    switch (type) {
      case "Improve":
        setInternalValue(
          await fetchAIResult(
            i18n.language === "sl"
              ? `Izboljšaj naslednje besedilo tako, da izboljšaš njegovo jasnost, povezanost in splošno kakovost, rezultat vrni s Slovenskem jeziku: ${internalValue}`
              : `Improve the following text by enhancing its clarity, coherence, and overall quality: ${internalValue}`
          )
        );
        break;
      case "Shorten":
        setInternalValue(
          await fetchAIResult(
            i18n.language === "sl"
              ? `Skrajšaj naslednje besedilo in pri tem ohrani njegov bistveni pomen in ključne točke, rezultat vrni s Slovenskem jeziku: ${internalValue}`
              : `Shorten the following text while retaining its essential meaning and key points: ${internalValue}`
          )
        );
        break;
      case "Longer":
        setInternalValue(
          await fetchAIResult(
            i18n.language === "sl"
              ? `Razširi naslednje besedilo z dodajanjem več podrobnosti in globine, rezultat vrni s Slovenskem jeziku: ${internalValue}`
              : `Expand the following text by adding more detail and depth: ${internalValue}`
          )
        );
        break;
      default:
        break;
    }
  };

  const handleAIClick = (e) => {
    handleMenuClick(e);
  };

  return (
    <MaterialTextField
      size={isMobile ? "small" : "large"}
      {...props}
      ref={ref}
      InputProps={{
        startAdornment: props.aiButtonEnabled ? (
          isPromptActive ? (
            <Box
              sx={{
                background: (theme) => theme.palette.bgTransparent.secondary,
                borderRadius: "5px",
                p: "5px 12px",
                color: (theme) => theme.palette.text.primary,
                mr: "10px",
              }}
            >
              <Typography
                sx={{
                  color: "inherit",
                  fontWeight: "700",
                  fontSize: "15px",
                  textTransform: "capitalize",
                }}
                variant={"subtitle1"}
              >{`${t("commons.prompt")}`}</Typography>
            </Box>
          ) : null
        ) : (
          props.startAdornment
        ),
        endAdornment: props.aiButtonEnabled ? (
          <>
            {isLoading && <UI.Busy.Indicator width={"40px"} height={"40px"} />}
            {!isLoading && <AIButton onClick={handleAIClick} />}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  borderRadius: (theme) => theme.borderRadius.primary,
                  height: "auto",
                  bgcolor: (theme) => theme.palette.colors.darkGray,
                  overflow: "visible",
                  mt: 1.5,
                  "&:before": {
                    content: '""',
                    display: "none",
                    position: "absolute",
                    top: 0,
                    right: 18,
                    width: 10,
                    height: 10,
                    bgcolor: (theme) => theme.palette.colors.darkGray,
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
            >
              <MenuItem
                onClick={async () => {
                  handleClose();
                  await fetchAI("Improve");
                }}
              >
                {t("commons.improve")}
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  handleClose();
                  await fetchAI("Shorten");
                }}
              >
                {t("commons.shorten")}
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  handleClose();
                  await fetchAI("Longer");
                }}
              >
                {t("commons.longer")}
              </MenuItem>
            </Menu>
          </>
        ) : (
          props.endAdornment
        ),
        classes: {
          notchedOutline: classes.notchedOutline,
          disabled: classes.disabled,
        },
      }}
      sx={{
        width: "100%",
        borderRadius: (theme) => theme.borderRadius.secondary,
        backgroundColor: props.backgroundcolor
          ? props.backgroundcolor
          : (theme) => theme.palette.bgTransparent.primary,
      }}
      variant="outlined"
      value={internalValue}
      onChange={
        props.onChange
          ? (e) => {
              setInternalValue(e.target.value);
              props.onChange(e);
            }
          : (e) => setInternalValue(e.target.value)
      }
    />
  );
});

export default TextField;
