import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import UI from "../../../@components/UI";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MaterialDialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { useUserContext } from "../../../@components/userContext";
import LogInDialogFlow from "../../../profile/dialogs/LogInDialogFlow";
import { useTranslation } from "react-i18next";

const EmailRedirectVote = ({ onClose, onConfirm }) => {
  const { t } = useTranslation();
  const { user: loggedInUser } = useUserContext();
  const [triggerLoginDialog, setTriggerLoginDialog] = React.useState(null);

  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          padding: "30px 10px 42px 10px",
          width: "550px",
          margin: { mobile: "15px", md: "0px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={true}
      onClose={onClose}
    >
      <CloseIcon
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 30,
          top: 30,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <DialogTitle sx={{ p: "8px 24px" }}>
      <Typography variant={"h3"}>{t("profile.dialogs.emailRedirectVote.title")}</Typography>
      </DialogTitle>
      <DialogContent sx={{ p: "0px 24px 5px 24px" }}>
        <Box sx={{ mt: "10px" }}>
          <DialogContentText>
            {loggedInUser && (
              <Typography variant={"subtitle2"}>
                {t("profile.dialogs.emailRedirectVote.loggedInSubtitle")}
              </Typography>
            )}
            {!loggedInUser && (
              <Typography variant={"subtitle2"}>
                {t("profile.dialogs.emailRedirectVote.loggedOutSubtitle")}
              </Typography>
            )}
          </DialogContentText>
        </Box>
        <Box sx={{ mt: "10px" }}></Box>
      </DialogContent>
      <Box
        sx={{
          mt: "20px",
          px: "15px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {loggedInUser && (
          <UI.Button
            sx={{ width: "150px" }}
            title={t("profile.dialogs.emailRedirectVote.button")}
            type={"primary"}
            onClick={onConfirm}
          />
        )}
        {!loggedInUser && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <UI.Button
              sx={{ width: "150px", margin: "0 auto" }}
              title={t("profile.dialogs.emailRedirectVote.logIn")}
              type={"primary"}
              onClick={() => setTriggerLoginDialog(Math.random())}
            />
          </Box>
        )}
      </Box>
      <LogInDialogFlow
        trigger={triggerLoginDialog}
        onClose={() => setTriggerLoginDialog(null)}
      />
    </MaterialDialog>
  );
};

export default EmailRedirectVote;
