export const getProposalState = async (proposalState) => {
  switch (proposalState) {
    case 0:
      return "Pending";
    case 1:
      return "Active";
    case 2:
      return "Canceled";
    case 3:
      return "Defeated";
    case 4:
      return "Succeeded";
    case 5:
      return "Queued";
    case 6:
      return "Expired";
    case 7:
      return "Executed";
    default:
      return "State id not recognized";
  }
};

export const prepareTransactionData = (id, proposal, contractData) => {
  return {
    transaction_trx: "",
    proposal_id: proposal.id,
    from_address: contractData.from,
    to_address: contractData.to,
    token_symbol: proposal.data.token.token_symbol,
    amount: proposal.data.amount,
    receipt: "",
  };
};
