import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { useTranslation } from "react-i18next";
import useCollectionService from "../../collections/useService";
import CustomHooks from "../../@components/hooks";
import UI from "../../@components/UI";

const CollectionCard = ({ item, onClick }) => {
  const { t } = useTranslation();
  item?.nft_file_type === "" && (item.nft_file_type = "image/png");
  return (
    <Card
      onClick={() => onClick(item)}
      sx={{
        maxWidth: 345,
        minHeight: "303.39px !important",
        margin: "auto",
        cursor: "pointer",
        background: (theme) => theme.palette.bgTransparent.primary,
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          background: (theme) => theme.palette.bgTransparent.secondary,
        },
      }}
    >
      {item?.nft_file_type.startsWith("image/") && (
        <CardMedia
          component="img"
          alt={item.name}
          height="180"
          image={item.nft_image_url}
        />
      )}
      {item?.nft_file_type.startsWith("video/") && (
        <CardMedia
          component="video"
          alt={item.name}
          height="180"
          sx={{ objectFit: "cover" }}
          src={item.nft_image_url}
          playsInline
          autoPlay
          muted
          loop
        />
      )}
      <CardContent
        sx={{
          p: "13px 15px 18px !important",
        }}
      >
        <Typography variant="subtitle4">{item.dao?.name}</Typography>
        <Typography
          gutterBottom
          variant="h7"
          component="div"
          sx={{ lineHeight: "1.3" }}
        >
          <UI.OverflowTip>{item.name}</UI.OverflowTip>
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {item?.total_vat_amount_string !== "0.00" && (
            <Box sx={{ display: "block" }}>
              <Typography variant="subtitle4" sx={{ fontSize: "11px" }}>
                {t("commons.price")}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ width: "18px", height: "18px", mr: "7px" }}>
                    <UI.PaymentIcon currency={item?.payment_currency} />
                  </Box>
                  <Typography variant="h8" sx={{ lineHeight: "1.2" }}>
                    {`${item?.total_vat_amount_string}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          {item?.total_vat_amount_string === "0.00" && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant={"h10"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0px 14px",
                  mt: "15px",
                  height: "22px",
                  width: "fit-content",
                  borderRadius: "100px",
                  backgroundColor: (theme) => theme.palette.tag.red,
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                {t("commons.free")}
              </Typography>
            </Box>
          )}

          {item.nft_amount_limit < 999999 && (
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "block", textAlign: "end" }}>
                <Typography variant={"subtitle4"} sx={{ fontSize: "11px" }}>
                  {t("commons.claimed")}
                </Typography>
                <Box sx={{ display: "flex", textAlign: "end" }}>
                  <Typography variant={"h8"} sx={{ lineHeight: "1.2" }}>
                    {`${item.nft_amount_minted} / ${item.nft_amount_limit}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

const SkeletonCard = () => {
  return (
    <Card
      sx={{
        maxWidth: 345,
        margin: "auto",
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
      }}
    >
      <Skeleton
        variant="rectangular"
        width="100%"
        height={180}
        sx={{ backgroundColor: (theme) => theme.palette.bgTransparent.primary }}
      />
      <CardContent>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="40%" />
        <Skeleton variant="text" width="80%" />
      </CardContent>
    </Card>
  );
};

const CollectionsSlider = ({ onClick }) => {
  const collectionService = useCollectionService();

  const { data: collections } = CustomHooks.useFetch(
    ["collections_featured"],
    collectionService.featured
  );

  console.log("collections: ", collections);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Swiper
        grabCursor={true}
        slidesPerView={1.2}
        spaceBetween={20}
        slidesPerGroup={1}
        autoplay={{
          delay: 6000,
          disableOnInteraction: true,
          pauseOnMouseEnter: false,
        }}
        loop={true}
        pagination={true}
        modules={[Autoplay]}
        breakpoints={{
          500: { slidesPerView: 2, spaceBetween: 20 },
          800: { slidesPerView: 3, spaceBetween: 20 },
          1280: { slidesPerView: 4, spaceBetween: 20 },
          1700: { slidesPerView: 5, spaceBetween: 20 },
        }}
        className="collection-slider"
      >
        {collections?.length > 0
          ? collections.map((item) => (
              <SwiperSlide key={item.id}>
                <CollectionCard item={item} onClick={onClick} />
              </SwiperSlide>
            ))
          : [0, 1, 2, 3].map((index) => (
              <SwiperSlide key={index}>
                <SkeletonCard />
              </SwiperSlide>
            ))}
      </Swiper>
    </Box>
  );
};

export default CollectionsSlider;
