import React from "react";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MaterialDialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";

const Dialog = ({ open, onClose, data }) => {
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
          position: "relative",
          padding: { md: "60px 20px", mobile: "60px 20px" },
          width: "550px",
          margin: { mobile: "15px", md: "0px" },
        },
      }}
      open={open}
      onClose={onClose}
    >
      <CloseIcon
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 15,
          top: 15,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <DialogContent sx={{ p: "0px" }}>
        <Box sx={{ mt: "10px" }}>
          <Typography
            variant={"subtitle3"}
          >
            {data.text}
          </Typography>
        </Box>
      </DialogContent>
    </MaterialDialog>
  );
};

const Action = ({ element: Component, data }) => {
  const [open, setOpen] = React.useState(null);

  return (
    <>
      <Box
        sx={{ cursor: "pointer" }}
        component={"span"}
        onClick={() => setOpen(!open)}
      >
        {Component}
      </Box>

      <Dialog open={open} onClose={() => setOpen(false)} data={data} />
    </>
  );
};

Dialog.Action = Action;

export default Dialog;
