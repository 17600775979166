import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../../@components/UI";
import Paper from "@mui/material/Paper";
import { Controller, useFieldArray } from "react-hook-form";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import { useFormContext } from "react-hook-form";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AdditionalInfo from "./AdditionalInfo";
import CollectInfo from "./CollectInfo";
import Link from "@mui/material/Link";
import CustomActionButton from "../CustomActionDialog";
import AddField from "../AddField";
import LinkIcon from "@mui/icons-material/Link";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import Media from "./Media";
import MenuItem from "@mui/material/MenuItem";
import useEventVenue from "../../../../../@components/hooks/venues/useEventVenue";

const useStyles = makeStyles(() => ({
  inputOuter: {
    marginBottom: "25px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Form = ({ data: utility, index: utilityIndex, onRemove }) => {
  const classes = useStyles();
  const [customActionButton, setCustomActionButton] = React.useState(null);
  const { t } = useTranslation();
  const { getSupportedVenues } = useEventVenue();
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useFormContext();
  console.log("utility: ", utility);
  const {
    append,
    fields: customInfoFields,
    remove: removeCustomInfoFields,
  } = useFieldArray({
    control: control,
    name: `extra_utilities.${utilityIndex}.custom_info_fields`,
  });

  const seatReservationEnabled = watch(
    `extra_utilities.${utilityIndex}.seat_reservation_enabled`
  );

  const utilityType = getValues(`extra_utilities.${utilityIndex}.type`);

  React.useEffect(() => {
    //setCustomActionButton
    const button = customInfoFields.find((item) => item?.type === "button");
    if (button) {
      setCustomActionButton(button);
    }
  }, [customInfoFields]);

  const removeCustomActionButton = () => {
    const index = customInfoFields.findIndex((item) => item?.type === "button");
    if (index > -1) {
      removeCustomInfoFields(index);
    }
  };
  return (
    <Paper
      sx={{
        width: "100%",
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
        px: { md: "50px", mobile: "10px" },
        py: { md: "50px", mobile: "20px" },
      }}
    >
      <Box
        sx={{ mb: "30px", display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant={"h3"}>{utility.displayName}</Typography>
        <DeleteForeverIcon
          sx={{
            cursor: "pointer",
            color: (theme) => theme.palette.colors.darkRed,
            opacity: "0.8",
            "&:hover": {
              opacity: 1,
            },
          }}
          onClick={() => onRemove(utilityIndex)}
        />
      </Box>
      {utility.type === "discord_access" && (
        <Box>
          <Box sx={{ display: "flex", mb: "10px" }}>
            <Typography variant={"h6"}>
              Authorize the Qvrse bot to your server
            </Typography>
          </Box>
          <Box sx={{ mb: "20px" }}>
            <Alert variant="outlined" severity="info">
              {t("brands.collection.create.extraUtilities.discordBotAuth")}
              <Link
                target="_blank"
                href={
                  "https://discord.com/api/oauth2/authorize?client_id=1118596151332061367&permissions=1477737195543&scope=bot"
                }
                sx={{
                  color: (theme) => theme.palette.text.linkBlue,
                  textDecorationColor: (theme) => theme.palette.text.linkBlue,
                  ml: "5px",
                  "&:hover": {
                    color: (theme) => theme.palette.text.linkBlueHover,
                    textDecorationColor: (theme) =>
                      theme.palette.text.linkBlueHover,
                    transition: "all 0.3s ease-in-out",
                  },
                }}
              >
                {t(
                  "brands.collection.create.extraUtilities.discordBotAuthLinkText"
                )}
              </Link>
            </Alert>
          </Box>
        </Box>
      )}
      <Box className={classes.inputOuter}>
        <Box sx={{ display: "flex", mb: "10px" }}>
          <Typography variant={"h6"}>
            {" "}
            {t("brands.collection.create.extraUtilities.form.name.title")}
          </Typography>
        </Box>

        <Controller
          name={`extra_utilities.${utilityIndex}.name`}
          control={control}
          rules={{ required: true }}
          render={({ field }) => <UI.TextField aiButtonEnabled {...field} />}
        />
        {Object.keys(errors).length > 0 &&
          errors?.extra_utilities[utilityIndex]?.name?.type === "required" && (
            <Box sx={{ mt: "15px" }}>
              <Alert variant="outlined" severity="error">
                {t(
                  "brands.collection.create.extraUtilities.form.name.error.required"
                )}
              </Alert>
            </Box>
          )}
      </Box>
      <Box className={classes.inputOuter}>
        <Box sx={{ display: "flex", mb: "10px" }}>
          <Typography variant={"h6"}>
            {t(
              "brands.collection.create.extraUtilities.form.description.title",
              {
                utilityName: utility?.displayName,
              }
            )}
          </Typography>
        </Box>

        <Controller
          name={`extra_utilities.${utilityIndex}.description`}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <UI.TextField
              aiButtonEnabled
              multiline
              rows={4}
              {...field}
              placeholder={""}
            />
          )}
        />
        <Box>
          {Object.keys(errors).length > 0 &&
            errors?.extra_utilities[utilityIndex]?.description?.type ===
              "required" && (
              <Alert variant="outlined" severity="error">
                Description is required
              </Alert>
            )}
        </Box>
      </Box>
      <Media control={control} utilityIndex={utilityIndex} />

      <Box>
        <AdditionalInfo
          control={control}
          utilityIndex={utilityIndex}
          setCustomActionButton={setCustomActionButton}
          customActionButton={customActionButton}
        />
      </Box>
      {utilityType === "ticket" && (
        <Box className={classes.inputOuter}>
          <Box sx={{ display: "flex", mb: "5px", mt: "15px" }}>
            <Typography variant={"h6"}>
              {t("brands.collection.create.mainUtility.enableSeatReservation")}
            </Typography>
            <UI.Tooltip>
              {t(
                "brands.collection.create.mainUtility.seatReservation.tooltip"
              )}
            </UI.Tooltip>
          </Box>

          <UI.Checkbox
            onClick={() => {
              setValue(
                `extra_utilities.${utilityIndex}.seat_reservation_enabled`,
                false,
                {
                  shouldDirty: true,
                }
              );
            }}
            defaultChecked={true}
            value={seatReservationEnabled === false ? true : false}
            nomargin={"true"}
            label={
              <Box
                component={"span"}
                sx={{
                  fontWeight: "regular",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {t("commons.disable")}
              </Box>
            }
          />
          <UI.Checkbox
            onClick={() => {
              setValue(
                `extra_utilities.${utilityIndex}.seat_reservation_enabled`,
                true,
                {
                  shouldDirty: true,
                }
              );
            }}
            value={seatReservationEnabled === true}
            nomargin={"true"}
            label={
              <Box
                component={"span"}
                sx={{
                  fontWeight: "regular",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {t("commons.enable")}
              </Box>
            }
          />
          {seatReservationEnabled && (
            <Box sx={{ mt: "40px" }}>
              <Controller
                name={`extra_utilities.${utilityIndex}.seat_reservation_venue`}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <UI.Select
                    onChange={(e) => {
                      setValue(
                        `extra_utilities.${utilityIndex}.seat_reservation_venue`,
                        e.target.value
                      );
                    }}
                    {...field}
                  >
                    {getSupportedVenues().map((venue) => (
                      <MenuItem key={venue.id} value={venue.id}>
                        {venue.name}
                      </MenuItem>
                    ))}
                  </UI.Select>
                )}
              />
              {Object.keys(errors).length > 0 &&
                errors?.extra_utilities[utilityIndex]?.seat_reservation_venue
                  ?.type === "required" && (
                  <Box sx={{ mt: "15px" }}>
                    <Alert variant="outlined" severity="error">
                      {t(
                        "brands.collection.create.basicInfo.enable.error.required"
                      )}
                    </Alert>
                  </Box>
                )}
            </Box>
          )}
        </Box>
      )}
      <Box sx={{ mt: "10px", mb: "20px" }}>
        <CollectInfo control={control} utilityIndex={utilityIndex} />
      </Box>
      {customActionButton == null && (
        <Box sx={{ mt: "10px", mb: "20px" }}>
          <Box
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <CustomActionButton.Action
              title={"Add Custom Link"}
              onAdd={(customField) => {
                setCustomActionButton(customField);
                append(customField);
              }}
              element={
                <AddField
                  title={"Custom Link"}
                  subtitle={
                    "(Embed a custom link that redirects your customers to the page you've selected)"
                  }
                />
              }
            />
          </Box>
        </Box>
      )}
      {customActionButton != null && (
        <Box sx={{ mb: "10px", mt: "15px" }}>
          <Typography
            variant={"h6"}
            sx={{ mb: "9px" }}
          >{`Custom Link`}</Typography>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.open(customActionButton.value, "_blank");
              }}
            >
              <Tooltip title={customActionButton.name} arrow>
                <Box
                  sx={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    backgroundColor: "rgba(148, 77, 255, 1);",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LinkIcon />
                </Box>
              </Tooltip>
            </Box>
            <Box
              onClick={() => {
                setCustomActionButton(null);
                removeCustomActionButton();
              }}
              sx={{
                cursor: "pointer",
                ml: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <DeleteForeverIcon
                sx={{
                  color: (theme) => theme.palette.colors.darkRed,
                  opacity: "0.8",
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default Form;
