import React from "react";
import LogInDialog from "./LogInDialog";
import NoExistingAccountDialog from "./NoExistingAccountDialog";
import PasswordResetDialog from "./ResetPasswordDialog";
import RegisterDialog from "./RegisterDialog";
import EmailConfirmationDialog from "./EmailConfirmation";
import SingleSignOnDialog from "./SingleSignOnDialog";
import SingleSignOnPasswordDialog from "./SingleSignOnPasswordDialog";
import CreateSmartAccountDialog from "./CreateSmartAccountDialog";

const LogInDialogFlow = ({ trigger, onLogin }) => {
  const [triggerLoginDialog, setTriggerLoginDialog] = React.useState(null);
  const [triggerRegisterDialog, setTriggerRegisterDialog] =
    React.useState(null);
  const [triggerPasswordResetDialog, setTriggerPasswordResetDialog] =
    React.useState(null);
  const [noExistingAccountDialog, setNoExistingAccountDialog] =
    React.useState(null);
  const [triggerEmailConfirmationDialog, setTriggerEmailConfirmation] =
    React.useState(null);
  const [showSingleSignOnDialog, setShowSingleSignOnDialog] =
    React.useState(null);
  const [showSingleSignOnPasswordDialog, setShowSingleSignOnPasswordDialog] =
    React.useState(null);
  const [ssoEmail, setSSOEmail] = React.useState(null);
  React.useState(null);
  const [showSmartAccountDialog, setShowSmartAccountDialog] =
    React.useState(false);

  React.useEffect(() => {
    trigger && setTriggerLoginDialog(Math.random());
  }, [trigger]);

  return (
    <>
      <LogInDialog
        toggleOpen={triggerLoginDialog}
        showNoExistingAccountError={() => {
          setTriggerLoginDialog(Math.random());
          setNoExistingAccountDialog(Math.random());
        }}
        showCreateAccountDialog={() => {
          setTriggerLoginDialog(Math.random());
          setTriggerRegisterDialog(Math.random());
        }}
        showPasswordResetDialog={() => {
          setTriggerLoginDialog(Math.random());
          setTriggerPasswordResetDialog(Math.random());
        }}
        showSingleSignOnDialog={() => {
          setTriggerLoginDialog(Math.random());
          setShowSingleSignOnDialog(true);
        }}
        onSuccess={(smartAccountAddress) => {
          if (smartAccountAddress === null) {
            setShowSmartAccountDialog(true);
          }

          onLogin && onLogin();
        }}
      />
      <NoExistingAccountDialog toggleOpen={noExistingAccountDialog} />
      <PasswordResetDialog toggleOpen={triggerPasswordResetDialog} />
      <EmailConfirmationDialog toggleOpen={triggerEmailConfirmationDialog} />
      <RegisterDialog
        toggleOpen={triggerRegisterDialog}
        onRegister={() => {
          setTriggerEmailConfirmation(Math.random());
        }}
        onGoogleRegister={(smartAccountAddress) => {
          if (smartAccountAddress === null) {
            setShowSmartAccountDialog(true);
          }
        }}
        showLogInDialog={() => {
          setTriggerRegisterDialog(Math.random());
          setTriggerLoginDialog(Math.random());
        }}
      />
      <SingleSignOnDialog
        open={showSingleSignOnDialog}
        onClose={() => setShowSingleSignOnDialog(false)}
        onSuccess={(email) => {
          setShowSingleSignOnDialog(false);
          setSSOEmail(email);
          setShowSingleSignOnPasswordDialog(true);
        }}
        showLogInDialog={() => {
          setShowSingleSignOnDialog(false);
          setTriggerLoginDialog(Math.random());
        }}
        onNoExistingAccountError={() => {
          setShowSingleSignOnDialog(false);
          setNoExistingAccountDialog(Math.random());
        }}
      />
      <SingleSignOnPasswordDialog
        open={showSingleSignOnPasswordDialog}
        email={ssoEmail}
        onClose={() => setShowSingleSignOnPasswordDialog(false)}
        onSuccess={(smartAccountAddress) => {
          setShowSingleSignOnPasswordDialog(false);
          if (smartAccountAddress === null) {
            setShowSmartAccountDialog(true);
          }
        }}
        showLogInDialog={() => {
          setShowSingleSignOnPasswordDialog(false);
          setTriggerLoginDialog(Math.random());
        }}
        onNoExistingAccountError={() => {
          setShowSingleSignOnPasswordDialog(false);
          setNoExistingAccountDialog(Math.random());
        }}
      />
      <CreateSmartAccountDialog toggleOpen={showSmartAccountDialog} />
    </>
  );
};

export default LogInDialogFlow;
