import React from "react";
import CollectionSlider from "./CollectionSlider";
import Item from "./Item";
const Index = () => {
  return <div></div>;
};

Index.Slider = CollectionSlider;
Index.Slider.Item = Item;

export default Index;
