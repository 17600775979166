import React from "react";
import Skeleton from "@mui/material/Skeleton";

const SkeletonTicket = () => {
    return (
        <Skeleton
            sx={{
                height: { mobile: "390px", md: "476px" },
                width: { mobile: "250px", md: "300px" },
            }}
            variant={"rectangular"}
        />
    );
};

export default SkeletonTicket;