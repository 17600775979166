import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.text.secondary + "!important",
  },
}));

const SpeedDialTooltipOpen = ({ items }) => {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <Backdrop sx={{ zIndex: 1199 }} className={classes.root} open={open} />
      <SpeedDial
        ariaLabel="SpeedDial"
        sx={{
          zIndex: 1199,
          position: "fixed",
          bottom: 35,
          right: 25,

          "& .MuiFab-primary": {
            backgroundColor: (theme) => theme.palette.colors.primary,
            color: (theme) => theme.palette.text.primary,
            boxShadow: "#944dff 0px 0px 5px 0px",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.colors.primary,
            },
          },
        }}
        icon={<MoreHorizIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {items.map((item, index) => (
          <SpeedDialAction
            FabProps={{
              sx: {
                backgroundColor: (theme) => theme.palette.colors.primary,
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.colors.primary,
                },
              },
            }}
            key={index}
            icon={item.icon}
            tooltipTitle={<Typography variant={"h4"}>{item.title}</Typography>}
            tooltipOpen
            onClick={() => {
              setOpen(false);
              history.push({
                pathname: item.url,
                state: { scrollToTop: true },
              });
            }}
          />
        ))}
      </SpeedDial>
    </React.Fragment>
  );
};

export default SpeedDialTooltipOpen;
