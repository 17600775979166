import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "./TextField";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import InputAdornment from "@mui/material/InputAdornment";

const DateTime = React.forwardRef((props, ref) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileDateTimePicker
        {...props}
        ref={ref}
        disablePast
        ampm={false}
        inputFormat="DD MMM YYYY HH:mm"
        sx={{
          "& .MuiInputBase-root": {
            color: "red",
          },
        }}
        slotProps={{
          toolbar: {
            sx: {
              "& .MuiPickersToolbarText-root": {
                color: "red",
              },
            },
          },
          day: {
            sx: {
              color: "red",
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            endAdornment={
              <InputAdornment position="start">
                <CalendarMonthIcon
                  sx={{ color: (theme) => theme.palette.text.primary }}
                />
              </InputAdornment>
            }
          />
        )}
      />
    </LocalizationProvider>
  );
});

export default DateTime;
