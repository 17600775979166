import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const SkeletonList = () => {
  return (
    <Box>
      <Box
        sx={{
          cursor: "pointer",
          padding: { md: "10px 30px", mobile: "10px 0px" },
          mb: "15px",
          backgroundColor: (theme) => theme.palette.colors.lightGray,
          display: "flex",
          justifyContent: "space-between",
          "&:hover": {
            boxShadow: "#944dff 0px 0px 10px 0px",
            border: "1px solid #944dff",
          },
        }}
      >
        <Skeleton variant={"rectangular"} width={"100%"} height={"30px"} />
      </Box>
      <Box
        sx={{
          cursor: "pointer",
          padding: { md: "10px 30px", mobile: "10px 0px" },
          mb: "15px",
          backgroundColor: (theme) => theme.palette.colors.lightGray,
          display: "flex",
          justifyContent: "space-between",
          "&:hover": {
            boxShadow: "#944dff 0px 0px 10px 0px",
            border: "1px solid #944dff",
          },
        }}
      >
        <Skeleton variant={"rectangular"} width={"100%"} height={"30px"} />
      </Box>
      <Box
        sx={{
          cursor: "pointer",
          padding: { md: "10px 30px", mobile: "10px 0px" },
          mb: "15px",
          backgroundColor: (theme) => theme.palette.colors.lightGray,
          display: "flex",
          justifyContent: "space-between",
          "&:hover": {
            boxShadow: "#944dff 0px 0px 10px 0px",
            border: "1px solid #944dff",
          },
        }}
      >
        <Skeleton variant={"rectangular"} width={"100%"} height={"30px"} />
      </Box>
    </Box>
  );
};

export default SkeletonList;
