import React from "react";
import { useFieldArray, useForm, FormProvider } from "react-hook-form";
import { useFormData } from "../context";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../../@components/UI";
import { useUserContext } from "../../../../../@components/userContext";
import AccordionItem from "./AccordionItem";
import Select from "./Select";
import Form from "./Form";
import { useTranslation } from "react-i18next";
import HomeIcon from "@mui/icons-material/Home";
import { Breadcrumbs } from "@mui/material";
import { useHistory as userHistory } from "react-router-dom";

const Index = ({ DAO, draft, nextStep, previouseSteps, onSave }) => {
  const history = userHistory();
  const { t } = useTranslation();
  const { user: loggedInUser } = useUserContext();
  const { data, setFormValues } = useFormData();

  const methods = useForm({
    defaultValues: {
      extra_utilities: data?.extra_utilities || [],
    },
    mode: "all",
  });

  const {
    fields: extraUtilities,
    append: addExtraUtility,
    remove: removeExtraUtility,
  } = useFieldArray({
    control: methods.control,
    name: "extra_utilities",
  });

  // Reset form with draft data.
  React.useEffect(() => {
    if (draft) {
      setFormValues(draft.data);
      methods.reset({
        extra_utilities: draft.data.extra_utilities,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draft]);

  React.useEffect(() => {
    setFormValues({
      ...data,
      extra_utilities: extraUtilities,
    });
    // eslint-disable-next-line
  }, [extraUtilities]);

  const onSubmit = async (values, action) => {
    switch (action) {
      case "save_and_continue": {
        await onSave(values, false);
        nextStep();
        break;
      }
      case "save": {
        await onSave(values, true);
        break;
      }
      default: {
        await onSave(values, true);
        break;
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onKeyDown={handleKeyDown}
        onSubmit={methods.handleSubmit((values) =>
          onSubmit(values, "save_and_continue")
        )}
      >
        <Box
          sx={{
            mt: { md: "15px", mobile: "20px" },
            p: { md: "40px 65px", mobile: "0px 10px" },
            mb: "10px",
          }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  cursor: "pointer",
                  color: (theme) => theme.palette.text.primary,
                },
              }}
              onClick={() => {
                history.push(`/brand/${DAO.id}`);
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Home
            </Box>
            <Box
              underline="hover"
              sx={{
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  cursor: "pointer",
                  color: (theme) => theme.palette.text.primary,
                },
              }}
              onClick={() => {
                history.push(`/brand/${DAO.id}/collection`);
              }}
            >
              Collections
            </Box>
            <Box
              underline="hover"
              sx={{
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  cursor: "pointer",
                  color: (theme) => theme.palette.text.primary,
                },
              }}
              onClick={() => previouseSteps(2)}
            >
              Basic Info
            </Box>
            <Box
              underline="hover"
              sx={{
                display: "flex",
                alignItems: "center",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  cursor: "pointer",
                  color: (theme) => theme.palette.text.primary,
                },
              }}
              onClick={() => previouseSteps(1)}
            >
              Main Utility
            </Box>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              Extra Utilities
            </Typography>
          </Breadcrumbs>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              {extraUtilities.map((item, utilityIndex) => (
                <Box sx={{ mb: "50px" }} key={utilityIndex}>
                  <Form
                    data={item}
                    index={utilityIndex}
                    onRemove={(index) => removeExtraUtility(index)}
                  />
                </Box>
              ))}
              <Box sx={{ mb: "15px" }}>
                <Select onSelect={(utility) => addExtraUtility(utility)} />
              </Box>
            </Grid>

            <Box
              sx={{
                width: "100%",
                mt: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "210px" }}>
                  <UI.Button
                    disabled={
                      !!Object.keys(methods?.formState?.errors).length ||
                      !loggedInUser?.email_verified
                    }
                    title={t("buttons.saveAndContinue")}
                    type={"primary"}
                  />
                  <UI.Button
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const allValues = methods.getValues();
                      await onSubmit(allValues, "save");
                    }}
                    disabled={!loggedInUser?.email_verified}
                    sx={{
                      mt: "10px",
                      filter:
                        "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
                    }}
                    title={t("buttons.saveForLater")}
                    type={"tertiary"}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>
      </form>
    </FormProvider>
  );
};

Index.AccordionItem = AccordionItem;

export default Index;
