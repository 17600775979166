import { useAccount, useSignMessage, useDisconnect } from "wagmi";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useUserContext } from "../userContext";
import { useTranslation } from "react-i18next";
import { useLogout } from "@alchemy/aa-alchemy/react";

const gateway = window.env.GATEWAY;

const useAuth = () => {
  const { setUser } = useUserContext();
  const { logout: smartAccountLogout } = useLogout();
  const { i18n } = useTranslation();
  const { disconnect } = useDisconnect({
    onSettled(data, error) {
      setUser(null);
      history.push("/");
    },
  });
  const { address } = useAccount();
  const history = useHistory();
  const { signMessageAsync } = useSignMessage();

  const register = (data) => {
    return new Promise(async (resolve, reject) => {
      axios
        .postForm(`${gateway}/register`, data, {
          headers: { "Accept-Language": i18n.language },
        })
        .then(async (res) => {
          const { access, refresh } = res.data;
          access && localStorage.setItem("access_token", access);
          refresh && localStorage.setItem("refresh_token", refresh);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const login = (data) => {
    return new Promise(async (resolve, reject) => {
      axios
        .postForm(`${gateway}/login`, data, {
          headers: { "Accept-Language": i18n.language },
        })
        .then(async (res) => {
          const { access, refresh } = res.data;
          access && localStorage.setItem("access_token", access);
          refresh && localStorage.setItem("refresh_token", refresh);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const loginWithOTP = (data) => {
    return new Promise(async (resolve, reject) => {
      axios
        .postForm(`${gateway}/login_with_one_time_code`, data, {
          headers: { "Accept-Language": i18n.language },
        })
        .then(async (res) => {
          const { access, refresh } = res.data;
          access && localStorage.setItem("access_token", access);
          refresh && localStorage.setItem("refresh_token", refresh);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const loginWithGoogle = (access_token) => {
    return new Promise(async (resolve, reject) => {
      axios
        .post(
          `${gateway}/google_login`,
          {},
          {
            headers: {
              Authorization: `${access_token}`,
              "Content-Type": "application/json",
              "Accept-Language": i18n.language,
            },
          }
        )
        .then(async (res) => {
          const { access, refresh } = res.data;
          access && localStorage.setItem("access_token", access);
          refresh && localStorage.setItem("refresh_token", refresh);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const registerWithGoogle = (access_token) => {
    return new Promise(async (resolve, reject) => {
      axios
        .post(
          `${gateway}/google_register`,
          {},
          {
            headers: {
              Authorization: `${access_token}`,
              "Content-Type": "application/json",
              "Accept-Language": i18n.language,
            },
          }
        )
        .then(async (res) => {
          const { access, refresh } = res.data;
          access && localStorage.setItem("access_token", access);
          refresh && localStorage.setItem("refresh_token", refresh);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const registerSmartAccountUser = (data) => {
    return new Promise(async (resolve, reject) => {
      axios
        .post(`${gateway}/abstraction_register`, data, {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": i18n.language,
          },
        })
        .then(async (res) => {
          const { access, refresh } = res.data;
          access && localStorage.setItem("access_token", access);
          refresh && localStorage.setItem("refresh_token", refresh);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const addSmartAccountToExistingUser = (data) => {
    return new Promise(async (resolve, reject) => {
      const access_token = localStorage.getItem("access_token");

      if (access_token != null) {
        axios
          .post(`${gateway}/set_user_smart_address`, data, {
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
              "Accept-Language": i18n.language,
            },
          })
          .then(async (res) => {
            const { access, refresh } = res.data;
            access && localStorage.setItem("access_token", access);
            refresh && localStorage.setItem("refresh_token", refresh);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  };

  const logout = async () => {
    //await signer.disconnect();
    smartAccountLogout();
    // disconnect();
    localStorage.removeItem("access_token");
    setUser(null);
    history.push("/");
  };

  const connectWalletToAccount = () => {
    return new Promise(async (resolve, reject) => {
      const access_token = localStorage.getItem("access_token");

      if (access_token != null) {
        axios
          .get(`${gateway}/users/nonce`, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          })
          .then(async (res) => {
            const message = res.data;
            try {
              const signature = await signMessageAsync({
                message,
              });
              axios
                .post(
                  `${gateway}/users/${address}/signature`,
                  {
                    signature,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${access_token}`,
                      "Accept-Language": i18n.language,
                    },
                  }
                )
                .then((res) => {
                  resolve(res.data);
                })
                .catch((error) => {
                  if (error.response) {
                    reject(error);
                  } else {
                    reject({ type: "metamask", error });
                  }
                });
            } catch (error) {
              if (error.response) {
                reject(error);
              } else {
                reject({ type: "metamask", error });
              }
            }
          });
      } else {
        resolve(true);
      }
    });
  };

  const loginWithWallet = () => {
    return new Promise(async (resolve, reject) => {
      const access_token = localStorage.getItem("access_token");
      if (access_token == null) {
        axios
          .get(`${gateway}/users/${address}/nonce`)
          .then(async (res) => {
            const message = res.data;
            try {
              const signature = await signMessageAsync({
                message,
              });

              axios
                .post(
                  `${gateway}/users/${address}/signature`,
                  {
                    signature,
                  },
                  {
                    headers: {
                      "Accept-Language": i18n.language,
                    },
                  }
                )
                .then((res) => {
                  const { access, refresh } = res.data;
                  access && localStorage.setItem("access_token", access);
                  refresh && localStorage.setItem("refresh_token", refresh);
                  resolve(res.data);
                })
                .catch((error) => {
                  reject(error);
                });
            } catch (error) {
              if (error.response) {
                reject(error);
              } else {
                reject({ type: "metamask", error });
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              reject(error);
            } else {
              reject({ type: "metamask", error });
            }
          });
      } else {
        resolve(true);
      }
    });
  };

  const disconnectWallet = () => {
    disconnect();
  };

  return {
    addSmartAccountToExistingUser,
    registerSmartAccountUser,
    register,
    login,
    logout,
    loginWithGoogle,
    loginWithOTP,
    disconnectWallet,
    connectWalletToAccount,
    loginWithWallet,
    registerWithGoogle,
  };
};

export default useAuth;
