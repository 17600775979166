import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import UI from "../../../../../@components/UI";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CollectInfoDialog from "../CollectInfoDialog";
import AddField from "../AddField";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "25px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const CollectInfo = ({ utilityIndex }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: `extra_utilities.${utilityIndex}.request_info_fields`,
  });
  return (
    <>
      {fields.map((item, index) => (
        <Grid item md={12} mobile={12} key={index}>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h4"}>{item?.title}</Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Controller
                name={`extra_utilities.${utilityIndex}.request_info_fields.${index}.value`}
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <UI.TextField {...field} placeholder={item?.placeholder} />
                )}
              />
              <Box
                onClick={() => remove(index)}
                sx={{
                  cursor: "pointer",
                  ml: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <DeleteForeverIcon
                  sx={{
                    color: (theme) => theme.palette.colors.darkRed,
                    opacity: "0.8",
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
      <Box
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <CollectInfoDialog.Action
          title={t("brands.collection.import.extraUtilities.collectInfo.title")}
          onAdd={(newFields) => append(newFields)}
          element={
            <AddField
              title={t(
                "brands.collection.import.extraUtilities.collectInfo.action.title"
              )}
              subtitle={t(
                "brands.collection.import.extraUtilities.collectInfo.action.subtitle"
              )}
            />
          }
        />
      </Box>
    </>
  );
};

export default CollectInfo;
