import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AccordionItem = (props) => {
  const { t } = useTranslation();
  const type = props?.type;
  let displayValue = props?.value;
  let title = props?.title;

  if (type === "date" || title === "Date") {
    title = t(
      "brands.collection.create.mainUtility.form.formInputs.ticket.custom_info_fields.date"
    );
    displayValue = moment(props?.value).format("DD MMM YYYY");
  } else if (type === "time" || title === "Time") {
    title = t(
      "brands.collection.create.mainUtility.form.formInputs.ticket.custom_info_fields.time"
    );
    displayValue = moment(props?.value).format("HH:mm");
  } else if (type === "location" || title === "Location") {
    title = t(
      "brands.collection.create.mainUtility.form.formInputs.ticket.custom_info_fields.location"
    );
  }
  return (
    <Box sx={{ display: "flex", mb: "10px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant={"subtitle3"}>
          {`${title}${displayValue !== "" ? ":" : ""} `}
        </Typography>
        {!props.isButton && (
          <Typography
            variant={"h8"}
            sx={{
              overflowWrap: "anywhere",
              textTransform: "unset",
            }}
          >
            {displayValue}
          </Typography>
        )}
        {props.isButton && (
          <Link to={{ pathname: displayValue }} target="_blank">
            <Typography
              variant={"h8"}
              sx={{
                overflowWrap: "anywhere",
                textTransform: "unset",
                cursor: "pointer",
                textDecoration: "underline",
                transition: "all 0.2s ease-in-out",
                color: (theme) => theme.palette.text.link,
                "&:hover": {
                  color: (theme) => theme.palette.text.linkHover,
                },
              }}
            >
              {displayValue}
            </Typography>
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default AccordionItem;
