import React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import UI from "../../@components/UI";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Ticket from "./Ticket";
import Skeleton from "@mui/material/Skeleton";
import { useSnackbar } from "notistack";
import marketplacABI from "../../abis/contracts/marketplace/marketplace.json";
import { useTranslation } from "react-i18next";
import { ethers } from "ethers";
import CustomHooks from "../../@components/hooks";
import useService from "../useService";

import {
  useSendUserOperation,
  useSmartAccountClient,
} from "@alchemy/aa-alchemy/react";
import { encodeFunctionData } from "viem";
import useHandleErrors from "../../@components/hooks/useHandleErrors";
import { useUserContext } from "../../@components/userContext";
import truncateEthAddress from "truncate-eth-address";

const marketplaceAddress = "0xA6576936A91924486204F8D642a555Dcf5fe7590";

const Index = ({ DAO }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { listingId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { handleMetamaskError } = useHandleErrors();
  const [mainUtility] = React.useState(null);
  const service = useService();
  const [isLoading, setIsLoading] = React.useState(false);
  const { user: loggedInUser } = useUserContext();
  const [showPaymentDialog, setShowPaymentDialog] = React.useState(false);

  const { data } = CustomHooks.useFetch(
    ["marketplace"],
    () => service.listing(listingId),
    {
      fetchPolicy: "network-only",
    }
  );

  // const { mutate: updateMarketplace } = CustomHooks.usePost(
  //   service.updateMarketplace
  // );

  const { client: smartAccountClient } = useSmartAccountClient({
    type: "MultiOwnerModularAccount",
    gasManagerConfig: {
      policyId:
        window.env.CHAIN === "AMOY"
          ? "2a7bc98c-5422-4d90-a8b1-91de6fdadc0d"
          : "9e29758c-dcb6-43a1-85f5-2acfd5a0c060",
    },
    opts: {
      txMaxRetries: 20,
    },
  });

  const { sendUserOperation } = useSendUserOperation({
    client: smartAccountClient,
    onSuccess: ({ hash, request }) => {
      enqueueSnackbar("NFT sucesfully bought", { variant: "success" });
      setIsLoading(false);
      //updateMarketplace();
    },
    onError: (error) => {
      setIsLoading(false);
      handleMetamaskError(error);
    },
    waitForTxn: true,
  });

  const buyNFT = async () => {
    setIsLoading(true);

    const params = {
      listingId: listingId,
      noOfTokensToBuy: "1",
      brandFeeWallet: "0x4d48bEC025De3AD0f06aB8b8562C685c373f83bb",
      brandRoyaltyFeePercentage: "5",
    };

    sendUserOperation({
      uo: {
        target: marketplaceAddress,
        data: encodeFunctionData({
          abi: marketplacABI,
          functionName: "buyNFT",
          args: [params],
        }),
        value: data.price,
      },
    });
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingLeft: { md: "75px", mobile: "0px" },
        paddingRight: { md: "75px", mobile: "0px" },
      }}
    >
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <Paper elevation={0} sx={{ width: "100%", background: "transparent" }}>
          <Box
            sx={{
              display: { mobile: "none", md: "flex" },
              flexDirection: { md: "row", mobile: "column" },
              justifyContent: { md: "space-between", mobile: "center" },
              px: { md: "0px", mobile: "10px" },
              pb: "30px",
              pt: { md: "0px", mobile: "20px" },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: { md: "block", mobile: "none" } }}>
                <UI.BackButton
                  title={"Back to marketplace"}
                  onClick={() =>
                    history.push({
                      pathname: `/marketplace`,
                      state: { scrollToTop: true },
                    })
                  }
                  address={data?.seller}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              mt: { mobile: "15px", md: "0px" },
              mb: { mobile: "40px", md: "0px" },
              width: "100%",
              paddingLeft: { mobile: "10px", md: "0px" },
              paddingRight: { mobile: "10px", md: "0px" },
            }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item md={4} mobile={12}>
                <Box
                  sx={{
                    display: "flex",
                    mb: { mobile: "54px", md: "34px" },
                    justifyContent: { mobile: "center", md: "unset" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Ticket
                      isFreeNFT={false}
                      brandName={data?.dao_info?.name}
                      brandAvatar={data?.dao_info?.avatar_url}
                      name={data?.collection_info?.name}
                      name_slo={data?.collection_info?.name_slo}
                      fileType={data?.collection_info?.nft_file_type}
                      media={data?.collection_info?.media}
                      data={data?.collection_info?.nft_image_url}
                      tokenId={data?.token_id}
                      animationUrl={
                        data?.collection_info?.nft_animation_url || null
                      }
                      mainUtility={mainUtility}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "250px",
                    margin: "auto",
                    mt: "34px",
                    mb: "34px",
                    display: { md: "none", mobile: "block" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <UI.Button
                      onClick={() => setShowPaymentDialog(true)}
                      title={"Buy"}
                      type={"primary"}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={8} mobile={12}>
                <Box sx={{ ml: { md: "60px", mobile: "0px" } }}>
                  <Box sx={{ mb: "2px" }}>
                    {data?.dao_info?.name ? (
                      <Typography variant={"subtitle3"}>
                        {data?.dao_info?.name}
                      </Typography>
                    ) : (
                      <Skeleton
                        sx={{ mb: "10px" }}
                        height={"15px"}
                        width={"300px"}
                        variant={"rectangular"}
                      />
                    )}
                  </Box>
                  <Box sx={{ mb: "10px" }}>
                    {data?.collection_info?.name ? (
                      <Typography variant={"h2"}>
                        {data?.collection_info?.name_slo !== null &&
                        data?.collection_info?.name_slo !== "" &&
                        i18n.language === "sl"
                          ? data?.collection_info?.name_slo
                          : data?.collection_info?.name || ""}
                      </Typography>
                    ) : (
                      <Skeleton
                        height={"30px"}
                        width={"300px"}
                        variant={"rectangular"}
                      />
                    )}
                  </Box>
                  <Box sx={{ mb: "10px" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant={"subtitle1"}
                        sx={{
                          mr: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Seller:
                      </Typography>
                      {data?.seller ? (
                        <Typography variant={"subtitle2"}>
                          {" "}
                          <UI.CopyToClipboard
                            label={truncateEthAddress(data.seller)}
                            value={data.seller}
                            showBorder
                          />
                        </Typography>
                      ) : (
                        <Skeleton
                          height={"30px"}
                          width={"300px"}
                          variant={"rectangular"}
                        />
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      mt: { mobile: "0px", md: "28px" },
                      mb: { mobile: "24px", md: "26px" },
                      wordBreak: "break-word",
                    }}
                  >
                    {data?.collection_info?.description && (
                      <UI.ReadMore fontSize={"16px"} maxTextLength={400}>
                        {data?.collection_info?.description_slo !== null &&
                        data?.collection_info?.description_slo !== "" &&
                        i18n.language === "sl"
                          ? data?.collection_info?.description_slo
                          : data?.collection_info?.description || ""}
                      </UI.ReadMore>
                    )}
                  </Box>
                  {data ? (
                    <>
                      <Box
                        sx={{
                          mb: "11px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography variant={"subtitle1"}>
                            {t("commons.price")}
                          </Typography>
                          <Typography
                            variant={"subtitle1"}
                            sx={{
                              ml: "4.5px",
                              fontSize: "11px !important",
                              mt: "4.3px",
                            }}
                          >
                            {t("commons.withTax")}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box
                            sx={{
                              width: "20px",
                              height: "20px",
                              ml: "10px",
                            }}
                          >
                            <UI.PaymentIcon currency={"matic"} />
                          </Box>
                          <Typography
                            variant={"h1"}
                            sx={{
                              fontSize: "15px !important",
                              ml: "10px",
                              lineHeight: "unset !important",
                            }}
                          >
                            {`${ethers.formatEther(data?.price || 0)} MATIC`}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <Skeleton
                      sx={{ mb: "10px" }}
                      height={"15px"}
                      width={"300px"}
                      variant={"rectangular"}
                    />
                  )}

                  <Box
                    sx={{
                      display: { md: "block", mobile: "none" },
                      mt: "25px",
                      width: "250px",
                    }}
                  >
                    <UI.Button
                      onClick={() => setShowPaymentDialog(true)}
                      title={"Buy"}
                      type={"primary"}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={12} mobile={12}>
                <Box sx={{ mb: { mobile: "10px", md: "15px" } }}>
                  <Typography
                    variant={"h2"}
                    sx={{
                      fontSize: {
                        mobile: "18px !important",
                        md: "22px !important",
                      },
                    }}
                  >
                    {t("commons.perks")}
                  </Typography>
                </Box>
                <UI.Utilities
                  showCustomLinks={false}
                  gasFeesPayedBySystem={
                    data?.collection_info?.free_nft_gas_fee_payed_by_system
                  }
                  utilities={data?.collection_info?.utilities}
                  currency={data?.collection_info?.currency}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
      <UI.PaymentDialog
        loggedInUser={loggedInUser}
        currency={"MATIC"}
        paymentToken={{ type: "NATIVE", decimals: 18, address: "" }}
        isBusy={isLoading}
        price={ethers.formatEther(data?.price || 0)}
        open={showPaymentDialog}
        onConfirm={() => {
          setShowPaymentDialog(false);
          buyNFT();
        }}
        onClose={() => setShowPaymentDialog(false)}
      />
    </Box>
  );
};

export default Index;
