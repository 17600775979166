import React from "react";
import { ethers } from "ethers";
import collectionNativeDeployerArtifact from "../../../../../abis/contracts/modules/collection/matic/Deployer.sol/DeployerCollectionMatic.json";
import collectionFiatDeployerArtifact from "../../../../../abis/contracts/modules/collection/fiat/Deployer.sol/DeployerCollectionFiat.json";
import collectionFreeDeployerArtifact from "../../../../../abis/contracts/modules/collection/free/Deployer.sol/DeployerCollectionFree.json";
import collectionERC20DeployerArtifact from "../../../../../abis/contracts/modules/collection/erc20/CollectionERC20.sol/CollectionERC20.json";
import useQvrseService from "../../../../../qvrse/useService";
import useCollectionService from "../../../collection/useService";
import useHandleErrors from "../../../../../@components/hooks/useHandleErrors";
import { useAccount } from "wagmi";
import CustomHooks from "../../../../../@components/hooks";
import { useSmartAccountClient } from "@alchemy/aa-alchemy/react";
import { encodeFunctionData } from "viem";

const useDeployCollection = () => {
  const { address } = useAccount();
  const qvrseService = useQvrseService();
  const collectionService = useCollectionService();
  const [fee_address, setFeeAddress] = React.useState(null);
  const { handleApiError } = useHandleErrors();

  const { client: smartAccountClient } = useSmartAccountClient({
    type: "MultiOwnerModularAccount",
    gasManagerConfig: {
      policyId:
        window.env.CHAIN === "AMOY"
          ? "2a7bc98c-5422-4d90-a8b1-91de6fdadc0d"
          : "9e29758c-dcb6-43a1-85f5-2acfd5a0c060",
    },
    opts: {
      txMaxRetries: 20,
    },
  });

  const { refetch: fetchNativeCollectionDeployer } = CustomHooks.useFetch(
    ["collection_matic"],
    collectionService.getCollectionDeployer,
    {
      enabled: false,
    }
  );

  const { refetch: fetchERC20CollectionDeployer } = CustomHooks.useFetch(
    ["collection_erc20"],
    collectionService.getCollectionDeployer,
    {
      enabled: false,
    }
  );

  const { refetch: fetchERC20MultiImageCollectionDeployer } =
    CustomHooks.useFetch(
      ["collection_erc20_multi_image"],
      collectionService.getCollectionDeployer,
      {
        enabled: false,
      }
    );

  const { refetch: fetchFiatCollectionDeployer } = CustomHooks.useFetch(
    ["collection_fiat"],
    collectionService.getCollectionDeployer,
    {
      enabled: false,
    }
  );
  const { refetch: fetchFreeCollectionDeployer } = CustomHooks.useFetch(
    ["collection_free"],
    collectionService.getCollectionDeployer,
    {
      enabled: false,
    }
  );
  const { refetch: fetchFiatMultiCollectionDeployer } = CustomHooks.useFetch(
    ["collection_fiat_multi_image"],
    collectionService.getCollectionDeployer,
    {
      enabled: false,
    }
  );

  const { refetch: fetchNativeMultiCollectionDeployer } = CustomHooks.useFetch(
    ["collection_matic_multi_image"],
    collectionService.getCollectionDeployer,
    {
      enabled: false,
    }
  );
  const { refetch: fetchFreeMultiCollectionDeployer } = CustomHooks.useFetch(
    ["collection_free_multi_image"],
    collectionService.getCollectionDeployer,
    {
      enabled: false,
    }
  );

  const handleEventLogs = (uoReceipt) => {
    const collectionFreeDeployerABI = collectionFreeDeployerArtifact.abi.find(
      (item) => item.type === "event" && item.name === "CollectionDeployed"
    );

    const collectionFreeDeployerInterface = new ethers.Interface([
      collectionFreeDeployerABI,
    ]);

    // Retrieve the event fragment using getEvent and extract the topic
    const collectionDeployedEventFragment =
      collectionFreeDeployerInterface.getEvent("CollectionDeployed");
    const CollectionDeployedSignature =
      collectionDeployedEventFragment.topicHash;

    const logs = uoReceipt.logs;

    // Filter for the CollectionDeployed event using the signature
    const collectionDeployedLogs = logs.filter(
      (log) => log.topics[0] === CollectionDeployedSignature
    );

    // Decode the logs
    const decodedEvents = collectionDeployedLogs.map((log) => {
      return collectionFreeDeployerInterface.parseLog(log);
    });

    const deployedAddress = decodedEvents.length
      ? decodedEvents[0].args.collection // Adjust based on your event args
      : null;

    const blockNumber = parseInt(uoReceipt.receipt.blockNumber, 16);

    return { args: [deployedAddress], blockNumber };
  };

  React.useEffect(() => {
    qvrseService
      .fee_address()
      .then((response) => {
        const {
          data: { fee_address },
        } = response;
        setFeeAddress(fee_address);
      })
      .catch((error) => {
        handleApiError(error);
      });
    // eslint-disable-next-line
  }, []);

  const deployNativeCollection = async (formData, DAO) => {
    const response = await fetchNativeCollectionDeployer();
    const { data: collectionDeployerData } = response.data.data;
    //check if error is returned
    if (response.data.error) {
      handleApiError(response.data.error);
      return;
    }

    const config = {
      owner: DAO.maintainer_address || address,
      minters: DAO.system_minters_address_list,
      name: formData.basic_info.name,
      symbol: formData.basic_info.symbol,
      uri: formData.nft_ipfs_uri,
      price: ethers.parseUnits(
        String(formData.totalPrice),
        formData?.payment?.crypto?.token?.decimals || 18
      ),
      maxMintLimit: formData.basic_info.supply,
      treasuryAddress: DAO.treasury_address,
      feeReceiverAddress: fee_address,
      percentTreasuryFee: DAO.collection_buy_fee,
    };

    const uo = await smartAccountClient.sendUserOperation({
      uo: {
        target: collectionDeployerData?.address,
        data: encodeFunctionData({
          abi: collectionNativeDeployerArtifact.abi,
          functionName: collectionDeployerData.call_function_name,
          args: [config],
        }),
      },
    });

    await smartAccountClient.waitForUserOperationTransaction(uo);
    const uoReceipt = await smartAccountClient.getUserOperationReceipt(uo.hash);

    return handleEventLogs(uoReceipt);
  };

  const deployERC20Collection = async (formData, DAO) => {
    const response = await fetchERC20CollectionDeployer();
    const { data: collectionDeployerData } = response.data.data;
    //check if error is returned
    if (response.data.error) {
      handleApiError(response.data.error);
      return;
    }

    const config = {
      owner: DAO.maintainer_address || address,
      minters: DAO.system_minters_address_list,
      name: formData.basic_info.name,
      symbol: formData.basic_info.symbol,
      uri: formData.nft_ipfs_uri,
      price: ethers.parseUnits(
        String(formData.totalPrice),
        formData?.payment?.crypto?.token?.decimals || 18
      ),
      maxMintLimit: formData.basic_info.supply,
      treasuryAddress: DAO.treasury_address,
      payTokenAddress: formData.payment.crypto.token.address,
      feeReceiverAddress: fee_address,
      percentTreasuryFee: DAO.collection_buy_fee,
    };

    const uo = await smartAccountClient.sendUserOperation({
      uo: {
        target: collectionDeployerData?.address,
        data: encodeFunctionData({
          abi: collectionERC20DeployerArtifact.abi,
          functionName: collectionDeployerData.call_function_name,
          args: [config],
        }),
      },
    });

    await smartAccountClient.waitForUserOperationTransaction(uo);
    const uoReceipt = await smartAccountClient.getUserOperationReceipt(uo.hash);

    return handleEventLogs(uoReceipt);
  };

  const deployFiatCollection = async (formData, DAO) => {
    const response = await fetchFiatCollectionDeployer();
    const { data: collectionDeployerData } = response.data.data;
    //check if error is returned
    if (response.data.error) {
      handleApiError(response.data.error);
      return;
    }

    const uo = await smartAccountClient.sendUserOperation({
      uo: {
        target: collectionDeployerData?.address,
        data: encodeFunctionData({
          abi: collectionFiatDeployerArtifact.abi,
          functionName: collectionDeployerData.call_function_name,
          args: [
            DAO.maintainer_address || address,
            DAO.system_minters_address_list,
            formData.basic_info.name,
            formData.basic_info.symbol,
            formData.nft_ipfs_uri,
            formData.basic_info.supply,
          ],
        }),
      },
    });

    await smartAccountClient.waitForUserOperationTransaction(uo);
    const uoReceipt = await smartAccountClient.getUserOperationReceipt(uo.hash);

    return handleEventLogs(uoReceipt);
  };

  const deployERC20MultiUploadCollection = async (formData, DAO) => {
    const response = await fetchERC20MultiImageCollectionDeployer();

    const { data: collectionDeployerData } = response.data.data;
    //check if error is returned
    if (response.data.error) {
      handleApiError(response.data.error);
      return;
    }

    const config = {
      owner: DAO.maintainer_address || address,
      minters: DAO.system_minters_address_list,
      name: formData.basic_info.name,
      symbol: formData.basic_info.symbol,
      uri: formData.nft_ipfs_uri,
      price: ethers.parseUnits(
        String(formData.totalPrice),
        formData?.payment?.crypto?.token?.decimals || 18
      ),
      maxMintLimit: formData.basic_info.supply,
      treasuryAddress: DAO.treasury_address,
      payTokenAddress: formData.payment.crypto.token.address,
      feeReceiverAddress: fee_address,
      percentTreasuryFee: DAO.collection_buy_fee,
    };

    const uo = await smartAccountClient.sendUserOperation({
      uo: {
        target: collectionDeployerData?.address,
        data: encodeFunctionData({
          abi: collectionERC20DeployerArtifact.abi,
          functionName: collectionDeployerData.call_function_name,
          args: [config],
        }),
      },
    });

    await smartAccountClient.waitForUserOperationTransaction(uo);
    const uoReceipt = await smartAccountClient.getUserOperationReceipt(uo.hash);

    return handleEventLogs(uoReceipt);
  };

  const deployNativeMultiUploadCollection = async (formData, DAO) => {
    const response = await fetchNativeMultiCollectionDeployer();
    const { data: collectionDeployerData } = response.data.data;
    //check if error is returned
    if (response.data.error) {
      handleApiError(response.data.error);
      return;
    }

    const config = {
      owner: DAO.maintainer_address || address,
      minters: DAO.system_minters_address_list,
      name: formData.basic_info.name,
      symbol: formData.basic_info.symbol,
      contractUri: formData.nft_ipfs_uri,
      price: ethers.parseUnits(
        String(formData.totalPrice),
        formData?.payment?.crypto?.token?.decimals || 18
      ),
      maxMintLimit: formData.basic_info.supply,
      treasuryAddress: DAO.treasury_address,
      feeReceiverAddress: fee_address,
      percentTreasuryFee: DAO.collection_buy_fee,
    };

    const uo = await smartAccountClient.sendUserOperation({
      uo: {
        target: collectionDeployerData?.address,
        data: encodeFunctionData({
          abi: collectionNativeDeployerArtifact.abi,
          functionName: collectionDeployerData.call_function_name,
          args: [config],
        }),
      },
    });

    await smartAccountClient.waitForUserOperationTransaction(uo);
    const uoReceipt = await smartAccountClient.getUserOperationReceipt(uo.hash);

    return handleEventLogs(uoReceipt);
  };
  const deployFiatMultiUploadCollection = async (formData, DAO) => {
    const response = await fetchFiatMultiCollectionDeployer();
    const { data: collectionDeployerData } = response.data.data;
    //check if error is returned
    if (response.data.error) {
      handleApiError(response.data.error);
      return;
    }

    const uo = await smartAccountClient.sendUserOperation({
      uo: {
        target: collectionDeployerData?.address,
        data: encodeFunctionData({
          abi: collectionFiatDeployerArtifact.abi,
          functionName: collectionDeployerData.call_function_name,
          args: [
            DAO.maintainer_address || address,
            DAO.system_minters_address_list,
            formData.basic_info.name,
            formData.basic_info.symbol,
            formData.nft_ipfs_uri,
            formData.basic_info.supply,
          ],
        }),
      },
    });

    await smartAccountClient.waitForUserOperationTransaction(uo);
    const uoReceipt = await smartAccountClient.getUserOperationReceipt(uo.hash);

    return handleEventLogs(uoReceipt);
  };

  const deployFreeCollection = async (formData, DAO) => {
    const response = await fetchFreeCollectionDeployer();
    const { data: collectionDeployerData } = response.data.data;
    //check if error is returned
    if (response.data.error) {
      handleApiError(response.data.error);
      return;
    }

    const uo = await smartAccountClient.sendUserOperation({
      uo: {
        target: collectionDeployerData?.address,
        data: encodeFunctionData({
          abi: collectionFreeDeployerArtifact.abi,
          functionName: collectionDeployerData.call_function_name,
          args: [
            DAO.maintainer_address || address,
            DAO.system_minters_address_list,
            formData.basic_info.name,
            formData.basic_info.symbol,
            formData.nft_ipfs_uri,
            formData.basic_info.supply,
          ],
        }),
      },
    });

    await smartAccountClient.waitForUserOperationTransaction(uo);
    const uoReceipt = await smartAccountClient.getUserOperationReceipt(uo.hash);

    return handleEventLogs(uoReceipt);
  };

  const deployFreeMultiCollection = async (formData, DAO) => {
    const response = await fetchFreeMultiCollectionDeployer();
    const { data: collectionDeployerData } = response.data.data;
    //check if error is returned
    if (response.data.error) {
      handleApiError(response.data.error);
      return;
    }

    const uo = await smartAccountClient.sendUserOperation({
      uo: {
        target: collectionDeployerData?.address,
        data: encodeFunctionData({
          abi: collectionFreeDeployerArtifact.abi,
          functionName: collectionDeployerData.call_function_name,
          args: [
            DAO.maintainer_address || address,
            DAO.system_minters_address_list,
            formData.basic_info.name,
            formData.basic_info.symbol,
            formData.nft_ipfs_uri,
            formData.basic_info.supply,
          ],
        }),
      },
    });

    await smartAccountClient.waitForUserOperationTransaction(uo);
    const uoReceipt = await smartAccountClient.getUserOperationReceipt(uo.hash);

    return handleEventLogs(uoReceipt);
  };

  return {
    deployNativeCollection,
    deployFiatCollection,
    deployNativeMultiUploadCollection,
    deployFiatMultiUploadCollection,
    deployFreeCollection,
    deployFreeMultiCollection,
    deployERC20Collection,
    deployERC20MultiUploadCollection,
  };
};

export default useDeployCollection;
