import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslatedFormInputs } from "../formInputs";
import { useFormData } from "../context";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "./styles.css";
// Styles must use direct files imports
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next"; // Pagination module
import { useTheme } from "@mui/material/styles";

const Item = ({ selected, index, onSelect, title }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: isMobile ? "170px" : "190px",
        height: isMobile ? "60px" : "60px",
        display: "flex",
        alignItems: "center",
        background: selected
          ? (theme) => theme.palette.bgTransparent.secondary
          : theme.palette.bgTransparent.primary,
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",
        marginLeft: "4px",
        marginRight: "4px",
        borderRadius: (theme) => theme.borderRadius.secondary,
        "&:hover": {
          background: (theme) => theme.palette.bgTransparent.secondary,
        },
      }}
      onClick={() => onSelect(index)}
    >
      <Typography
        sx={{
          margin: "0 auto",
          fontWeight: "bold",
          fontSize: { mobile: "12px", md: "15px" },
          padding: "0 10px",
        }}
      >
        {title.toUpperCase()}
      </Typography>
    </Box>
  );
};

const Desktop = ({ selected, setSelected, onSelect }) => {
  const formInputs = useTranslatedFormInputs();
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Item
        selected={selected === 0}
        onSelect={() => {
          setSelected(0);
          onSelect(formInputs.digitalContent);
        }}
        index={0}
        title={t("brands.collection.create.mainUtility.select.utility1")}
      />
      <Item
        selected={selected === 1}
        onSelect={() => {
          setSelected(1);
          onSelect(formInputs.product);
        }}
        index={1}
        title={t("brands.collection.create.mainUtility.select.utility2")}
      />
      <Item
        selected={selected === 2}
        onSelect={() => {
          setSelected(2);
          onSelect(formInputs.service);
        }}
        index={2}
        title={t("brands.collection.create.mainUtility.select.utility3")}
      />
      <Item
        selected={selected === 3}
        onSelect={() => {
          setSelected(3);
          onSelect(formInputs.ticket);
        }}
        index={3}
        title={t("brands.collection.create.mainUtility.select.utility4")}
      />
    </Box>
  );
};

const Mobile = ({ selected, setSelected, onSelect }) => {
  const { t } = useTranslation();
  const formInputs = useTranslatedFormInputs();
  return (
    <Swiper
      spaceBetween={5}
      grabCursor={true}
      slidesPerView={3}
      className="collection-category-swiper"
    >
      <SwiperSlide>
        <Item
          selected={selected === 0}
          onSelect={() => {
            setSelected(0);
            onSelect(formInputs.digitalContent);
          }}
          index={0}
          title={t("brands.collection.create.mainUtility.select.utility1")}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Item
          selected={selected === 1}
          onSelect={() => {
            setSelected(1);
            onSelect(formInputs.product);
          }}
          index={1}
          title={t("brands.collection.create.mainUtility.select.utility2")}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Item
          selected={selected === 2}
          onSelect={() => {
            setSelected(2);
            onSelect(formInputs.service);
          }}
          index={2}
          title={t("brands.collection.create.mainUtility.select.utility3")}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Item
          selected={selected === 3}
          onSelect={() => {
            setSelected(3);
            onSelect(formInputs.ticket);
          }}
          index={3}
          title={t("brands.collection.create.mainUtility.select.utility4")}
        />
      </SwiperSlide>
    </Swiper>
  );
};

const Select = ({ draft, onSelect }) => {
  const [selected, setSelected] = React.useState(0);
  const { data } = useFormData();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  React.useEffect(() => {
    if (data && draft) {
      let formData = {};
      if (draft !== null) {
        formData = draft.data;
      } else {
        formData = data;
      }
      const {
        main_utility: { type },
      } = formData;

      if (type === "digital_content") {
        setSelected(0);
      } else if (type === "product") {
        setSelected(1);
      } else if (type === "service") {
        setSelected(2);
      } else if (type === "ticket") {
        setSelected(3);
      }
    }
  }, [data, draft]);
  return (
    <Box sx={{ width: "100%" }}>
      {!isMobile ? (
        <Desktop
          selected={selected}
          onSelect={onSelect}
          setSelected={setSelected}
        />
      ) : (
        <Mobile
          selected={selected}
          onSelect={onSelect}
          setSelected={setSelected}
        />
      )}
    </Box>
  );
};

export default Select;
