import React from "react";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import Tooltip from "@mui/material/Tooltip";
import collectionIcon from "../../../static/collection-icon.png";
import collectionIconDark from "../../../static/collection-icon-dark.png";
import PushPinIcon from "@mui/icons-material/PushPin";
import UI from "../../../@components/UI";
import DeleteDialog from "./DeleteDialog";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PreviewSwiper from "./PreviewSwiper";
import Poll from "./poll";
import PublicIcon from '@mui/icons-material/Public';
import { useTheme } from '@mui/material/styles';


const Item = ({
  DAO,
  data,
  isBrandMember,
  onRemove,
  onPinPost,
  refetch,
  noMargin,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getMaxTextLength = () => {
    if (data?.files?.length > 0) return 200;
    if (data?.content?.type === "poll") return 400;
    return 800;
  };

  return (
    <Paper
      sx={{
        backgroundColor: "transparent",
        mb: noMargin ? "0" : "25px",
        width: "100%",
        position: "relative",
        "&:hover .removeIcon": {
          display: "block",
          transition: "all 0.2s ease-in-out",
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.bgTransparent.primary,
            borderRadius: "10px 10px 0 0 ",
            display: "flex",
            p: { md: "20px 20px 16px", mobile: "20px 10px 16px" },
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                mr: { md: "14px", mobile: "10px" },
              }}
            >
              <Avatar src={DAO?.avatar_url} alt={"avatar"} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant={"h8"}>{DAO?.name}</Typography>
              <Typography
                variant={"subtitle4"}
                sx={{
                  display: { md: "flex", mobile: "flex" },
                }}
              >
                {moment(data.datetime_created).format("DD MMM YYYY HH:mm")}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {data.is_public ? (
                <Tooltip
                  sx={{
                    width: "16px",
                    cursor: "pointer",
                    ml: "5px",
                    color: (theme) => theme.palette.text.primary,
                  }}
                  title={t("commons.public")}
                  placement="top"
                  arrow
                >
                  <PublicIcon sx={{ color: (theme) => theme.palette.text.tertiary, fontSize: "20px", transition: "all 0.2s ease-in-out", "&:hover": {color: (theme) => theme.palette.text.primary,} }}></PublicIcon>
                </Tooltip>
              ) : (
                <Tooltip
                  sx={{
                    width: "16px",
                    cursor: "pointer",
                    ml: "5px",
                    color: (theme) => theme.palette.text.primary,
                  }}
                  title={`${t("brands.feed.tooltip")} ${data.collections.map(
                    (collection) => " " + collection.name
                  )}`}
                  placement="top"
                  arrow
                >
                {theme.palette.type === "dark" ?
                  <Box
                    component={"img"}
                    sx={{
                      width: "23.3px",
                      height: "17px",
                      marginRight: "7px",
                      opacity: "0.7",
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        opacity: "1",
                      }
                    }}
                    src={collectionIcon}
                  /> :
                  <Box
                    component={"img"}
                    sx={{
                      width: "23.3px",
                      height: "17px",
                      marginRight: "7px",
                      opacity: "0.7",
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        opacity: "1",
                      }
                    }}
                    src={collectionIconDark}
                  />
                }
                </Tooltip>
              )}
            </Box>
            {isBrandMember && (
              <Box
                onClick={() => onPinPost(data.id)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Tooltip
                  sx={{
                    width: "19px",
                    cursor: "pointer",
                    ml: "6px",
                    color: (theme) => ({
                      color: data?.pinned_post ? theme.palette.text.primary : theme.palette.text.tertiary,
                      "&:hover": {
                        color: theme.palette.text.primary
                      }
                    })
                  }}
                  title={
                    data?.pinned_post
                      ? t("brands.feed.tooltip2")
                      : t("brands.feed.tooltip3")
                  }
                  placement="top"
                  arrow
                >
                  <PushPinIcon />
                </Tooltip>
              </Box>
            )}
            {isBrandMember && (
              <Box
                sx={{
                  display: "flex",
                  ml: "2px",
                }}
              >
                <UI.VertMenu 
                  fontSize={"1.5rem"}
                >
                  <DeleteDialog.Action
                    element={
                      <MenuItem
                        disabled={!isBrandMember}
                        sx={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          fontSize: "12px",
                          mx: "10px",
                          transition: "all 0.2s ease-in-out",
                          "&: hover": {
                            background: (theme) => theme.palette.bgTransparent.primary,
                          }
                        }}
                      >
                        <ListItemIcon sx={{ mr: "4px" }}>
                          <DeleteOutlineIcon
                            sx={{
                              color: "#944DFF",
                              fontSize: "20px !important",
                            }}
                          />
                        </ListItemIcon>
                        {t("actions.removePost")}
                      </MenuItem>
                    }
                    onConfirm={() => onRemove(data.id)}
                  />
                </UI.VertMenu>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            px: { md: "20px", mobile: "10px" },
            pb: "10px",
            backgroundColor: (theme) => theme.palette.bgTransparent.primary,
            borderRadius: "0",
          }}
        >
          <UI.ReadMoreDescription
            maxTextLength={getMaxTextLength()}
            sx={{
              overflowWrap: "break-word",
            }}
          >
            {data.description}
          </UI.ReadMoreDescription>
        </Box>
        {data.files !== null && data.files.length > 0 && (
          <PreviewSwiper items={data.files} />
        )}
        {data.iframe_url !== null && (
          <Box
            sx={{
              position: "relative",
              background: "#000",
            }}
          >
            <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
              <iframe
                src={data.iframe_url}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="PODKASST MRFI 1_1"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </Box>
        )}
        {data.content?.type === "poll" && (
          <Box>
            <Poll
              daoId={DAO?.id}
              postId={data?.id}
              data={data.content}
              refetch={refetch}
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default Item;
