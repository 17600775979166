import Gateway from "../../../../@components/gateway";

const useService = (daoId, postId) => {
  const gateway = Gateway.useGateway();

  const vote = (optionId) => {
    return gateway.postForm(`/dao/${daoId}/gated_content/${postId}/vote/`, {
      option_id: optionId,
    });
  };

  return {
    vote,
  };
};

export default useService;
