import React from "react";
import MaterialButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const QvrsButton = styled(MaterialButton)(
  ({ theme, type, ismobile, height }) => ({
    color:
        (type === "primary" && "#fff") ||
        (type === "button" && "#fff") ||
        (type === "secondary" && theme.palette.text.primary) ||
        (type === "tertiary" && theme.palette.text.primary) ||
        (type === "delete" && "#fff"),
    border: "1px solid transparent",
    borderRadius: theme.borderRadius.secondary,
    boxShadow: "none",
    backgroundColor:
      (type === "primary" && theme.palette.buttons.primary) ||
      (type === "button" && theme.palette.buttons.primary) ||
      (type === "secondary" && theme.palette.bgTransparent.quaternary) ||
      (type === "tertiary" && theme.palette.buttons.tertiary) ||
      (type === "delete" && "rgba(255, 0, 0, 0.85)"),
    "&:hover": {
      filter: 
      (type === "primary" && "drop-shadow(0px 0px 5px rgba(138, 38, 255, 0.2))") ||
      (type === "button" && "drop-shadow(0px 0px 5px rgba(138, 38, 255, 0.2))") ||
      (type === "secondary" && "drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.15))") ||
      (type === "delete" && "drop-shadow(0px 0px 8px rgba(255, 0, 0, 0.35))"),
      transition: "all 0.2s ease-in-out",
      boxShadow: "none",
      backgroundColor:
        (type === "primary" && "#b185f2") ||
        (type === "button" && "#b185f2") ||
        (type === "secondary" && theme.palette.bgTransparent.secondary) ||
        (type === "tertiary" && "unset") ||
        (type === "delete" && "rgba(255, 0, 0, 0.65)"),
      color:
        (type === "primary" && "#fff") ||
        (type === "button" && "#fff") ||
        (type === "secondary" && theme.palette.text.primary) ||
        (type === "tertiary" && theme.palette.text.secondary) ||
        (type === "delete" && "#fff"),
    },
    "&:disabled": {
      color: theme.palette.text.primary,
      opacity: "0.26"
    },

    width: "100%",
    height: ismobile === "true" ? "42px" : "46px",
    fontFamily: "Quantico",
    fontWeight: "700",
    fontSize: "16px",
  })
);

const Button = ({ title, type, onClick, ...props }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <QvrsButton
      {...props}
      onClick={onClick}
      variant={"contained"}
      ismobile={isMobile ? "true" : "false"}
      type={type}
    >
      {title}
    </QvrsButton>
  );
};

export default Button;