import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../@components/UI";
import CustomHooks from "../../../../@components/hooks";
import useCollectionService from "../useService";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// Styles must use direct files imports
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./cardsStyles.css";

import { EffectCards } from "swiper/modules";
import { useTranslation } from "react-i18next";

const CardItem = ({ item }) => {
  const history = useHistory();
  return (
    <Box
      onClick={() => {
        history.push({
          pathname: `/profile/nft/${item.id}`,
          state: { scrollToTop: true },
        });
      }}
      key={item.id}
      sx={{
        mr: "20px",
        borderRadius: (theme) => theme.borderRadius.secondary,
        position: "relative",
        cursor: "pointer",
        width: "102px",
        height: "156px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: "#944dff 0px 0px 0px 0px",
        border: "1px solid transparent",
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
        "&:hover": {
          boxShadow: "#944dff 0px 0px 15px 0px",
          border: "1px solid #944dff",
          transition: "all 0.3s ease-in-out",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "28px",
        }}
      >
        <Typography
          variant={"h9"}
          sx={{
            p: "8px",
            fontSize: "11px !important",
            maxWidth: "90px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <UI.OverflowTip>{`#${item?.nft_id}`}</UI.OverflowTip>
        </Typography>
      </Box>

      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100px",
          height: "100px",
          overflow: "hidden",
        }}
      >
        <Box
          component={"span"}
          sx={{
            boxSizing: "border-box",
            display: "block",
            overflow: "hidden",
            width: "initial",
            height: "initial",
            background: "none",
            opacity: 1,
            border: "0px",
            margin: "0px",
            padding: "0px",
            position: "absolute",
            inset: 0,
            lineHeight: "0",
            fontSize: "0",
          }}
        >
          {item?.image_file_type?.startsWith("video") && (
            <video
              playsInline
              width={"100%"}
              height={"auto"}
              autoPlay="autoplay"
              loop
            >
              <source src={item?.nft_image_url} />
            </video>
          )}
          {item?.image_file_type?.startsWith("image") && (
            <UI.ImageWithSkeleton
              sx={{
                position: "absolute",
                inset: 0,
                boxSizing: "border-box",
                padding: 0,
                border: "none",
                margin: "auto",
                display: "block",
                width: 0,
                height: 0,
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: "100%",
                maxHeight: "100%",
                objectFit: "cover",
              }}
              src={item?.nft_image_url}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const CardItemMobile = ({ item }) => {
  const history = useHistory();
  return (
    <Box
      key={item.id}
      onClick={() => {
        history.push({
          pathname: `/profile/nft/${item.id}`,
          state: { scrollToTop: true },
        });
      }}
      sx={{
        backgroundColor: (theme) => theme.palette.colors.darkGray,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        borderRadius: (theme) => theme.borderRadius.secondary,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          borderTop: "2px solid #944DFF",
          borderLeft: "2px solid #944DFF",
          borderRight: "2px solid #944DFF",
          height: "50px",
          alignItems: "center",
          borderRadius: "5px 5px 0 0",
        }}
      >
        <Typography
          variant={"h8"}
          sx={{
            p: "8px",
            maxWidth: "185.4px",
          }}
        >
          {`#${item?.nft_id}`}
        </Typography>
      </Box>
      {item.image_file_type.startsWith("image") && (
        <Box
          sx={{
            position: "relative",
            height: "200px",
            width: "auto",
            maxHeight: "100%",
            maxWidth: "100%",
            overflow: "hidden",
            borderRadius: "0rem",
            background:
              "linear-gradient(180deg, rgb(8 10 25 / 0%) 54.17%, rgb(8 10 25 / 68%) 100%)",
            borderLeft: "2px solid #944DFF",
            borderRight: "2px solid #944DFF",
          }}
        >
          <UI.ImageWithSkeleton
            sx={{
              inset: "0px",
              boxSizing: "border-box",
              padding: "0px",
              border: "none",
              margin: "auto",
              display: "block",
              width: "0px",
              height: "0px",
              minWidth: "100%",
              maxWidth: "100%",
              minHeight: "100%",
              maxHeight: "100%",
              objectFit: "cover",
              transition: "all 0.3s ease-in-out",
            }}
            src={item.nft_image_url}
          />
        </Box>
      )}
      {item.image_file_type.startsWith("video") && (
        <Box
          sx={{
            position: "relative",
            height: "200px",
            width: "auto",
            maxHeight: "100%",
            maxWidth: "100%",
            overflow: "hidden",
            borderRadius: "0",
            background:
              "linear-gradient(180deg, rgb(8 10 25 / 0%) 54.17%, rgb(8 10 25 / 68%) 100%)",
            borderLeft: "1px solid #944DFF",
            borderRight: "1px solid #944DFF",
          }}
        >
          <Box
            sx={{
              inset: "0px",
              boxSizing: "border-box",
              padding: "0px",
              border: "none",
              margin: "auto",
              display: "block",
              width: "0px",
              height: "0px",
              minWidth: "100%",
              maxWidth: "100%",
              minHeight: "100%",
              maxHeight: "100%",
              objectFit: "cover",
              transition: "all 0.3s ease-in-out",
            }}
          >
            <video
              playsInline
              width={"fit-content"}
              height={"100%"}
              autoplay="autoplay"
              loop
              muted
            >
              <source src={item.nft_image_url} />
            </video>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          borderLeft: "2px solid #944DFF",
          borderRight: "2px solid #944DFF",
          borderBottom: "2px solid #944DFF",
          height: "50px",
          alignItems: "center",
          borderRadius: "0 0 5px 5px",
        }}
      ></Box>
    </Box>
  );
};

const UserNFTS = ({ DAO, collectionId }) => {
  const { t } = useTranslation();
  const collectionService = useCollectionService(DAO?.id);

  const { data: userNFTs, refetch } = CustomHooks.useFetch(
    ["userNFTs"],
    () => collectionService.getUserNFTs(collectionId),
    {
      enabled: false,
    }
  );

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  React.useEffect(() => {
    DAO && collectionId && refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DAO, collectionId]);
  return (
    <>
      {userNFTs && userNFTs?.length > 0 && (
        <Box sx={{ mb: "40px" }}>
          <Box
            sx={{
              height: "46px",
              minHeight: "54px !important",
              p: "0",
            }}
          >
            {" "}
            <Typography
              variant={"h2"}
              sx={{
                fontSize: {
                  mobile: "18px !important",
                  md: "22px !important",
                },
              }}
            >
              {t("commons.collectedCards")}
            </Typography>
          </Box>
          {!isMobile && (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {userNFTs?.map((item) => (
                <div key={item.id}>
                  <CardItem item={item} />
                </div>
              ))}
            </Box>
          )}
          {isMobile && (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Swiper
                effect={"cards"}
                grabCursor={true}
                preventClicks={false}
                preventClicksPropagation={false}
                modules={[EffectCards]}
                className="userNFTSwiper"
              >
                {userNFTs?.map((item, index) => (
                  <SwiperSlide key={"userNFTSwiper_" + index}>
                    <CardItemMobile item={item} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default UserNFTS;
