import React from "react";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import Skeleton from "@mui/material/Skeleton";

const NotificationItem = () => {
  return (
    <Box sx={{ display: "flex", py: "10px" }}>
      <ListItemIcon>
        <Skeleton
          sx={{ mr: "5px", borderRadius: "50%" }}
          height={"56px"}
          width={"56px"}
          variant={"circular"}
        />
      </ListItemIcon>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          width: "100%",
          ml: "10px",
        }}
      >
        <Skeleton
          height={"14px"}
          width={"90%"}
          variant={"rectangular"}
        />
        <Skeleton
          sx={{ mt: "8px" }}
          height={"14px"}
          width={"70%"}
          variant={"rectangular"}
        />
      </Box>
    </Box>
  );
};

const SkeletonList = () => {
  return (
    <>
      <NotificationItem />
      <NotificationItem />
      <NotificationItem />
      <NotificationItem />
      <NotificationItem />
      <NotificationItem />
      <NotificationItem />
    </>
  );
};

export default SkeletonList;
