import Gateway from "../@components/gateway";

const useService = () => {
  const gateway = Gateway.useGateway();

  const save = (data) => {
    return gateway.post(`/user/settings/save/`, data);
  };

  const sendConfirmationEmail = () => {
    return gateway.post(`/users/jwt_send_confirm_mail/`);
  };

  const info = async () => {
    const resposne = await gateway.post(`/user/info/`);
    return resposne.data;
  };

  const basic_info = async () => {
    const resposne = await gateway.post(`/user/basic_info/`);
    return resposne.data;
  };

  const register = (data) => {
    return gateway.postForm(`/register`, data);
  };

  const login = (data) => {
    return gateway.postForm(`/login`, data);
  };

  const google_login = (data) => {
    return gateway.postForm(`/google_login`, data);
  };

  const resetPassword = (data) => {
    return gateway.postForm(`/send_reset_password_email`, data);
  };

  const nft = async (nftId) => {
    const response = await gateway.post(`user/nft/${nftId}/`);
    return response.data;
  };

  const changePassword = (data) => {
    return gateway.postForm(`/change_password`, data);
  };
  const changeLanguage = (lang) => {
    return gateway.post(`/user/change_language/`, { language_code: lang });
  };

  const markNftMovedFromVault = (nft_id) => {
    return gateway.post(
      `/user/nft/${nft_id}/mark_nft_from_vault_moved_to_wallet/`
    );
  };

  const generateQR = (data) => {
    return gateway.post(`/qr_ticket_scan/refresh_ticket/`, data);
  };

  const getOTP = (email) => {
    return gateway.postForm(`/get_login_one_time_code`, { email: email });
  };

  const loginWithOTP = (code) => {
    return gateway.postForm(`/login_with_one_time_code`, { code: code });
  };

  const generateDiscordCode = (data) => {
    return gateway.post(
      `/dao/${data.daoId}/collection/${data.collectionId}/generate_discord_code/`,
      { nft_id: data.nftId }
    );
  };

  const checkUser = async (searchString) => {
    const response = await gateway.post(`search_user_match/`, {
      search: searchString,
    });
    return response.data;
  };

  const albums = async (data) => {
    const response = await gateway.get(
      `dao/${data.dao_id}/collection/${data.collection_id}/compatible_albums_list/`
    );
    return response.data;
  };

  const posts = async (data) => {
    const response = await gateway.get(
      `dao/${data.dao_id}/collection/${data.collection_id}/gated_content_list/`
    );
    return response.data;
  };

  return {
    save,
    info,
    albums,
    posts,
    sendConfirmationEmail,
    register,
    login,
    google_login,
    resetPassword,
    changePassword,
    generateQR,
    nft,
    basic_info,
    generateDiscordCode,
    checkUser,
    changeLanguage,
    getOTP,
    loginWithOTP,
    markNftMovedFromVault,
  };
};

export default useService;
