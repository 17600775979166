import Withdraw from "./withdraw";
import Settings from "./settings";
import AddMember from "./addMember";

const Components = {
  Withdraw,
  Settings,
  AddMember,
};

export default Components;
