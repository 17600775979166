import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Draft from "./draft";
import Grid from "@mui/material/Grid";
import Brands from "./brands";
import { useHistory } from "react-router-dom";
import UI from "../../@components/UI";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pb: "20px",
          pt: { md: "10px", mobile: "10px" },
        }}
      >
        <Grid container>
          <Grid item mobile={12} md={12}>
            <Typography variant={"h1"} sx={{ display: "none" }}>
              {t("profile.created.title")}
            </Typography>

            <Box sx={{ padding: { md: "0px 0px 30px", mobile: "0px 0px 30px" } }}>
              <Brands />
            </Box>
          </Grid>
          <Grid
            item
            mobile={12}
            md={12}
            sx={{
              mt: { mobile: "60px", md: "60px" },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant={"h3"}>
                {t("profile.created.brandDrafts")}
              </Typography>
              <Box
                sx={{
                  width: "240px",
                  height: "42px",
                  display: { md: "flex", mobile: "none" },
                }}
              >
                <UI.Button
                  type={"primary"}
                  title={t("profile.created.applyYourBrandButton")}
                  onClick={() =>
                    history.push({
                      pathname: `/brand/apply`,
                      state: { scrollToTop: true },
                    })
                  }
                />
              </Box>
            </Box>

            <Box
              sx={{
                padding: { md: "10px 0px", mobile: "10px 0px" },
              }}
            >
              <Draft.BrandList
                draftType={"applyForDao"}
                onClick={(item) =>
                  history.push({
                    pathname: `/brand/apply/draft/${item?.id}`,
                    state: {
                      scrollToTop: true,
                      draft: item.data,
                      draft_id: item?.id,
                    },
                  })
                }
              />
            </Box>
            <Box
              sx={{
                width: "240px",
                height: "42px",
                mt: "10px",
                mx: "auto",
                display: { md: "none", mobile: "flex" },
              }}
            >
              <UI.Button
                type={"primary"}
                title={t("profile.created.applyYourBrandButton")}
                onClick={() =>
                  history.push({
                    pathname: `/brand/apply`,
                    state: { scrollToTop: true },
                  })
                }
              />
            </Box>
          </Grid>
          <Grid
            item
            mobile={12}
            md={12}
            sx={{
              mt: { mobile: "60px", md: "60px" },
            }}
          >
            <Typography variant={"h3"}>
              {t("profile.created.collectionDrafts")}
            </Typography>

            <Box sx={{ padding: { md: "10px 0px", mobile: "10px 0px" } }}>
              <Draft.CollectionList
                draftType={"collection"}
                onClick={(item, index) =>
                  history.push({
                    pathname:
                      item.draft_type === "collection_import"
                        ? `/brand/${item?.data?.dao_id}/collection/import/draft/${item?.id}`
                        : `/brand/${item?.data?.dao_id}/collection/new/draft/${item?.id}`,
                    state: {
                      scrollToTop: true,
                      draft: item.data,
                      draft_id: item?.id,
                      draft_display_index: index,
                    },
                  })
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Index;
