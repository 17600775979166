import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import UI from "../../@components/UI";
import secureAccountIcon from "../../static/secure-account.png";
import { useExportAccount } from "@alchemy/aa-alchemy/react";
import { useTranslation } from "react-i18next";

const TurnkeyExportWalletContainerId = "turnkey-export-wallet-container-id";
const TurnkeyExportWalletElementId = "turnkey-export-wallet-element-id";

const iframeCss = `
iframe {
    box-sizing: border-box;
    width: 100%;
    height: 120px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(216, 219, 227, 1);
    padding: 20px;
}
`;

const ExportWalletDialog = ({ toggleOpen }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [buttonEnabled, setButtonEnabled] = React.useState(true); // State to manage button enable/disable

  const { ExportAccountComponent, exportAccount, isExporting, isExported } =
    useExportAccount({
      params: {
        iframeContainerId: TurnkeyExportWalletContainerId,
        iframeElementId: TurnkeyExportWalletElementId,
      },
    });

  // Check if the element with ID 'turnkey-export-wallet-container-id' exists in the DOM
  React.useEffect(() => {
    const containerElement = document.getElementById(
      TurnkeyExportWalletContainerId
    );

    if (containerElement) {
      // If the container element exists, disable the button
      setButtonEnabled(false);
    } else {
      // If the container element doesn't exist, enable the button
      setButtonEnabled(true);
    }
  }, [isExporting, isExported, open]);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);

  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          padding: { md: "40px 60px 50px", mobile: "28px 10px 32px" },
          width: "650px",
          textAlign: "center",
          margin: { mobile: "15px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ p: "0px" }}>
        <CloseIcon
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 20,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <Grid container>
          <Grid item xs={12} md={12}>
            <Box
              component={"img"}
              src={secureAccountIcon}
              sx={{ width: "60px", display: "flex", margin: "0 auto" }}
            />
            <Typography
              variant={"h3"}
              sx={{
                textAlign: "center",
                mt: { md: "35px", mobile: "26px" },
              }}
            >
              {t(`profile.dialogs.exportWalletDialog.title`)}
            </Typography>
            <Typography
              variant={"subtitle2"}
              sx={{ m: { mobile: "10px 0px 0", md: "16px 10px 0" } }}
            >
              {t(`profile.dialogs.exportWalletDialog.subtitle`)}
            </Typography>
            <Box sx={{ mt: { md: "40px", mobile: "20px" }, px: "10px" }}>
              <Box sx={{ mt: "20px" }}>
                <ExportAccountComponent
                  iframeCss={iframeCss}
                  className="w-full"
                  isExported={isExported}
                />
              </Box>
            </Box>
            <>
              {buttonEnabled && (
                <UI.Button
                  disabled={!buttonEnabled}
                  type={"primary"}
                  title={t(`profile.dialogs.exportWalletDialog.button`)}
                  sx={{
                    mt: "10px",
                    width: "200px",
                    height: { md: "50px", mobile: "40px" },
                  }}
                  onClick={() => {
                    exportAccount();
                  }}
                />
              )}
              {isExporting && (
                <UI.Busy.Indicator width={"80px"} height={"80px"} />
              )}
            </>
          </Grid>
        </Grid>
      </DialogContent>
    </MaterialDialog>
  );
};

export default ExportWalletDialog;
