import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const Item = () => {
  return (
    <Grid item mobile={12} tablet={12} sm={12} md={6} lg={6}>
      <Paper
        sx={{
          backgroundColor: (theme) => theme.palette.bgTransparent.primary,
          mb: "2px",
        }}
      >
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            padding: { md: "17px 20px", mobile: "15px 10px" },
          }}
        >
          <Box sx={{ flex: 1, paddingRight: { md: "80px", mobile: "0px" } }}>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ mr: "20px" }}>
                <Skeleton variant={"rectangular"} width={"40px"} height={"40px"} sx={{ borderRadius: (theme) => theme.borderRadius.primary, backgroundColor: (theme) => theme.palette.bgTransparent.secondary }}  />
              </Box>
              <Typography
                variant={"h4"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  variant={"rectangular"}
                  width={"200px"}
                  height={"40px"}
                  sx={{ borderRadius: (theme) => theme.borderRadius.primary, backgroundColor: (theme) => theme.palette.bgTransparent.secondary }} 
                />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

const SmallSkeletonList = () => {
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  return (
    <>
      {items.map((index) => (
        <Item key={index} />
      ))}
    </>
  );
};

export default SmallSkeletonList;
