import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import UI from "../../../../@components/UI";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Controller, useForm } from "react-hook-form";
import useService from "./useService";
import { useSnackbar } from "notistack";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import TokenIcon from "@mui/icons-material/Token";
import IconButton from "@mui/material/IconButton";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import erc20Artifact from "../../../../abis/contracts/modules/token/ERC20Token.sol/ERC20Token.json";

import { ethers } from "ethers";
import { useUserRole } from "../../context";
import CustomHooks from "../../../../@components/hooks";
import customHooks from "../../../../@components/hooks";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Create = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    state: { daoId },
  } = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const service = useService(daoId);
  const provider = customHooks.useEthersProvider();
  const { isBrandMember } = useUserRole();
  CustomHooks.useProtectedRoute({ id: daoId }, isBrandMember);

  const {
    control,
    formState: { errors },
    clearErrors,
    handleSubmit,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      address: "",
      name: "",
      symbol: "",
      total_supply: "",
      icon_url: "",
    },
  });

  const getTokenData = async (address) => {
    const token = new ethers.Contract(address, erc20Artifact.abi, provider);
    try {
      const name = await token.name();
      const symbol = await token.symbol();
      const totalSupply = await token.totalSupply();

      setValue("name", name);
      setValue("symbol", symbol);

      setValue("total_supply", ethers.formatEther(totalSupply).toString());
    } catch (e) {
      enqueueSnackbar("Token not found on this blockchain", {
        variant: "error",
      });
    }

    clearErrors();
  };

  const onSubmit = async (form) => {
    const data = { ...form, icon_url: form.icon_url.ipfs };

    await service
      .save(data)
      .then(() => {
        enqueueSnackbar("Token succesfully added to treasury", {
          variant: "success",
        });
        history.go(-1);
      })
      .catch((error) => {
        enqueueSnackbar(error?.message || "Something went wrong", {
          variant: "error",
        });
      });
  };

  return (
    <Paper
      sx={{ width: "100%", backgroundColor: (theme) => theme.palette.bgTransparent.primary,}}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { md: "70px", mobile: "0px" },
            pb: "20px",
            pt: { md: "55px", mobile: "30px" },
          }}
        >
          <UI.BackButton
            title={t("brands.treasury.addTokenForm.backButton")}
            onClick={() => history.go(-1)}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            px: { md: "80px", mobile: "10px" },
            pb: "0px",
          }}
        >
          <Typography
            variant={"h1"}
            sx={{
              mb: "25px",
              mt: "10px",
            }}
          >
            {t("brands.treasury.addTokenForm.title")}
          </Typography>
          <Typography variant={"subtitle1"} sx={{ mb: "30px" }}>
            {t("brands.treasury.addTokenForm.subtitle")}
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter} sx={{ mb: "0px" }}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h4"}>
                    {t("brands.treasury.addTokenForm.address.title")}
                  </Typography>
                  <UI.Tooltip>
                    {t("brands.treasury.addTokenForm.address.tooltip")}
                  </UI.Tooltip>
                </Box>

                <Controller
                  name={"address"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.TextField
                      {...field}
                      startAdornment={
                        <InputAdornment position="start">
                          <TokenIcon sx={{ color: "white" }} />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => getTokenData(getValues("address"))}
                            onMouseDown={() => {}}
                            edge="end"
                          >
                            <SavedSearchIcon sx={{ color: "white" }} />
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder={t(
                        "brands.treasury.addTokenForm.address.placeholder"
                      )}
                    />
                  )}
                />
                <Box sx={{ mt: "15px" }}>
                  {errors.address?.type === "required" && (
                    <Alert
                      variant="outlined"
                      severity="error"
                    >
                      {t("brands.treasury.addTokenForm.address.error.required")}
                    </Alert>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter} sx={{ mb: "0px" }}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h4"}>
                    {t("brands.treasury.addTokenForm.name.title")}
                  </Typography>
                  <UI.Tooltip>
                    {t("brands.treasury.addTokenForm.name.tooltip")}
                  </UI.Tooltip>
                </Box>

                <Controller
                  name={"name"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.TextField
                      disabled
                      {...field}
                      placeholder={t(
                        "brands.treasury.addTokenForm.name.placeholder"
                      )}
                    />
                  )}
                />
                <Box sx={{ mt: "15px" }}>
                  {errors.name?.type === "required" && (
                    <Alert
                      variant="outlined"
                      severity="error"
                    >
                      {t("brands.treasury.addTokenForm.name.error.required")}
                    </Alert>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter} sx={{ mb: "0px" }}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h4"}>
                    {t("brands.treasury.addTokenForm.symbol.title")}
                  </Typography>
                  <UI.Tooltip>
                    {t("brands.treasury.addTokenForm.symbol.tooltip")}
                  </UI.Tooltip>
                </Box>

                <Controller
                  name={"symbol"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.TextField
                      disabled
                      {...field}
                      placeholder={t(
                        "brands.treasury.addTokenForm.symbol.placeholder"
                      )}
                    />
                  )}
                />
                <Box sx={{ mt: "15px" }}>
                  {errors.symbol?.type === "required" && (
                    <Alert
                      variant="outlined"
                      severity="error"
                    >
                      {t("brands.treasury.addTokenForm.symbol.error.required")}
                    </Alert>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box className={classes.inputOuter} sx={{ mb: "0px" }}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h4"}>
                    {t("brands.treasury.addTokenForm.supply.title")}
                  </Typography>
                  <UI.Tooltip>
                    {t("brands.treasury.addTokenForm.supply.tooltip")}
                  </UI.Tooltip>
                </Box>

                <Controller
                  name={"total_supply"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.TextField
                      disabled
                      {...field}
                      placeholder={t(
                        "brands.treasury.addTokenForm.supply.placeholder"
                      )}
                    />
                  )}
                />
                <Box sx={{ mt: "15px" }}>
                  {errors.total_supply?.type === "required" && (
                    <Alert
                      variant="outlined"
                      severity="error"
                    >
                      {t("brands.treasury.addTokenForm.supply.error.required")}
                    </Alert>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box
                sx={{
                  mt: "0px",
                  pt: "0px !important",
                }}
              >
                <Controller
                  name={"icon_url"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.Upload
                      {...field}
                      id={"profile"}
                      title={t("brands.treasury.addTokenForm.upload.title")}
                      subtitle={t(
                        "brands.treasury.addTokenForm.upload.subtitle"
                      )}
                      acceptFiles={"image/jpeg,image/gif,image/png"}
                    />
                  )}
                />
                <Box className={classes.description} sx={{ mt: "15px" }}>
                  {errors.icon_url?.type === "required" && (
                    <Alert
                      variant="outlined"
                      severity="error"
                    >
                      {t("brands.treasury.addTokenForm.upload.error.required")}
                    </Alert>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: "100%",
            pb: "75px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "210px", mt: "0px" }}>
            <UI.Button
              // disabled={!!loading}
              title={t("buttons.addToken")}
              type={"primary"}
            />
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default Create;
