import Gateway from "../@components/gateway";

const useService = () => {
  const gateway = Gateway.useGateway();

  const dao = async () => {
    const response = await gateway.get(`/dao/${window.env.QVRSE_DAO_ID}/`);
    return response.data;
  };

  const fee_address = () => {
    return gateway.get(`/fee_address/`);
  };

  return {
    dao,
    fee_address,
  };
};

export default useService;
