import { useQuery } from "@apollo/client";
import useHandleErrors from "./useHandleErrors";
import { marketplaceGateway } from "../gateway/apolloClient.js";

const useApolloFetch = (
  graphQlQuery,
  client = marketplaceGateway,
  options = {}
) => {
  const { handleApiError } = useHandleErrors();
  return useQuery(graphQlQuery, {
    client: client,
    ...options,
    onError: (error) => {
      handleApiError(error.message);
    },
  });
};

export default useApolloFetch;
