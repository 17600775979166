import Gateway from "../../../@components/gateway";

const useService = (daoId) => {
  const gateway = Gateway.useGateway();

  const save = (data) => {
    return gateway.post(`/dao/${daoId}/album/new/`, data);
  };

  const edit = (data) => {
    return gateway.post(
      `/dao/${daoId}/album/${data.albumId}/edit_data/`,
      data.data
    );
  };

  const album = async (id) => {
    const response = await gateway.get(`/dao/${daoId}/album/${id}/`);
    return response.data;
  };

  const list = async () => {
    const response = await gateway.get(`/dao/${daoId}/album/`);
    return response.data.results;
  };

  const cards = async (albumId) => {
    const response = await gateway.get(
      `/dao/${daoId}/album/${albumId}/album_space/`
    );
    return response.data;
  };

  const newCard = async (data) => {
    return await gateway.post(
      `/dao/${daoId}/album/${data.albumId}/album_space/new/`,
      data.data
    );
  };

  const removeCard = async (data) => {
    return await gateway.post(
      `/dao/${daoId}/album/${data.albumId}/album_space/${data.cardId}/delete/`
    );
  };

  const remove = (id) => {
    return gateway.post(`/dao/${daoId}/album/${id}/delete/`);
  };

  const toggleVisibility = (id) => {
    return gateway.post(`/dao/${daoId}/album/${id}/toggle_visible/`);
  };

  const submitCard = (data) => {
    return gateway.post(
      `/dao/${daoId}/album/${data.album_id}/submit_card/`,
      data
    );
  };

  const missingCollections = async (data) => {
    const { ids } = data;

    let url = `dao/${daoId}/collection/`;

    const missingIds = ids.join(",");
    url += `?ids=${missingIds}`;

    const response = await gateway.get(url);
    return response.data.results;
  };

  const collections = async (id) => {
    const response = await gateway.post(
      `/dao/${daoId}/album/${id}/collections/`
    );
    return response.data;
  };

  return {
    album,
    edit,
    save,
    list,
    missingCollections,
    remove,
    cards,
    newCard,
    removeCard,
    toggleVisibility,
    submitCard,
    collections,
  };
};

export default useService;
