import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../../../@components/UI";
import collectionsIcon from "../../../../../../static/group-nft-icon.png";
import { useDrop } from "react-dnd";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const ConfirmedSpace = ({ item }) => {
  return (
    <Box
      sx={{
        borderRadius: (theme) => theme.borderRadius.primary,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: { md: "50px", mobile: "32px" },
        }}
      >
        <Typography
          variant={"h9"}
          sx={{
            p: "8px",
            mt: "3px",
            maxWidth: { md: "135px", mobile: "90px" },
            display: "flex",
            alignItems: "center",
            fontSize: { md: "14px", mobile: "10px" },
          }}
        >
          <UI.OverflowTip>
            {(item?.album_card.length !== 0 &&
              item?.album_card[0]?.collection_name) ||
              null}
          </UI.OverflowTip>
        </Typography>
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: { md: "100%", mobile: "100%" },
          height: { md: "145px", mobile: "98px" },
          overflow: "hidden",
        }}
      >
        {item && item?.collections && (
          <UI.ImageWithSkeleton
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
              transition: "all 0.3s ease-in-out",
            }}
            src={
              (item?.album_card.length !== 0 &&
                item?.album_card[0]?.image_url) ||
              null
            }
          />
        )}
      </Box>
    </Box>
  );
};

const UnconfirmedSpace = ({ display, item }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          display: display,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
          borderRadius: (theme) => theme.borderRadius.secondary,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          border: "2px solid #2fd729",
          boxShadow: "#2fd729 0px 0px 10px 0px",
        }}
      >
        <Typography variant={"h8"} sx={{ color: "#ffffff" }}>
          {t("brands.albums.cards.dropHere")}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "155px",
          width: "100%",
          overflow: "hidden",
          borderRadius: (theme) => theme.borderRadius.secondary,
        }}
      >
        <Box
          component={"img"}
          sx={{
            width: { md: "70px", mobile: "45px" },
            height: { md: "70px", mobile: "45px" },
            mb: { md: "5px", mobile: "6px" },
            ml: { md: "2px", mobile: "3px" },
            filter: (theme) =>
              theme.palette.type === "dark" ? "none" : "invert(1)",
          }}
          src={collectionsIcon}
        />
      </Box>
      <Typography
        variant={"h8"}
        sx={{
          position: "absolute",
          bottom: { md: "20px", mobile: "12px" },
          lineHeight: { mobile: "1.2", md: "1.3" },
          mt: { md: "24px", mobile: "20px" },
          fontSize: { md: "14px", mobile: "10px" },
          display: "flex",
          textAlign: "center",
          px: { md: "30px", mobile: "10px" },
        }}
      >
        {t("brands.albums.cards.nftChoice")}
      </Typography>
    </>
  );
};

const Card = ({ item, onDrop }) => {
  const collectionIds =
    item &&
    item.collections !== null &&
    item.collections.length > 0 &&
    item?.collections.map((collection) => collection.id.toString());

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: collectionIds,
    drop: (data, nft) => {
      onDrop({ droppedItem: data, spaceItem: item });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const spaceIsConfirmed = item.album_card.length !== 0;
  const isLocked = item.is_locked;

  const isActive = isOver && canDrop && !isLocked;
  let display = "none";
  if (isActive) {
    display = "flex";
  } else if (canDrop && !isLocked) {
    display = "flex";
  }
  return (
    <>
      <Box
        ref={drop}
        key={item.id}
        sx={{
          borderRadius: (theme) => theme.borderRadius.secondary,
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          position: "relative",
          cursor: "pointer",
          width: { md: "155px", mobile: "102px" },
          height: { md: "232px", mobile: "153px" },
          display: "flex",
          justifyContent: "center",
          alignItems: spaceIsConfirmed ? "flex-start" : "center",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            backgroundColor: (theme) => theme.palette.bgTransparent.secondary,
            transform: "scale(1.02)",
          },
        }}
      >
        {!spaceIsConfirmed && !isLocked && !isOver && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              zIndex: 1000,
              borderRadius: (theme) => theme.borderRadius.secondary,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px solid #944dff",
              boxShadow: "#944dff 0px 0px 5px 0px",
              animation: "pulse 3s infinite",
              "@keyframes pulse": {
                "0%": {
                  transform: "scale(1)",
                },
                "50%": {
                  transform: "scale(1.02)",
                  backgroundColor: "rgba(177,133,242,0.3)",
                  transition: "all ease-in-out",
                },
                "100%": {
                  transform: "scale(1)",
                },
              },
            }}
          ></Box>
        )}
        {!spaceIsConfirmed && (
          <UnconfirmedSpace item={item} display={display} />
        )}
        {spaceIsConfirmed && <ConfirmedSpace item={item} display={display} />}
      </Box>
    </>
  );
};

const Action = ({ element: Component, item, onSelect }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const collectionIds =
    item &&
    item.collections !== null &&
    item.collections.length > 0 &&
    item?.collections.map((collection) => collection.id);
  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          !isMobile && onSelect([]);
        }}
      >
        <Box
          sx={{ cursor: "pointer" }}
          component={"span"}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(collectionIds);
          }}
        >
          {Component}
        </Box>
      </ClickAwayListener>
    </>
  );
};

Card.Action = Action;

export default Card;
