import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useUserContext } from "../../@components/userContext";
import Link from "@mui/material/Link";
import { useDisconnect } from "wagmi";
import truncateEthAddress from "truncate-eth-address";
import UI from "../../@components/UI";
import { useTranslation } from "react-i18next";

const WrongAccountDialog = ({ toggleOpen }) => {
  const { t } = useTranslation();
  const { disconnect } = useDisconnect();
  const { user: loggedInUser } = useUserContext();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);

  const handleClose = () => {
    disconnect();
    setOpen(false);
  };
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          padding: { md: "40px 60px 44px", mobile: "28px 0px 30px" },
          width: "650px",
          textAlign: "center",
          margin: { mobile: "20px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <CloseIcon
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 20,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <Grid container>
          <Grid item xs={12} md={12}>
            <AccountBalanceWalletIcon
              sx={{
                width: "60px",
                height: "60px",
                display: "flex",
                margin: "0 auto",
              }}
            />
            <Typography
              variant={"h3"}
              sx={{ textAlign: "center", mt: "20px" }}
            >
              {t("profile.dialogs.wrongAccountDialog.title")}
            </Typography>
            <Typography variant={"subtitle2"} sx={{ mt: "16px" }}>
              {t("profile.dialogs.wrongAccountDialog.subtitle")}{" "}
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                {loggedInUser?.email}
              </Box>{" "}
              {t("profile.dialogs.wrongAccountDialog.subtitle2")}{" "}
              <Box
                sx={{
                  fontWeight: "bold",
                  width: "200px",
                  margin: "0 auto",
                  mt: "22px",
                }}
              >
                <UI.CopyToClipboard
                  label={truncateEthAddress(loggedInUser?.address || "")}
                  value={loggedInUser?.address}
                  showBorder
                />
              </Box>{" "}
            </Typography>
            <Box 
              sx={{ 
                mt: { mobile: "34px", md: "54px" },
                borderBottom: "1px solid",
                borderColor: (theme) => theme.palette.colors.gray, 
                mx: { mobile: "10px" } 
              }}
            ></Box>
            <Box sx={{ mt: { mobile: "32px", md: "44px" }, px: "10px" }}>
              <Typography
                variant={"subtitle3"}
                sx={{ textAlign: "center" }}
              >
                {t("profile.dialogs.wrongAccountDialog.support")}{" "}
                <Link
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    transition: "all 0.2s ease-in-out",
                    color: (theme) => theme.palette.text.link,
                      "&:hover": {
                        color: (theme) => theme.palette.text.linkHover,
                      },
                  }}
                  target="_blank"
                  href={"mailto: mainUtility@qvrse.io"}
                >
                  info@qvrse.io
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </MaterialDialog>
  );
};

export default WrongAccountDialog;
