import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
    },
  },
  categoryItem: {
    width: "80px",
    height: "80px",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    background: "transparent",
    marginLeft: ({ isMobile, isFirstItem }) => {
      return isFirstItem ? "0px" : isMobile ? "10px" : "17px";
    },
    marginRight: ({ isMobile }) => {
      return isMobile ? "10px" : "17px";
    },
    borderRadius: "50%",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      opacity: "0.6",
    },
  },
}));

const Item = ({ brand, contentType, selected, contentData, isFirstItem }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const classes = useStyles({ selected, isMobile, isFirstItem });
  const history = useHistory();
  return (
    <Box
      sx={{
        position: "relative",
        borderRadius: (theme) => theme.borderRadius.primary,
        cursor: "pointer",
      }}
      className={classes.categoryItem}
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/brand/${brand?.id}`);
      }}
    >
      {contentType?.startsWith("video") && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}>
          <video
            playsInline
            width="100%"
            height={"auto"}
            autoplay="autoplay"
            loop
          >
            <source src={contentData} />
          </video>
        </Box>
      )}
      {contentType?.startsWith("image") && (
        <Box
          component={"img"}
          src={contentData}
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
        />
      )}
    </Box>
  );
};

export default Item;
