import React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UI from "../../../../@components/UI";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const CustomActionDialog = ({ open, title, onClose, onAdd }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      value: "",
      type: "button",
    },
    mode: "all",
  });

  const onSubmit = (values) => {
    onAdd(values);
    reset();
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "800px",
          p: { md: "50px", mobile: "30px 20px" },
          margin: { md: "32px", mobile: "10px" },
          backgroundColor: (theme) => theme.palette.colors.darkGray,
        },
      }}
      onClose={() => {
        reset();
        onClose();
      }}
      open={open}
    >
      <CloseIcon
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 30,
          top: 30,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <form>
        <Typography variant={"h2"}>{title}</Typography>
        <Box sx={{ mt: "20px" }}>
          <Box>
            <Typography variant={"subtitle1"}>
              {t("brands.collection.create.customLinkDialog.subtitle")}
            </Typography>
            <Typography variant={"subtitle1"}></Typography>
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", my: "10px" }}>
              <Typography variant={"h4"}>
                {" "}
                {t(
                  "brands.collection.create.customLinkDialog.form.externalUrl.title"
                )}
              </Typography>
              <UI.Tooltip>
                {t(
                  "brands.collection.create.customLinkDialog.form.externalUrl.tooltip"
                )}
              </UI.Tooltip>
            </Box>
            <Controller
              name={"value"}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UI.TextField
                  {...field}
                  placeholder={t(
                    "brands.collection.create.customLinkDialog.form.externalUrl.placeholder"
                  )}
                />
              )}
            />
            {errors.name?.type === "required" && (
              <Box sx={{ mt: "15px" }}>
                <Alert
                  variant="outlined"
                  severity="error"
                >
                  {t(
                    "brands.collection.create.customLinkDialog.form.externalUrl.error.required"
                  )}
                </Alert>
              </Box>
            )}
          </Box>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h4"}>
                {t(
                  "brands.collection.create.customLinkDialog.form.addButton.title"
                )}
              </Typography>
              <UI.Tooltip>
                {t(
                  "brands.collection.create.customLinkDialog.form.addButton.tooltip"
                )}
              </UI.Tooltip>
            </Box>
            <Controller
              name={"name"}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UI.TextField
                  {...field}
                  placeholder={t(
                    "brands.collection.create.customLinkDialog.form.addButton.placeholder"
                  )}
                />
              )}
            />
            {errors.value?.type === "required" && (
              <Box sx={{ mt: "15px" }}>
                <Alert
                  variant="outlined"
                  severity="error"
                >
                  {t(
                    "brands.collection.create.customLinkDialog.form.addButton.error.required"
                  )}
                </Alert>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: "20px",
              px: "15px",
              justifyContent: "center",
            }}
          >
            <UI.Button
              type={"primary"}
              title={t("buttons.addLink")}
              onClick={handleSubmit(onSubmit)}
              sx={{
                width: i18n.language === "sl" ? "200px" : "150px",
                height: "50px",
                filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
              }}
            />
          </Box>
        </Box>
      </form>
    </Dialog>
  );
};

const Action = ({ element: Component, title, onAdd }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Box component={"span"} onClick={() => setOpen(!open)}>
        {Component}
      </Box>

      <CustomActionDialog
        open={open}
        title={title}
        onClose={() => setOpen(false)}
        onAdd={(values) => {
          setOpen(false);
          onAdd(values);
        }}
      />
    </>
  );
};

CustomActionDialog.Action = Action;

export default CustomActionDialog;
