import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard as ReactCopyToClipBoard } from "react-copy-to-clipboard";
import MobileTooltip from "./MobileTooltip";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const CopyToClipboard = ({
  label,
  value,
  showBorder = false,
  showIcon = true,
  overrideStyles,
}) => {
  const { t } = useTranslation();
  const [tooltipMessage, setTooltipMessage] = React.useState(
    t("commons.copyToClipboard")
  );

  return (
    <React.Fragment>
      <Box sx={{ display: { md: "block", mobile: "none" } }}>
        {value && label ? (
          <ReactCopyToClipBoard
            text={value}
            onCopy={() => {
              setTooltipMessage(`${t("commons.copied")}!`);
              setTimeout(
                () => setTooltipMessage(t("commons.copyToClipboard")),
                1000
              );
            }}
          >
            <Tooltip title={tooltipMessage} disableFocusListener arrow>
              <Chip
                sx={
                  overrideStyles
                    ? overrideStyles
                    : {
                        width: "100%",
                        p: "6px 6px",
                        maxHeight: "26px",
                        borderRadius: "100px",
                        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
                        "&:hover": {
                          transition: "all 0.2s ease",
                          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
                        },
                      }
                }
                variant="contained"
                clickable
                icon={
                  showIcon && (
                    <ContentCopyIcon
                      sx={{
                        color: (theme) => theme.palette.text.tertiary + "!important",
                        width: "0.9rem",
                        height: "0.9rem",
                        ml: "8px !important",
                      }}
                    />
                  )
                }
                label={
                  <Typography variant="subtitle4" display={"flex"}>
                    {" "}
                    {`${label}`}
                  </Typography>
                }
              />
            </Tooltip>
          </ReactCopyToClipBoard>
        ) : (
          <Skeleton variant={"rectangular"} height={"20px"} width={"136px"} />
        )}
      </Box>
      <Box sx={{ display: { md: "none", mobile: "block" } }}>
        <MobileTooltip
          title={
            <Typography variant="subtitle4">
              {`${t("commons.copied")}!`}
            </Typography>
          }
        >
          {value && label ? (
            <ReactCopyToClipBoard text={value}>
              <Chip
                sx={
                  overrideStyles
                    ? overrideStyles
                    : {
                        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
                        borderRadius: "100px",
                        height: "26px",
                        "&:hover": {
                          transition: "all 0.2s ease",
                          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
                        },
                      }
                }
                variant="contained"
                clickable
                icon={
                  showIcon && (
                    <ContentCopyIcon
                      sx={{
                        color: (theme) => theme.palette.text.tertiary + "!important",
                        width: "0.9rem",
                        height: "0.9rem",
                        ml: "8px !important",
                      }}
                    />
                  )
                }
                label={
                  <Typography variant={"subtitle4"} >
                    {" "}
                    {`${label}`}
                  </Typography>
                }
              />
            </ReactCopyToClipBoard>
          ) : (
            <Skeleton variant={"rectangular"} height={"20px"} width={"136px"} />
          )}
        </MobileTooltip>
      </Box>
    </React.Fragment>
  );
};

export default CopyToClipboard;
