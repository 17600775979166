import React, { useState, createContext, useContext } from "react";

export const FormContext = createContext();

export default function FormProvider({ children }) {
  const [formData, setData] = useState({
    token_type: "ERC20",
    quorum: "4",
    voting_delay: "1",
    voting_period: "400",
    timelock_delay: "1",
    token_icon_url: {
      ipfs: "",
      file: { type: "image/png" },
    },
    token_name: "",
    token_symbol: "",
    token_supply: "1",
    country_of_incorporation: { code: "SI", label: "Slovenia", phone: "386" },
  });

  const setFormData = (values) => {
    setData((prevValues) => ({
      ...prevValues,
      ...values,
    }));
  };

  return (
    <FormContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormContext.Provider>
  );
}

export const useFormDataContext = () => useContext(FormContext);
