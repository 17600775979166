import React from "react";
import { useAddPasskey, useUser } from "@alchemy/aa-alchemy/react";
import { Box } from "@material-ui/core";
import { useUserContext } from "../../@components/userContext";
import { useSnackbar } from "notistack";

const AddNewPaskey = () => {
  const { user: loggedInUser } = useUserContext();
  const user = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const { addPasskey } = useAddPasskey({
    onSuccess: (authenticatorIds) => {
      console.log("authenticatorIds: ", authenticatorIds);
      enqueueSnackbar("Passkey added successfully", { variant: "success" });
    },
    onError: (error) => {
      console.error("Error adding passkey: ", error);
      enqueueSnackbar("Error adding passkey", { variant: "error" });
    },
  });

  return (
    <Box
      sx={{
        cursor: user === null ? "default" : "pointer",
        textDecoration: "underline",
        transition: "all 0.3s ease-in-out",
        color:
          user === null
            ? "rgb(208 208 208)"
            : (theme) => theme.palette.text.linkBlue,
        textDecorationColor:
          user === null
            ? "rgb(208 208 208)"
            : (theme) => theme.palette.text.linkBlue,
        ml: "5px !important",
        "&:hover": {
          color:
            user === null
              ? "rgb(208 208 208)"
              : (theme) => theme.palette.text.linkBlueHover,
          textDecorationColor:
            user === null
              ? "rgb(208 208 208)"
              : (theme) => theme.palette.text.linkBlueHover,
        },
      }}
      onClick={() => {
        if (user === null) {
          return;
        }
        addPasskey({
          publicKey: {
            rp: {
              id:
                process.env.NODE_ENV === "development"
                  ? "localhost"
                  : "qvrse.io",
              name: "QVRS",
            },
            user: {
              name: loggedInUser?.email,
              displayName: loggedInUser?.email,
            },
          },
        });
      }}
    >
      {" "}
      Add Passkey
    </Box>
  );
};

export default AddNewPaskey;
