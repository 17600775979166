import ApplyForDao from "./applyForDao";
import Withdraw from "./withdraw";
import Settings from "./settings";

const Components = {
  ApplyForDao,
  Withdraw,
  Settings,
};

export default Components;
