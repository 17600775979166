import useFetch from "./useFetch";
import usePost from "./usePost";
import useQuery from "./useQuery";
import useProtectedRoute from "./useProtectedRoute";
import { useEthersSigner } from "./ethers/useEthersSigner";
import { useEthersProvider } from "./ethers/useEthersProvider";
import useBlockchainState from "./useBlockchainState";
import useEventVenue from "./venues/useEventVenue";
import useApolloFetch from "./useApolloFetch";

const CustomHooks = {
  useFetch,
  usePost,
  useQuery,
  useProtectedRoute,
  useEthersSigner,
  useEthersProvider,
  useBlockchainState,
  useEventVenue,
  useApolloFetch,
};

export default CustomHooks;
