import React from "react";
import Box from "@mui/material/Box";
import UI from "../../../../../@components/UI";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Controller, useWatch } from "react-hook-form";
import maticLogo from "../../../../../static/matic-logo.png";
import usdtLogo from "../../../../../static/usdt-logo.png";
import usdcLogo from "../../../../../static/usdc-icon.png";
import euroLogo from "../../../../../static/euroLogo.png";
import dollarLogo from "../../../../../static/dollarLogo.png";
import qvrseLogo from "../../../../../static/qvrse-logo.png";
import { useDebounce } from "use-debounce";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const currencyIcon = (selectedCurrency) => {
  return (
    <Box
      component={"img"}
      src={
        selectedCurrency.toLowerCase() === "usdt"
          ? usdtLogo
          : selectedCurrency.toLowerCase() === "usdc"
          ? usdcLogo
          : selectedCurrency.toLowerCase() === "matic"
          ? maticLogo
          : selectedCurrency.toLowerCase() === "eur"
          ? euroLogo
          : selectedCurrency.toLowerCase() === "qvrs"
          ? qvrseLogo
          : selectedCurrency.toLowerCase() === "usd"
          ? dollarLogo
          : maticLogo // default logo
      }
      sx={{ width: "23px", height: "23px", mr: "10px" }}
    />
  );
};

const Price = ({
  control,
  errors,
  setValue,
  utilities,
  currency,
  isFreeMint,
}) => {
  const classes = useStyles();
  const [totalPrice, setTotalPrice] = React.useState(0);
  const { t } = useTranslation();
  const extra_utilities = useWatch({
    control,
    name: "extra_utilities",
  });

  const main_utility = useWatch({
    control,
    name: "main_utility",
  });

  const [changedUtilities] = useDebounce(extra_utilities, 500);
  const [changedMainUtility] = useDebounce(main_utility, 500);

  React.useEffect(() => {
    if (isFreeMint) {
      const price = 0;
      setValue("main_utility.price", price);
      extra_utilities.forEach((utility, index) => {
        setValue(`extra_utilities.${index}.price`, 0);
      });

      setTotalPrice(price.toFixed(2));
      setValue("totalPrice", price.toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFreeMint]);

  React.useEffect(() => {
    const calculateTotalPrice = () => {
      let total = parseFloat(changedMainUtility.price || 0);
      changedUtilities.forEach((utility) => {
        total += parseFloat(utility?.price || 0);
      });
      setValue("totalPrice", total.toFixed(2));
      setTotalPrice(total.toFixed(2));
    };

    changedUtilities && changedMainUtility && calculateTotalPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedUtilities, changedMainUtility]);

  return (
    <UI.Accordion
      expanded={true}
      sx={{ backgroundColor: (theme) => theme.palette.bgTransparent.primary }}
      title={
        isFreeMint
          ? t("brands.collection.create.payment.price.accordionTitle")
          : `${totalPrice} ${currency}`
      }
      readonly={true}
    >
      <Box className={classes.inputOuter}>
        <Box sx={{ display: "flex", mb: "10px" }}>
          <Typography
            variant={"h6"}
            sx={{
              maxWidth: { mobile: "250px", md: "600px" },
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <UI.OverflowTip> {main_utility?.name} </UI.OverflowTip>
          </Typography>
        </Box>

        <Controller
          name={`main_utility.price`}
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /^\d+(\.\d{1,2})?$/,
              message: t(
                "brands.collection.create.payment.price.error.pattern"
              ),
            },
          }}
          render={({ field }) => (
            <UI.NumberField
              {...field}
              disabled={isFreeMint}
              endAdornment={currencyIcon(currency)}
            />
          )}
        />
        {errors?.main_utility?.price?.type === "required" && (
          <Box sx={{ mt: "15px" }}>
            <Alert variant="outlined" severity="error">
              {t("brands.collection.create.payment.price.error.required")}
            </Alert>
          </Box>
        )}
        {errors?.main_utility?.price?.type === "pattern" && (
          <Box sx={{ mt: "15px" }}>
            <Alert variant="outlined" severity="error">
              {errors?.main_utility?.price?.message}
            </Alert>
          </Box>
        )}
      </Box>
      {utilities.map((utility, utilityIndex) => (
        <Box className={classes.inputOuter}>
          <Box sx={{ display: "flex", mb: "10px" }}>
            <Typography
              variant={"h6"}
              sx={{
                maxWidth: { mobile: "250px", md: "600px" },
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <UI.OverflowTip> {utility?.name} </UI.OverflowTip>
            </Typography>
          </Box>

          <Controller
            name={`extra_utilities.${utilityIndex}.price`}
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^\d+(\.\d{1,2})?$/,
                message: t(
                  "brands.collection.create.payment.price.error.pattern"
                ),
              },
            }}
            render={({ field }) => (
              <UI.NumberField
                {...field}
                disabled={isFreeMint}
                endAdornment={currencyIcon(currency)}
              />
            )}
          />
          {Object.keys(errors)?.length > 0 &&
            errors?.extra_utilities &&
            errors?.extra_utilities[utilityIndex]?.price?.type ===
              "required" && (
              <Box sx={{ mt: "15px" }}>
                <Alert variant="outlined" severity="error">
                  Price is required
                </Alert>
              </Box>
            )}
          {Object.keys(errors)?.length > 0 &&
            errors?.extra_utilities &&
            errors?.extra_utilities[utilityIndex]?.price?.type ===
              "pattern" && (
              <Box sx={{ mt: "15px" }}>
                <Alert variant="outlined" severity="error">
                  {errors?.extra_utilities[utilityIndex]?.price?.message}
                </Alert>
              </Box>
            )}
        </Box>
      ))}
    </UI.Accordion>
  );
};

export default Price;
