import React from "react";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import UI from "../../UI";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotificationItem = ({
  avatar,
  brandName,
  url,
  dateTime,
  message,
  onClick,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        py: "10px",
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          background: (theme) => theme.palette.bgTransparent.primary,
          borderRadius: (theme) => theme.borderRadius.secondary,
        },
      }}
      onClick={() => onClick(url)}
    >
      <ListItemIcon>
        <Avatar
          sx={{
            width: 56,
            height: 56,
            ml: "10px",
            mr: "15px",
          }}
          alt={brandName}
          src={avatar}
        />
      </ListItemIcon>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 600, lineHeight: "1.3", fontSize: "14px" }}
        >
          <Box component={"span"} sx={{ fontWeight: "bold" }}>
            {brandName}
          </Box>{" "}
          {message}
        </Typography>
        <Typography
          variant="subtitle4"
          sx={{ fontWeight: 400, fontSize: "12px !important" }}
        >
          {moment(dateTime).format("DD MMM YYYY HH:mm")}
        </Typography>
      </Box>
    </Box>
  );
};

const List = ({ notifications }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const handleClick = (url) => {
    history.push(url);
  };
  return (
    <>
      {notifications &&
        notifications.map((notification) => (
          <NotificationItem
            url={notification.url}
            avatar={notification.avatar}
            brandName={notification.name}
            message={notification.message}
            dateTime={notification.dateTime}
            onClick={handleClick}
          />
        ))}
      <Box sx={{ display: "flex", justifyContent: "center", mt: "20px" }}>
        <UI.Button
          type={"secondary"}
          sx={{
            width: "270px",
            fontSize: "14px !important",
            height: "40px",
            mt: "20px"
          }}
          title={t("profile.dialogs.notificationsDialog.button")}
        />
      </Box>
    </>
  );
};

export default List;