import React, { useState } from "react";
import Box from "@mui/material/Box";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Typography from "@mui/material/Typography";
import qvrseLogo from "../../../../../static/qvrse-logo.png";
import UI from "../../../../UI";
import truncateEthAddress from "truncate-eth-address";
import { useUserContext } from "../../../../userContext";
import Paper from "@mui/material/Paper";
import maticLogo from "../../../../../static/matic-logo.png";
import usdcLogo from "../../../../../static/usdc-icon.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExportWalletDialog from "./ExportWalletDialog";
import CreateSmartAccountDialog from "../../../../../profile/dialogs/CreateSmartAccountDialog";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useBalance } from "wagmi";
import { polygonAmoy, polygon } from "wagmi/chains";
import { ethers } from "ethers";

const TokenItem = (props) => {
  const { name, icon, amount } = props;
  return (
    <Box {...props} sx={{ width: "100%", ...props.sx }}>
      <Paper
        sx={{
          cursor: "default",
          borderRadius: (theme) => theme.borderRadius.primary,
          backgroundColor: "rgba(255, 255, 255, 0.12)",
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.3)",
          },
        }}
      >
        <Box
          sx={{
            height: "60px",
            padding: { md: "20px 30px", mobile: "20px 15px" },
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                display: { md: "flex", mobile: "none" },
              }}
              variant={"h8"}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                display: { md: "none", mobile: "flex" },
              }}
              variant={"h8"}
            >
              {name}
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                width: "25px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <img src={icon} alt={"icon"} />
            </Box>
            <Box
              sx={{
                ml: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant={"h7"}>
                {parseFloat(amount).toFixed(2)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

const Index = ({ refetchUser }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { user: loggedInUser } = useUserContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [triggerExportWallet, setTriggerExportWallet] = useState(false);
  const [showSmartAccountDialog, setShowSmartAccountDialog] =
    React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: nativeBalance } = useBalance({
    address: loggedInUser?.smart_address,
    chainId: window.env.CHAIN === "AMOY" ? polygonAmoy.id : polygon.id,
  });

  const { data: qvrseBalance } = useBalance({
    address: loggedInUser?.smart_address,
    chainId: window.env.CHAIN === "AMOY" ? polygonAmoy.id : polygon.id,
    token: "0x14d1996D29616C1fb589522ce6A81a583f1c32bE",
  });

  const { data: usdcBalance } = useBalance({
    address: loggedInUser?.smart_address,
    chainId: window.env.CHAIN === "AMOY" ? polygonAmoy.id : polygon.id,
    token: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
  });

  console.log("nativeBalance: ", nativeBalance);
  console.log("loggedInUser: ", loggedInUser);

  return (
    <Box
      sx={{
        position: "relative",
        mt: "24px",
        background:
          theme.palette.type === "dark"
            ? "rgba(255, 255, 255, 0.1)"
            : "rgba(255, 255, 255, 0.2)",
        boxShadow: (theme) => theme.palette.dialog.boxShadow,
        width: "100%",
        borderRadius: "8px",
        p: "24px 12px",
      }}
    >
      {loggedInUser?.smart_address === null && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: theme.palette.type === "dark" ? "#3C3E44" : "#DDDDE1",
            backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
            zIndex: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            color: "#fff",
            borderRadius: (theme) => theme.borderRadius.primary,
          }}
        >
          <Box sx={{ width: i18n.language === "en" ? "200px" : "245px" }}>
            <UI.Button
              type={"primary"}
              title={t("commons.createWallet")}
              variant="contained"
              color="primary"
              startIcon={<AccountBalanceWalletIcon />}
              onClick={() => {
                setShowSmartAccountDialog(Math.random());
              }}
            />
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
          mb: "10px",
        }}
      >
        <Typography
          variant={"h5"}
          sx={{
            ml: "6px",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {" "}
          {t("appBar.wallet")}
        </Typography>
        <Typography
          variant={"h5"}
          sx={{
            ml: "10px",
            flexDirection: "column",
            justifyContent: "center",
            mb: "3px",
          }}
        >
          {loggedInUser?.smart_address && (
            <UI.CopyToClipboard
              label={truncateEthAddress(loggedInUser?.smart_address || "")}
              value={loggedInUser?.smart_address || ""}
            />
          )}
        </Typography>
        <MoreHorizIcon
          onClick={handleClick}
          sx={{ position: "absolute", top: "12px", right: "12px" }}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              backgroundColor: (theme) => theme.palette.dialog.background,
              backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
            },
          }}
        >
          <MenuItem
            onClick={() => setTriggerExportWallet(Math.random())}
            sx={{
              fontFamily: "Open Sans",
              fontSize: "14px",
              fontWeight: "500",
              padding: "0 18px",
            }}
          >
            {t("commons.export")}
          </MenuItem>
        </Menu>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TokenItem
          sx={{ mb: "10px" }}
          name={"QVRS"}
          icon={qvrseLogo}
          amount={ethers.formatEther(qvrseBalance?.value || 0)}
        />
        <TokenItem
          sx={{ mb: "10px" }}
          name={"MATIC"}
          icon={maticLogo}
          amount={ethers.formatEther(nativeBalance?.value || 0)}
        />
        <TokenItem
          name={"USDC"}
          icon={usdcLogo}
          amount={ethers.formatEther(usdcBalance?.value || 0)}
        />

        {/*<UI.Button disabled type={"primary"} title={"BUY"} />*/}
      </Box>
      <ExportWalletDialog toggleOpen={triggerExportWallet} />
      <CreateSmartAccountDialog
        toggleOpen={showSmartAccountDialog}
        onSuccess={() => refetchUser()}
      />
    </Box>
  );
};

export default Index;
