import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Create from "../qvrse/proposal/applyForDAO/index";
import ID from "./@id";
import Explore from "./explore";
import BigList from "./BigList";

const Index = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/apply`} component={Create} />
      <Route path={`${match.url}/apply/draft/:id`} component={Create} />
      <Route path={`${match.url}/explore`} component={Explore} />
      <Route path={`${match.url}/:id`} component={ID} />
      <Route path="/">
        <Explore />
      </Route>
      <Redirect from="*" to="/" />
    </Switch>
  );
};

Index.List = BigList;

export default Index;
