import React from "react";
import Box from "@mui/material/Box";
import UI from "../../../../../../@components/UI";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const Card = ({ item }) => {
  return (
    <Box
      key={item.id}
      sx={{
        borderRadius: (theme) => theme.borderRadius.secondary,
        backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
        position: "relative",
        cursor: "pointer",
        width: { md: "155px", mobile: "102px" },
        height: { md: "232px", mobile: "153px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          backgroundColor: (theme) => theme.palette.bgTransparent.secondary,
          transform: "scale(1.02)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: { md: "50px", mobile: "32px" },
        }}
      >
        <Typography
          variant={"h9"}
          sx={{
            p: "8px",
            mt: "3px",
            maxWidth: { md: "135px", mobile: "90px" },
            display: "flex",
            alignItems: "center",
            fontSize: { md: "14px", mobile: "10px" },
          }}
        >
          <UI.OverflowTip>{item?.collections[0]?.name}</UI.OverflowTip>
        </Typography>
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: { md: "100%", mobile: "100%" },
          height: { md: "145px", mobile: "98px" },
          overflow: "hidden",
        }}
      >
        {item && item?.collections && (
          <UI.ImageWithSkeleton
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
              transition: "all 0.3s ease-in-out",
            }}
            src={
              (item?.collections.length !== 0 &&
                item?.collections[0]?.nft_image_url) ||
              null
            }
          />
        )}
      </Box>
    </Box>
  );
};

const Action = ({ element: Component, item, onSelect }) => {
  const collectionId =
    item &&
    item.collections !== null &&
    item.collections.length > 0 &&
    item?.collections[0].id;
  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          onSelect([]);
        }}
      >
        <Box
          sx={{
            transition: "all 0.3s ease-in-out",
            cursor: "pointer",
          }}
          component={"span"}
          onClick={(e) => {
            e.stopPropagation();
            onSelect([collectionId]);
          }}
        >
          {Component}
        </Box>
      </ClickAwayListener>
    </>
  );
};

Card.Action = Action;

export default Card;
