import React from "react";
import Collection from "./Collection";
import Text from "./Text";
import Image from "./Image";
import Video from "./Video";
import Game from "./Game";
import Discount from "./Discount";
import Animation from "./Animation";
import Spatial from "./Spatial";
import Code from "./Code";

const Index = () => {
  return <div></div>;
};

Index.Collection = Collection;
Index.Text = Text;
Index.Image = Image;
Index.Video = Video;
Index.Game = Game;
Index.Discount = Discount;
Index.Animation = Animation;
Index.Spatial = Spatial;
Index.Code = Code;

export default Index;
