import List from "./List";
import SkeletonList from "./SkeletonList";
import useService from "./useService";
import CustomHooks from "../../../@components/hooks";
import React from "react";

const Index = () => {
  const service = useService();

  const { data: notificationsData, isLoading } = CustomHooks.useFetch(
    ["notifications"],
    service.notifications
  );
  return (
    <>
      {isLoading ? (
        <SkeletonList />
      ) : (
        <List notifications={notificationsData} />
      )}
    </>
  );
};

Index.List = List;
Index.SkeletonList = SkeletonList;

export default Index;
