import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const SkeletonList = () => {
  return (
    <>
      <Grid item md={6} mobile={12}>
        <Paper
          sx={{
            backgroundColor: (theme) => theme.palette.colors.darkGray,
            mb: "15px",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              border: `1px solid transparent`,
              padding: { md: "20px 35px", mobile: "20px 10px" },
              "&:hover": {
                border: `1px solid #944dff`,
                boxShadow: "#944dff 0px 0px 10px 0px",
              },
            }}
          >
            <Box sx={{ display: "flex", flex: 1 }}>
              <Box
                sx={{
                  mr: "25px",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <Skeleton variant={"circular"} height={"40px"} width={"40px"} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ paddingRight: { md: "80px", mobile: "0px" } }}>
                  <Skeleton
                    variant={"rectangular"}
                    height={"40px"}
                    width={"150px"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={6} mobile={12}>
        <Paper
          sx={{
            backgroundColor: (theme) => theme.palette.colors.darkGray,
            mb: "15px",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              border: `1px solid transparent`,
              padding: { md: "20px 35px", mobile: "20px 10px" },
              "&:hover": {
                border: `1px solid #944dff`,
                boxShadow: "#944dff 0px 0px 10px 0px",
              },
            }}
          >
            <Box sx={{ display: "flex", flex: 1 }}>
              <Box
                sx={{
                  mr: "25px",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <Skeleton variant={"circular"} height={"40px"} width={"40px"} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ paddingRight: { md: "80px", mobile: "0px" } }}>
                  <Skeleton
                    variant={"rectangular"}
                    height={"40px"}
                    width={"150px"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={6} mobile={12}>
        <Paper
          sx={{
            backgroundColor: (theme) => theme.palette.colors.darkGray,
            mb: "15px",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              border: `1px solid transparent`,
              padding: { md: "20px 35px", mobile: "20px 10px" },
              "&:hover": {
                border: `1px solid #944dff`,
                boxShadow: "#944dff 0px 0px 10px 0px",
              },
            }}
          >
            <Box sx={{ display: "flex", flex: 1 }}>
              <Box
                sx={{
                  mr: "25px",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <Skeleton variant={"circular"} height={"40px"} width={"40px"} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ paddingRight: { md: "80px", mobile: "0px" } }}>
                  <Skeleton
                    variant={"rectangular"}
                    height={"40px"}
                    width={"150px"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={6} mobile={12}>
        <Paper
          sx={{
            backgroundColor: (theme) => theme.palette.colors.darkGray,
            mb: "15px",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              border: `1px solid transparent`,
              padding: { md: "20px 35px", mobile: "20px 10px" },
              "&:hover": {
                border: `1px solid #944dff`,
                boxShadow: "#944dff 0px 0px 10px 0px",
              },
            }}
          >
            <Box sx={{ display: "flex", flex: 1 }}>
              <Box
                sx={{
                  mr: "25px",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <Skeleton variant={"circular"} height={"40px"} width={"40px"} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ paddingRight: { md: "80px", mobile: "0px" } }}>
                  <Skeleton
                    variant={"rectangular"}
                    height={"40px"}
                    width={"150px"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={6} mobile={12}>
        <Paper
          sx={{
            backgroundColor: (theme) => theme.palette.colors.darkGray,
            mb: "15px",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              border: `1px solid transparent`,
              padding: { md: "20px 35px", mobile: "20px 10px" },
              "&:hover": {
                border: `1px solid #944dff`,
                boxShadow: "#944dff 0px 0px 10px 0px",
              },
            }}
          >
            <Box sx={{ display: "flex", flex: 1 }}>
              <Box
                sx={{
                  mr: "25px",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <Skeleton variant={"circular"} height={"40px"} width={"40px"} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ paddingRight: { md: "80px", mobile: "0px" } }}>
                  <Skeleton
                    variant={"rectangular"}
                    height={"40px"}
                    width={"150px"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={6} mobile={12}>
        <Paper
          sx={{
            backgroundColor: (theme) => theme.palette.colors.darkGray,
            mb: "15px",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              border: `1px solid transparent`,
              padding: { md: "20px 35px", mobile: "20px 10px" },
              "&:hover": {
                border: `1px solid #944dff`,
                boxShadow: "#944dff 0px 0px 10px 0px",
              },
            }}
          >
            <Box sx={{ display: "flex", flex: 1 }}>
              <Box
                sx={{
                  mr: "25px",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <Skeleton variant={"circular"} height={"40px"} width={"40px"} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ paddingRight: { md: "80px", mobile: "0px" } }}>
                  <Skeleton
                    variant={"rectangular"}
                    height={"40px"}
                    width={"150px"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={6} mobile={12}>
        <Paper
          sx={{
            backgroundColor: (theme) => theme.palette.colors.darkGray,
            mb: "15px",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              border: `1px solid transparent`,
              padding: { md: "20px 35px", mobile: "20px 10px" },
              "&:hover": {
                border: `1px solid #944dff`,
                boxShadow: "#944dff 0px 0px 10px 0px",
              },
            }}
          >
            <Box sx={{ display: "flex", flex: 1 }}>
              <Box
                sx={{
                  mr: "25px",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <Skeleton variant={"circular"} height={"40px"} width={"40px"} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ paddingRight: { md: "80px", mobile: "0px" } }}>
                  <Skeleton
                    variant={"rectangular"}
                    height={"40px"}
                    width={"150px"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={6} mobile={12}>
        <Paper
          sx={{
            backgroundColor: (theme) => theme.palette.colors.darkGray,
            mb: "15px",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              border: `1px solid transparent`,
              padding: { md: "20px 35px", mobile: "20px 10px" },
              "&:hover": {
                border: `1px solid #944dff`,
                boxShadow: "#944dff 0px 0px 10px 0px",
              },
            }}
          >
            <Box sx={{ display: "flex", flex: 1 }}>
              <Box
                sx={{
                  mr: "25px",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <Skeleton variant={"circular"} height={"40px"} width={"40px"} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ paddingRight: { md: "80px", mobile: "0px" } }}>
                  <Skeleton
                    variant={"rectangular"}
                    height={"40px"}
                    width={"150px"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </>
  );
};

export default SkeletonList;
