import venueSeats from "./venueSeats.json"; // Adjust the path if necessary

const supportedVenues = [
  {
    id: "100",
    name: "Cankarjev Dom - Dvorana Duše Počkaj",
  },
];

const useEventVenue = () => {
  const getSupportedVenues = () => {
    return supportedVenues;
  };
  const getVenueSeatsById = (id) => {
    if (!id) return null;
    const seats = venueSeats[id];

    //find all array items that are "None" and replace the value with null and isReserved: "True" with isReserved: true
    if (seats) {
      return seats.map((row) => {
        return row.map((seat) => {
          if (seat === "None") {
            return null;
          } else if (seat.isReserved === "True") {
            return {
              ...seat,
              isReserved: true,
            };
          }
          return seat;
        });
      });
    }

    return seats || null;
  };

  return {
    getVenueSeatsById,
    getSupportedVenues,
  };
};

export default useEventVenue;
