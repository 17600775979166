import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Tag = ({ children, sx }) => {
  return (
    <Box
      sx={{
        height: { md: "23px", mobile: "21px" },
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        borderRadius: "100px",
        width: "fit-content",
        p: "4px 14px",
        ...sx,
      }}
    >
      <Typography variant={"h10"} sx={{ color: "#fff" }}>
        {children}
      </Typography>
    </Box>
  );
};

export default Tag;
