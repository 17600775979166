import React from "react";
import Card from "./Card";
import Dialog from "./Dialog";
import Overlay from "./Overlay";
import Locked from "./Locked";

const Index = () => {
  return <></>;
};

Index.Card = Card;
Index.Dialog = Dialog;
Index.Overlay = Overlay;
Index.Locked = Locked;

export default Index;
