import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import UI from "../../@components/UI";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import { Controller, useForm, useWatch } from "react-hook-form";
import Alert from "@mui/material/Alert";
import useHandleErrors from "../../@components/hooks/useHandleErrors";
import { useSnackbar } from "notistack";
import useService from "../useService";
import { useUserContext } from "../../@components/userContext";
import Auth from "../../@components/auth";
import googleLoginIcon from "../../static/googleLogin.png";
import { useGoogleLogin } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const RegisterDialog = ({
  toggleOpen,
  onRegister,
  showLogInDialog,
  onGoogleRegister,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { register, registerWithGoogle } = Auth.useAuth();
  const { setUser: setUserContext } = useUserContext();
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const { handleApiError } = useHandleErrors();
  const { enqueueSnackbar } = useSnackbar();
  const service = useService();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
      termsAndConditions: false,
    },
    mode: "all",
  });

  const password = useWatch({ control, name: "password" });

  const sendConfirmationEmail = async () => {
    await service
      .sendConfirmationEmail()
      .then(() => {
        setIsLoading(false);
        setOpen(false);
        onRegister();
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const googleAuth = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await googleRegister(tokenResponse.access_token);
    },
  });

  const googleRegister = async (access_token) => {
    setError(null);
    setIsLoading(true);
    await registerWithGoogle(access_token)
      .then((response) => {
        const { user } = response;
        setUserContext(user);
        onGoogleRegister(user.smart_address);
        enqueueSnackbar(t("profile.dialogs.createAccount.snackbar"), {
          variant: "success",
        });
        setIsLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setError(t("profile.dialogs.createAccount.error"));
        } else {
          setError(error?.response?.data.message);
        }
        setIsLoading(false);
      });
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const data = {
      email: formData.email,
      password_1: formData.password,
      password_2: formData.confirmPassword,
    };

    await register(data)
      .then(async (response) => {
        const { user } = response;
        enqueueSnackbar(t("profile.dialogs.createAccount.snackbar"), {
          variant: "success",
        });

        setUserContext(user);
        await sendConfirmationEmail();
      })
      .catch((error) => {
        handleApiError(error);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);
  const handleClose = () => {
    reset();
    setOpen(false);
  };
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          padding: { md: "40px 60px 50px", mobile: "28px 10px 30px" },
          width: "650px",
          textAlign: "center",
          margin: { mobile: "10px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ p: { mobile: "5px 10px 17px", md: "0px" } }}>
        <CloseIcon
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 20,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Typography
                variant={"h3"}
                sx={{ textAlign: "center", mb: "10px" }}
              >
                {t("profile.dialogs.createAccount.title")}
              </Typography>
              <Typography variant={"subtitle2"} sx={{ textAlign: "center" }}>
                {t("profile.dialogs.createAccount.alreadyHaveAccount")}{" "}
                <Box
                  onClick={() => {
                    reset();
                    showLogInDialog();
                  }}
                  component={"span"}
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    transition: "all 0.2s ease-in-out",
                    color: (theme) => theme.palette.text.link,
                    "&:hover": {
                      color: (theme) => theme.palette.text.linkHover,
                    },
                  }}
                >
                  {t("profile.dialogs.createAccount.logIn")}
                </Box>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { md: "row", mobile: "column" },
                  justifyContent: "center",
                  mt: { mobile: "18px", md: "26px" },
                }}
              >
                <Box
                  onClick={() => googleAuth()}
                  sx={{
                    margin: { mobile: "0 auto", md: "0 auto" },
                    cursor: "pointer",
                    display: "flex",
                    width: isMobile ? "280px" : "358px",
                    borderRadius: (theme) => theme.borderRadius.secondary,
                    height: "46px",
                    p: "10px 18px",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.85)",
                    border: "1px solid transparent",
                    "&:hover": {
                      border: "1px solid transparent",
                      filter:
                        "drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.2))",
                      transition: "all 0.2s ease",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                    },
                  }}
                >
                  <Box
                    component={"img"}
                    src={googleLoginIcon}
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <Typography
                    variant={"h6"}
                    sx={{
                      ml: "15px",
                      color: "black !important",
                    }}
                  >
                    {t("profile.dialogs.createAccount.createWithGoogle")}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  m: { mobile: "24px 16px 22px", md: "34px 30px 33px" },
                  borderBottom: "1px solid",
                  borderColor: (theme) => theme.palette.colors.gray,
                }}
              ></Box>
              <Box>
                <Controller
                  name={"email"}
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: t("profile.dialogs.createAccount.emailPattern"),
                    },
                  }}
                  render={({ field }) => (
                    <UI.TextField
                      data-test={"email"}
                      {...field}
                      fullWidth
                      placeholder={t("profile.dialogs.createAccount.email")}
                    />
                  )}
                />
                <Box sx={{ mt: "8px" }}>
                  {errors.email && (
                    <Alert variant="outlined" severity="error">
                      {errors.email?.type === "pattern" && errors.email.message}
                      {errors.email?.type === "required" &&
                        t("profile.dialogs.createAccount.emailRequired")}
                    </Alert>
                  )}
                </Box>
              </Box>
              <Box sx={{ mt: "12px" }}>
                <Controller
                  name={"password"}
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^.{8,}$/,
                      message: t(
                        "profile.dialogs.createAccount.passwordPattern"
                      ),
                    },
                  }}
                  render={({ field }) => (
                    <UI.PasswordField
                      data-test={"password"}
                      {...field}
                      fullWidth
                      placeholder={t("profile.dialogs.createAccount.password")}
                    />
                  )}
                />
                <Box sx={{ mt: "8px" }}>
                  {errors.password && (
                    <Alert variant="outlined" severity="error">
                      {errors.password?.type === "pattern" &&
                        errors.password.message}
                      {errors.password?.type === "required" &&
                        t("profile.dialogs.createAccount.passwordRequired")}
                    </Alert>
                  )}
                </Box>
              </Box>
              <Box sx={{ mt: " 12px" }}>
                <Box sx={{ mt: " 12px" }}>
                  <Controller
                    name={"confirmPassword"}
                    control={control}
                    rules={{
                      required: true,
                      validate: (value) =>
                        value === password ||
                        t(
                          "profile.dialogs.createAccount.confirmPasswordValidate"
                        ),
                    }}
                    render={({ field }) => (
                      <UI.PasswordField
                        {...field}
                        data-test={"confirmPassword"}
                        fullWidth
                        placeholder={t(
                          "profile.dialogs.createAccount.confirmPassword"
                        )}
                      />
                    )}
                  />
                  <Box sx={{ mt: "8px" }}>
                    {errors.confirmPassword && (
                      <Alert variant="outlined" severity="error">
                        {errors.confirmPassword?.type === "validate" &&
                          errors.confirmPassword.message}
                        {errors.confirmPassword?.type === "required" &&
                          t(
                            "profile.dialogs.createAccount.confirmPasswordRequired"
                          )}
                      </Alert>
                    )}
                  </Box>
                </Box>
                <Box sx={{ mt: { mobile: "20px", md: "26px" } }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Controller
                      name={"termsAndConditions"}
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <UI.Checkbox
                          {...field}
                          data-test={"termsAndConditions"}
                        />
                      )}
                    />
                    <Typography
                      variant={"subtitle2"}
                      sx={{
                        ml: {
                          mobile: "0px !important",
                          md: "-11px !important",
                        },
                        textAlign: "start",
                      }}
                    >
                      {t("profile.dialogs.createAccount.terms")}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: "8px" }}>
                    {errors.termsAndConditions?.type === "required" && (
                      <Alert
                        variant="outlined"
                        severity="error"
                        sx={{ mt: "8px" }}
                      >
                        {t("profile.dialogs.createAccount.termsRequired")}
                      </Alert>
                    )}
                  </Box>
                </Box>
                <Box sx={{ mt: "8px" }}>
                  {error && (
                    <Alert variant="outlined" severity="error">
                      {error}
                    </Alert>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  mt: { md: "34px", mobile: "24px" },
                  px: "15px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <UI.Button
                  data-test={"createAccount"}
                  disabled={Object.keys(errors).length}
                  sx={{ width: { mobile: "285px", md: "340px" } }}
                  title={t("profile.dialogs.createAccount.button")}
                  type={"primary"}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle1"}>
          {t("profile.dialogs.createAccount.loading")}
        </Typography>
        <Typography variant={"subtitle1"}>
          {t("profile.dialogs.createAccount.loading2")}
        </Typography>
      </UI.Busy.FullscreenIndicator>
    </MaterialDialog>
  );
};

export default RegisterDialog;
