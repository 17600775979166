import React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 56,
  height: 35,
  padding: 6,
  "& .MuiSwitch-switchBase": {
    margin: 1.5,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(28px)",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.type === "dark" ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.08)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.type === "dark" ? "rgba(255, 255, 255, 0.8)" : "rgba(0, 0, 0, 0.8)",
    width: 15,
    height: 15,
    position: "absolute",
    left: "2px",
    top: "8.5px",
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.type === "dark" ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.08)",
    borderRadius: 30 / 2,
  },
}));

const SwitchButton = ({ leftText, rightText, isChecked = true, onChange }) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant={"h8"} sx={{ ml: "20px" }}>
        {leftText}
      </Typography>
      <div onClick={() => onChange(!isChecked)} style={{cursor: "pointer"}}>
        <MaterialUISwitch
          checked={isChecked}
          inputProps={{ "aria-label": "ant design" }}
        />
      </div>
      <Typography variant={"h8"} sx={{ ml: "20px" }}>
        {rightText}
      </Typography>
    </Stack>
  );
};

export default SwitchButton;
