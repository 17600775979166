import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../@components/UI";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

const Promotion = ({
  image,
  video,
  title,
  subtitle,
  url,
  hideButton = false,
  isExteranlUrl = false,
  reverse,
}) => {
  const history = useHistory();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          md: reverse ? "row-reverse" : "row",
          mobile: "column",
        },
        maxHeight: { md: "400px", mobile: "100%" },
      }}
    >
      <Box
        sx={{
          ...(isMobile
            ? { borderRadius: "8px 8px 0 0" }
            : { borderRadius: "8px" }),
          flex: 1,
          maxWidth: { md: "50%", mobile: "100%" },
          height: "auto",
          overflow: "hidden",
          margin: 0, // Reset margin
          padding: 0, // Reset padding
        }}
      >
        {image && (
          <Box
            component={"img"}
            src={image}
            sx={{
              width: "100%",
              height: { lg: "400px", md: "350px", mobile: "300px" },
              objectFit: "cover",
              margin: 0, // Reset margin
              padding: 0, // Reset padding
              display: "block", // Ensures inline spacing isn't applied
            }}
          />
        )}
        {video && (
          <video
            playsInline
            style={{
              width: "100%",
              height: { lg: "400px", md: "350px", mobile: "300px" },
              textAlign: "center",
              objectFit: "cover",
              color: "transparent",
              textIndent: "10000px",
              float: "left",
            }}
            autoPlay="autoplay"
            loop
            muted
          >
            <source src={video} />
          </video>
        )}
      </Box>
      <Box
        sx={{
          ...(isMobile
            ? { borderRadius: "0 0 8px 8px" }
            : { borderRadius: "8px" }),
          ...(isMobile
            ? { mr: "0" }
            : { mr: reverse ? { md: "20px", mobile: "0px" } : "0px" }),
          ...(isMobile
            ? { ml: "0" }
            : { ml: reverse ? "0px" : { md: "20px", mobile: "0px" } }),
          flex: 1,
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            ml: { md: "100px", mobile: "0px" },
            mr: { md: "120px", mobile: "0px" },
            padding: { mobile: "40px 20px 45px", md: "unset" },
          }}
        >
          <Box sx={{ flexDirection: "column" }}>
            <Typography
              variant={"h3"}
              sx={{
                whiteSpace: "pre-line",
                lineHeight: "1.4",
                mt: "5px",
                textAlign: { mobile: "center", md: "start" },
              }}
            >
              {title}
            </Typography>
            <Typography
              variant={"subtitle1"}
              sx={{
                mt: { md: "10px", mobile: "14px" },
                mb: { mobile: "12px", md: "18px" },
                textAlign: { mobile: "center", md: "start" },
              }}
            >
              {subtitle}
            </Typography>
            {isExteranlUrl === false && hideButton === false && (
              <UI.Button
                type={"secondary"}
                size={"small"}
                onClick={() =>
                  history.push({
                    pathname: url,
                    state: { scrollToTop: true },
                  })
                }
                sx={{
                  mt: { md: "10px", mobile: "20px" },
                  width: { mobile: "100%", md: "230px" },
                  height: "46px",
                }}
                title={t("commons.seeMore")}
              />
            )}
            {isExteranlUrl === true && (
              <Link to={{ pathname: url }} target="_blank" rel="noreferrer">
                <UI.Button
                  type={"secondary"}
                  size={"small"}
                  sx={{
                    mt: { md: "10px", mobile: "20px" },
                    width: { mobile: "100%", md: "230px" },
                    height: "46px",
                  }}
                  title={t("commons.seeMore")}
                />
              </Link>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Promotion;
