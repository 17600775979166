import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import UI from "../../@components/UI";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../@components/userContext";
import useAuth from "../../@components/auth/useAuth";
import Link from "@mui/material/Link";
import { createMultiOwnerModularAccount } from "@alchemy/aa-accounts";
import { custom, http } from "viem";
import { createBundlerClient, polygonAmoy, polygon } from "@alchemy/aa-core";
import secureAccountIcon from "../../static/secure-account.png";
import faceRecognitionIcon from "../../static/face-recognition.png";
import { useAuthenticate, useSigner } from "@alchemy/aa-alchemy/react";

const publicClient = createBundlerClient({
  chain: window.env.CHAIN === "AMOY" ? polygonAmoy : polygon,
  transport: http(window.env.GATEWAY + "/api/rpc"),
});

const CreateSmartAccountDialog = ({ toggleOpen, onSuccess }) => {
  const { addSmartAccountToExistingUser } = useAuth();
  const { user: loggedInUser, setUser: setUserContext } = useUserContext();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsloading] = React.useState(false);
  const signer = useSigner();

  const { authenticate } = useAuthenticate({
    onSuccess: async (user) => {
      await signer.getAuthDetails().catch(() => {
        return null;
      });

      const smartAccount = await createMultiOwnerModularAccount({
        transport: custom(publicClient),
        chain: window.env.CHAIN === "AMOY" ? polygonAmoy : polygon,
        signer: signer,
      });

      await addSmartAccountToExistingUser({
        smart_address: smartAccount.address,
      });

      setIsloading(false);
      setUserContext({ ...loggedInUser, smart_address: smartAccount.address });

      onSuccess && onSuccess();
      handleClose();
    },
    onError: (e) => {
      console.error("Failed to login", e);
      setIsloading(false);
    },
  });

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MaterialDialog
      // TransitionComponent={Transition}
      PaperProps={{
        sx: {
          // Default styles that apply to all breakpoints
          position: "absolute", // Needed to allow for custom positioning
          textAlign: "center",
          borderTopRightRadius: "35px",
          borderTopLeftRadius: "35px",
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,

          // Styles for mobile and smaller screens
          // This makes the dialog stick to the bottom and occupy 50% of the screen height on mobile devices
          "@media (max-width: 600px)": {
            maxHeight: "60vh", // Limits the dialog height to 50% of the viewport height
            width: "100%", // Dialog occupies the full width of the screen
            m: 0, // Removes margin
            mt: "auto", // Pushes the dialog to the bottom of the screen
            bottom: 0, // Aligns to the bottom of the screen
            padding: "28px 10px 30px", // Mobile-specific padding
          },

          // Styles for desktop and larger screens
          // Reverts to the original design on desktops
          "@media (min-width: 600px)": {
            borderRadius: "15px",
            position: "relative", // Use relative positioning on desktop
            background: (theme) => theme.palette.dialog.background,
            backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
            padding: "40px 60px 50px", // Desktop-specific padding
            width: "650px", // Fixed width for desktop
            textAlign: "center",
            margin: "10px", // Margin around the dialog on desktop
          },
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ p: { mobile: "5px 10px 17px", md: "0px" } }}>
        <CloseIcon
          onClick={() => {
            setOpen(false);
          }}
          sx={{
            position: "absolute",
            right: 20,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <Grid container>
          <Grid item mobile={12} md={12}>
            <Typography variant={"h3"} sx={{ textAlign: "center" }}>
              {t("profile.dialogs.createSmartAccountDialog.title")}
            </Typography>
            <Box
              component={"img"}
              src={secureAccountIcon}
              alt={t("commons.secureAccount")}
              sx={{
                width: "60px",
                margin: "0 auto",
                mt: "20px",
                filter: "invert(1)",
              }}
            />
            <Typography variant={"subtitle2"} sx={{ mt: "16px" }}>
              {t("profile.dialogs.createSmartAccountDialog.subtitle")}
            </Typography>
            <Box
              sx={{
                mt: { md: "34px", mobile: "26px" },
                px: "15px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <UI.Button
                onClick={() => {
                  setIsloading(true);
                  authenticate({
                    type: "passkey",
                    createNew: true,
                    creationOpts: {
                      publicKey: {
                        rp: {
                          id:
                            process.env.NODE_ENV === "development"
                              ? "localhost"
                              : "qvrse.io",
                          name: "QVRS",
                        },
                        user: {
                          name: loggedInUser?.email,
                          displayName: loggedInUser?.email,
                        },
                      },
                    },
                  });
                }}
                sx={{ width: "340px" }}
                title={
                  <>
                    <Box
                      component={"img"}
                      src={faceRecognitionIcon}
                      alt={"Face Recognition"}
                      sx={{ width: "20px", filter: "invert(1)", mr: "10px" }}
                    />
                    {t("profile.dialogs.createSmartAccountDialog.button")}
                  </>
                }
                type={"primary"}
              />
              <Box sx={{ mt: { mobile: "10px", md: "12px" }, px: "10px" }}>
                <Typography variant={"subtitle3"} sx={{ textAlign: "center" }}>
                  {t("profile.dialogs.wrongAccountDialog.support")}{" "}
                  <Link
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      transition: "all 0.2s ease-in-out",
                      color: (theme) => theme.palette.text.link,
                      "&:hover": {
                        color: (theme) => theme.palette.text.linkHover,
                      },
                    }}
                    target="_blank"
                    href={"mailto: mainUtility@qvrse.io"}
                  >
                    info@qvrse.io
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle1"}>{t("commons.upgrading")}</Typography>
        <Typography variant={"subtitle1"}>
          {t("profile.dialogs.resetPasswordDialog.loading2")}
        </Typography>
      </UI.Busy.FullscreenIndicator>
    </MaterialDialog>
  );
};

export default CreateSmartAccountDialog;
