import Gateway from "../@components/gateway";

const useService = () => {
  const gateway = Gateway.useGateway();

  const featured = async () => {
    const response = await gateway.get(`/featured_news/`);
    return response.data.results;
  };

  return {
    featured,
  };
};

export default useService;
