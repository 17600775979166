import React from "react";
import List from "./List";
import { Box } from "@material-ui/core";
import Container from "@mui/material/Container";
const Index = ({ DAO }) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: { md: "0px 0px", mobile: "0px 0px" },
      }}
    >
      {DAO && (
        <Container
          maxWidth={false}
          sx={{
            minHeight: "100vh",
          }}
        >
          <List DAO={DAO} />
        </Container>
      )}
    </Box>
  );
};

export default Index;
