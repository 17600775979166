import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useAccount } from "wagmi";
import { useUserContext } from "../@components/userContext";
import Typography from "@mui/material/Typography";
import SettingsButton from "./SettingsButton";
import { useHistory, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  categoryItem: {
    width: "180px",
    height: "40px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    background: ({ selected }) => {
      return selected
        ? theme.palette.bgTransparent.secondary
        : theme.palette.bgTransparent.tertiary;
    },
    marginLeft: "2px",
    marginRight: "2px",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
  },
}));

const MobileMenu = ({ DAO, currentUrl, isUserMaintainer }) => {
  const { t } = useTranslation();
  const { user: loggedInUser } = useUserContext();
  const { isConnected, address } = useAccount();
  const history = useHistory();
  const location = useLocation();

  const swiperRef = React.useRef(null);

  const initialSlideToMenuItem = () => {
    if (location.pathname === `${currentUrl}/tresuary`) {
      swiperRef.current.swiper.slideTo(1);
    } else if (location.pathname === `${currentUrl}/vote`) {
      swiperRef.current.swiper.slideTo(2);
    } else if (location.pathname === `${currentUrl}/membership`) {
      swiperRef.current.swiper.slideTo(3);
    } else {
      swiperRef.current.swiper.slideTo(0);
    }
  };

  React.useEffect(() => {
    initialSlideToMenuItem();

    // eslint-disable-next-line
  }, [DAO]);

  return (
    <Box
      sx={{
        mb: "30px",
        display: {
          mobile: "block",
          md: "none",
        },
      }}
    >
      <Box
        sx={{
          justifyContent: "left",
          alignItems: "center",
          ml: "16px",
          mb: "20px",
          pr: "16px",
          display: "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Paper
            sx={{
              borderRadius: "50%",
              width: { md: "175px", mobile: "43px" },
              height: { md: "175px", mobile: "43px" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            <Box
              onClick={() => {
                history.push(`/qvrse`);
              }}
            >
              {DAO?.avatar_url && (
                <img
                  style={{ width: "100%", height: "auto", margin: "0 auto" }}
                  src={DAO?.avatar_url}
                  alt={"dao-logo"}
                />
              )}
            </Box>
            {isConnected && isUserMaintainer && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "15px",
                  right: "calc(50% + -60px)",
                  width: "35px",
                  height: "35px",
                  zIndex: 2,
                }}
              >
                <SettingsButton DAO={DAO} />
              </Box>
            )}
          </Paper>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              ml: "10px",
              textAlign: "left",
              maxWidth: "268px",
            }}
            variant={"h4"}
          >
            {DAO?.name}{" "}
          </Typography>
        </Box>
      </Box>
      <Swiper
        ref={swiperRef}
        grabCursor={true}
        slidesPerView={3.5}
        className="collection-category-swiper"
      >
        <SwiperSlide>
          <Item
            selected={location.pathname === `/qvrse`}
            onSelect={() => {
              swiperRef.current.swiper.slideTo(0);
              history.push(`${currentUrl}`);
            }}
            index={0}
            title={t("qvrseDAO.menu.home")}
          />
        </SwiperSlide>

        {(DAO?.maintainer_address?.toLowerCase() === address?.toLowerCase() ||
          DAO?.isMember === true) &&
          isConnected &&
          loggedInUser?.email_verified && (
            <SwiperSlide>
              <Item
                isAdminItem={true}
                selected={!!location.pathname.startsWith(`/qvrse/tresuary`)}
                onSelect={() => {
                  swiperRef.current.swiper.slideTo(1);
                  history.push(`${currentUrl}/tresuary`);
                }}
                index={3}
                title={t("qvrseDAO.menu.treasury")}
              />
            </SwiperSlide>
          )}
        <SwiperSlide>
          <Item
            selected={!!location.pathname.startsWith(`/qvrse/vote`)}
            onSelect={() => {
              swiperRef.current.swiper.slideTo(2);
              history.push(`${currentUrl}/vote`);
            }}
            index={3}
            title={t("qvrseDAO.menu.vote")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Item
            selected={!!location.pathname.startsWith(`/qvrse/membership`)}
            onSelect={() => {
              swiperRef.current.swiper.slideTo(3);
              history.push(`${currentUrl}/membership`);
            }}
            index={3}
            title={t("qvrseDAO.menu.membership")}
          />
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};

const Item = (props) => {
  const { selected, index, onSelect, title, isAdminItem } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const classes = useStyles({ selected, isMobile });
  return (
    <Box
      sx={{ position: "relative" }}
      className={classes.categoryItem}
      onClick={() => onSelect(index)}
      {...props}
    >
      {isAdminItem && (
        <Typography
          variant={"subtitle4"}
          sx={{
            position: "absolute",
            top: "7px",
            left: "50%",
            transform: "translateX(-50%)",
            fontSize: "10px !important",
            display: "none",
          }}
        >
          admin
        </Typography>
      )}
      <Typography
        sx={{
          margin: "0 auto",
          fontWeight: "bold",
          fontSize: "12px !important",
        }}
      >
        {title.toUpperCase()}
      </Typography>
    </Box>
  );
};

export default MobileMenu;
