import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import UI from "../../../@components/UI";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useAccount } from "wagmi";
import useService from "./useService";
import { useSnackbar } from "notistack";
import Alert from "@mui/material/Alert";
import CustomHooks from "../../../@components/hooks";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import Proposals from "../proposal";
import { useUserRole } from "../context";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  backButtonOuter: {
    "&:hover .backArrow": {
      transform: "translateX(-50%)",
    },
  },
  inputOuter: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Create = ({ DAO }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const service = useService(DAO?.id);
  const history = useHistory();
  const classes = useStyles();
  const { isConnected } = useAccount();
  const [isUserValid, setIsUserValid] = React.useState(null);
  const { isBrandMember } = useUserRole();
  const [existingUser, setExistingUser] = React.useState(null);
  CustomHooks.useProtectedRoute(DAO, isBrandMember);

  const { mutateAsync: addMember, isLoading } = CustomHooks.usePost(
    service.add,
    {
      onSuccess: () => {
        enqueueSnackbar(t("brands.membership.addMember.snackbar.success"), {
          variant: "success",
        });
        history.push({
          pathname: `/brand/${DAO?.id}/membership`,
          state: { scrollToTop: true },
        });
      },
    }
  );

  const { data: existingMembers, refetch } = CustomHooks.useFetch(
    ["members"],
    service.list,
    {
      enabled: false,
    }
  );

  React.useEffect(() => {
    DAO && refetch();
  }, [DAO, refetch]);

  const { mutate: checkUser } = CustomHooks.usePost(service.checkUser, {
    onSuccess: (response) => {
      const { address } = response;

      setValue("address", address, {
        shouldValidate: true,
      });
      if (!address) {
        setError("address", { type: "required" }, { shouldFocus: true });
      }
      const ifExistingUser = existingMembers?.find(
        (member) => member.user.address === address
      );
      if (ifExistingUser) {
        enqueueSnackbar(
          t("brands.membership.addMember.snackbar.alreadyMember"),
          {
            variant: "error",
          }
        );
        return;
      }
      setExistingUser(response);
      setIsUserValid(true);
    },
    onError: (e) => {
      setIsUserValid(false);
    },
  });

  const {
    control,
    setValue,
    setError,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: "",
      role: "admin",
      token_amount: 1,
    },
  });

  const emailValue = useWatch({ control, name: "email" });
  const roleValue = useWatch({ control, name: "role" });
  const tokenAmount = useWatch({ control, name: "token_amount" });
  const addressValue = useWatch({ control, name: "address" });

  const onSubmit = async (form) => {
    const { email, role } = form;
    await addMember({ email, role });
  };

  return (
    <Paper
      sx={{
        width: "100%",
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
      }}
    >
      <UI.Busy.FullscreenIndicator show={isLoading} />
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", mobile: "column" },
          justifyContent: { md: "space-between", mobile: "center" },
          px: { md: "70px", mobile: "0px" },
          pb: "20px",
          pt: { md: "55px", mobile: "30px" },
        }}
      >
        <UI.BackButton
          title={t("brands.membership.addMember.backButton")}
          onClick={() =>
            history.push({
              pathname: `/brand/${DAO?.id}/membership`,
              state: { scrollToTop: true },
            })
          }
        />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: "100%",
            px: { md: "80px", mobile: "10px" },
            pb: "75px",
          }}
        >
          <Typography
            variant={"h1"}
            sx={{
              mb: "25px",
              mt: "10px",
            }}
          >
            {t("brands.membership.addMember.title")}
          </Typography>

          <Typography variant={"subtitle1"}>
            {t("brands.membership.addMember.subtitle")}
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item mobile={12} md={12}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px", mt: "30px" }}>
                  <Typography variant={"h4"}>
                    {t("brands.membership.addMember.email.title")}
                  </Typography>
                  <UI.Tooltip>
                    {t("brands.membership.addMember.email.tooltip")}
                  </UI.Tooltip>
                </Box>

                <Controller
                  name={"email"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.TextField
                      {...field}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          checkUser(emailValue);
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <Box
                            sx={{
                              width: "230px",
                              display: { md: "flex", mobile: "none" },
                            }}
                          >
                            <UI.Button
                              title={t(
                                "brands.membership.addMember.email.action"
                              )}
                              sx={{ height: "38px" }}
                              type={"button"}
                              startIcon={<PersonSearchIcon />}
                              onClick={() => checkUser(emailValue)}
                            />
                          </Box>
                          <Box
                            sx={{
                              width: "120px",
                              display: { md: "none", mobile: "flex" },
                            }}
                          >
                            <UI.Button
                              title={t(
                                "brands.membership.addMember.email.actionSmall"
                              )}
                              sx={{ height: "28px" }}
                              type={"button"}
                              startIcon={<PersonSearchIcon />}
                              onClick={() => checkUser(emailValue)}
                            />
                          </Box>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                {isUserValid == null && (
                  <Box sx={{ mt: "10px" }}>
                    <Alert variant="outlined" severity="info">
                      {t("brands.membership.addMember.alert1")}
                    </Alert>
                  </Box>
                )}
                {isUserValid === false && (
                  <Box sx={{ mt: "10px" }}>
                    <Alert variant="outlined" severity="error">
                      {t("brands.membership.addMember.alert2")}
                    </Alert>
                  </Box>
                )}
                {isUserValid === true && (
                  <Box sx={{ mt: "10px" }}>
                    <Alert variant="outlined" severity="success">
                      {t("brands.membership.addMember.alert3")}
                    </Alert>
                  </Box>
                )}
              </Box>
            </Grid>
            {isUserValid && (
              <Grid item mobile={12} md={12}>
                <Box className={classes.inputOuter}>
                  <Box sx={{ display: "flex", mb: "10px" }}>
                    <Typography variant={"h4"}>
                      {t("brands.membership.addMember.walletAddress.title")}
                    </Typography>
                    <UI.Tooltip>
                      {t("brands.membership.addMember.walletAddress.tooltip")}
                    </UI.Tooltip>
                  </Box>

                  <Controller
                    name={"address"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <UI.TextField
                        disabled
                        {...field}
                        placeholder={t(
                          "brands.membership.addMember.walletAddress.placeholder"
                        )}
                      />
                    )}
                  />
                  <Box sx={{ mt: "15px" }}>
                    {errors.address?.type === "required" && (
                      <Alert variant="outlined" severity="error">
                        {t("brands.membership.addMember.alert4")}
                      </Alert>
                    )}
                  </Box>
                </Box>
              </Grid>
            )}
            {isUserValid && (
              <Grid item mobile={12} md={12}>
                <Box className={classes.inputOuter}>
                  <Box sx={{ display: "flex", mb: "10px" }}>
                    <Typography variant={"h4"}>
                      {t("brands.membership.addMember.role.title")}
                    </Typography>
                    <UI.Tooltip>
                      {t("brands.membership.addMember.role.tooltip")}
                    </UI.Tooltip>
                  </Box>

                  <Controller
                    name={"role"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <UI.Select {...field}>
                        <MenuItem value={"admin"}>
                          {t("brands.membership.addMember.role.roles.admin")}
                        </MenuItem>
                        <MenuItem value={"moderator"}>
                          {t(
                            "brands.membership.addMember.role.roles.moderator"
                          )}
                        </MenuItem>
                        <MenuItem value={"ticket_redeemer"}>
                          {t(
                            "brands.membership.addMember.role.roles.ticketRedeemer"
                          )}
                        </MenuItem>
                        )
                      </UI.Select>
                    )}
                  />

                  <Typography
                    variant={"subtitle4"}
                    sx={{
                      mt: "12px",
                    }}
                  >
                    <span>
                      <Box
                        component={"span"}
                        sx={{
                          fontWeight: "bold",
                          color: "#944dff",
                          fontSize: "16px !important",
                        }}
                      >
                        **
                      </Box>{" "}
                      <Box component={"span"} sx={{ fontWeight: "bold" }}>
                        {t("brands.membership.addMember.role.subtitle1")}
                      </Box>{" "}
                      {t("brands.membership.addMember.role.subtitle2")}{" "}
                      <Box component={"span"} sx={{ fontWeight: "bold" }}>
                        {t("brands.membership.addMember.role.subtitle3")}
                      </Box>{" "}
                      {t("brands.membership.addMember.role.subtitle4")}{" "}
                      <Box component={"span"} sx={{ fontWeight: "bold" }}>
                        {t("brands.membership.addMember.role.subtitle5")}
                      </Box>{" "}
                      {t("brands.membership.addMember.role.subtitle6")}{" "}
                    </span>
                  </Typography>

                  <Box sx={{ mt: "15px" }}>
                    {errors.role?.type === "required" && (
                      <Alert variant="outlined" severity="error">
                        {t("brands.membership.addMember.role.error.required")}
                      </Alert>
                    )}
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
          <Box
            sx={{
              width: "100%",
              mt: "45px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {roleValue !== "admin" && (
                <Box sx={{ width: "210px" }}>
                  <UI.Button
                    disabled={
                      !isConnected ||
                      !isUserValid ||
                      Object.keys(errors).length > 0
                    }
                    title={t("buttons.addMember")}
                    type={"primary"}
                  />
                </Box>
              )}
              {roleValue === "admin" && (
                <Proposals.AddMember
                  DAO={DAO}
                  emailAddress={emailValue}
                  amountTokens={tokenAmount}
                  walletAddress={addressValue}
                  userProfileImage={existingUser?.avatar_url}
                  isUserValid={isUserValid}
                  errors={errors}
                />
              )}
            </Box>
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default Create;
