import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useFieldArray } from "react-hook-form";
import UI from "../../../../../@components/UI";
import { useTranslation } from "react-i18next";

const Media = ({ control, errors, data }) => {
  const { t } = useTranslation();

  const { append, remove: removeMedia } = useFieldArray({
    control: control,
    name: `basic_info.media`,
  });

  const updateMedia = (isDefaultValue, newFiles) => {
    if (!newFiles) return;
    //remove all previous files
    removeMedia();

    //add new files
    newFiles.forEach((file) => {
      append({
        name: isDefaultValue ? file.name : "",
        image_url: file.ipfs,
        file_type: file.type,
      });
    });
  };

  return (
    <>
      <Grid item md={12} mobile={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "15px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <UI.Dropzone
            onChange={(files) => {
              updateMedia(false, files);
            }}
            onDefaultValueSet={(files) => {
              updateMedia(true, files);
            }}
            defaultValue={data?.basic_info?.media}
            id={"files"}
            title={t("commons.uploadPreviewStory") + " (" + t("brands.collection.create.basicInfo.animation.optional") + ")"}
            subtitle={t("brands.collection.create.basicInfo.uploadPhoto.subtitle")}
            acceptFiles={{
              "image/*": [".jpg", ".jpeg", ".png", ".gif", ".webp"],
              "video/*": [".mp4"],
            }}
          />
        </Box>
      </Grid>
    </>
  );
};

export default Media;
