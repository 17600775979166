import Gateway from "../../../@components/gateway";

const useService = () => {
  const gateway = Gateway.useGateway();

  const marketplace = async (daoId) => {
    const response = await gateway.get(`listings/${daoId}/by_dao/`);
    return response.data;
  };

  return {
    marketplace,
  };
};

export default useService;
