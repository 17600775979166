import React from "react";
import UI from "../../../../../@components/UI";
import useBlockchainState from "../useBlockchainState";
import useVote from "../useVote";
import useQueue from "../useQueue";
import useExecute from "../useExecute";
import { useSnackbar } from "notistack";
import { useMenu } from "../../../context";
import { ethers } from "ethers";
import tokenArtifact from "../../../../../abis/contracts/modules/token/ERC20Token.sol/ERC20Token.json";
import useProposalService from "../../../proposal/useService";
import { handleVote } from "../../../../../qvrse/vote/@id/utils";
import customHooks from "../../../../../@components/hooks";
import { useTranslation } from "react-i18next";

const VotingPanel = ({ DAO, proposal, onChange }) => {
  const { t } = useTranslation();
  const signer = customHooks.useEthersSigner();
  const proposalService = useProposalService(DAO?.id);
  const { updateMenu } = useMenu();
  const { enqueueSnackbar } = useSnackbar();
  const [eventListeners, setEventListeners] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const { votingPower } = useBlockchainState(DAO, proposal);
  const { vote } = useVote(DAO);
  const { queue } = useQueue(DAO);
  const { execute } = useExecute(DAO);

  const updateProposalStatus = async () => {
    await proposalService
      .setProposalExecuted(proposal.id)
      .then(() => {
        updateMenu();
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(error?.message, { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addEventListeners = () => {
    if (eventListeners.length === 0) {
      const tokenInstance = new ethers.Contract(
        DAO?.token_contract?.address,
        tokenArtifact.abi,
        signer
      );
      tokenInstance.on("Transfer", async () => {
        await updateProposalStatus();
        removeEventListeners();
      });
      setEventListeners((prevValue) => [...prevValue, "Transfer"]);
    }
  };

  const removeEventListeners = () => {
    const tokenInstance = new ethers.Contract(
      DAO?.token_contract?.address,
      tokenArtifact.abi,
      signer
    );
    tokenInstance.removeAllListeners();
  };

  React.useEffect(() => {
    return () => {
      removeEventListeners();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <UI.Busy.FullscreenIndicator show={isLoading} />
      <UI.VotingPoll
        DAO={DAO}
        proposal={proposal}
        onVote={async (option) => {
          setIsLoading(true);
          await handleVote(option, { voteFunc: vote, proposal, votingPower })
            .then(async (response) => {
              setIsLoading(false);
              enqueueSnackbar(t("brands.vote.votingResultsPanel.snackbar"), {
                variant: "success",
              });
              onChange(response);
            })
            .catch((reason) => {
              setIsLoading(false);
            });
        }}
        onQueue={async () => {
          setIsLoading(true);
          queue(proposal)
            .then((response) => {
              onChange(response);
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        }}
        onExecute={async () => {
          addEventListeners();
          setIsLoading(true);
          execute(proposal)
            .then((response) => {
              onChange(response);
            })
            .catch(() => {
              removeEventListeners();
              setIsLoading(false);
            });
        }}
      />
    </React.Fragment>
  );
};

export default VotingPanel;
