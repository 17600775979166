import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import UI from "./index";

import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import connectIcon from "../../static/connect.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useUserContext } from "../userContext";
import { useUser } from "@alchemy/aa-alchemy/react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";

import { useLogout } from "@alchemy/aa-alchemy/react";

const CircularIntegration = ({ title, onClick, isBusy }) => {
  const { logout: smartAccountLogout } = useLogout();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { user: loggedInUser } = useUserContext();
  const [showConnectSmartAccountDialog, setShowConnectSmartAccountDialog] =
    React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const user = useUser();

  useEffect(() => {
    console.log("isAuthenticated", user);
    if (user !== null) {
      setSuccess(true);
    }
  }, [user]);

  const handleLogOut = () => {
    smartAccountLogout();
    setSuccess(false);
    setAnchorEl(null);
    window.location.reload();
  };

  const handleButtonClick = () => {
    if (!loading && user === null) {
      setSuccess(false);
      setLoading(true);
      setShowConnectSmartAccountDialog(true);
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ position: "relative" }}>
        <Fab
          aria-label="save"
          color="primary"
          disabled={!loggedInUser?.email_verified}
          sx={{
            backgroundColor: (theme) => theme.palette.buttons.primary,
            "&:hover": {
              backgroundColor: "#b185f2",
              transform: "scale(1.04)",
            },
            ...(success && {
              backgroundColor: green[500],
              "&:hover": {
                backgroundColor: green[700],
              },
            }),
            width: { md: "46px", mobile: "40px" },
            height: { md: "46px", mobile: "40px" },
            mb: "2px",
            ...(!success && {
              animation: "pulse 2s infinite",
              "@keyframes pulse": {
                "0%": {
                  transform: "scale(1)",
                },
                "50%": {
                  transform: "scale(1.04)",
                  backgroundColor: "#b185f2",
                  transition: "all ease-in-out",
                },
                "100%": {
                  transform: "scale(1)",
                },
              },
            }),
          }}
          onClick={(event) =>
            success === false
              ? handleButtonClick()
              : setAnchorEl(event.currentTarget)
          }
        >
          {success ? (
            <CheckIcon />
          ) : (
            <Box
              component={"img"}
              src={connectIcon}
              sx={{ width: "24px", filter: "invert(1)" }}
            />
          )}
        </Fab>
      </Box>
      <Box sx={{ ml: isMobile ? "12px" : "15px", position: "relative" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: (theme) => theme.palette.buttons.primary,
            color: "#fff",
            fontFamily: "Quantico",
            fontWeight: "700",
            fontSize: "16px",
            "&:hover": {
              backgroundColor: "#b185f2",
            },
            "&.Mui-disabled": {
              backgroundColor: (theme) => theme.palette.colors.disabled,
              color: (theme) => theme.palette.text.primary,
              opacity: 0.25,
            },
          }}
          disabled={user === null || isBusy}
          onClick={onClick}
        >
          {title}
        </Button>
        {isBusy && (
          <CircularProgress
            size={24}
            sx={{
              color: "#b185f2",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Box>
      <UI.ConnectToSmartAccount
        open={showConnectSmartAccountDialog}
        onClose={() => {
          setSuccess(false);
          setLoading(false);
          setShowConnectSmartAccountDialog(false);
        }}
        onSuccess={() => {
          setShowConnectSmartAccountDialog(false);
          setSuccess(true);
          setLoading(false);
        }}
      />

      <Menu
        id="logout-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: (theme) => theme.borderRadius.primary,
            height: "auto",
            bgcolor: (theme) => theme.palette.colors.darkGray,
            overflow: "visible",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "none",
              position: "absolute",
              top: 0,
              right: 18,
              width: 10,
              height: 10,
              bgcolor: (theme) => theme.palette.colors.darkGray,
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem
          sx={{
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: "12px",
            mx: "10px",
            transition: "all 0.2s ease-in-out",
            "&: hover": {
              background: (theme) => theme.palette.bgTransparent.primary,
            },
          }}
          onClick={() => {
            handleLogOut();
          }}
        >
          Disconnect
        </MenuItem>
      </Menu>
    </Box>
  );
};

const SmartAccountActionButton = ({ title, onClick, isLoading }) => {
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <CircularIntegration
          title={title}
          onClick={onClick}
          isBusy={isLoading}
        />
      </Box>
    </>
  );
};

export default SmartAccountActionButton;
