import * as React from "react";
import { useUserContext } from "../../userContext";
import Box from "@mui/material/Box";
import nightModeIcon from "../../../static/night-mode-icon.png";
import LightModeIcon from "@mui/icons-material/LightMode";

const Index = () => {
  const { setSelectedTheme, selectedTheme } = useUserContext();

  const toggleUserChoice = () => {
    setSelectedTheme(selectedTheme === "light" ? "dark" : "light");
    localStorage.setItem(
      "user_theme",
      selectedTheme === "light" ? "dark" : "light"
    );
  };

  return (
    <Box
      onClick={(e) => toggleUserChoice(e)}
      sx={{
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor:
          selectedTheme === "dark"
            ? "rgba(255,255,255,0.06)"
            : "rgba(0,0,0,0.09)",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          backgroundColor:
            selectedTheme === "dark"
              ? "rgba(255,255,255,0.14)"
              : "rgba(0,0,0,0.16)",
          color: "#fff",
        },
      }}
    >
      {selectedTheme === "light" && (
        <img style={{ width: "20px" }} src={nightModeIcon} alt="nightMode" />
      )}
      {selectedTheme === "dark" && (
        <LightModeIcon sx={{ width: "20px" }} alt="lightMode" />
      )}
    </Box>
  );
};

export default Index;
