import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import UI from "../../../../../@components/UI";
import { useFormData } from "../context";
import Ticket from "./Ticket";
import useService from "../../../collection/useService";
import useHandleErrors from "../../../../../@components/hooks/useHandleErrors";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import MaterialAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useTranslation } from "react-i18next";
import useDeployCollection from "./useDeployCollection";
import { Breadcrumbs } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

const Create = ({ previouseSteps, DAO, onRemoveDraft }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const collectionService = useService(DAO?.id);
  const { data } = useFormData();

  const [isLoading, setIsLoading] = React.useState(false);
  const { prepareCollectionNFTUri, prepareNFTUri } = UI.useIPFS();
  const { handleApiError, handleMetamaskError } = useHandleErrors();
  const {
    deployNativeCollection,
    deployFiatCollection,
    deployFiatMultiUploadCollection,
    deployNativeMultiUploadCollection,
    deployFreeCollection,
    deployFreeMultiCollection,
    deployERC20Collection,
    deployERC20MultiUploadCollection,
  } = useDeployCollection();

  const saveCollectionToDatabase = async (collectionData) => {
    const data = {
      name: collectionData.basic_info.name,
      description: collectionData?.basic_info?.description,
      scheduled_date_time: collectionData?.basic_info?.scheduled_date_time,
      nft_address: collectionData.address,
      address: collectionData.address,
      picture_url: collectionData.nft_url,
      media: collectionData?.basic_info?.media,
      ticket_supply: collectionData.basic_info.supply,
      ticket_name: collectionData.basic_info.name,
      ticket_symbol: collectionData.basic_info.symbol,
      nft_file_type: collectionData.basic_info.picture_url.file?.type,
      nft_animation_url: collectionData.basic_info.nft_animation_url,
      custom_actions: collectionData?.custom_actions,
      payment_token:
        collectionData?.payment?.crypto?.token != null
          ? collectionData?.payment?.crypto?.token
          : null,
      currency: collectionData?.isFreeMintEnabled
        ? "MATIC"
        : collectionData?.payment?.crypto?.token
        ? collectionData?.payment?.crypto?.currency
        : collectionData?.payment?.fiat.currency,
      stripe: collectionData?.payment.fiat.stripe,
      paypal: collectionData?.payment.fiat.paypal,
      isFreeMintEnabled: collectionData?.isFreeMintEnabled || false,
      free_nft_gas_fee_payed_by_system:
        collectionData?.free_nft_gas_fee_payed_by_system || false,
      visible: collectionData?.basic_info?.visible,
      total_price_no_vat: collectionData?.total_price,
      total_price_with_tax: collectionData?.total_price,
      total_vat_amount: collectionData?.total_price,
      royalty: {
        fee: collectionData?.royalty?.fee,
        fee_address: collectionData?.royalty?.fee_address,
      },
      utilities: [
        collectionData?.main_utility,
        ...collectionData?.extra_utilities,
      ],
      request_info_fields: [],
      start_block: collectionData?.blockNumber,
      //Multi upload
      ...(collectionData.basic_info.collection_images
        ? { collection_images: collectionData.basic_info.collection_images }
        : {}),
      ...(collectionData.basic_info.has_multiple_images
        ? { has_multiple_images: collectionData.basic_info.has_multiple_images }
        : {}),
      ...(collectionData.basic_info.manual_nft_selection
        ? {
            manual_nft_selection:
              collectionData.basic_info.manual_nft_selection,
          }
        : {}),
      ...(collectionData.basic_info.random_images
        ? { random_images: collectionData.basic_info.random_images }
        : {}),
    };

    await collectionService
      .save(data)
      .then(() => {
        enqueueSnackbar(t("brands.collection.create.review.snackbar.success"), {
          variant: "success",
        });

        onRemoveDraft();
        history.push({
          pathname: `/brand/${DAO?.id}/collection`,
          state: { scrollToTop: true },
        });
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkAndPrepareDiscordAccessPerks = async (form) => {
    form.extra_utilities.forEach((item, index) => {
      if (item.type === "discord_access") {
        const channelNameItem = item.custom_info_fields.find(
          (field) => field.name === "Channel Name"
        );
        const inviteLinkItem = item.custom_info_fields.find(
          (field) => field.name === "Invite Link"
        );

        form.extra_utilities[index].discord_fields = {
          channel_name: channelNameItem ? channelNameItem.value : "",
          invite_link: inviteLinkItem ? inviteLinkItem.value : "",
        };
      }
    });
    return form;
  };

  const getCustomActions = (form) => {
    let customActions = [];
    // Custom actions in main utility
    form.main_utility.custom_info_fields =
      form.main_utility.custom_info_fields.filter((field) => {
        // if (field.type === "button") {
        //   customActions.push(field);
        //   return false;
        // }
        return true;
      });

    // Check if extra_utilities array exists and has length > 0
    if (form.extra_utilities && form.extra_utilities.length > 0) {
      // Loop over each item in the array
      form.extra_utilities.forEach((extra_utility, index) => {
        // In each item, filter the custom_info_fields for objects where type !== 'button'
        extra_utility.custom_info_fields =
          extra_utility.custom_info_fields.filter((field) => {
            // if (field.type === "button") {
            //   customActions.push(field);
            //   return false;
            // }
            return true;
          });
      });

      return customActions;
    }
  };

  const onSubmit = async () => {
    let form = { ...data };
    form = await checkAndPrepareDiscordAccessPerks(form);
    const nftUri = await prepareCollectionNFTUri(form, DAO);
    form.custom_actions = getCustomActions(form);

    const allData = {
      ...form,
      nft_ipfs_uri: nftUri,
      nft_url: form.basic_info.picture_url.ipfs,
      avatar_url: form.basic_info.picture_url.ipfs,
      total_price: form?.totalPrice,
      total_price_with_tax: form?.totalPrice,
      total_tax_amount: form?.totalPrice,
    };

    setIsLoading(true);
    try {
      //Deploy collection on chain.
      let deployEvent;

      if (
        form.basic_info.type_of_media === "suprise-bag" ||
        form.basic_info.type_of_media === "choose-nft"
      ) {
        // Multi NFT
        //Create IPFS URI for each image
        const collectionImages = await Promise.all(
          allData.basic_info.collection_images.map(async (imageData) => {
            const imageUri = await prepareNFTUri(allData, imageData, DAO);
            return {
              ...imageData,
              ipfs_data_url: imageUri,
            };
          })
        );

        allData.basic_info.collection_images = collectionImages;

        if (form.payment.fiat.stripe || form.payment.fiat.paypal) {
          //Fiat multiple
          deployEvent = await deployFiatMultiUploadCollection(allData, DAO);
        } else if (form.isFreeMintEnabled) {
          //Free
          deployEvent = await deployFreeMultiCollection(allData, DAO);
        } else {
          if (form.payment.crypto.token.type === "ERC20") {
            //ERC20
            deployEvent = await deployERC20MultiUploadCollection(allData, DAO);
          } else {
            //Native multiple
            deployEvent = await deployNativeMultiUploadCollection(allData, DAO);
          }
        }
      } else {
        // Single NFT
        if (form.payment.fiat.stripe || form.payment.fiat.paypal) {
          //Fiat
          deployEvent = await deployFiatCollection(allData, DAO);
        } else if (form.isFreeMintEnabled) {
          //Free
          deployEvent = await deployFreeCollection(allData, DAO);
        } else if (form.payment.crypto.token.type === "ERC20") {
          //Free
          deployEvent = await deployERC20Collection(allData, DAO);
        } else {
          //Native
          deployEvent = await deployNativeCollection(allData, DAO);
        }
      }
      setIsLoading(false);
      //Save collection to BE
      await saveCollectionToDatabase({
        ...allData,
        address: deployEvent.args[0],
        blockNumber: deployEvent.blockNumber,
      });
    } catch (error) {
      handleMetamaskError(error);
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        mt: { md: "15px", mobile: "20px" },
        p: { md: "40px 65px", mobile: "0px 10px" },
        mb: "10px",
        overflowWrap: "break-word",
      }}
    >
      <UI.Busy.FullscreenIndicator show={isLoading} />

      <Breadcrumbs aria-label="breadcrumb">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              cursor: "pointer",
              color: (theme) => theme.palette.text.primary,
            },
          }}
          onClick={() => {
            history.push(`/brand/${DAO.id}`);
          }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Home
        </Box>
        <Box
          underline="hover"
          sx={{
            display: "flex",
            alignItems: "center",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              cursor: "pointer",
              color: (theme) => theme.palette.text.primary,
            },
          }}
          onClick={() => {
            history.push(`/brand/${DAO.id}/collection`);
          }}
        >
          Collections
        </Box>
        <Box
          underline="hover"
          sx={{
            display: "flex",
            alignItems: "center",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              cursor: "pointer",
              color: (theme) => theme.palette.text.primary,
            },
          }}
          onClick={() => previouseSteps(4)}
        >
          Basic Info
        </Box>
        <Box
          underline="hover"
          sx={{
            display: "flex",
            alignItems: "center",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              cursor: "pointer",
              color: (theme) => theme.palette.text.primary,
            },
          }}
          onClick={() => previouseSteps(3)}
        >
          Main Utility
        </Box>
        <Box
          underline="hover"
          sx={{
            display: "flex",
            alignItems: "center",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              cursor: "pointer",
              color: (theme) => theme.palette.text.primary,
            },
          }}
          onClick={() => previouseSteps(2)}
        >
          Extra Utilities
        </Box>
        <Box
          underline="hover"
          sx={{
            display: "flex",
            alignItems: "center",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              cursor: "pointer",
              color: (theme) => theme.palette.text.primary,
            },
          }}
          onClick={() => previouseSteps(1)}
        >
          Payment
        </Box>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          Payment
        </Typography>
      </Breadcrumbs>
      <Box
        sx={{
          mb: "50px",
          display: "flex",
          flexDirection: { md: "row", mobile: "column" },
          textAlign: "left",
        }}
      >
        <Box>
          <Typography variant={"h3"}>
            {t("brands.collection.create.review.title")}
          </Typography>
        </Box>
      </Box>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={4} mobile={12}>
          <Box
            sx={{
              display: "flex",
              mb: "60px",
              justifyContent: { mobile: "center", md: "unset" },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Ticket
                brandName={DAO?.name}
                brandAvatar={DAO?.avatar_url}
                name={data?.basic_info?.name}
                fileType={data?.basic_info?.picture_url?.file?.type}
                data={data?.basic_info?.picture_url?.ipfs}
                media={data?.basic_info?.media}
                mainUtility={data?.main_utility}
                isLimited={data?.basic_info?.supply < 999999}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={8} mobile={12}>
          <Box sx={{ ml: { md: "60px", mobile: "0px" } }}>
            <Box sx={{ mb: "14px" }}>
              {DAO?.name ? (
                <Typography
                  variant={"subtitle3"}
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  {DAO?.name}
                </Typography>
              ) : (
                <Skeleton
                  sx={{ mb: "10px" }}
                  height={"15px"}
                  width={"300px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            <Box sx={{ mb: "18px" }}>
              {data?.basic_info?.name ? (
                <Typography variant={"h2"}>{data?.basic_info?.name}</Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"300px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {data ? (
              <Box sx={{ mb: "30px", display: "flex" }}>
                <UI.UtilityTag utilityType={data?.main_utility?.type} />
                {data?.basic_info?.supply < 999999 && (
                  <UI.Tag
                    sx={{
                      ml: "10px",
                      backgroundColor: (theme) => theme.palette.tag.green,
                    }}
                  >
                    {t("utilityTag.limited")}
                  </UI.Tag>
                )}
              </Box>
            ) : (
              <Skeleton
                sx={{ mb: "30px", display: "flex" }}
                height={"30px"}
                width={"300px"}
                variant={"rectangular"}
              />
            )}
            <Box sx={{ mb: "30px" }}>
              {data?.basic_info?.description ? (
                <UI.ReadMore fontSize={"16px"} maxTextLength={400}>
                  {data?.basic_info?.description}
                </UI.ReadMore>
              ) : (
                <Skeleton
                  height={"150px"}
                  width={"100%"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {data ? (
              <Box
                sx={{
                  mb: "11px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Typography variant={"subtitle1"}>
                    {t("commons.price")}
                  </Typography>
                  <Typography
                    variant={"subtitle1"}
                    sx={{
                      ml: "4.5px",
                      fontSize: "11px !important",
                      mt: "4.3px",
                    }}
                  >
                    {t("commons.withTax")}
                  </Typography>
                </Box>

                {!data?.isFreeMintEnabled && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ width: "20px", height: "20px", ml: "10px" }}>
                      <UI.PaymentIcon
                        currency={
                          data?.payment?.crypto?.token
                            ? data?.payment?.crypto?.currency
                            : data?.payment?.fiat?.currency
                        }
                      />
                    </Box>

                    <Typography
                      variant={"h1"}
                      sx={{
                        fontSize: "15px !important",
                        ml: "10px",
                        lineHeight: "unset !important",
                      }}
                    >
                      {`${data?.totalPrice} ${
                        data?.payment?.crypto?.token
                          ? data?.payment?.crypto?.currency
                          : data?.payment?.fiat?.currency
                      }`}
                    </Typography>
                  </Box>
                )}
                {data?.isFreeMintEnabled && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant={"h1"}
                      sx={{
                        fontSize: "15px !important",
                        ml: "10px",
                        lineHeight: "unset !important",
                      }}
                    >
                      {t("commons.free")}
                    </Typography>
                  </Box>
                )}
              </Box>
            ) : (
              <Skeleton
                sx={{ mb: "10px" }}
                height={"15px"}
                width={"300px"}
                variant={"rectangular"}
              />
            )}
            {data?.basic_info?.supply < 999999 && (
              <Box sx={{ mb: "30px", display: "flex", alignItems: "center" }}>
                <Typography variant={"subtitle1"}>
                  {t("commons.claimed")}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant={"h1"}
                    sx={{
                      fontSize: "15px !important",
                      ml: "10px",
                      lineHeight: "unset !important",
                    }}
                  >
                    {`0/${data?.basic_info?.supply}`}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
        {data?.basic_info?.has_multiple_images && (
          <Grid
            item
            md={12}
            mobile={12}
            sx={{
              mt: { md: "5px", mobile: "10px" },
              mb: { md: "15px", mobile: "16px" },
              pt: "0 !important",
            }}
          >
            <MaterialAccordion
              TransitionProps={{ unmountOnExit: true }}
              sx={{
                backgroundColor: "transparent",
              }}
              elevation={0}
              expanded={true}
            >
              <AccordionSummary
                sx={{
                  height: "48px",
                  minHeight: "48px !important",
                  p: "0",
                }}
              >
                {" "}
                {data?.basic_info?.manual_nft_selection === false && (
                  <Typography variant={"h3"}>
                    {t("brands.collection.collectionPage.sneakPeak")}
                  </Typography>
                )}
                {data?.basic_info?.manual_nft_selection === true && (
                  <Typography
                    variant={"h2"}
                    sx={{
                      fontSize: { mobile: "18px", md: "22px" },
                    }}
                  >
                    {t("brands.collection.collectionPage.chooseYourNft")}
                  </Typography>
                )}
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  p: "0",
                  mt: "20px",
                }}
              >
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {data?.basic_info.collection_images.map((item, index) => (
                    <CardItem item={item} />
                  ))}
                </Box>
              </AccordionDetails>
            </MaterialAccordion>
          </Grid>
        )}
        <Grid
          item
          md={12}
          mobile={12}
          sx={{
            mt: { md: "5px", mobile: "10px" },
            mb: { md: "15px", mobile: "16px" },
            pt: "0 !important",
          }}
        >
          <Box sx={{ mb: "30px" }}>
            <Typography
              variant={"h2"}
              sx={{
                fontSize: {
                  mobile: "18px !important",
                  md: "22px !important",
                },
              }}
            >
              {t("brands.collection.create.review.perks")}
            </Typography>
          </Box>
          <UI.Utilities
            utilities={[
              {
                ...data?.main_utility,
                custom_info_fields: data?.main_utility.custom_info_fields.map(
                  (field) => ({
                    ...field,
                    title: field.name,
                  })
                ),
              },
              ...data?.extra_utilities.map((utility) => ({
                ...utility,
                custom_info_fields: utility.custom_info_fields.map((field) => ({
                  ...field,
                  title: field.name,
                })),
              })),
            ]}
            currency={
              data?.payment?.crypto?.token
                ? data?.payment?.crypto?.currency
                : data?.payment?.fiat?.currency
            }
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          mt: "40px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "280px" }}>
            <UI.SmartAccountActionButton
              title={t("buttons.createCollection")}
              isLoading={isLoading}
              onClick={() => {
                onSubmit();
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const CardItem = ({ item }) => {
  console.log("item: ", item);
  return (
    <Box
      sx={{
        width: "150px",
        mr: { md: "30px", mobile: "20px" },
        mt: { md: "12px", mobile: "10px" },
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        transition: "all  0.3s ease-in-out",
        "&:hover .removeIcon": {
          display: "block",
          transition: "all 0.1s ease 0s",
        },
      }}
    >
      <Box
        sx={{
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "140px",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            height: "140px",
            overflow: "hidden",
            border: "2px solid transparent",
            transition: "all 0.3s ease-in-out",
            zIndex: 1,
            "&:hover": {
              boxShadow: "#944dff 0px 0px 15px 0px",
              border: "2px solid #944dff",
              transition: "all 0.3s ease-in-out",
            },
          }}
        >
          {item ? (
            <>
              {item?.file_type.startsWith("image") && (
                <UI.ImageWithSkeleton
                  sx={{
                    position: "absolute",
                    inset: 0,
                    boxSizing: "border-box",
                    padding: 0,
                    border: "none",
                    margin: "auto",
                    display: "block",
                    width: 0,
                    height: 0,
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                  }}
                  src={item.image_url}
                  width={"100%"}
                  alt={"slider-item"}
                />
              )}
              {item?.file_type.startsWith("video") && (
                <video
                  autoPlay="autoplay"
                  playsInline
                  loop
                  muted
                  alt={"slider-item"}
                >
                  <source src={item.image_url} />
                </video>
              )}
            </>
          ) : (
            <Skeleton
              height={"300px"}
              width={"300px"}
              variant={"rectangular"}
            />
          )}
        </Box>
        <Box
          sx={{
            mt: { md: "14px", mobile: "8px" },
            justifyContent: "center",
            textAlign: "center",
            height: "50px",
            overflow: "hidden",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              variant={"h8"}
              sx={{
                margin: "0 auto",
                maxWidth: "300px",
                whiteSpace: "wrap",
              }}
            >
              {item?.name.length > 15
                ? item?.name.slice(0, 15) + "... "
                : item?.name + " "}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Create;
