import React from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./styles.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Typography from "@mui/material/Typography";
import Item from "./Item";
import UI from "../../../../../@components/UI";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";

import "./styles.css";
import "swiper/css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useHistory } from "react-router-dom";

const Desktop = ({
  brands,
  selected,
  setSelected,
  onSelect,
  page = 0,
  isLoading,
}) => {
  const history = useHistory();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant={"h5"}>{t("appBar.favouriteBrands")}</Typography>
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();

            history.push({
              pathname: `/profile/brands`,
              state: { scrollToTop: true },
            });
          }}
        >
          <ArrowForwardIcon sx={{ fontSize: "20px" }} />
        </Box>
      </Box>

      <Box sx={{ display: brands?.length > 0 && "flex", mt: "20px" }}>
        <Swiper
          grabCursor={true}
          slidesPerView={
            brands?.length === 1 ? 1 : brands?.length === 2 ? 2 : 3
          }
          spaceBetween={10}
          className="owned-nfts-slider"
        >
          {!isLoading &&
            brands &&
            brands?.length > 0 &&
            brands.map((brand, index) => {
              return (
                <SwiperSlide>
                  <Item
                    onSelect={() => {
                      setSelected(index);
                      onSelect();
                    }}
                    brand={brand}
                    contentType={"image/"}
                    contentData={brand.avatar_url}
                    index={index}
                  />
                </SwiperSlide>
              );
            })}
        </Swiper>
        {!isLoading && brands && brands?.length === 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography
              variant={"subtitle3"}
              sx={{ color: (theme) => theme.palette.text.tertiary }}
            >
              {t("infoMessages.noFavoriteBrands")}
            </Typography>
          </Box>
        )}
        {isLoading && (
          <UI.Busy.Indicator
            width={isMobile ? "100px" : "160px"}
            height={isMobile ? "100px" : "160px"}
          />
        )}
      </Box>
    </>
  );
};

const CollectionSlider = ({ onSelect, brands, page, isLoading }) => {
  const [selected, setSelected] = React.useState(0);

  return (
    <Box sx={{ width: "100%", my: "20px" }}>
      <Desktop
        isLoading={isLoading}
        page={page}
        brands={brands}
        selected={selected}
        onSelect={onSelect}
        setSelected={setSelected}
      />
    </Box>
  );
};

export default CollectionSlider;
