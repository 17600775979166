import React from "react";
import notFoundImg from "../../static/not-found.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const NoResults = ({ text, image }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <img
        alt={"noresult"}
        style={{
          margin: "0 auto",
          width: "150px",
          textAlign: "center",
          opacity: "0.2",
          filter: "grayscale(1)",
        }}
        src={image ? image : notFoundImg}
      />
      <Typography variant={"subtitle2"} sx={{ textAlign: "center", opacity: "0.5" }}>
        {text}
      </Typography>
    </Box>
  );
};

export default NoResults;
