import React from "react";
import Box from "@mui/material/Box";
import lockedIcon from "../../../../../../static/lock-icon.png";
import textIcon from "../../../../../../static/text-small-icon.png";

const Locked = ({ onClose, onDelete }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 900,
        borderRadius: (theme) => theme.borderRadius.secondary
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "36px",
          height: "36px",
          borderRadius: "0px 5px 0px 20px",
          backgroundColor: "rgba(255, 255, 255, 0.25)",
        }}
      >
        <Box
          component={"img"}
          src={textIcon}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-40%, -56%)",
            width: "15px",
            height: "15px",
          }}
        />
      </Box>
      <Box
        component={"img"}
        src={lockedIcon}
        sx={{ height: { md: "55px", mobile: "45px" }  }}
      />
    </Box>
  );
};
const Action = ({ element: Component, onDelete }) => {
  const [open, setOpen] = React.useState(null);

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{ cursor: "pointer" }}
        component={"span"}
        onClick={() => setOpen(!open)}
      >
        {Component}
      </Box>

      <Locked
        open={open}
        onDelete={() => onDelete()}
        onClose={() => setOpen(false)}
      />
    </Box>
  );
};

Locked.Action = Action;

export default Locked;
