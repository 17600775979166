import * as React from "react";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import SvgIcon from "@mui/material/SvgIcon";
import Box from "@mui/material/Box";
const useStyles = makeStyles((theme) => ({
  dropdownStyle: {
    backgroundColor: "#1B1E2F",
    marginTop: "10px",
    borderRadius: "8px",
  },
  dropdownListStyle: {
    "& .Mui-selected": {
      backgroundColor: "#272A3A !important",
      color: theme.palette.text.primary,
    },
    "& .MuiMenuItem-root": {
      color: theme.palette.text.secondary,
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "#272A3A",
      color: theme.palette.text.primary,
    },
  },
  icon: {
    fill: "#FFFFFF",
    right: "30px",
    fontSize: "30px",
    color: theme.palette.text.primary + " !important",
  },
}));

const Sort = ({ value, onChange, children }) => {
  const classes = useStyles();

  return (
    <Select
      sx={{
        backgroundColor: (theme) => theme.palette.colors.darkGray,
        width: "170px",
        height: "55px",
        textAlign: "center",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "19px",
      }}
      MenuProps={{
        classes: {
          paper: classes.dropdownStyle,
          list: classes.dropdownListStyle,
        },
      }}
      value={value}
      onChange={onChange}
      displayEmpty
      inputProps={{
        classes: {
          icon: classes.icon,
        },
      }}
      renderValue={(value) => {
        return (
          <Box sx={{ display: "flex", gap: "15px", padding: "0px 20px" }}>
            <SvgIcon>
              <ImportExportIcon />
            </SvgIcon>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {value}
            </Box>
          </Box>
        );
      }}
    >
      {children}
    </Select>
  );
};

export default Sort;
