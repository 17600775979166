import { useQuery } from "@tanstack/react-query";
import useHandleErrors from "./useHandleErrors";

const useFetch = (queryKey, queryFn, options) => {
  const { handleApiError } = useHandleErrors();
  return useQuery({
    queryKey,
    queryFn,
    ...{
      onError: (error) => handleApiError(error.message),
      ...options,
    },
  });
};

export default useFetch;
