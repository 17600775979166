import React from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./styles.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Typography from "@mui/material/Typography";
import Item from "./Item";
import UI from "../../../../../@components/UI";
import { useTranslation } from "react-i18next";

const Desktop = ({
  collections,
  selected,
  setSelected,
  onSelect,
  maxItems,
  page = 0,
  isLoading,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex" }}>
      {!isLoading &&
        collections &&
        collections?.length > 0 &&
        collections.slice(page, page + maxItems).map((collection, index) => {
          return (
            <Item
              cardType={collection.collection_id}
              isFirstItem={index === 0}
              selected={selected === index}
              onSelect={() => {
                setSelected(index);
                onSelect();
              }}
              collection={collection}
              contentType={collection.nft_file_type}
              contentData={collection.nft_image_url}
              index={index}
            />
          );
        })}
      {!isLoading && collections && collections?.length === 0 && (
        <Box sx={{ display: "flex" }}>
          <Typography
            variant={"subtitle3"}
            sx={{
              fontWeight: "bold",
              color: (theme) => theme.palette.text.tertiary,
            }}
          >
            {t("brands.albums.collectionsNeeded")}
            {": "}
          </Typography>
          <Typography
            variant={"subtitle3"}
            sx={{ color: (theme) => theme.palette.text.tertiary, ml: "6px" }}
          >
            {t("brands.albums.selectCard")}
          </Typography>
        </Box>
      )}
      {isLoading && (
        <UI.Busy.Indicator
          width={isMobile ? "100px" : "160px"}
          height={isMobile ? "100px" : "160px"}
        />
      )}
    </Box>
  );
};

const CollectionSlider = ({
  maxItems,
  onSelect,
  collections,
  page,
  isLoading,
}) => {
  const [selected, setSelected] = React.useState(0);
  return (
    <Box sx={{ width: "100%" }}>
      <Desktop
        isLoading={isLoading}
        page={page}
        maxItems={maxItems}
        collections={collections}
        selected={selected}
        onSelect={onSelect}
        setSelected={setSelected}
      />
    </Box>
  );
};

export default CollectionSlider;
