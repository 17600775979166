import Gateway from "../../@components/gateway";

const useService = () => {
  const gateway = Gateway.useGateway();

  const save = (data) => {
    return gateway.post(`dao/${window.env.QVRSE_DAO_ID}/proposal/`, data);
  };

  const createBrand = (data) => {
    return gateway.post(`dao/new_post/`, data);
  };

  const proposals = async (statusList) => {
    if (statusList && statusList.length > 0) {
      return proposalsByStatus(statusList);
    } else {
      return gateway.get(`dao/${window.env.QVRSE_DAO_ID}/proposal/all/`);
    }
  };

  const proposalsByStatus = async (statusList = []) => {
    return gateway.post(
      `dao/${window.env.QVRSE_DAO_ID}/proposal/votes_by_status/`,
      { status_list: statusList }
    );
  };

  const setProposalExecuted = async (id) => {
    return gateway.post(
      `dao/${window.env.QVRSE_DAO_ID}/proposal/${id}/execute/`
    );
  };

  const proposal = async (id) => {
    return gateway.get(`dao/${window.env.QVRSE_DAO_ID}/proposal/${id}/`);
  };

  const getDAOLauncher = async () => {
    const response = await gateway.get(`contracts/dao_launcher`);
    return response.data;
  };

  return {
    save,
    createBrand,
    proposals,
    proposal,
    setProposalExecuted,
    proposalsByStatus,
    getDAOLauncher,
  };
};

export default useService;
