import React from "react";
import DialogContent from "@mui/material/DialogContent";
import MaterialDialog from "@mui/material/Dialog";
import Feed from "../../daos/@id/feed";

const PostDialog = ({ DAO, post, onClose, refetch }) => {
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
          position: "relative",
          width: "500px",
          maxHeight: {
            mobile: "calc(100vh - 200px)",
            md: "calc(100vh - 100px)",
          },
          margin: { mobile: "0px" },
        },
      }}
      open={post !== null}
      onClose={onClose}
    >
      <DialogContent sx={{ p: { mobile: "0px" } }}>
        {post && (
          <Feed.Item
            DAO={DAO}
            data={post}
            isBrandMember={false}
            refetch={refetch}
            noMargin
          />
        )}
      </DialogContent>
    </MaterialDialog>
  );
};

export default PostDialog;
