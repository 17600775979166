import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import UI from "../../../../../@components/UI";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MaterialDialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import CustomHooks from "../../../../../@components/hooks";
import useService from "../../useService";
import { useSnackbar } from "notistack";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

const EditDialog = ({ open, DAO, album, onClose, onConfirm }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const service = useService(DAO?.id);

  const { mutate: editAlbum } = CustomHooks.usePost(service.edit, {
    onSuccess: () => {
      enqueueSnackbar(t("brands.albums.editAlbumDialog.snackbar"), {
        variant: "success",
      });
      onConfirm();
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      name: album?.name || "",
      description: album?.description || "",
      avatar_url: album?.avatar_url || "",
    },
  });

  React.useEffect(() => {
    if (album) {
      reset({
        name: album?.name,
        description: album?.description,
        avatar_url: album?.avatar_url,
      });
    }
    // eslint-disable-next-line
  }, [album]);

  const onSubmit = async (form) => {
    editAlbum({
      albumId: album.id,
      data: { ...form, avatar_url: form.avatar_url.ipfs },
    });
  };

  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          padding: { md: "30px 10px 42px 10px", mobile: "0px 0px" },
          width: "750px",
          margin: { mobile: "15px", md: "0px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={open}
      onClose={onClose}
    >
      <CloseIcon
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 30,
          top: 30,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ p: { md: "8px 24px", mobile: "28px 24px 10px" } }}>
          <Typography variant="h3">
            {t("brands.albums.editAlbumDialog.title")}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ p: "0px 24px 5px 24px" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              <Box sx={{ mt: "10px" }}>
                <Controller
                  name={"avatar_url"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.Upload
                      previewHeight={"150px"}
                      aiButtonEnabled
                      noPadding
                      noHeight
                      {...field}
                      id={"profile"}
                      title={t("brands.albums.create.upload.title")}
                      subtitle={t("brands.albums.create.upload.subtitle")}
                      acceptFiles={"image/jpg, image/jpeg,image/gif,image/png"}
                    />
                  )}
                />
                {errors?.avatar_url?.type === "required" && (
                  <Box sx={{ mt: "15px" }}>
                    <Alert variant="outlined" severity="error">
                      {t("brands.albums.create.upload.error.required")}
                    </Alert>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box
                sx={{
                  mt: "10px",
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", mb: "10px", pt: "0px" }}>
                  <Typography variant={"h6"}>
                    {t("brands.albums.create.name.title")}
                  </Typography>
                </Box>

                <Controller
                  name={"name"}
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => <UI.TextField aiButtonEnabled {...field} />}
                />
                <Box sx={{ mt: "15px" }}>
                  {errors.name?.type === "required" && (
                    <Alert variant="outlined" severity="error">
                      {t("brands.albums.create.name.error.required")}
                    </Alert>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box
                sx={{
                  mt: "10px",
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", mb: "10px", pt: "0px" }}>
                  <Typography variant={"h6"}>
                    {t("brands.albums.create.description.title")}
                  </Typography>
                </Box>

                <Controller
                  name={"description"}
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <UI.TextField multiline rows={3} aiButtonEnabled {...field} />
                  )}
                />
                <Box sx={{ mt: "15px" }}>
                  {errors.description?.type === "required" && (
                    <Alert variant="outlined" severity="error">
                      {t("brands.albums.create.description.error.required")}
                    </Alert>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <Box
          sx={{
            mt: { md: "20px", mobile: "10px" },
            px: "15px",
            display: "flex",
            justifyContent: "center",
            mb: { md: "0px", mobile: "24px" },
          }}
        >
          <UI.Button
            sx={{ width: "150px" }}
            title={t("buttons.save")}
            type={"primary"}
            onClick={onConfirm}
          />
        </Box>
      </form>
    </MaterialDialog>
  );
};

const Action = ({ element: Component, onConfirm, DAO, album }) => {
  const [open, setOpen] = React.useState(null);

  return (
    <>
      <Box component={"span"} onClick={() => setOpen(!open)}>
        {Component}
      </Box>

      <EditDialog
        DAO={DAO}
        album={album}
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={() => {
          onConfirm();
          setOpen(false);
        }}
      />
    </>
  );
};

EditDialog.Action = Action;

export default EditDialog;
