import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../../@components/UI";
import Paper from "@mui/material/Paper";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import AddRequestInfoDialog from "../AdditionalInfoDialog";
import CollectInfoDialog from "../CollectInfoDialog";
import AddField from "../AddField";
import Grid from "@mui/material/Grid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CustomActionButton from "../CustomActionDialog";
import LinkIcon from "@mui/icons-material/Link";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  inputOuter: {
    marginBottom: "25px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Form = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [customActionButton, setCustomActionButton] = React.useState(null);
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const displayName = getValues("main_utility.displayName");
  const utilityType = getValues("main_utility.type");
  const {
    fields: customInfoFields,
    append: appendCustomInfoFields,
    remove: removeCustomInfoFields,
  } = useFieldArray({
    control,
    name: "main_utility.custom_info_fields",
  });

  const {
    fields: requestInfoFields,
    append: appendRequestInfoFields,
    remove: removeRequestInfoFields,
  } = useFieldArray({
    control,
    name: "main_utility.request_info_fields",
  });

  return (
    <Paper
      sx={{
        width: "100%",
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
        px: { md: "50px", mobile: "10px" },
        py: { md: "50px", mobile: "50px" },
      }}
    >
      <Box sx={{ display: "flex", mb: "50px", alignItems: "center" }}>
        <UI.UtilityTag sx={{ height: { md: "23px", mobile: "21px" }, }} utilityType={utilityType} />
        <Typography variant={"h2"} sx={{ ml: "15px" }}>
          {displayName}
        </Typography>
      </Box>
      <Box className={classes.inputOuter}>
        <Box sx={{ display: "flex", mb: "10px" }}>
          <Typography variant={"h4"}>
            {t("brands.collection.import.mainUtility.form.name.title")}
          </Typography>
        </Box>

        <Controller
          name={"main_utility.name"}
          control={control}
          rules={{ required: true }}
          render={({ field }) => <UI.TextField {...field} />}
        />
        {errors.main_utility?.name?.type === "required" && (
          <Box sx={{ mt: "15px" }}>
            <Alert
              variant="outlined"
              severity="error"
            >
              {t(
                "brands.collection.import.mainUtility.form.name.error.required"
              )}
            </Alert>
          </Box>
        )}
      </Box>
      <Box className={classes.inputOuter}>
        <Box sx={{ display: "flex", mb: "10px" }}>
          <Typography variant={"h4"}>
            {t("brands.collection.import.mainUtility.form.description.title", {
              displayName,
            })}
          </Typography>
        </Box>

        <Controller
          name={"main_utility.description"}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <UI.TextField multiline rows={4} {...field} placeholder={""} />
          )}
        />
        {errors.main_utility?.description?.type === "required" && (
          <Box sx={{ mt: "15px" }}>
            <Alert
              variant="outlined"
              severity="error"
            >
              {t(
                "brands.collection.import.mainUtility.form.description.error.required"
              )}
            </Alert>
          </Box>
        )}
      </Box>
      {customInfoFields.length > 0 &&
        customInfoFields?.map((item, index) => (
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "10px" }}>
              <Typography variant={"h4"}>{item?.name}</Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Controller
                name={`main_utility.custom_info_fields.${index}.value`}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UI.Inputs.GetInputByType
                    type={item?.type}
                    {...field}
                    placeholder={item?.placeholder}
                  />
                )}
              />

              <Box
                onClick={() => removeCustomInfoFields(index)}
                sx={{
                  cursor: "pointer",
                  ml: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <DeleteForeverIcon
                  sx={{
                    color: (theme) => theme.palette.colors.darkRed,
                    opacity: "0.8",
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                />
              </Box>
            </Box>
            {Object.keys(errors).length > 0 &&
              errors?.main_utility?.custom_info_fields &&
              errors?.main_utility?.custom_info_fields[index]?.value?.type ===
                "required" && (
                <Box sx={{ mt: "15px" }}>
                  <Alert
                    variant="outlined"
                    severity="error"
                  >
                    {t(
                      "brands.collection.import.mainUtility.form.customInfoField.error.required",
                      {
                        name: item?.name,
                      }
                    )}
                  </Alert>
                </Box>
              )}
          </Box>
        ))}
      <Box sx={{ mb: "20px", mt: "35px" }}>
        <Box
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <AddRequestInfoDialog.Action
            title={t(
              "brands.collection.import.mainUtility.form.additionalInfoField.title"
            )}
            onAdd={(customField) => {
              appendCustomInfoFields(customField);
            }}
            element={
              <AddField
                title={t(
                  "brands.collection.import.mainUtility.form.additionalInfoField.action.title"
                )}
                subtitle={t(
                  "brands.collection.import.mainUtility.form.additionalInfoField.action.subtitle"
                )}
              />
            }
          />
        </Box>
      </Box>
      {requestInfoFields.map((item, index) => (
        <Grid item md={12} mobile={12} key={index}>
          <Box className={classes.inputOuter}>
            <Box sx={{ display: "flex", mb: "0px" }}>
              <Typography variant={"h4"}>{item?.title}</Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Controller
                name={`main_utility.request_info_fields.${index}.value`}
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <UI.TextField {...field} placeholder={item?.placeholder} />
                )}
              />
              <Box
                onClick={() => removeRequestInfoFields(index)}
                sx={{
                  cursor: "pointer",
                  ml: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <DeleteForeverIcon
                  sx={{
                    color: (theme) => theme.palette.colors.darkRed,
                    opacity: "0.8",
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
      <Box sx={{ mt: "10px" }}>
        <Box
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <CollectInfoDialog.Action
            title={t(
              "brands.collection.import.mainUtility.form.collectInformation.title"
            )}
            onAdd={(customField) => {
              appendRequestInfoFields(customField);
            }}
            element={
              <AddField
                title={t(
                  "brands.collection.import.mainUtility.form.collectInformation.action.title"
                )}
                subtitle={t(
                  "brands.collection.import.mainUtility.form.collectInformation.action.subtitle"
                )}
              />
            }
          />
        </Box>
      </Box>
      {customActionButton == null && (
        <Box sx={{ mt: "10px" }}>
          <Box
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <CustomActionButton.Action
              title={t(
                "brands.collection.import.mainUtility.form.customLink.title"
              )}
              onAdd={(customField) => {
                setCustomActionButton(customField);
                appendCustomInfoFields(customField);
              }}
              element={
                <AddField
                  title={t(
                    "brands.collection.import.mainUtility.form.customLink.action.title"
                  )}
                  subtitle={t(
                    "brands.collection.import.mainUtility.form.customLink.action.subtitle"
                  )}
                />
              }
            />
          </Box>
        </Box>
      )}
      {customActionButton != null && (
        <Box sx={{ mb: "10px", mt: "15px" }}>
          <Typography variant={"h4"} sx={{ mb: "9px" }}>
            {t(
              "brands.collection.import.mainUtility.form.customLink.action.title"
            )}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.open(customActionButton.value, "_blank");
              }}
            >
              <Tooltip title={customActionButton.name} arrow>
                <Box
                  sx={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    backgroundColor: "rgba(148, 77, 255, 1);",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LinkIcon />
                </Box>
              </Tooltip>
            </Box>
            <Box
              onClick={() => setCustomActionButton(null)}
              sx={{
                cursor: "pointer",
                ml: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <DeleteForeverIcon
                sx={{
                  color: (theme) => theme.palette.colors.darkRed,
                  opacity: "0.8",
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default Form;
