import Gateway from "../../../@components/gateway";

const useService = () => {
  const gateway = Gateway.useGateway();

  const list = async () => {
    const response = await gateway.get("/user/created_brands/");
    return response.data;
  };

  return {
    list,
  };
};

export default useService;
