import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Edit from "./Edit";
import Grid from "@mui/material/Grid";
import Vote from "./vote";
import UI from "../../@components/UI";
import { useHistory, useLocation } from "react-router-dom";
import Link from "@mui/material/Link";
import CustomHooks from "../../@components/hooks";
import EmailRedirectVote from "./EmailRedirectVote";
import { useTranslation } from "react-i18next";

const Index = ({ DAO }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  let query = CustomHooks.useQuery();
  const [showVoteRedirectDialog, setShowVoteRedirectDialog] =
    React.useState(null);
  const [voteRedirectUrl, setVoteRedirectUrl] = React.useState(null);

  React.useEffect(() => {
    const is_email_redirect_vote = query.get("email_redirect_vote");
    const redirect_url = query.get("redirect_url");
    if (is_email_redirect_vote && redirect_url) {
      setShowVoteRedirectDialog(true);
      setVoteRedirectUrl(redirect_url);
    }
  }, [query]);

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        pb: "40px",
        background: "transparent",
        px: { md: "0px", mobile: "15px" },
      }}
    >
      {showVoteRedirectDialog && (
        <EmailRedirectVote
          onClose={() => {
            setShowVoteRedirectDialog(false);
            history.push(`${location.pathname}`);
          }}
          onConfirm={() => {
            setShowVoteRedirectDialog(false);
            history.push(`${location.pathname}/${voteRedirectUrl}`);
          }}
        />
      )}
      <Grid container>
        <Grid
          item
          mobile={12}
          md={12}
          sx={{
            mt: { mobile: "8px", md: "15px" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: { md: "745px", mobile: "100%" } }}>
            <Typography
              sx={{
                fontSize: { mobile: "26px !important", md:"30px !important" },
                textAlign: "center",
              }}
              variant={"h2"}
            >
              {t("qvrseDAO.home.title")}
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                mt: { md: "6px", mobile: "20px" },
              }}
              variant={"h3"}
            >
              {t("qvrseDAO.home.title2")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: { mobile: "column", md: "row" },
                mt: { mobile: "40px", md: "45px" },
              }}
            >
              <UI.Button
                sx={{
                  width: i18n.language === "sl" ? "320px" : "260px",
                  margin: { mobile: "auto", md: "0 15px" },
                }}
                type={"primary"}
                title={t("qvrseDAO.home.goToVoting")}
                onClick={() => {
                  history.push(`/qvrse/vote`);
                }}
              />
              <UI.Button
                sx={{
                  width: i18n.language === "sl" ? "240px" : "180px",
                  margin: { mobile: "auto", md: "0 15px" },
                  mt: { mobile: "22px", md: "0px" },
                }}
                type={"secondary"}
                title={t("qvrseDAO.home.joinQvrseDAO")}
                onClick={() => {
                  history.push(`/qvrse/membership/new`);
                }}
              />
            </Box>
            <Typography
              sx={{
                mt: { mobile: "70px", md: "100px" },
                textAlign: "center",
              }}
              variant={"h3"}
            >
              {t("qvrseDAO.home.subtitle")}
            </Typography>
            <Typography
              sx={{
                mt: "12px",
                textAlign: "center",
              }}
              variant={"subtitle1"}
            >
              {t("qvrseDAO.home.subtitle2")}
            </Typography>

            <Box
              sx={{
                width: "100%",
                mt: { mobile: "70px", md: "100px" },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography sx={{ textAlign: "center" }} variant={"h3"}>
                {t("qvrseDAO.home.latestProposals")}
              </Typography>
              <Box sx={{ mt: "24px", width: { md: "600px", mobile: "100%" } }}>
                <Vote
                  DAO={DAO}
                  filter={["Active", "Succeeded", "Queued"]}
                  limit={3}
                  gridItem={12}
                />
              </Box>
              <UI.Button
                sx={{
                  width: i18n.language === "sl" ? "330px" : "260px",
                  mt: { mobile: "35px", md: "45px" },
                }}
                type={"primary"}
                title={t("qvrseDAO.home.goToVoting")}
                onClick={() => {
                  history.push({
                    pathname: `/qvrse/vote`,
                    state: { scrollToTop: true },
                  });
                }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          mobile={12}
          md={12}
          sx={{
            mt: { mobile: "73px", md: "105px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { mobile: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.bgTransparent.primary,
                borderRadius: (theme) => theme.borderRadius.primary,
                alignItems: "flex-start",
                px: "40px",
                mx: "15px",
                width: "353px",
                height: { mobile: "230px", md: "270px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mb: { mobile: "16px", md: "0px" },
              }}
            >
              <Typography
                variant={"h2"}
                sx={{ mb: "10px" }}
              >
                {t("qvrseDAO.home.vote")}
              </Typography>
              <Typography
                variant={"subtitle3"}
              >
                {t("qvrseDAO.home.voteText")}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.bgTransparent.primary,
                alignItems: "flex-start",
                px: "40px",
                mx: "15px",
                width: "353px",
                height: { mobile: "230px", md: "270px" },
                borderRadius: (theme) => theme.borderRadius.primary,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mb: { mobile: "16px", md: "0px" },
              }}
            >
              <Typography
                variant={"h2"}
                sx={{ mb: "10px" }}
              >
                {t("qvrseDAO.home.belong")}
              </Typography>
              <Typography
                variant={"subtitle3"}
              >
                {t("qvrseDAO.home.belongText")}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.bgTransparent.primary,
                alignItems: "flex-start",
                px: "40px",
                mx: "15px",
                width: "353px",
                height: { mobile: "230px", md: "270px" },
                borderRadius: (theme) => theme.borderRadius.primary,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                mb: "0px",
              }}
            >
              <Typography
                variant={"h2"}
                sx={{ mb: "10px" }}
              >
                {t("qvrseDAO.home.enjoyPerks")}
              </Typography>
              <Typography
                variant={"subtitle3"}
              >
                {t("qvrseDAO.home.enjoyText")}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          mobile={12}
          md={12}
          sx={{
            mt: { mobile: "70px", md: "100px" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: { md: "850px", mobile: "100%" } }}>
            <Typography sx={{ textAlign: "center" }} variant={"h3"}>
              {t("qvrseDAO.home.tokenTitle")}
            </Typography>
            <Typography
              sx={{
                mt: "12px",
                textAlign: "center",
              }}
              variant={"subtitle1"}
            >
              {t("qvrseDAO.home.tokenText")}
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Link
                href={"https://qvrse.io/participate_in_presale.php"}
                target={"_blank"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "none",
                }}
              >
                <UI.Button
                  sx={{
                    width: "250px",
                    mt: { mobile: "35px", md: "45px" },
                  }}
                  onClick={() => {}}
                  type={"primary"}
                  title={t("qvrseDAO.home.getYourTokens")}
                />
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

Index.Edit = Edit;

export default Index;
