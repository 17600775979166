import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import UI from "../../../../../../../@components/UI";
import { Controller, useForm } from "react-hook-form";
import Paper from "@mui/material/Paper";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import { useUserContext } from "../../../../../../../@components/userContext";
import useService from "../../../../useService";
import CustomHooks from "../../../../../../../@components/hooks";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import PublicIcon from "@mui/icons-material/Public";
import CollectionSelect from "../../../../../collection/Select";
import collectionIcon from "../../../../../../../static/collection-icon.png";
import collectionIconDark from "../../../../../../../static/collection-icon-dark.png";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

const Collection = ({ DAO }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const theme = useTheme();
  const { albumId } = useParams();
  const history = useHistory();
  const [toggle, setToggle] = React.useState("all");
  const { user: loggedInUser } = useUserContext();
  const service = useService(DAO?.id);

  const { mutate: createCollection } = CustomHooks.usePost(service.newCard, {
    onSuccess: () => {
      enqueueSnackbar(t("brands.albums.cards.snackbar"), {
        variant: "success",
      });
      history.push({
        pathname: `/brand/${DAO?.id}/album/${albumId}/edit`,
        state: { scrollToTop: true },
      });
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      collections: [],
    },
  });

  const onSubmit = async (form) => {
    const data = {
      name: "Collection",
      album_space_type: "Collection",
      data_json: {},
      collections_ids: form.collections.map((collection) => collection.id),
    };

    await createCollection({ albumId: albumId, data: data });
  };
  return (
    <Paper
      sx={{
        width: "100%",
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: "100%",
            px: { md: "80px", mobile: "10px" },
            pb: "50px",
            pt: { md: "55px", mobile: "30px" },
          }}
        >
          <UI.BackButton
            title={t("brands.albums.backToAlbum")}
            onClick={() => history.go(-1)}
          />

          <Typography
            variant={"h3"}
            sx={{
              mb: "30px",
              mt: "30px",
            }}
          >
            {t("brands.albums.createCard.collection.createCollectionCard")}
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", mb: "6px", mt: "0" }}>
                  <Typography variant={"h6"}>
                    {t("brands.albums.createCard.collection.selectCollections")}
                  </Typography>
                </Box>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="collection-amount"
                    name="all"
                    value={toggle}
                    onChange={(event) => setToggle(event.target.value)}
                  >
                    <FormControlLabel
                      value="all"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: (theme) => theme.palette.colors.primary,
                            },
                          }}
                        />
                      }
                      label={
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <PublicIcon
                              sx={{
                                color: (theme) => theme.palette.text.secondary,
                                fontSize: "22px",
                                marginRight: "7px",
                              }}
                            ></PublicIcon>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant={"subtitle3"}>
                              {t(
                                "brands.albums.createCard.collection.allCollections"
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value="multiple"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: (theme) => theme.palette.colors.primary,
                            },
                          }}
                        />
                      }
                      label={
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            {theme.palette.type === "dark" ? (
                              <Box
                                component={"img"}
                                alt={t(
                                  "brands.albums.createCard.collection.multipleCollections"
                                )}
                                sx={{
                                  width: "21px",
                                  marginRight: "8px",
                                  opacity: "0.7",
                                }}
                                src={collectionIcon}
                              />
                            ) : (
                              <Box
                                component={"img"}
                                alt={t(
                                  "brands.albums.createCard.collection.multipleCollections"
                                )}
                                sx={{
                                  width: "21px",
                                  marginRight: "8px",
                                  opacity: "0.7",
                                }}
                                src={collectionIconDark}
                              />
                            )}
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant={"subtitle3"}>
                              {t(
                                "brands.albums.createCard.collection.multipleCollections"
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
            {toggle === "multiple" && (
              <Grid item md={6} mobile={12}>
                <Box
                  sx={{
                    mt: "0px",
                    marginBottom: "15px",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <Controller
                    name={"collections"}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <CollectionSelect {...field} DAO={DAO} />
                    )}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box
          sx={{
            width: "100%",
            mb: "60px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "210px" }}>
            <UI.Button
              disabled={
                !!Object.keys(errors).length || !loggedInUser?.email_verified
              }
              title={t("brands.albums.createCard.button.createCard")}
              type={"primary"}
            />
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default Collection;
