import Gateway from "../../../@components/gateway";

const useService = () => {
  const gateway = Gateway.useGateway();

  const draft = (id) => {
    return gateway.get(`/draft/${id}/`);
  };

  const list = async () => {
    const response = await gateway.get(`/draft/`);
    return response.data.results;
  };

  const remove = (id) => {
    return gateway.post(`/draft/${id}/delete/`);
  };

  const save = (data) => {
    return gateway.post(`draft/apply/`, data);
  };

  return {
    draft,
    list,
    save,
    remove,
  };
};

export default useService;
