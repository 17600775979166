import React from "react";
import Box from "@mui/material/Box";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// Styles must use direct files imports
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";

import "./styles.css";
import Dialog from "@mui/material/Dialog";
import playButtonImg from "../../../../../static/play.png";

const ImageItem = ({ item, onClick }) => {
  return (
    <Box
      onClick={() => onClick(item)}
      sx={{
        cursor: "pointer",
        backgroundColor: "transparent",
        textAlign: "center",
        position: "relative",
      }}
    >
      <Box
        component={"img"}
        sx={{
          maxHeight: { md: "450px", mobile: "340px" },
          width: { md: "auto", mobile: "auto" },
          margin: "0 auto",
        }}
        src={item.image_url}
      />
    </Box>
  );
};

const VideoItem = ({ item, onClick }) => {
  return (
    <Box
      onClick={() => onClick(item)}
      sx={{
        position: "relative",
        cursor: "pointer",
        backgroundColor: "transparent",
        textAlign: "center",
        mb: "-7.5px",
      }}
    >
      <Box
        component={"video"}
        autoPlay
        muted
        onContextMenu={(e) => e.preventDefault()}
        sx={{
          maxHeight: { md: "450px", mobile: "340px" },
          width: { md: "auto", mobile: "auto" },
          margin: "0 auto",
        }}
      >
        <source src={item.image_url} />
      </Box>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
          pointerEvents: "none",
        }}
      ></div>
      <Box
        sx={{
          margin: "0",
          position: "absolute",
          top: "50%",
          left: "50%",
          filter: "drop-shadow(4px 14px 12px black) brightness(0.8)",
          transform: "translate(-50%, -50%)",
          "&:hover": {
            filter: "brightness(2)",
          },
        }}
      >
        <Box
          component={"img"}
          sx={{ width: "100px !important" }}
          src={playButtonImg}
          alt={"play-video"}
        />
      </Box>
    </Box>
  );
};
const PreviewSwiper = ({ items }) => {
  const [previewFile, setPreviewFile] = React.useState(false);

  return (
    <Box className={"previewSwiper"}>
      {items?.length && (
        <Swiper
          autoHeight={true}
          pagination={true}
          modules={[Pagination]}
          slidesPerView={1}
          grabCursor={true}
          className="storySwiper"
        >
          {items.map((item, index) => (
            <Box key={`${item.id + index}`}>
              <SwiperSlide>
                {(item.file_type.toLowerCase().startsWith("image") ||
                  item.file_type.toLowerCase().startsWith("gif")) && (
                  <ImageItem
                    item={item}
                    onClick={(file) => setPreviewFile(file)}
                  />
                )}
                {item.file_type.startsWith("video") && (
                  <VideoItem
                    onClick={(file) => setPreviewFile(file)}
                    item={item}
                  />
                )}
              </SwiperSlide>
            </Box>
          ))}
        </Swiper>
      )}
      <Dialog
        fullWidth
        onClose={() => setPreviewFile(null)}
        open={previewFile}
        sx={{
          "& .MuiPaper-root": {
            background: "transparent",
            boxShadow: "none",
            maxWidth: "max-content",
            height: "auto",
          },
        }}
        PaperProps={{
          maxWidth: "1200px",
        }}
      >
        {previewFile &&
          (previewFile.file_type.startsWith("image") ||
            previewFile.file_type.startsWith("gif")) && (
            <Box sx={{ position: "relative" }}>
              <Box
                component={"img"}
                sx={{
                  maxHeight: { md: "600px", mobile: "auto" },
                  width: { md: "auto", mobile: "100%" },
                  margin: "0 auto",
                }}
                src={previewFile.image_url}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 1,
                }}
              ></div>
            </Box>
          )}
        {previewFile && previewFile.file_type.startsWith("video") && (
          <Box sx={{ position: "relative" }}>
            <Box
              component={"video"}
              autoPlay
              controlsList="nodownload"
              controls
              onContextMenu={(e) => e.preventDefault()}
              sx={{
                maxHeight: { md: "600px", mobile: "auto" },
                width: { md: "auto", mobile: "100%" },
                margin: "0 auto",
              }}
            >
              <source src={previewFile.image_url} />
            </Box>
          </Box>
        )}
      </Dialog>
    </Box>
  );
};

export default PreviewSwiper;
