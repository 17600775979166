import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { hardhat, polygon, polygonAmoy } from "wagmi/chains";
import { http } from "wagmi";

const getChainsForEnvironment = () => {
  if (window.env.CHAIN === "POLYGON") {
    console.log("POLYGON chain");
    return [polygon];
  } else if (window.env.CHAIN === "AMOY") {
    console.log("Amoy chain");
    return [polygonAmoy];
  } else {
    console.log("Return utilities chains");
    return [hardhat, polygonAmoy, polygon];
  }
};

export const config = getDefaultConfig({
  appName: "Qvrse",
  projectId: "baab8c3625087d0dadd45a3c5b5c815e",
  chains: getChainsForEnvironment(),
  transports: {
    [polygon.id]: http(
      "https://polygon-mainnet.g.alchemy.com/v2/kFVPnW8dD4QsF9H-zStP6XdH_9yGABam"
    ),
    [polygonAmoy.id]: http(
      "https://polygon-amoy.infura.io/v3/ed8f41cd961b407ca7310eec767be5a7"
    ),
  },
});
