import { useState, useEffect, createContext, useContext } from "react";
import useProposalService from "../proposal/useService";
import useHandleErrors from "../../../@components/hooks/useHandleErrors";
import { useUserContext } from "../../../@components/userContext";

export const MenuContext = createContext();
export const UserRoleContext = createContext();

export function UserRoleProvider({ DAO, children }) {
  const { user: loggedInUser } = useUserContext();
  const [isBrandMember, setIsBrandMember] = useState(null);

  useEffect(() => {
    if (DAO) {
      if (
        DAO?.maintainer_address.toLowerCase() ===
          loggedInUser?.smart_address?.toLowerCase() ||
        DAO.memberRole === "ADMIN" ||
        DAO.memberRole === "MODERATOR"
      ) {
        setIsBrandMember(true);
      } else {
        setIsBrandMember(false);
      }
    }
    // eslint-disable-next-line
  }, [DAO]);

  return (
    <UserRoleContext.Provider value={{ isBrandMember }}>
      {children}
    </UserRoleContext.Provider>
  );
}

export function MenuProvider({ DAO, children }) {
  const service = useProposalService(DAO?.id);
  const { handleApiError } = useHandleErrors();
  const [activeVotes, setActiveVotes] = useState(0);

  useEffect(() => {
    DAO && updateMenu();
    // eslint-disable-next-line
  }, [DAO]);

  const updateMenu = async () => {
    await service
      .proposals("active")
      .then((response) => {
        response.data.results && setActiveVotes(response.data.results.length);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  return (
    <MenuContext.Provider value={{ activeVotes, updateMenu }}>
      {children}
    </MenuContext.Provider>
  );
}

export const useMenu = () => useContext(MenuContext);
export const useUserRole = () => useContext(UserRoleContext);
