import React from "react";
import UI from "../../index";
import { Box } from "@material-ui/core";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpIcon from "@mui/icons-material/Help";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ExtraButtons = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Box>
      <UI.Button
        onClick={() =>
          history.push({
            pathname: "/profile/settings",
            state: { scrollToTop: true },
          })
        }
        type={"secondary"}
        sx={{ mt: "20px" }}
        variant={"contained"}
        title={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SettingsIcon sx={{ mr: "10px" }} />
            {t("appBar.userMenu.settings")}
          </Box>
        }
      />

      <UI.Button
        onClick={() => (window.location.href = "mailto:info@qvrse.io")}
        type={"secondary"}
        sx={{ mt: "10px" }}
        variant={"contained"}
        title={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <HelpIcon sx={{ mr: "10px" }} />
            {t("commons.help&support")}
          </Box>
        }
      />
    </Box>
  );
};

export default ExtraButtons;
