import React from "react";
import axios from "axios";
import Auth from "../auth";

import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";

const useGateway = (
  config = {
    baseURL: window.env.GATEWAY,
  }
) => {
  const [instance] = React.useState(() => axios.create(config));
  const { logout } = Auth.useAuth();
  const { i18n } = useTranslation();
  React.useEffect(() => {
    //Request interceptor
    instance?.interceptors?.request?.use(
      async (request) => {
        const access_token = localStorage.getItem("access_token");
        request.headers["Accept-Language"] = i18n.language;

        if (access_token) {
          const jwtToken = jwt_decode(access_token);
          // Token valid
          if (jwtToken.exp > Date.now() / 1000) {
            request.headers.Authorization = `Bearer ${access_token}`;
          } else {
            // Token expired, logout user
            logout();
          }
        }
        return request;
      },
      (error) => {
        console.log("error: ", error);
        return Promise.reject(error);
      }
    );

    // eslint-disable-next-line
  }, [instance]);

  return instance;
};

export default useGateway;
