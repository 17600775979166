import React from "react";
import Box from "@mui/material/Box";
import ProgressIndicator from "../../static/slider-indicator.png";

const progressBarItems = (parentWidth, progress) => {
  const itemWidth = 15;
  const itemHeight = 15;

  const numberOfItemsAvailable = parentWidth / itemWidth;

  const activeItems = Math.floor((numberOfItemsAvailable * progress) / 100);

  let items = [];
  for (let i = 1; i < numberOfItemsAvailable; i++) {
    items.push(
      <img
        style={{
          opacity: `${i <= activeItems ? "1" : "0.2"}`,
        }}
        key={i}
        width={itemWidth + "px"}
        height={itemHeight + "px"}
        alt={"progress-indicator"}
        src={ProgressIndicator}
      />
    );
  }
  return items;
};

const ProgressBar = ({ value }) => {
  const parentRef = React.useRef();
  const [size, setSize] = React.useState(0);

  const updateDimensions = () => {
    if (parentRef.current) setSize(parentRef.current.clientWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    setSize(parentRef.current.clientWidth);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  return <Box ref={parentRef}>{progressBarItems(size, value)}</Box>;
};

export default ProgressBar;
