const useExternalURL = () => {
  const getURL = (url) => {
    return url && url.match(/^https?:\/\//) ? url : `https://${url}`;
  };

  return {
    getURL,
  };
};

export default useExternalURL;
