import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import UI from "../../@components/UI";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import useService from "../useService";
import CustomHooks from "../../@components/hooks";
import useHandleErrors from "../../@components/hooks/useHandleErrors";
import { useUserContext } from "../../@components/userContext";
import useAuth from "../../@components/auth/useAuth";
import { useSnackbar } from "notistack";

const SingleSignOnDialog = ({ open, email, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const { handleApiError, handleMetamaskError } = useHandleErrors();
  const { setUser: setUserContext } = useUserContext();
  const [isLoading, setIsLoading] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const service = useService();
  const { loginWithOTP } = useAuth();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: "",
    },
    mode: "onSubmit",
  });

  const { mutate: getPassword } = CustomHooks.usePost(service.getOTP, {
    onSuccess: async () => {
      enqueueSnackbar("Email was sent successfully", {
        variant: "success",
      });
    },
  });

  const onSubmit = async (form) => {
    setIsLoading(true);
    loginWithOTP({ code: form.code })
      .then((response) => {
        const { user } = response;
        setIsLoading(false);
        setUserContext(user);
        onSuccess(user.smart_address);
        enqueueSnackbar(t("profile.dialogs.loginWithWallet.snackbar"), {
          variant: "success",
        });
        reset();
        onSuccess();
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.type === "metamask") {
          handleMetamaskError(error?.error);
        } else {
          handleApiError(error);
          //onNoExistingAccountError();
        }
        reset();
      });
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <MaterialDialog
      fullWidth={true}
      PaperProps={{
        sx: {
          position: "relative",
          padding: { md: "40px 60px 50px", mobile: "28px 10px 30px" },
          width: "650px",
          textAlign: "center",
          margin: { mobile: "10px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ p: { mobile: "5px 10px 17px", md: "0px" } }}>
        <CloseIcon
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 20,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />

        <Typography variant={"h3"} sx={{ textAlign: "center", mb: "10px" }}>
          {t("profile.dialogs.singleSignOnPasswordDialog.title")}
        </Typography>
        <Typography variant={"subtitle2"} sx={{ textAlign: "center" }}>
          {t("profile.dialogs.singleSignOnPasswordDialog.subtitle")}
        </Typography>
        <Grid container>
          <Grid item mobile={12} md={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ mt: "25px" }}>
                <Controller
                  name={"code"}
                  rules={{
                    required: true,
                  }}
                  control={control}
                  render={({ field }) => (
                    <UI.TextField
                      {...field}
                      fullWidth
                      placeholder={t(
                        "profile.dialogs.singleSignOnPasswordDialog.password"
                      )}
                    />
                  )}
                />
                <Box sx={{ mt: { md: "15px", mobile: "15px" } }}>
                  <Typography
                    variant={"subtitle3"}
                    sx={{ textAlign: "center" }}
                  >
                    {t("profile.dialogs.singleSignOnPasswordDialog.message1")}{" "}
                    <Box
                      onClick={() => {
                        getPassword(email);
                      }}
                      component={"span"}
                      sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        transition: "all 0.2s ease-in-out",
                        color: (theme) => theme.palette.text.link,
                        "&:hover": {
                          color: (theme) => theme.palette.text.linkHover,
                        },
                      }}
                    >
                      {t("profile.dialogs.singleSignOnPasswordDialog.message2")}
                    </Box>{" "}
                    {t("profile.dialogs.singleSignOnPasswordDialog.message3")}
                  </Typography>
                </Box>
                <Box sx={{ mt: "8px" }}>
                  {errors.code && (
                    <Alert variant="outlined" severity="error">
                      {errors.code?.type === "required" &&
                        t(
                          "profile.dialogs.singleSignOnPasswordDialog.error.required"
                        )}
                    </Alert>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  mt: { md: "34px", mobile: "24px" },
                  px: "15px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <UI.Button
                  disabled={Object.keys(errors).length}
                  sx={{ width: "340px" }}
                  title={t("profile.dialogs.logIn.button")}
                  type={"primary"}
                />
              </Box>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle1"}>
          {t("profile.dialogs.loginWithWallet.loading")}
        </Typography>
        <Typography variant={"subtitle1"}>
          {t("profile.dialogs.loginWithWallet.loading2")}
        </Typography>
      </UI.Busy.FullscreenIndicator>
    </MaterialDialog>
  );
};

const Action = ({ element: Component, onSuccess }) => {
  const [open, setOpen] = React.useState(null);

  return (
    <>
      <Box component={"span"} onClick={() => setOpen(!open)}>
        {Component}
      </Box>

      <SingleSignOnDialog
        open={open}
        onClose={() => setOpen(false)}
        onSuccess={(address) => {
          onSuccess(address);
          setOpen(false);
        }}
      />
    </>
  );
};

SingleSignOnDialog.Action = Action;

export default SingleSignOnDialog;
