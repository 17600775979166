import React from "react";
import Box from "@mui/material/Box";
import playButtonImg from "../../static/play.png";
import UI from "./index";

const Video = ({ children }) => {
  const [showVideo, setShowVideo] = React.useState(null);

  return (
    <div>
      <Box
        sx={{
          position: "relative",
          height: "800px",
          width: "100%",
          backgroundColor: "#0F1224",
          cursor: "pointer",
        }}
      >
        <Box
          onClick={() => setShowVideo(true)}
          sx={{
            margin: "0",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            "&:hover": {
              filter: "brightness(2)",
            },
          }}
        >
          <img src={playButtonImg} alt={"play-video"} />
        </Box>
      </Box>
      {showVideo && (
        <UI.Modal onClose={() => setShowVideo(false)}>{children}</UI.Modal>
      )}
    </div>
  );
};

export default Video;
