import { useState, createContext, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const FormContext = createContext();

export default function FormProvider({ children }) {
  const { t } = useTranslation();
  const [data, setData] = useState({
    main_utility: {
      type: "digital_content",
      displayName: t(
        "brands.collection.import.mainUtility.form.formInputs.digitalContent.displayName"
      ),
      descriptionDisplayName: t(
        "brands.collection.import.mainUtility.form.formInputs.digitalContent.descriptionDisplayName"
      ),
      is_main_utility: true,
      name: "",
      price: 0,
      description: "",
      custom_info_fields: [],
      request_info_fields: [],
      seat_reservation_enabled: false,
      seat_reservation_venue: null,
    },
    extra_utilities: [],
    basic_info: {
      picture_url: "",
      name: "",
      symbol: "",
      supply: "",
      description: "",
      scheduled_date_time: null,
      is_scheduled_later: false,
      visible: true,
      media: [],
    },
    payment: {
      crypto: {
        enabled: false,
        currency: "MATIC", //USDT, USDC, MATIC
        token: null, //USDT, USDC, MATIC
      },
      fiat: {
        paypal: false,
        stripe: false,
        currency: "EUR", //USD, EUR,
      },
    },
    royalty: {
      fee: 0,
      fee_address: "",
    },
    isFreeMintEnabled: true,
    free_nft_gas_fee_payed_by_system: true,
    totalPrice: 0.0,
  });

  useEffect(() => {
    console.log("form: ", data);
  }, [data]);

  const setFormValues = (values) => {
    setData((prevValues) => ({
      ...prevValues,
      ...values,
    }));
  };

  return (
    <FormContext.Provider value={{ data, setFormValues }}>
      {children}
    </FormContext.Provider>
  );
}

export const useFormData = () => useContext(FormContext);
