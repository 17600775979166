import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Link from "@mui/material/Link";
import truncateEthAddress from "truncate-eth-address";
import UI from "../../@components/UI";
import { useTranslation } from "react-i18next";

const OwnedByEOADialog = ({ open, onClose, eoa }) => {
  const { t } = useTranslation();

  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          padding: { md: "40px 60px 44px", mobile: "28px 0px 30px" },
          width: "650px",
          textAlign: "center",
          margin: { mobile: "20px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <CloseIcon
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 20,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <Grid container>
          <Grid item xs={12} md={12}>
            <AccountBalanceWalletIcon
              sx={{
                width: "60px",
                height: "60px",
                display: "flex",
                margin: "0 auto",
              }}
            />
            <Typography variant={"h3"} sx={{ textAlign: "center", mt: "20px" }}>
              {t("profile.dialogs.ownedByEOADialog.title")}
            </Typography>
            <Typography variant={"subtitle2"} sx={{ mt: "16px" }}>
              {t("profile.dialogs.ownedByEOADialog.subtitle")}{" "}
              {t("profile.dialogs.ownedByEOADialog.subtitle2")}{" "}
            </Typography>
            <Typography variant={"subtitle2"} sx={{ mt: "16px" }}>
              {t("profile.dialogs.ownedByEOADialog.subtitle3")}
            </Typography>
            <Box
              sx={{
                py: "5px",
                fontWeight: "bold",
                width: "180px",
                margin: "0 auto",
              }}
            >
              <UI.CopyToClipboard
                label={truncateEthAddress(eoa || "")}
                value={eoa || ""}
              />
            </Box>{" "}
            <Box sx={{ mt: { mobile: "32px", md: "44px" }, px: "10px" }}>
              <Typography variant={"subtitle3"} sx={{ textAlign: "center" }}>
                {t("profile.dialogs.wrongAccountDialog.support")}{" "}
                <Link
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    transition: "all 0.2s ease-in-out",
                    color: (theme) => theme.palette.text.link,
                    "&:hover": {
                      color: (theme) => theme.palette.text.linkHover,
                    },
                  }}
                  target="_blank"
                  href={"mailto: mainUtility@qvrse.io"}
                >
                  info@qvrse.io
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </MaterialDialog>
  );
};

export default OwnedByEOADialog;
