import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../@components/UI";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import marketplaceIcon from "../../static/marketplace_icon.png";

const Iframe = ({ url }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box>
      <iframe
        title={"animation"}
        style={{ border: "none" }}
        src={url}
        width="100%"
        height={isMobile ? "250px" : "300px"}
      />
    </Box>
  );
};

const Ticket = ({
  brandName,
  brandAvatar,
  name,
  name_slo,
  data,
  fileType,
  tokenId,
  animationUrl,
}) => {
  const [previewfile, setPreviewfile] = React.useState(null);
  const { i18n } = useTranslation();
  return (
    <Box
      sx={{
        position: "relative",
        width: { mobile: "250px", md: "300px" },
        borderRadius: (theme) => theme.borderRadius.primary,
        "-webkit-transform": "translateZ(0)",
        "-webkit-perspective": "1000",
        "-webkit-backface-visibility": "hidden",
        boxShadow: "0px 0px 20px #944DFF",
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          borderRadius: "8px 8px 0 0",
          border: "2px solid #944DFF",
          borderBottom: "none",
          padding: { mobile: "13px", md: "20px" },
          height: { mobile: "70px", md: "88px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar src={brandAvatar} alt={"avatar"} sx={{ mr: "12px" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              mt: "2px",
            }}
          >
            <Box>
              {brandName ? (
                <Typography
                  variant={"subtitle4"}
                  sx={{
                    maxWidth: { mobile: "175.5px", md: "185.4px" },
                  }}
                >
                  <UI.OverflowTip>{`${brandName}`}</UI.OverflowTip>
                </Typography>
              ) : (
                <Skeleton
                  height={"30px"}
                  width={"130px"}
                  variant={"rectangular"}
                />
              )}
            </Box>
            {name ? (
              <Typography
                variant={"h2"}
                sx={{
                  lineHeight: { mobile: "17px", md: "unset !important" },
                  fontSize: {
                    mobile: "15px !important",
                    md: "1rem !important",
                  },
                  maxWidth: { mobile: "175.5px", md: "185.4px" },
                }}
              >
                <UI.OverflowTip>
                  {name_slo !== null &&
                  name_slo !== "" &&
                  i18n.language === "sl"
                    ? name_slo
                    : name || ""}
                </UI.OverflowTip>
              </Typography>
            ) : (
              <Skeleton
                height={"10px"}
                width={"130px"}
                variant={"rectangular"}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        onClick={() => setPreviewfile(data)}
        sx={{
          cursor: "pointer",
          textAlign: "center",
          height: { mobile: "250px", md: "300px" },
          width: { mobile: "250px", md: "300px" },
          overflow: "hidden",
          borderLeft: "2px solid #944DFF",
          borderRight: "2px solid #944DFF",
        }}
      >
        <Box
          sx={{
            height: { mobile: "250px", md: "300px" },
            width: "100%",
            position: "relative",
          }}
        >
          <Box
            component={"span"}
            sx={{
              boxSizing: "border-box",
              display: "block",
              overflow: "hidden",
              width: "initial",
              height: "initial",
              background: "none",
              opacity: 1,
              border: "0px",
              margin: "0px",
              padding: "0px",
              position: "absolute",
              inset: 0,
              lineHeight: "0",
              fontSize: "0",
            }}
          >
            {animationUrl === null ? (
              data ? (
                fileType?.startsWith("video") ? (
                  <video
                    playsInline
                    width={"fit-content"}
                    height={"100%"}
                    autoplay="autoplay"
                    loop
                    muted
                  >
                    <source src={data} />
                  </video>
                ) : (
                  <UI.ImageWithSkeleton
                    sx={{
                      position: "absolute",
                      inset: 0,
                      boxSizing: "border-box",
                      padding: 0,
                      border: "none",
                      margin: "auto",
                      display: "block",
                      width: 0,
                      height: 0,
                      minWidth: "100%",
                      maxWidth: "100%",
                      minHeight: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                    src={data}
                    width={"100%"}
                    alt={"slider-item"}
                  />
                )
              ) : (
                <Skeleton
                  sx={{
                    height: { mobile: "250px", md: "300px" },
                    width: { mobile: "250px", md: "300px" },
                  }}
                  variant={"rectangular"}
                />
              )
            ) : (
              <Iframe url={animationUrl} />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
          borderRadius: "0 0 8px 8px",
          border: "2px solid #944DFF",
          borderTop: "none",
          padding: { mobile: "13px", md: "20px" },
          height: { mobile: "70px", md: "88px" },
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "25px",
              height: "25px",
              backgroundColor: (theme) =>
                theme.palette.bgTransparent.quaternary,
              borderRadius: "50%",
            }}
          >
            <Box
              component={"img"}
              src={marketplaceIcon}
              sx={{ width: "13px", height: "11px" }}
            />
          </Box>
          <UI.UtilityTag sx={{ ml: "10px" }} utilityType={"ticket"} />
        </Box>
        <Typography
          variant={"h2"}
          sx={{
            fontSize: {
              mobile: "15px !important",
              md: "1rem !important",
            },
          }}
        >
          {`#${tokenId}`}
        </Typography>
      </Box>
      <Dialog
        onClose={() => setPreviewfile(null)}
        open={!!previewfile}
        sx={{
          "& .MuiPaper-root": {
            maxWidth: "700px",
          },
        }}
        PaperProps={{
          maxWidth: "700px",
        }}
      >
        {previewfile && fileType?.startsWith("video") && (
          <video
            playsInline
            width="100%"
            height={"auto"}
            autoplay="autoplay"
            loop
          >
            <source src={previewfile} />
          </video>
        )}
        {previewfile && fileType?.startsWith("image") && (
          <Box
            component={"img"}
            sx={{
              maxHeight: { md: "600px", mobile: "auto" },
              width: { md: "auto", mobile: "100%" },
              margin: "0 auto",
            }}
            src={previewfile}
          />
        )}
      </Dialog>
    </Box>
  );
};

export default Ticket;
