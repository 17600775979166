import React from "react";
import AddNewCard from "./AddNewCard";
import { Box } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import Collection from "../../cards/collection";
import Discount from "../../cards/discount";
import Code from "../../cards/code";
import Spatial from "../../cards/spatial";
import Video from "../../cards/video";
import Animation from "../../cards/animation";
import Text from "../../cards/text";
import Image from "../../cards/image";
import Game from "../../cards/Game";
import AllCollection from "../../cards/allCollection";
const List = ({ cards, onRemoveCard }) => {
  return (
    <Grid
      container
      columnSpacing={{ mobile: 0, md: 3 }}
      rowSpacing={{ mobile: 1, md: 3 }}
      justifyContent="flex-start"
    >
      {cards?.map((item) => {
        return (
          <Grid item mobile={4} md={2.4} lg={2.4}>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              {item.album_space_type === "Collection" &&
                item?.collections?.length <= 1 && (
                  <Collection.Overlay.Action
                    onDelete={() => {
                      onRemoveCard(item.id);
                    }}
                    element={<Collection.EditCard item={item} />}
                  />
                )}
              {item.album_space_type === "Collection" &&
                item?.collections?.length > 1 && (
                  <AllCollection.Overlay.Action
                    onDelete={() => {
                      onRemoveCard(item.id);
                    }}
                    element={<AllCollection.EditCard item={item} />}
                  />
                )}
              {item.album_space_type === "Discount" && (
                <Discount.Overlay.Action
                  onDelete={() => {
                    onRemoveCard(item.id);
                  }}
                  element={<Discount.Card item={item} isEditing={true} />}
                />
              )}
              {item.album_space_type === "Spatial" && (
                <Discount.Overlay.Action
                  onDelete={() => {
                    onRemoveCard(item.id);
                  }}
                  element={<Spatial.Card item={item} isEditing={true} />}
                />
              )}
              {item.album_space_type === "Code" && (
                <Code.Overlay.Action
                  onDelete={() => {
                    onRemoveCard(item.id);
                  }}
                  element={<Code.Card item={item} isEditing={true} />}
                />
              )}
              {item.album_space_type === "Video" && (
                <Video.Overlay.Action
                  onDelete={() => {
                    onRemoveCard(item.id);
                  }}
                  element={<Video.Card item={item} />}
                />
              )}
              {item.album_space_type === "Animation" && (
                <Animation.Overlay.Action
                  onDelete={() => {
                    onRemoveCard(item.id);
                  }}
                  element={<Animation.Card item={item} isEditing={true} />}
                />
              )}
              {item.album_space_type === "Text" && (
                <Text.Overlay.Action
                  onDelete={() => {
                    onRemoveCard(item.id);
                  }}
                  element={<Text.Card item={item} isEditing={true} />}
                />
              )}
              {item.album_space_type === "Image" && (
                <Image.Overlay.Action
                  onDelete={() => {
                    onRemoveCard(item.id);
                  }}
                  element={<Image.Card item={item} />}
                />
              )}
              {item.album_space_type === "game" && <Game item={{ id: 0 }} />}
            </Box>
          </Grid>
        );
      })}
      <Grid item mobile={4} md={2.4} lg={2.4}>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <AddNewCard item={{ id: 1 }} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default List;
