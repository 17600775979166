import React from "react";
import { Box } from "@material-ui/core";
import coinImage from "../../../../static/Coins_side_front.svg";
import { keyframes } from "@emotion/react";

const Presale = ({ width = 80, height = 80 }) => {
  const animateRotate = keyframes`
      0% {
        transform: rotateY(-180deg);
      }
      100% {
        transform: rotateY(180deg);
      }
    `;

  const animateBrightness = keyframes`
      0%, 50%, 100% {
        filter: blur(0px) brightness(120%) drop-shadow(0 0 2.5px rgba(255, 255, 255, 0.1)) drop-shadow(0 0 5px rgba(255, 255, 255, 0.075)) drop-shadow(0 0 7.5px rgba(255, 255, 255, 0.045)) drop-shadow(0 0 10px rgba(255, 255, 255, 0.025));
      }
      25%, 75% {
        filter: blur(1px) brightness(50%) drop-shadow(0 0 2.5px rgba(255, 255, 255, 0.1)) drop-shadow(0 0 5px rgba(255, 255, 255, 0.075)) drop-shadow(0 0 7.5px rgba(255, 255, 255, 0.045)) drop-shadow(0 0 10px rgba(255, 255, 255, 0.025));
      }
    `;
  return (
    <Box
      sx={{
        transition: "all 0.4s ease-out",
        cursor: "pointer",
        animation: `${animateBrightness} 2.5s infinite linear`,
        width: 30,
        height: 30,
        transform: "scale(0.3)",
        mt: "8px",
        transformOrigin: "left top",
      }}
    >
      <Box
        sx={{
          width: width,
          height: height,
          position: "relative",
          zIndex: 2,
          perspective: "10000px",
          transformStyle: "preserve-3d",
          transformOrigin: "center",
          animation: `${animateRotate} 2.5s infinite linear`,
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${coinImage})`,
            transform: "translateZ(-5px)",
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        ></Box>
        <Box
          sx={{
            backgroundImage: `url(${coinImage})`,
            transform: "translateZ(-4px)",
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        ></Box>
        <Box
          sx={{
            backgroundImage: `url(${coinImage})`,
            transform: "translateZ(-3px)",
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        ></Box>
        <Box
          sx={{
            backgroundImage: `url(${coinImage})`,
            transform: "translateZ(-2px)",
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        ></Box>
        <Box
          sx={{
            backgroundImage: `url(${coinImage})`,
            transform: "translateZ(-1px)",
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        ></Box>
        <Box
          sx={{
            backgroundImage: `url(${coinImage})`,
            transform: "translateZ(1px)",
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        ></Box>
        <Box
          sx={{
            backgroundImage: `url(${coinImage})`,
            transform: "translateZ(2px)",
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        ></Box>
        <Box
          sx={{
            backgroundImage: `url(${coinImage})`,
            transform: "translateZ(3px)",
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        ></Box>
        <Box
          sx={{
            backgroundImage: `url(${coinImage})`,
            transform: "translateZ(4px)",
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        ></Box>
        <Box
          sx={{
            backgroundImage: `url(${coinImage})`,
            transform: "translateZ(5px)",
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default Presale;
