import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../userContext";
import LoginIcon from "@mui/icons-material/Login";
import Logo from "../../../static/qvrse-logo.png";
import collectionsIcon from "../../../static/collection-icon-dark.png";
import brandsIcon from "../../../static/brands-icon.png";
//import marketplaceIcon from "../../../static/marketplace_icon.png";
import Presale from "./Presale";
import LogInDialogFlow from "../../../profile/dialogs/LogInDialogFlow";
import UserAvatar from "../appBar/UserAvatar";
import useUserService from "../../../profile/useService";
import CreateSmartAccountDialog from "../../../profile/dialogs/CreateSmartAccountDialog";
import Divider from "@mui/material/Divider";
import Settings from "./Settings";
//import NotificationsIcon from "@mui/icons-material/Notifications";
//import Badge from "@mui/material/Badge";
import NotificationsMenu from "./NotificationsMenu";

const drawerWidth = 80;

const SideMenu = (props) => {
  const userService = useUserService();
  const { selectedTheme } = useUserContext();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { user: loggedInUser, setUser: setUserContext } = useUserContext();
  const [triggerLoginDialog, setTriggerLoginDialog] = React.useState(null);
  const [showSmartAccountDialog, setShowSmartAccountDialog] =
    React.useState(false);
  const [notificationsAnchoerEl, setNotificationsAnchorEl] =
    React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const access_token = localStorage.getItem("access_token");
      if (access_token) {
        await userService
          .basic_info()
          .then(async (response) => {
            setUserContext(response);
            if (response.smart_address === null) {
              setShowSmartAccountDialog(true);
            }
            props.onThemeChange(null, response.user_theme === "dark");
          })
          .catch(() => {});
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const renderListItem = (key, icon, text, onClick, removePadding) => (
    <ListItem
      sx={{
        mt: "20px",
        height: "60px",
        "&:hover": {
          border: "none",
        },
      }}
      key={key}
      disablePadding
    >
      <ListItemButton
        sx={{
          px: removePadding ? "0px" : "unset",
          "&:hover": {
            background: "transparent",
          },
        }}
        onClick={onClick}
      >
        <ListItemIcon
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40px",
            width: "40px",
            backgroundColor:
              location.pathname === key
                ? selectedTheme === "dark"
                  ? "#ffffff14"
                  : "rgba(0, 0, 0, 0.09)"
                : "transparent",
            borderRadius: "50%",
            padding: "10px",
            transition: "all 0.2s ease-in-out",
            "&:hover": {
              background:
                selectedTheme === "dark" ? "#ffffff14" : "rgba(0, 0, 0, 0.09)",
              borderRadius: "50%",
            },
          }}
        >
          {icon}
        </ListItemIcon>
        <Typography
          variant={"subtitle4"}
          sx={{
            fontSize: "10.5px !important",
            fontWeight: "700",
          }}
        >
          {text}
        </Typography>
      </ListItemButton>
    </ListItem>
  );

  const handleNavigation = (path) => {
    history.push({
      pathname: path,
      state: { scrollToTop: true },
    });
  };

  return (
    <>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          position: "relative",
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            backgroundColor: (theme) => theme.palette.appbar.background,
            backdropFilter: (theme) => theme.palette.appbar.backdropFilter,
            width: drawerWidth,
            boxSizing: "border-box",
            borderRadius: "0px",
            border: 0,
          },
        }}
      >
        <Box sx={{ overflow: "hidden" }}>
          <Box
            onClick={() => handleNavigation("/")}
            component={"img"}
            alt={"logo"}
            src={Logo}
            sx={{
              cursor: "pointer",
              width: "50px",
              margin: "0 auto",
              display: "block",
              pt: "20px",
            }}
          />
          <Typography
            variant="h10"
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "6px",
              fontSize: "11px",
            }}
          >
            QVRSE
          </Typography>
          <List sx={{ mt: "10px" }}>
            {renderListItem(
              "/collection/explore",
              <img
                style={{
                  width: "20px",
                  filter: selectedTheme === "dark" ? "invert(1)" : "none",
                }}
                src={collectionsIcon}
                alt="collections"
              />,
              t("appBar.collections"),
              () => handleNavigation("/collection/explore")
            )}
            {renderListItem(
              "/brand/explore",
              <img
                style={{
                  width: "20px",
                  filter: selectedTheme === "dark" ? "invert(1)" : "none",
                }}
                src={brandsIcon}
                alt="brands"
              />,
              t("appBar.brands"),
              () => handleNavigation("/brand/explore")
            )}
            {/*{renderListItem(*/}
            {/*  "/marketplace",*/}
            {/*  <img*/}
            {/*    style={{*/}
            {/*      width: "20px",*/}
            {/*      filter: selectedTheme === "dark" ? "invert(1)" : "none",*/}
            {/*    }}*/}
            {/*    src={marketplaceIcon}*/}
            {/*    alt="collections"*/}
            {/*  />,*/}
            {/*  t("appBar.marketplace"),*/}
            {/*  () => handleNavigation("/marketplace")*/}
            {/*)}*/}
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "center",
                "&:hover": {
                  border: "none",
                },
                mt: "20px",
                height: "60px",
              }}
              key={"token"}
              disablePadding
            >
              <Link
                target="_blank"
                to={{ pathname: "https://token.qvrse.io/" }}
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "40px",
                    width: "40px",
                    "&:hover": {
                      background:
                        selectedTheme === "dark"
                          ? "#ffffff14"
                          : "rgba(0, 0, 0, 0.09)",
                      padding: "10px",
                      borderRadius: "50%",
                    },
                  }}
                >
                  <Presale />
                </ListItemIcon>
                <Typography
                  variant={"subtitle4"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "5px",
                    fontSize: "10.5px !important",
                    fontWeight: "700",
                  }}
                >
                  Token
                </Typography>
              </Link>
            </ListItem>
            <Divider
              sx={{
                m: "20px 12px 10px",
                borderColor:
                  selectedTheme === "dark"
                    ? "rgb(255 255 255 / 14%)"
                    : "rgba(0, 0, 0, 0.14)",
              }}
            />
            {!loggedInUser && (
              <Box onClick={() => setTriggerLoginDialog(Math.random())}>
                {renderListItem(
                  "login",
                  <LoginIcon
                    sx={{
                      fontSize: "26px !important",
                      mr: "6px",
                      color: (theme) => theme.palette.text.primary,
                    }}
                  />,
                  t("appBar.logIn"),
                  () => {}
                )}
              </Box>
            )}
            {loggedInUser && (
              <>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <UserAvatar user={loggedInUser} />
                </Box>
                {renderListItem(
                  "/profile/nfts",
                  <img
                    style={{
                      width: "20px",
                      pt: "0px",
                      mt: "0px",
                      filter: selectedTheme === "dark" ? "invert(1)" : "none",
                    }}
                    src={collectionsIcon}
                    alt="myNFTs"
                  />,
                  t("appBar.myNFTs"),
                  () => handleNavigation("/profile/nfts"),
                  true
                )}
              </>
            )}
          </List>
        </Box>
        {/*{loggedInUser && (*/}
        {/*  <Box*/}
        {/*    sx={{*/}
        {/*      display: "flex",*/}
        {/*      justifyContent: "center",*/}
        {/*      mt: "15px",*/}
        {/*      cursor: "pointer",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <More />*/}
        {/*  </Box>*/}
        {/*)}*/}

        {/*{loggedInUser && (*/}
        {/*  <Box*/}
        {/*    sx={{*/}
        {/*      display: "flex",*/}
        {/*      alignItems: "center",*/}
        {/*      justifyContent: "center",*/}
        {/*      position: "absolute",*/}
        {/*      bottom: "75px",*/}
        {/*      transition: "all 0.2s ease-in-out",*/}
        {/*      background: (theme) => theme.palette.bgTransparent.quaternary,*/}
        {/*      "&:hover": {*/}
        {/*        background: (theme) => theme.palette.bgTransparent.secondary,*/}
        {/*      },*/}
        {/*      width: 42,*/}
        {/*      height: 42,*/}
        {/*      borderRadius: "50%",*/}
        {/*      left: "50%",*/}
        {/*      transform: "translateX(-50%)",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <ListItem*/}
        {/*      onClick={(e) => setNotificationsAnchorEl(e.currentTarget)}*/}
        {/*      sx={{*/}
        {/*        cursor: "pointer",*/}
        {/*        display: "flex",*/}
        {/*        justifyContent: "center",*/}
        {/*        flexDirection: "column",*/}
        {/*        "&:hover": {*/}
        {/*          border: "none",*/}
        {/*        },*/}
        {/*        height: "60px",*/}
        {/*      }}*/}
        {/*      key={"token"}*/}
        {/*      disablePadding*/}
        {/*    >*/}
        {/*      <ListItemIcon*/}
        {/*        sx={{*/}
        {/*          display: "flex",*/}
        {/*          justifyContent: "center",*/}
        {/*          alignItems: "center",*/}
        {/*          height: "40px",*/}
        {/*          width: "40px",*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <Badge badgeContent={2} color={"error"}>*/}
        {/*          <NotificationsIcon*/}
        {/*            sx={{*/}
        {/*              color: (theme) => theme.palette.text.primary,*/}
        {/*              width: "22px",*/}
        {/*            }}*/}
        {/*            alt="notification"*/}
        {/*          />*/}
        {/*        </Badge>*/}
        {/*      </ListItemIcon>*/}
        {/*    </ListItem>*/}
        {/*  </Box>*/}
        {/*)}*/}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            bottom: "25px",
            transition: "all 0.2s ease-in-out",
            background: (theme) => theme.palette.bgTransparent.quaternary,
            "&:hover": {
              background: (theme) => theme.palette.bgTransparent.secondary,
            },
            width: 42,
            height: 42,
            borderRadius: "50%",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Settings />
        </Box>
      </Drawer>
      <NotificationsMenu
        anchorEl={notificationsAnchoerEl}
        handleClose={() => setNotificationsAnchorEl(null)}
      />
      <LogInDialogFlow
        trigger={triggerLoginDialog}
        onClose={() => setTriggerLoginDialog(null)}
      />
      <CreateSmartAccountDialog toggleOpen={showSmartAccountDialog} />
    </>
  );
};

export default SideMenu;
