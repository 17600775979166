import React from "react";
import UI from "../../../@components/UI";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import CustomHooks from "../../../@components/hooks";
import useService from "./useService";
import Paper from "@mui/material/Paper";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import { useUserContext } from "../../../@components/userContext";


const Create = ({ DAO }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();
  const { user: loggedInUser } = useUserContext();
  const service = useService(DAO?.id);

  const { mutate: createAlbum } = CustomHooks.usePost(service.save, {
    onSuccess: () => {
      enqueueSnackbar(t("brands.feed.create.snackbar"), {
        variant: "success",
      });
      history.push({
        pathname: `/brand/${DAO?.id}/album`,
        state: { scrollToTop: true },
      });
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      avatar_url: "",
      name: "",
      description: "",
      is_public: true,
    },
  });

  const onSubmit = async (form) => {
    const data = { ...form, avatar_url: form.avatar_url.ipfs };
    await createAlbum(data);
  };

  return (
    <Paper
      sx={{ width: "100%", backgroundColor: (theme) => theme.palette.bgTransparent.primary }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: "100%",
            px: { md: "80px", mobile: "10px" },
            pb: "50px",
            pt: { md: "55px", mobile: "30px" },
          }}
        >
          <UI.BackButton
            title={t("brands.albums.backToAlbums")}
            onClick={() => history.push(`/brand/${DAO.id}/album`)}
          />
          <Typography
            variant={"h3"}
            sx={{
              mb: "25px",
              mt: "30px",
            }}
          >
            {t("brands.albums.create.title")}
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={12} mobile={12}>
              <Box sx={{ mt: "10px" }}>
                <Controller
                  name={"avatar_url"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UI.Upload
                      aiButtonEnabled
                      noPadding
                      noHeight
                      {...field}
                      id={"profile"}
                      title={t("brands.albums.create.upload.title")}
                      subtitle={t("brands.albums.create.upload.subtitle")}
                      acceptFiles={"image/jpg, image/jpeg,image/gif,image/png"}
                    />
                  )}
                />
                {errors?.avatar_url?.type === "required" && (
                  <Box sx={{ mt: "15px" }}>
                    <Alert
                      variant="outlined"
                      severity="error"
                    >
                      {t("brands.albums.create.upload.error.required")}
                    </Alert>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box
                sx={{
                  mt: "10px",
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h6"}>
                    {t("brands.albums.create.name.title")}
                  </Typography>
                </Box>
                <Controller
                  name={"name"}
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => <UI.TextField {...field} aiButtonEnabled />}
                />
                {errors?.name?.type === "required" && (
                  <Box sx={{ mt: "15px" }}>
                    <Alert
                      variant="outlined"
                      severity="error"
                    >
                      {t("brands.albums.create.name.error.required")}
                    </Alert>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item md={12} mobile={12}>
              <Box
                sx={{
                  mt: "10px",
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h6"}>
                    {t("brands.albums.create.description.title")}
                  </Typography>
                </Box>
                <Controller
                  name={"description"}
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <UI.TextField multiline rows={4} aiButtonEnabled {...field} />
                  )}
                />
                {errors?.description?.type === "required" && (
                  <Box sx={{ mt: "15px" }}>
                    <Alert
                      variant="outlined"
                      severity="error"
                    >
                      {t("brands.albums.create.description.error.required")}
                    </Alert>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: "100%",
            mb: "70px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "210px" }}>
            <UI.Button
              disabled={
                !!Object.keys(errors).length ||
                !loggedInUser?.email_verified
              }
              title={t("brands.albums.create.title")}
              type={"primary"}
            />
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default Create;
