import React, { useState } from "react";
import Skeleton from "@mui/lab/Skeleton";
import Box from "@mui/material/Box";

const ImageWithSkeleton = (props) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      {!imageLoaded && (
        <Skeleton variant="rectangular" width={props.width} height={"100%"} />
      )}
      <Box
        component="img"
        alt={props.alt}
        onLoad={handleImageLoad}
        style={imageLoaded ? {} : { visibility: "hidden" }}
        {...props}
      />
    </>
  );
};

export default ImageWithSkeleton;
