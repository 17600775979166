import * as React from "react";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import LanguageSwitcher from "./appBar/languageSwitcher";
import UI from "./index";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "100%",
    borderRadius: "0",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.dialog.background
        : "rgba(255, 255, 255, 0.7) !important",
    backdropFilter: theme.palette.type === "dark"
      ? theme.palette.dialog.backdropFilter
      : "blur(20px)",
  },
}));

const MobileSidebar = ({ toggleMenuOpen, children }) => {
  const classes = useStyles();
  const location = useLocation();
  const [state, setState] = React.useState(false);

  React.useEffect(() => {
    setState(false);
  }, [location]);

  React.useEffect(() => {
    toggleMenuOpen && setState(!state);
    // eslint-disable-next-line
  }, [toggleMenuOpen]);

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor={"right"}
      open={state}
      onClose={() => setState(false)}
      transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
    >
      <DialogTitle
        className="drawerTitle"
        sx={{ display: "flex", mt: "15px", justifyContent: "space-between" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LanguageSwitcher />
          <UI.ThemeSwitcher />
        </Box>
        <IconButton onClick={() => setState(false)}>
          <CloseIcon
            sx={{
              color: (theme) => theme.palette.text.primary,
              fontSize: "24px",
              "&:hover": {
                transition: "all 0.1s ease-in-out",
                transform: "scale(1.1) rotate(-90deg)",
              },
            }}
          />
        </IconButton>
      </DialogTitle>
      <Box sx={{ width: "auto", zIndex: 0 }} role="presentation">
        {children}
      </Box>
    </Drawer>
  );
};

export default MobileSidebar;
