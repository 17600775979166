import React from "react";
import Box from "@mui/material/Box";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import "./styles.css";
import Skeleton from "@mui/material/Skeleton";
import CustomHooks from "../../@components/hooks";
import useDaoService from "../../daos/useService";

const ImageItem = ({ item, onClick }) => {
  return (
    <>
      <Box
      onClick={() => onClick(item?.id)}
      sx={{
        position: "relative",
        width: { xl: "120px", md: "120px", mobile: "120px" },
        height: { xl: "120px", md: "120px", mobile: "120px" },
        borderRadius: "50%",
        overflow: "hidden",
        cursor: "pointer",
      }}
    >
      <Box
        component="img"
        src={item.avatar_url}
        alt={item.title}
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          objectFit: "cover",
          transition: "all 0.3s ease-in-out",
        }}
      />
      <Box
        className="overlay"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          backgroundColor: (theme) => theme.palette.bgTransparent.hoverOverlay,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          fontSize: "16px",
          opacity: 0,
          transition: "opacity 0.3s ease-in-out",
          "&:hover": {
            opacity: 1,
          },
        }}
      >
      </Box>
    </Box>
    </>
  );
};

const SkeletonItem = () => {
  return (
    <Box
      sx={{
        position: "relative",
        height: "400px",
        width: "100%",
        maxHeight: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        borderRadius: "0px",
        background:
          "linear-gradient(rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%)",
      }}
    >
      <Skeleton
        variant={"rectangular"}
        sx={{
          inset: "0px",
          boxSizing: "border-box",
          padding: "0px",
          border: "none",
          margin: "auto",
          display: "block",
          width: "0px",
          height: "0px",
          minWidth: "100%",
          maxWidth: "100%",
          minHeight: "100%",
          maxHeight: "100%",
          objectFit: "cover",
        }}
      />
    </Box>
  );
};
const Trending = ({ onClick }) => {
  const daoService = useDaoService();
  const { data: brands, isLoading: brandsBusy } = CustomHooks.useFetch(
    ["brands"],
    daoService.featured
  );

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: { mobile: "column", md: "row" } }}
      >

        <Swiper
          grabCursor={true}
          slidesPerView={2}
          spaceBetween={15}
          slidesPerGroup={1}
          speed={1000}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
            pauseOnMouseEnter: false,
          }}
          loop={true}
          loopFillGroupWithBlank={true}
          breakpoints={{
            500: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            700: {
              slidesPerView: 4,
              spaceBetween: 15,
            },
            1000: {
              slidesPerView: 5,
              spaceBetween: 15,
            },
            1400: {
              slidesPerView: 6,
              spaceBetween: 30,
            },
            1600: {
              slidesPerView: 7,
              spaceBetween: 50,
            },
          }}
          pagination={true}
          modules={[Autoplay]}
          className="trendingSwiper"
        >
          {!brandsBusy &&
            brands?.map((item) => (
              <SwiperSlide key={item.id}>
                <ImageItem item={item} onClick={(item) => onClick(item)} />
              </SwiperSlide>
            ))}
          {brandsBusy &&
            [0, 1, 2, 3].map((index) => (
              <SwiperSlide key={index}>
                <SkeletonItem />
              </SwiperSlide>
            ))}
        </Swiper>
      </Box>
    </>
  );
};

export default Trending;
