import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import UI from "../../@components/UI";
import { useHistory, useLocation } from "react-router-dom";

import List from "./List";
import Create from "./Create";
import { useAccount } from "wagmi";
import { useUserContext } from "../../@components/userContext";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();
  const { user: loggedInUser } = useUserContext();
  const location = useLocation();
  const history = useHistory();
  const { isConnected } = useAccount();

  return (
    <Paper elevation={0} sx={{ width: "100%", background: "transparent" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          px: { md: "0px", mobile: "10px" },
          pb: "50px",
          pt: { md: "40px", mobile: "40px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { mobile: "center", md: "center" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "178px",
            }}
          >
            <UI.Button
              disabled={!isConnected || !loggedInUser?.email_verified}
              onClick={() => history.push(`${location.pathname}/new`)}
              title={t("qvrseDAO.membership.joinDAO")}
              type={"primary"}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { md: "0px 0px", mobile: "0px 0px" },
        }}
      >
        <List />
      </Box>
    </Paper>
  );
};

Index.List = List;
Index.Create = Create;

export default Index;
