const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : window.env.CHAIN === "AMOY"
    ? "https://payment.mumbai.qvrse.io"
    : "https://payment.dapp.qvrse.io";

const baseQvrseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : window.env.CHAIN === "AMOY"
    ? "https://mumbai.qvrse.io"
    : "https://qvrse.io";

const handleRedirectToPayments = (
  collection,
  DAO,
  selectedNFT,
  loggedInUser,
  i18n,
  selectedSeats = null
) => {
  if (loggedInUser) {
    //LoggedIn Qvrse user
    window.location.href = `${baseUrl}/brand/${DAO.id}/collection/${
      collection.id
    }?email=${encodeURIComponent(loggedInUser.email)}&lng=${
      i18n.language
    }&manualNFTSelection=${collection?.manual_nft_selection}&selectedNFT=${
      selectedNFT?.id || null
    }&selectedSeats=${
      selectedSeats?.toString() || null
    }&source=qvrse&redirectUrl=${baseQvrseUrl}/brand/${DAO.id}/collection/${
      collection.id
    }?selectedNFT=${selectedNFT?.id || null}`;
  } else {
    //Not logged in Qvrse user
    window.location.href = `${baseUrl}/brand/${DAO.id}/collection/${
      collection.id
    }?manualNFTSelection=${collection?.manual_nft_selection}&selectedNFT=${
      selectedNFT?.id || null
    }&selectedSeats=${selectedSeats?.toString() || null}&source=qvrse&lng=${
      i18n.language
    }&redirectUrl=${baseQvrseUrl}/brand/${DAO.id}/collection/${
      collection.id
    }?selectedNFT=${selectedNFT?.id || null}`;
  }
};

export default handleRedirectToPayments;
