import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";

const AddField = (props) => {
  const { onClick, subtitle, title } = props;
  return (
    <Box
      {...props}
      onClick={onClick}
      variant="raised"
      component="span"
      sx={{
        cursor: "pointer",
        padding: { md: "12px 20px 12px 12px", mobile: "10px 20px 10px 12px" },
        borderRadius: (theme) => theme.borderRadius.secondary,
        backgroundColor: (theme) => theme.palette.bgTransparent.tertiary,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "flex-start", alignItems: "center" }}>
        <AddIcon sx={{ color: "#944DFF", fontSize: "1.5rem" }} />
        <Box
          sx={{
            ml: "6px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "left",
          }}
        >
          <Typography variant={"h8"}>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant={"subtitle4"} >
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AddField;
