import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

function Modal({ onClose, title, children }) {
  return (
    <Dialog
      fullWidth
      onClose={onClose}
      open={true}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "1200px",
        },
      }}
      PaperProps={{
        maxWidth: "1200px",
      }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {children}
    </Dialog>
  );
}

export default Modal;
