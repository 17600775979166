//import Gateway from "../../../@components/gateway";

const useService = () => {
  //const gateway = Gateway.useGateway();

  const notifications = async (daoId) => {
    // Simulate a delay
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(1000); // 1 second delay

    // Mock response data
    const mockData = {
      data: [
        {
          id: 1,
          url: "/brand/210/collection/801",
          avatar:
            "https://hazellabs.infura-ipfs.io/ipfs/QmUrTyA623sZXGZ6sbYAGzYC3qK1nRTvRJxUrffsXXXid8",
          name: "Builder Nights",
          message: "created a new collection",
          dateTime: new Date(Date.now()),
        },
        {
          id: 2,
          url: "/brand/210/collection/801",
          avatar:
            "https://prod-backend.qvrse.io/media/daos/avatar_url/41/619_Tony%20Cetinski_avatar.jpeg",
          name: "Tony Cetinski",
          message: "created a new collection",
          dateTime: new Date(Date.now()),
        },
        {
          id: 3,
          url: "/brand/210/collection/801",
          avatar:
            "https://backend.qvrse.io/media/daos/small_avatar_url/176/734_MerifyTech_small_avatar.JPEG",
          name: "MRFY",
          message: "posted to the feed",
          dateTime: new Date(Date.now()),
        },
        {
          id: 4,
          url: "/brand/210/collection/801",
          avatar:
            "https://backend.qvrse.io/media/daos/small_avatar_url/172/745_TOKEN%202049_small_avatar.PNG",
          name: "Alchemy",
          message: "joined QVRSE",
          dateTime: new Date(Date.now()),
        },
        {
          id: 5,
          url: "/brand/210/collection/801",
          avatar:
            "https://backend.qvrse.io/media/daos/small_avatar_url/172/745_TOKEN%202049_small_avatar.PNG",
          name: "Alchemy",
          message: "joined QVRSE",
          dateTime: new Date(Date.now()),
        },
      ],
    };

    // Simulate the gateway.get call
    const response = await new Promise((resolve) => {
      resolve(mockData);
    });

    return response.data;
  };

  return {
    notifications,
  };
};

export default useService;
