import React from "react";
import UI from "../../../../../@components/UI";
import Box from "@mui/material/Box";
import CustomHooks from "../../../../../@components/hooks";
import useCollectionService from "../../useService";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Reservation from "./Reservation";

const Index = ({ DAO }) => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const collectionService = useCollectionService(DAO?.id);

  const { data: collection, refetch: fetchCollection } = CustomHooks.useFetch(
    ["full_collection"],
    () => collectionService.collection(id),
    {
      enabled: false,
    }
  );

  const { data: reservedSeatsResponse, refetch: fetchReservedSeats } =
    CustomHooks.useFetch(
      ["reserved_seats"],
      () => collectionService.reservedSeats(id),
      {
        enabled: false,
      }
    );

  console.log("location", location);

  React.useEffect(() => {
    if (DAO && id) {
      fetchCollection();
      fetchReservedSeats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DAO, id]);

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Paper elevation={0} sx={{ width: "100%", background: "transparent" }}>
        <Box
          sx={{
            display: { mobile: "flex", md: "flex" },
            flexDirection: { md: "row", mobile: "column" },
            justifyContent: { md: "space-between", mobile: "center" },
            px: { md: "0px", mobile: "10px" },
            pb: "30px",
            pt: { md: "0px", mobile: "20px" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: { md: "block", mobile: "block" } }}>
              <UI.BackButton
                title={"brands.collection.collectionPage.success.backButton"}
                onClick={() =>
                  history.push({
                    pathname: `/brand/${DAO?.id}/collection/${id}`,
                    state: { scrollToTop: true },
                  })
                }
              />
            </Box>
          </Box>
        </Box>
        <Reservation
          collection={collection}
          reservedSeats={reservedSeatsResponse?.reserved_seats || null}
        />
      </Paper>
    </Box>
  );
};

export default Index;
