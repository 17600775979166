import React from "react";
import Container from "@mui/material/Container";
import CardList from "./CardList";
import SmallList from "./SmallList";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import useCollectionService from "../useService";
import CustomHooks from "../../../../@components/hooks";
import { useUserRole } from "../../context";
import useMediaQuery from "@mui/material/useMediaQuery";

const Index = ({ DAO }) => {
  const { isBrandMember } = useUserRole();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [display, setDisplay] = React.useState("big");
  const collectionService = useCollectionService(DAO?.id);

  React.useEffect(() => {
    if (isMobile) {
      setDisplay("small");
    } else {
      setDisplay("big");
    }
  }, [isMobile]);

  const { data: collections, isLoading: collectionsLoading } =
    CustomHooks.useFetch(["collections"], collectionService.list, {
      select: (response) => {
        // Filter the items based on the visibility condition
        const filteredItems = response.filter((item) => {
          // Return all items if user has ADMIN or MODERATOR role
          if (isBrandMember) {
            return true;
          }
          // Filter out items that have item.visible = false
          return item.visible !== false;
        });

        return filteredItems;
      },
    });

  const handleDisplay = (event, newDisplay) => {
    if (newDisplay !== null) {
      setDisplay(newDisplay);
    }
  };
  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "30px",
        }}
      >
        <ToggleButtonGroup
          value={display}
          exclusive
          onChange={handleDisplay}
          aria-label="item display"
        >
          <ToggleButton
            value="big"
            aria-label="big display list"
            sx={{ padding: { mobile: "8px", md: "11px" } }}
          >
            <ViewModuleIcon
              sx={{ fontSize: { mobile: "1.2rem", md: "1.5rem" } }}
            />
          </ToggleButton>
          <ToggleButton
            value="small"
            aria-label="small display list"
            sx={{ padding: { mobile: "8px", md: "11px" } }}
          >
            <ViewListIcon
              sx={{ fontSize: { mobile: "1.2rem", md: "1.5rem" } }}
            />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {display === "big" && (
        <CardList items={collections} busy={collectionsLoading} />
      )}
      {display === "small" && (
        <SmallList items={collections} busy={collectionsLoading} />
      )}
    </Container>
  );
};

Index.SmallList = SmallList;

export default Index;
