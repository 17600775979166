import React, { createContext, useContext } from "react";

export const UserContext = createContext();

const getInitialTheme = () => {
  // Check localStorage for a theme preference
  const storedTheme = localStorage.getItem("user_theme");
  return storedTheme ? storedTheme : "light";
};

export default function UserProvider({ children }) {
  const [user, setUser] = React.useState(null);
  const [selectedTheme, setSelectedTheme] = React.useState(getInitialTheme());

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        selectedTheme,
        setSelectedTheme,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useUserContext = () => useContext(UserContext);
