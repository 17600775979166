import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import UI from "../../../../../@components/UI";
import { useHistory, useLocation } from "react-router-dom";
import useService from "./useService";
import useHandleErrors from "../../../../../@components/hooks/useHandleErrors";
import List from "./List";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useUserRole } from "../../../context";
import CustomHooks from "../../../../../@components/hooks";
import { useTranslation } from "react-i18next";

const Index = () => {
  const {
    state: { daoId, collectionId, collection },
  } = useLocation();
  const { t } = useTranslation();
  const { handleApiError } = useHandleErrors();
  const service = useService(daoId, collectionId);
  const history = useHistory();
  const [items, setItems] = React.useState(null);
  const [csvData, setCsvData] = React.useState([]);
  const { isBrandMember } = useUserRole();
  CustomHooks.useProtectedRoute({ id: daoId }, isBrandMember);

  const prepareExcelData = (data) => {
    if (data.length > 0) {
      const header = [
        "Date of purchase",
        "Wallet address",
        "Email address",
        "Ticket ID",
        ...Object.keys(data[0].additional_info),
        "Ticket utilized",
        "Receipt sent",
      ];

      const rows = data.map((item) => [
        moment(item.datetime_created).format("DD MMM YYYY HH:mm"),
        item.buyer_address,
        item.user.email,
        item.ticket_id,
        ...Object.keys(item.additional_info).map(
          (key) => item.additional_info[key]
        ),
        item.nft_used ? "YES" : "NO",
        item.receipt_issued ? "YES" : "NO",
      ]);
      setCsvData([header, ...rows]);
    }
  };

  const getItems = async () => {
    service
      .allBuyers(collectionId)
      .then((response) => {
        setItems(response.data);
        prepareExcelData(response.data);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const onRecieptToggle = (ticketId) => {
    service
      .recieptToggle(ticketId)
      .then((response) => {
        getItems();
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const onNftToggle = (ticketId) => {
    service
      .nftToggle(ticketId)
      .then((response) => {
        getItems();
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  React.useEffect(() => {
    daoId && collectionId && getItems();
    // eslint-disable-next-line
  }, [daoId, collectionId]);

  return (
    <Paper elevation={0} sx={{ width: "100%", background: "transparent" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", mobile: "column" },
          justifyContent: { md: "space-between", mobile: "center" },
          px: { md: "70px", mobile: "10px" },
          pb: "20px",
        }}
      >
        <UI.BackButton
          title={t("brands.collection.admin.backButton")}
          onClick={() => history.go(-1)}
        />

        <Box sx={{ width: "150px", mt: { mobile: "30px", md: "0px" } }}>
          <CSVLink
            style={{ textUnderline: "none" }}
            filename={`${collection?.name.replaceAll(" ", "-")}.csv`}
            data={csvData}
          >
            <UI.Button
              sx={{ textUnderline: "none" }}
              title={t("brands.collection.admin.export")}
              type={"primary"}
            />
          </CSVLink>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { md: "75px 80px", mobile: "20px 10px" },
        }}
      >
        <List
          items={items}
          onRecieptToggle={(ticketId) => onRecieptToggle(ticketId)}
          onNftToggle={(ticketId) => onNftToggle(ticketId)}
        />
      </Box>
    </Paper>
  );
};

export default Index;
