import React from "react";
import UI from "../../../../@components/UI";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useUserContext } from "../../../../@components/userContext";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import handleRedirectToPayments from "./handleRedirectToPayments";
import CreateSmartAccountDialog from "../../../../profile/dialogs/CreateSmartAccountDialog";

const BuyButton = ({
  selectedNFT,
  collection,
  toggleEnablePurchasing,
  toggleVisiblity,
  isBrandMember,
  DAO,
}) => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const { user: loggedInUser } = useUserContext();
  const { t } = useTranslation();
  const [showSmartAccountDialog, setShowSmartAccountDialog] =
    React.useState(false);

  return (
    <>
      {collection && DAO && (
        <UI.AnimatedSplitButton
          onMenuItemClick={(action) => {
            if (action === "set_purchase") {
              toggleEnablePurchasing({
                collectionId: collection?.id,
                isEnabled: !collection?.purchase_enabled,
              });
            } else if (action === "set_visibility") {
              toggleVisiblity({
                collectionId: collection?.id,
                isVisible: !collection?.visible,
              });
            } else if (action === "open_admin_panel") {
              history.push({
                pathname: `${pathname}/admin`,
                state: {
                  scrollToTop: false,
                  daoId: DAO?.id,
                  collectionId: id,
                  collection: collection,
                },
              });
            }
          }}
          isFree={collection?.total_price_with_tax === "0.00"}
          isPurchaseEnabled={collection.purchase_enabled}
          isVisibilityEnabled={collection.visible}
          hideDropDownButton={!isBrandMember || !loggedInUser?.email_verified}
          disabled={
            (collection?.manual_nft_selection === true &&
              selectedNFT == null) ||
            loggedInUser?.smart_address === null ||
            collection.user_has_this_free_nft ||
            !collection.purchase_enabled ||
            collection?.schedule_status.toLowerCase() !== "active" ||
            collection?.nft_amount_minted >= collection?.nft_amount_limit
          }
          sx={{
            height: "46px",
            width: { md: "300px", mobile: "100%" },
            borderRadius: (theme) =>
              theme.borderRadius.secondary + "!important",
            mb: "40px",
            mr: {
              md: isBrandMember ? "12px" : "0px",
              mobile: isBrandMember ? "10px" : "0px",
            },
          }}
          type={"primary"}
          title={
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Typography sx={{ fontWeight: "bold", color: "white" }}>
                  {collection?.total_price_with_tax === "0.00" ? (
                    <Typography
                      sx={{
                        fontSize: {
                          md: "15px !important",
                          mobile: "14px !important",
                        },
                        fontWeight: "bold",
                        color: "white !important",
                      }}
                    >
                      {t("brands.collection.collectionPage.buyButton.freeNft")}
                    </Typography>
                  ) : (
                    t("brands.collection.collectionPage.buyButton.buy")
                  )}
                </Typography>
              </Grid>
              <Grid item>
                {collection?.total_price_with_tax > 0 && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant={"h9"}
                      sx={{
                        fontSize: "13px !important",
                        ml: { md: "120px", mobile: "60px" },
                        color: "white",
                        opacity: "0.7",
                        mt: "1px",
                      }}
                    >
                      {`${collection?.total_price_with_tax} ${collection?.currency}`}
                    </Typography>
                  </Box>
                )}
                {collection?.total_price_with_tax === "0.00" && (
                  <Typography
                    variant={"h9"}
                    sx={{
                      fontSize: "13px !important",
                      ml: i18n.language === "en" ? "100px" : "40px",
                      display: { md: "block", mobile: "none" },
                      color: "white",
                      opacity: "0.7",
                      mt: "1px",
                    }}
                  >
                    0.00
                  </Typography>
                )}
              </Grid>
            </Grid>
          }
          onClick={() => {
            //find out if the collection has seat reservation enabled under utilities
            let hasSeatReservation = false;

            collection.utilities.forEach((utility) => {
              if (utility.seat_reservation_enabled) {
                hasSeatReservation = true;
              }
            });

            if (hasSeatReservation) {
              history.push({
                pathname: `${pathname}/seat-reservation`,
                state: {
                  daoId: DAO?.id,
                  collectionId: id,
                  collection: collection,
                  selectedNFT: selectedNFT,
                },
              });
            } else {
              handleRedirectToPayments(
                collection,
                DAO,
                selectedNFT,
                loggedInUser,
                i18n
              );
            }
          }}
        />
      )}
      {collection && collection?.schedule_status !== "active" && (
        <Box sx={{ mt: "-25px", mb: "40px" }}>
          <Alert variant="outlined" severity="info">
            <Typography>
              {t("brands.collection.collectionPage.buyButton.headsUpMessage", {
                dateTime: moment(collection?.scheduled_date_time).format(
                  "DD MMM YYYY HH:mm"
                ),
              })}
            </Typography>
          </Alert>
        </Box>
      )}
      {loggedInUser?.smart_address === null && (
        <Typography
          variant={"subtitle2"}
          sx={{
            mt: "-25px",
            display: "flex",
            mb: "40px",
          }}
        >
          <Alert variant="outlined" severity="info">
            <Box>
              You need to secure your account before you can buy NFTs. Click{" "}
              <Box
                onClick={() => {
                  setShowSmartAccountDialog(Math.random());
                }}
                component={"span"}
                sx={{
                  color: (theme) => theme.palette.text.link,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                here
              </Box>{" "}
              to get started.
            </Box>
          </Alert>
        </Typography>
      )}
      {collection?.total_price_with_tax === "0.00" && (
        <Typography
          variant={"subtitle2"}
          sx={{
            mt: "-25px",
            display: "flex",
            mb: "40px",
          }}
        >
          <Alert variant="outlined" severity="info">
            {t("brands.collection.collectionPage.buyButton.freeNFTMessage")}
          </Alert>
        </Typography>
      )}
      <CreateSmartAccountDialog toggleOpen={showSmartAccountDialog} />
    </>
  );
};

export default BuyButton;
