import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useFormDataContext } from "../context";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../../@components/UI";
import { makeStyles } from "@mui/styles";
import { useUserContext } from "../../../../@components/userContext";
import Alert from "@mui/material/Alert";
import RegisterDialog from "../../../../profile/dialogs/RegisterDialog";
import { useTranslation } from "react-i18next";
import LogInDialogFlow from "../../../../profile/dialogs/LogInDialogFlow";
import EmailConfirmationDialog from "../../../../profile/dialogs/EmailConfirmation";
import useService from "../../useService";
import { useSnackbar } from "notistack";
import useHandleErrors from "../../../../@components/hooks/useHandleErrors";
import { useHistory } from "react-router-dom";
import CreateSmartAccountDialog from "../../../../profile/dialogs/CreateSmartAccountDialog";

const useStyles = makeStyles(() => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: { md: "20px", mobile: "0px" },
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const Index = ({ draft }) => {
  const history = useHistory();
  const service = useService();
  const { t } = useTranslation();
  const classes = useStyles();
  const { user: loggedInUser } = useUserContext();
  const { formData, setFormData } = useFormDataContext();
  const { enqueueSnackbar } = useSnackbar();
  const { handleApiError } = useHandleErrors();
  const [triggerRegisterDialog, setTriggerRegisterDialog] =
    React.useState(null);
  const [triggerLoginDialog, setTriggerLoginDialog] = React.useState(null);
  const [triggerEmailConfirmationDialog, setTriggerEmailConfirmation] =
    React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showSmartAccountDialog, setShowSmartAccountDialog] =
    React.useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      draft_id: formData?.draft_id,
      avatar_url: formData?.avatar_url,
      name: formData?.name,
      description: formData?.description,
    },
    mode: "onChange",
  });

  React.useEffect(() => {
    if (!!draft) {
      setFormData(draft.data);
      reset(draft.data);
    }
    //eslint-disable-next-line
  }, [draft]);

  const onSubmit = async (data) => {
    service
      .createBrand({
        avatar_url: data.avatar_url.ipfs,
        name: data.name,
        description: data.description,
        industry: data.industry,
        maintainer_address: loggedInUser?.smart_address,
      })
      .then((response) => {
        const { id: brandId } = response.data;
        enqueueSnackbar("Brand succesfully created.", { variant: "success" });
        history.push({
          pathname: `/brand/${brandId}`,
          state: {
            scrollToTop: true,
          },
        });
        setIsLoading(false);
      })
      .catch((error) => {
        handleApiError(error);
        setIsLoading(false);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <UI.Busy.FullscreenIndicator show={isLoading} />
      <form
        onKeyDown={handleKeyDown}
        onSubmit={handleSubmit((values) => onSubmit(values))}
      >
        <Box
          sx={{
            width: "100%",
            px: { md: "100px", mobile: "10px" },
          }}
        >
          <Alert
            variant="outlined"
            severity="info"
            sx={{
              textAlign: "start",
            }}
          >
            {t("applyYourBrand.basicInfo.surtitle")}
          </Alert>
        </Box>
        {loggedInUser &&
        loggedInUser?.email_verified &&
        loggedInUser?.smart_address ? (
          <Box sx={{ p: { md: "40px 100px", mobile: "50px 10px 60px" } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                mb: { md: "40px", mobile: "30px" },
              }}
            >
              <Typography variant={"h3"}>
                {t("applyYourBrand.basicInfo.title")}
              </Typography>
            </Box>
            <Box>
              <Controller
                name={"avatar_url"}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UI.Upload
                    aiButtonEnabled
                    noHeight
                    noPadding
                    showAvatarPreview
                    defaultValue={formData?.avatar_url}
                    {...field}
                    id={"profile"}
                    title={t("applyYourBrand.basicInfo.upload.title")}
                    subtitle={t("applyYourBrand.basicInfo.upload.subtitle")}
                    acceptFiles={"image/jpeg,image/gif,image/png"}
                  />
                )}
              />
              <Box sx={{ mt: "15px" }}>
                {errors?.avatar_url?.type === "required" && (
                  <Alert variant="outlined" severity="error">
                    {t("applyYourBrand.basicInfo.upload.error.required")}
                  </Alert>
                )}
              </Box>
            </Box>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid
                item
                md={12}
                mobile={12}
                sx={{
                  mt: { md: "34px", mobile: "18px" },
                  pt: "0px !important",
                }}
              >
                <Box className={classes.inputOuter}>
                  <Box sx={{ display: "flex", mb: "10px" }}>
                    <Typography variant={"h5"}>
                      {t("applyYourBrand.basicInfo.formInputs.brandName.title")}
                    </Typography>
                  </Box>

                  <Controller
                    name={"name"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <UI.TextField aiButtonEnabled {...field} />
                    )}
                  />
                  <Box sx={{ mt: "15px" }}>
                    {errors?.name?.type === "required" && (
                      <Alert variant="outlined" severity="error">
                        {t(
                          "applyYourBrand.basicInfo.formInputs.brandName.error.required"
                        )}
                      </Alert>
                    )}
                  </Box>
                </Box>
                <Box className={classes.inputOuter}>
                  <Box sx={{ display: "flex", mb: "10px" }}>
                    <Typography variant={"h5"}>
                      {t(
                        "applyYourBrand.basicInfo.formInputs.brandDescription.title"
                      )}
                    </Typography>
                  </Box>

                  <Controller
                    name={"description"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <UI.TextField
                        aiButtonEnabled
                        multiline
                        rows={5}
                        {...field}
                      />
                    )}
                  />
                  <Box sx={{ mt: "15px" }}>
                    {errors?.description?.type === "required" && (
                      <Alert variant="outlined" severity="error">
                        {t(
                          "applyYourBrand.basicInfo.formInputs.brandDescription.error.required"
                        )}
                      </Alert>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                width: "100%",
                mt: { md: "60px", mobile: "40px" },
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ width: "210px" }}>
                  <UI.Button
                    disabled={
                      !!Object.keys(errors).length ||
                      !loggedInUser?.email_verified
                    }
                    sx={{
                      filter:
                        "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
                    }}
                    title={t("buttons.create")}
                    type={"primary"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <Typography
              variant={"h2"}
              sx={{
                mt: { md: "66px", mobile: "40px" },
                lineHeight: "1.6 !important",
                fontSize: { md: "20px !important", mobile: "16px !important" },
              }}
            >
              {t("applyYourBrand.basicInfo.logInMessage1")}
            </Typography>

            <Box
              sx={{
                mt: { md: "48px", mobile: "40px" },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {!loggedInUser?.email_verified && (
                <UI.Button
                  sx={{ width: "190px" }}
                  type={"primary"}
                  title={t("buttons.logIn")}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setTriggerLoginDialog(Math.random());
                  }}
                />
              )}
              {loggedInUser?.smart_address === null &&
                loggedInUser?.email_verified && (
                  <UI.Button
                    sx={{ width: "240px" }}
                    type={"primary"}
                    title={t("commons.secureAccount")}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowSmartAccountDialog(Math.random());
                    }}
                  />
                )}
              <UI.Button
                sx={{ width: "240px", mt: "15px" }}
                type={"tertiary"}
                title={t("buttons.createAccount")}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setTriggerRegisterDialog(Math.random());
                }}
              />
            </Box>
          </Box>
        )}
      </form>
      <CreateSmartAccountDialog toggleOpen={showSmartAccountDialog} />
      <LogInDialogFlow
        trigger={triggerLoginDialog}
        onClose={() => setTriggerLoginDialog(null)}
      />
      <EmailConfirmationDialog toggleOpen={triggerEmailConfirmationDialog} />
      <RegisterDialog
        toggleOpen={triggerRegisterDialog}
        onRegister={() => setTriggerEmailConfirmation(Math.random())}
        showLogInDialog={() => {
          setTriggerRegisterDialog(Math.random());
          setTriggerLoginDialog(Math.random());
        }}
      />
    </>
  );
};

export default Index;
