import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import UI from "../../../@components/UI";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";

const VotingPowerDialog = ({ toggleOpen, onConfirm }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          padding: { md: "40px 30px", mobile: "40px 10px" },
          width: "700px",
          margin: { mobile: "0px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ p: { mobile: "0px" } }}>
        <CloseIcon
          onClick={() => {
            setOpen(false);
          }}
          sx={{
            position: "absolute",
            right: 30,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <Grid container>
          <Grid item mobile={12} md={12}>
            <Typography variant={"h3"} sx={{ textAlign: "center" }}>
              {t("brands.membership.votingPowerDialog.title")}
            </Typography>
            <Box sx={{ mt: "40px", px: "10px" }}>
              <Box>
                <Typography variant={"subtitle2"} sx={{ mt: "20px" }}>
                  <span>
                    {t("brands.membership.votingPowerDialog.subtitle")}
                  </span>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                mt: "50px",
                mb: "15px",
                px: "15px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <UI.Button
                onClick={onConfirm}
                sx={{ width: "260px" }}
                title={t("buttons.proceedConfirm")}
                type={"primary"}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </MaterialDialog>
  );
};

export default VotingPowerDialog;
