import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useHistory, useLocation } from "react-router-dom";
import { useUserRole } from "./context";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";

const Menu = ({ DAO, currentUrl }) => {
  const { t } = useTranslation();
  const { isBrandMember } = useUserRole();
  let location = useLocation();
  const history = useHistory();
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (DAO && location.pathname) {
      if (location.pathname.startsWith(`/brand/${DAO?.id}/feed`)) {
        setValue(1);
      } else if (location.pathname.startsWith(`/brand/${DAO?.id}/album`)) {
        setValue(2);
      } else if (location.pathname.startsWith(`/brand/${DAO?.id}/collection`)) {
        setValue(3);
      } else if (location.pathname.startsWith(`/brand/${DAO?.id}/settings`)) {
        //setValue(0);
      } else {
        setValue(0);
      }
    }
    // eslint-disable-next-line
  }, [DAO, location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        mt: "0px",
        mb: "30px",
        px: "10px",
        display: {
          md: "block",
          mobile: "none",
        },
      }}
    >
      {DAO ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Tabs
            TabIndicatorProps={{
              sx: {
                backgroundColor: "transparent",
              },
            }}
            variant={isBrandMember ? "scrollable" : "unset"}
            scrollButtons
            allowScrollButtonsMobile
            value={value}
            onChange={handleChange}
            centered={true}
          >
            <Tab
              index={"home"}
              sx={{
                "&.MuiTab-root": {
                  transition: "all 0.3s ease-in-out",
                  width: "140px",
                  fontSize: "14px",
                  opacity: location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? 0.5
                    : 1,
                  backgroundColor: (theme) =>
                    location.pathname.startsWith(`/brand/${DAO?.id}/settings`)
                      ? theme.palette.bgTransparent.tertiary + "!important"
                      : "transparent",
                },
              }}
              onClick={() => history.push(`${currentUrl}`)}
              label={t("brands.menu.home")}
            />
            <Tab
              index={"feed"}
              sx={{
                "&.MuiTab-root": {
                  transition: "all 0.3s ease-in-out",
                  width: "140px",
                  fontSize: "14px",
                  opacity: location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? 0.5
                    : 1,
                  backgroundColor: (theme) =>
                    location.pathname.startsWith(`/brand/${DAO?.id}/settings`)
                      ? theme.palette.bgTransparent.tertiary + "!important"
                      : "transparent",
                },
              }}
              onClick={() => history.push(`${currentUrl}/feed`)}
              label={t("brands.menu.feed")}
            />
            <Tab
              index={"album"}
              sx={{
                "&.MuiTab-root": {
                  transition: "all 0.3s ease-in-out",
                  width: "140px",
                  fontSize: "14px",
                  opacity: location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? 0.5
                    : 1,
                  backgroundColor: (theme) =>
                    location.pathname.startsWith(`/brand/${DAO?.id}/settings`)
                      ? theme.palette.bgTransparent.tertiary + "!important"
                      : "transparent",
                },
              }}
              onClick={() => history.push(`${currentUrl}/album`)}
              label={t("brands.menu.albums")}
            />
            <Tab
              index={"collection"}
              sx={{
                "&.MuiTab-root": {
                  transition: "all 0.3s ease-in-out",
                  width: "140px",
                  fontSize: "14px",
                  opacity: location.pathname.startsWith(
                    `/brand/${DAO?.id}/settings`
                  )
                    ? 0.5
                    : 1,
                  backgroundColor: (theme) =>
                    location.pathname.startsWith(`/brand/${DAO?.id}/settings`)
                      ? theme.palette.bgTransparent.tertiary + "!important"
                      : "transparent",
                },
              }}
              onClick={() => history.push(`${currentUrl}/collection`)}
              label={t("brands.menu.collections")}
            />
            {/*<Tab*/}
            {/*  index={"marketplace"}*/}
            {/*  sx={{*/}
            {/*    "&.MuiTab-root": {*/}
            {/*      transition: "all 0.3s ease-in-out",*/}
            {/*      width: "140px",*/}
            {/*      fontSize: "14px",*/}
            {/*      opacity: location.pathname.startsWith(*/}
            {/*        `/brand/${DAO?.id}/settings`*/}
            {/*      )*/}
            {/*        ? 0.5*/}
            {/*        : 1,*/}
            {/*      backgroundColor: (theme) =>*/}
            {/*        location.pathname.startsWith(`/brand/${DAO?.id}/settings`)*/}
            {/*          ? theme.palette.bgTransparent.tertiary + "!important"*/}
            {/*          : "transparent",*/}
            {/*    },*/}
            {/*  }}*/}
            {/*  onClick={() => history.push(`${currentUrl}/marketplace`)}*/}
            {/*  label={t("brands.menu.marketplace")}*/}
            {/*/>*/}
          </Tabs>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mt: "20px",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Skeleton
              sx={{
                borderRadius: (theme) => theme.borderRadius.secondary,
                background: (theme) => theme.palette.bgTransparent.primary,
              }}
              variant="rectangular"
              width={140}
              height={62}
            />
            <Skeleton
              sx={{
                ml: "2px",
                borderRadius: (theme) => theme.borderRadius.secondary,
                background: (theme) => theme.palette.bgTransparent.primary,
              }}
              variant="rectangular"
              width={140}
              height={62}
            />
            <Skeleton
              sx={{
                ml: "2px",
                borderRadius: (theme) => theme.borderRadius.secondary,
                background: (theme) => theme.palette.bgTransparent.primary,
              }}
              variant="rectangular"
              width={140}
              height={62}
            />
            <Skeleton
              sx={{
                ml: "2px",
                borderRadius: (theme) => theme.borderRadius.secondary,
                background: (theme) => theme.palette.bgTransparent.primary,
              }}
              variant="rectangular"
              width={140}
              height={62}
            />
            <Skeleton
              sx={{
                ml: "2px",
                borderRadius: (theme) => theme.borderRadius.secondary,
                background: (theme) => theme.palette.bgTransparent.primary,
              }}
              variant="rectangular"
              width={140}
              height={62}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Menu;
