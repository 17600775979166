import React from "react";
import useService from "./useService";
import SkeletonList from "./SkeletonList";
import Box from "@mui/material/Box";
import UI from "../../../@components/UI";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import CustomHooks from "../../../@components/hooks";
import { useTranslation } from "react-i18next";
import Item from "./Item";
import { useUserRole } from "../context";

const List = ({ DAO }) => {
  const { isBrandMember } = useUserRole();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const service = useService(DAO?.id);

  const {
    data: items,
    isLoading,
    refetch,
  } = CustomHooks.useFetch(["posts", DAO?.id], service.list, {
    select: (response) => {
      // Find the pinned post
      const pinnedPost = response.find((item) => item.pinned_post === true);
      if (pinnedPost) {
        // Remove the pinned post from the original array
        const filteredItems = response.filter(
          (item) => item.pinned_post !== true
        );

        // Create a new array with the pinned post as the first item
        const reorganizedItems = [pinnedPost, ...filteredItems];
        return reorganizedItems;
      } else {
        return response;
      }
    },
  });

  const { mutate: removePost, isLoading: isRemovingPost } = CustomHooks.usePost(
    service.remove,
    {
      onSuccess: () => {
        enqueueSnackbar(t("brands.feed.snackbar.successRemove"), {
          variant: "success",
        });
        refetch();
      },
    }
  );

  const { mutate: pinPost, isLoading: isPinningPost } = CustomHooks.usePost(
    service.pinPost,
    {
      onSuccess: () => {
        enqueueSnackbar(t("brands.feed.snackbar.successPin"), {
          variant: "success",
        });
        refetch();
      },
    }
  );

  React.useEffect(() => {
    DAO && refetch();
    // eslint-disable-next-line
  }, [DAO, refetch]);

  return (
    <Box>
      <UI.Busy.FullscreenIndicator show={isRemovingPost}>
        <Typography variant={"subtitle1"}>
          {t("brands.feed.loading.isRemovingPost")}
        </Typography>
        <Typography variant={"subtitle1"}>
          {t("brands.feed.loading.dontCloseWindow")}
        </Typography>
      </UI.Busy.FullscreenIndicator>
      <UI.Busy.FullscreenIndicator show={isPinningPost}>
        <Typography variant={"subtitle1"}>
          {t("brands.feed.loading.isPinningPost")}
        </Typography>
        <Typography variant={"subtitle1"}>
          {t("brands.feed.loading.dontCloseWindow")}
        </Typography>
      </UI.Busy.FullscreenIndicator>
      {isLoading && <SkeletonList />}
      {!isLoading &&
        items &&
        items.length > 0 &&
        items.map((item) => (
          <Item
            key={item.id}
            DAO={DAO}
            isBrandMember={isBrandMember}
            data={item}
            refetch={refetch}
            onPinPost={(id) => pinPost(id)}
            onRemove={(id) => removePost(id)}
          />
        ))}
      {!isLoading && items && items.length === 0 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <UI.NoResults text={t("infoMessages.postNotFound")} />
        </Box>
      )}
    </Box>
  );
};

export default List;
