import React from "react";
import Box from "@mui/material/Box";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Autoplay, Pagination } from "swiper/modules";

import "./styles.css";

const Slider = ({ items, onRemove }) => {
  return (
    <>
      <Swiper
        grabCursor={true}
        slidesPerView={4}
        spaceBetween={10}
        speed={500}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }}
        initialSlide={3}
        loop={true}
        centeredSlides={true}
        navigation={true}
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        modules={[Autoplay, Navigation, Pagination]}
        className="tokens-slider"
      >
        {items
          ? items.map((item) => (
              <SwiperSlide>
                <Item item={item} onRemove={onRemove} />
              </SwiperSlide>
            ))
          : [0, 1, 2, 3].map((index) => (
              <SwiperSlide key={index}>
                <Item />
              </SwiperSlide>
            ))}
      </Swiper>
    </>
  );
};

const Item = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        "&:hover .removeIcon": {
          display: "flex",
        },
      }}
    >
      <Box>
        <Box
          sx={{ textAlign: "center", height: { md: "80px", mobile: "50px" } }}
        >
          {!props?.item?.isDefaultToken && !props?.item?.isDaoToken && (
            <Box
              className={"removeIcon"}
              sx={{
                display: "none",
                position: "absolute",
                left: "calc(50% + 50px)",
                cursor: "pointer",
                color: "white",
              }}
            >
              <HighlightOffIcon
                onClick={() => props.onRemove(props?.item?.id)}
              />
            </Box>
          )}
          <Box
            sx={{
              display: { md: "inline-block", mobile: "none" },
              width: "70px",
            }}
          >
            {props?.item?.logo ? (
              <img
                style={{ display: "initial" }}
                width={"100%"}
                src={props.item.logo}
                alt={"tokens-logo"}
              />
            ) : (
              <Skeleton variant={"circular"} height={"70px"} width={"70px"} />
            )}
          </Box>
          <Box
            sx={{
              display: { md: "none", mobile: "inline-block" },
              width: "40px",
            }}
          >
            {props?.item?.logo ? (
              <img
                style={{ display: "initial" }}
                width={"100%"}
                src={props.item.logo}
                alt={"tokens-logo"}
              />
            ) : (
              <Skeleton variant={"circular"} height={"40px"} width={"40px"} />
            )}
          </Box>
        </Box>
        {props?.item?.balance ? (
          <Typography variant={"h4"} sx={{ textAlign: "center" }}>
            {`${props.item.balance}`}
          </Typography>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Skeleton variant={"rectangular"} width={"100px"} />
          </Box>
        )}
        {props?.item?.symbol ? (
          <Typography
            variant={"h4"}
            sx={{ textAlign: "center", lineHeight: "20px" }}
          >
            {`${props.item.symbol}`}
          </Typography>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Skeleton variant={"rectangular"} width={"100px"} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Slider;
