import AppBar from "./appBar";
import Confirm from "./Confirm";
import ConnectWallet from "./connectWallet";
import LoginWithWallet from "./loginWithWallet";
import ThemeSwitcher from "../designSystem/themeSwitcher";
import Button from "./Button";
import MaterialSlider from "./MaterialSlider";
import Modal from "./Modal";
import Footer from "./Footer";
import Video from "./Video";
import NumberedTitle from "./NumberedTitle";
import ScrollToTop from "./ScrollToTop";
import ProgressBar from "./ProgressBar";
import Filter from "./Filter";
import Sort from "./Sort";
import TextField from "./inputs/TextField";
import NumberField from "./inputs/NumberField";
import Select from "./inputs/Select";
import Busy from "./busy";
import Upload from "./Upload";
import Stepper from "./Stepper";
import Checkbox from "./inputs/Checkbox";
import Tooltip from "./Tooltip";
import CopyToClipboard from "./CopyToClipboard";
import CopyToClipboardPassword from "./CopyToClipboardPassword";
import NoResults from "./NoResults";
import Snackbar from "./Snackbar";
import useHandleErrors from "../hooks/useHandleErrors";
import BackButton from "./BackButton";
import FloatingActionButton from "./FloatingActionButton";
import MobileSidebar from "./MobileSidebar";
import MobileTooltip from "./MobileTooltip";
import useIPFS from "./useIPFS";
import SpeedDial from "./SpeedDial";
import DateTime from "./inputs/DateTime";
import TransferDialog from "./TransferDialog";
import AddField from "./AddField";
import Accordion from "./Accordion";
import OverflowTip from "./OverflowTooltip";
import SwiperMobile from "./swiperMobile";
import SwiperDesktop from "./swiperDesktop";
import VertMenu from "./VertMenu";
import HeaderTags from "./HeaderTags";
import Dropzone from "./Dropzone";
import DropzoneVideo from "./DropzoneVideo";
import ReadMore from "./ReadMore";
import ReadMoreDescription from "./ReadMoreDescription";
import PasswordField from "./inputs/PasswordField";
import UtilityTag from "./UtilityTag";
import Tag from "./Tag";
import Inputs from "./inputs";
import PaymentIcon from "./PaymentIcon";
import FlipCard from "./FlipCard";
import ImageWithSkeleton from "./ImageWithSkeleton";
import CountrySelect from "./inputs/CountrySelect";
import ExternalURL from "./ExternalURL";
import SwitchButton from "./Switch";
import InfoDialog from "./InfoDialog";
import VotingPoll from "./VotingPoll";
import SplitButton from "./SplitButton";
import ColorPicker from "./ColorPicker";
import QvrsePayment from "./QvrsePayment";
import AnimatedSplitButton from "./AnimatedSplitButton";
import Utilities from "./utilities";
import ConnectToSmartAccount from "./ConnectToSmartAccount";
import SmartAccountActionButton from "./SmartAccountActionButton";
import SideMenu from "./sideMenu";
import PaymentDialog from "./PaymentDialog";

const Components = {
  QvrsePayment,
  Utilities,
  ColorPicker,
  SplitButton,
  SwitchButton,
  InfoDialog,
  AnimatedSplitButton,
  AppBar,
  Confirm,
  ThemeSwitcher,
  Button,
  Footer,
  MaterialSlider,
  Upload,
  Checkbox,
  Busy,
  Modal,
  Video,
  NumberedTitle,
  ScrollToTop,
  ProgressBar,
  Filter,
  Sort,
  TextField,
  Select,
  ConnectWallet,
  Stepper,
  Tooltip,
  CopyToClipboard,
  CopyToClipboardPassword,
  NoResults,
  Snackbar,
  useHandleErrors,
  BackButton,
  FloatingActionButton,
  MobileSidebar,
  MobileTooltip,
  useIPFS,
  SpeedDial,
  DateTime,
  TransferDialog,
  AddField,
  Accordion,
  NumberField,
  OverflowTip,
  SwiperMobile,
  SwiperDesktop,
  VertMenu,
  HeaderTags,
  Dropzone,
  DropzoneVideo,
  ReadMore,
  ReadMoreDescription,
  CountrySelect,
  PasswordField,
  LoginWithWallet,
  UtilityTag,
  Tag,
  PaymentIcon,
  Inputs,
  FlipCard,
  ImageWithSkeleton,
  ExternalURL,
  VotingPoll,
  ConnectToSmartAccount,
  SmartAccountActionButton,
  SideMenu,
  PaymentDialog,
};

export default Components;
