import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { styled } from "@mui/material/styles";
import MaterialButton from "@mui/material/Button";
import { purple } from "@mui/material/colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

const StyledButtonGroup = styled(ButtonGroup)({
  boxShadow: "none",
});

const slideEffect = `
   @keyframes slideEffect {
     0%, 28.57% { // 28.57% of 7s is 2s
        left: -75px;
     }
     14.28% { // 14.28% of 7s is 1s
        left: 160%;
     }
     100% {
        left: -75px; // Reset to start position for next cycle
     }
  }
`;

const QvrsButton = styled(MaterialButton)(({ theme, type, isdisabled }) => ({
  color: theme.palette.getContrastText(purple[500]),
  border: "1px solid transparent",
  filter: "drop-shadow(0px 0px 5px rgba(138, 38, 255, 0.2))",
  borderRadius: (theme) => theme.borderRadius.secondary,
  boxShadow: "none",
  position: "relative",
  overflow: "hidden", // Important for the sliding effect
  backgroundColor:
    (type === "primary" && theme.palette.buttons.primary) ||
    (type === "button" && theme.palette.buttons.primary) ||
    (type === "secondary" && theme.palette.buttons.secondary) ||
    (type === "tertiary" && theme.palette.buttons.tertiary),

  "&::after": {
    content: '""',
    position: "absolute",
    width: "50px",
    height: "155px",
    backgroundColor: "#fff",
    opacity: 0.2,
    transform: "rotate(35deg)",
    top: "-50px",
    left: "-75px",
    zIndex: -10,
    transition: "all 550ms cubic-bezier(0.19, 1, 0.22, 1)",
    animation: "slideEffect 7s infinite",
    // Disable animation if button is disabled
    animationPlayState: isdisabled ? "paused" : "running",
  },

  "&:hover": {
    border: "1px solid #944DFF",
    filter: "drop-shadow(0px 0px 10px rgba(138, 38, 255, 0.8))",
    transition: "all 0.2s ease",
    boxShadow: "none",
    backgroundColor:
      (type === "primary" && "#b185f2") ||
      (type === "button" && "#b185f2") ||
      (type === "secondary" && "#5B5E72") ||
      (type === "tertiary" && "unset"),
  },
  "&:disabled": {
    opacity: "0.6",
  },
  width: "100%",
  height: "46px",
  fontFamily: "Quantico",
  fontWeight: "700",
  fontSize: "16px",
}));

const StyledComponent = styled("div")`
  ${slideEffect}
`;

export default function AnimatedSplitButton({
  title,
  type,
  onClick,
  onMenuItemClick,
  isPurchaseEnabled,
  isFree,
  isVisibilityEnabled,
  hideDropDownButton,
  ...props
}) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleMenuItemClick = (action) => {
    onMenuItemClick(action);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <StyledComponent>
      <StyledButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        sx={{
          width: { md: "auto", mobile: "100%" },
          "& .MuiButton-root": {
            borderColor: "transparent !important",
            borderRight: hideDropDownButton
              ? "1px solid transparent !important"
              : "1px solid transparent !important",
          },
        }}
      >
        <QvrsButton
          data-test={"buy-button"}
          {...props}
          onClick={onClick}
          variant={"contained"}
          isdisabled={props.disabled}
          type={type}
        >
          {title}
        </QvrsButton>
        {!hideDropDownButton && (
          <Button
            sx={{
              backgroundColor: "#944DFF",
              border: "1px solid transparent",
              borderRadius: "100px !important",
              filter: "drop-shadow(0px 0px 5px rgba(138, 38, 255, 0.2))",
              padding: "4px 8px",
              width: "46px",
              height: "46px",
              minWidth: "46px !important",
              "&:hover": {
                border: "1px solid transparent",
                filter: "drop-shadow(0px 0px 10px rgba(138, 38, 255, 0.8))",
                transition: "all 0.2s ease",
                boxShadow: "none",
                backgroundColor:
                  (type === "primary" && "#b185f2") ||
                  (type === "button" && "#b185f2") ||
                  (type === "secondary" && "#5B5E72") ||
                  (type === "tertiary" && "unset"),
              },
              "&:disabled": {
                color: (theme) => theme.palette.text.primary,
                opacity: "0.26",
              },
            }}
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </StyledButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper sx={{ backgroundColor: (theme) => theme.palette.colors.darkGray, mt: "12px", mb: "10px" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {!isFree && (
                    <MenuItem
                      sx={{
                        fontFamily: "Open Sans",
                        fontSize: "14px",
                        padding: "3px 22px 6px",
                        minHeight: "34px",
                      }}
                      key={"disablePurchase"}
                      onClick={() => handleMenuItemClick("set_purchase")}
                    >
                      {isPurchaseEnabled
                        ? t(
                            "brands.collection.collectionPage.buyButton.actions.disable"
                          )
                        : t(
                            "brands.collection.collectionPage.buyButton.actions.enable"
                          )}
                    </MenuItem>
                  )}
                  {isFree && (
                    <MenuItem
                      sx={{
                        fontFamily: "Open Sans",
                        fontSize: "14px",
                        padding: "3px 22px 6px",
                        minHeight: "34px",
                      }}
                      key={"disablePurchase"}
                      onClick={() => handleMenuItemClick("set_purchase")}
                    >
                      {isPurchaseEnabled
                        ? t(
                            "brands.collection.collectionPage.buyButton.actions.freeDisable"
                          )
                        : t(
                            "brands.collection.collectionPage.buyButton.actions.freeEnable"
                          )}
                    </MenuItem>
                  )}
                  {isMobile && (
                    <MenuItem
                      sx={{
                        fontFamily: "Open Sans",
                        fontSize: "14px",
                        padding: "3px 22px 6px",
                        minHeight: "34px",
                      }}
                      key={"visibility"}
                      onClick={() => handleMenuItemClick("set_visibility")}
                    >
                      {isVisibilityEnabled
                        ? t(
                            "brands.collection.collectionPage.buyButton.actions.hide"
                          )
                        : t(
                            "brands.collection.collectionPage.buyButton.actions.show"
                          )}
                    </MenuItem>
                  )}
                  {isMobile && (
                    <MenuItem
                      sx={{
                        fontFamily: "Open Sans",
                        fontSize: "14px",
                        padding: "3px 22px 6px",
                        minHeight: "34px",
                      }}
                      key={"visibility"}
                      onClick={() => handleMenuItemClick("open_admin_panel")}
                    >
                      {t(
                        "brands.collection.collectionPage.buyButton.actions.admin"
                      )}
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </StyledComponent>
  );
}
