import React from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./styles.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Typography from "@mui/material/Typography";
import Item from "./Item";
import UI from "../../../../../@components/UI";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./styles.css";
import "swiper/css";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const Desktop = ({ collections, isLoading }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant={"h5"}>{t("appBar.myNFTs")}</Typography>
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();

            history.push({
              pathname: `/profile/nfts`,
              state: { scrollToTop: true },
            });
          }}
        >
          <ArrowForwardIcon sx={{ fontSize: "20px" }} />
        </Box>
      </Box>
      <Box sx={{ display: collections?.length > 0 && "flex", mt: "12px" }}>
        <Swiper
          grabCursor={true}
          slidesPerView={
            collections?.length === 1
              ? 1
              : collections?.length === 2
              ? 2
              : collections?.length === 3
              ? 3
              : 2.3
          }
          spaceBetween={10}
          className="owned-nfts-slider"
        >
          {!isLoading &&
            collections &&
            collections?.length > 0 &&
            collections.slice(0, 5)?.map((collection, index) => {
              return (
                <SwiperSlide>
                  <Item
                    collection={collection}
                    contentType={collection.nft_file_type}
                    contentData={collection.nft_image_url}
                    index={index}
                  />
                </SwiperSlide>
              );
            })}
          {collections?.length > 3 && (
            <SwiperSlide>
              <Box
                sx={{
                  position: "relative",
                  borderRadius: (theme) => theme.borderRadius.primary,
                  cursor: "pointer",
                  width: "130px",
                  height: "130px",
                  display: "flex",
                  flexDirection: "column", // Adjusted to column direction
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                  background: "transparent",
                  border: "2px solid transparent",
                  transition: "all 0.3s ease-in-out",
                  "&:hover": {
                    opacity: "0.6",
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`/profile/nfts`);
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "5px",
                    background:
                      theme.palette.type === "dark"
                        ? "rgba(255, 255, 255, 0.04)"
                        : "rgba(255, 255, 255, 0.14)",
                    boxShadow: (theme) => theme.palette.dialog.boxShadow,
                  }}
                >
                  <Typography
                    variant={"subtitle2"}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%) translateX(-50%)",
                      left: "50%",
                      width: "100%",
                    }}
                  >
                    {t("commons.showMore")}
                  </Typography>
                </Box>
              </Box>
            </SwiperSlide>
          )}
        </Swiper>
        {!isLoading && collections && collections?.length === 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography
              variant={"subtitle3"}
              sx={{ color: (theme) => theme.palette.text.tertiary }}
            >
              {t("infoMessages.noCollectedNFTs")}
            </Typography>
          </Box>
        )}
        {isLoading && (
          <UI.Busy.Indicator
            width={isMobile ? "100px" : "160px"}
            height={isMobile ? "100px" : "160px"}
          />
        )}
      </Box>
    </>
  );
};

const CollectionSlider = ({ onSelect, collections, page, isLoading }) => {
  const [selected, setSelected] = React.useState(0);

  return (
    <Box sx={{ width: "100%" }}>
      <Desktop
        isLoading={isLoading}
        page={page}
        collections={collections}
        selected={selected}
        onSelect={onSelect}
        setSelected={setSelected}
      />
    </Box>
  );
};

export default CollectionSlider;
