import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import List from "./List";
import comingSoon from "../../../static/qpeople-comingsoon.png";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Quantico",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "30px",
    lineHeight: "1.5",
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
}));

const qPeopleMocked = [
  {
    avatar:
      "https://static.generated.photos/vue-static/face-generator/landing/wall/14.jpg",
    address: "0x3C44CdDdB6a900fa2b585dd299e03d12FA4293BC",
    stakedAmount: "100",
    profileUrl: "https://",
  },
  {
    avatar: "https://static.generated.photos/vue-static/home/feed/adult.png",
    address: "0x3C44CdDdB6a900fa2b585dd299e03d12FA4293BC",
    stakedAmount: "75",
    profileUrl: "https://",
  },
  {
    avatar:
      "https://i.dailymail.co.uk/1s/2019/09/24/14/18870210-0-image-a-25_1569331604556.jpg",
    address: "0x3C44CdDdB6a900fa2b585dd299e03d12FA4293BC",
    stakedAmount: "345",
    profileUrl: "https://",
  },
];

const Index = () => {
  const classes = useStyles();
  const [, setItems] = React.useState([]);

  React.useEffect(() => {
    setItems(qPeopleMocked);
  }, []);
  return (
    <Paper sx={{ width: "100%", marginLeft: "20px" }}>
      <Box sx={{ width: "100%", padding: "75px 80px" }}>
        <Box className={classes.title}>QPEOPLE</Box>
        <Box sx={{ mt: "55px" }}>
          {/*<CollectionList items={items} />*/}
          <img width={"100%"} src={comingSoon} alt={"coming soong"} />
        </Box>
      </Box>
    </Paper>
  );
};

Index.List = List;

export default Index;
