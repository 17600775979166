import React from "react";
import UI from "../../../../@components/UI";
import useBlockchainState from "../useBlockchainState";
import { handleVote, prepareDaoData } from "../utils";
import useQueue from "../useQueue";
import useExecute from "../useExecute";
import useDaoService from "../../../../daos/useService";
import useProposalService from "../../../proposal/useService";
import { ethers } from "ethers";
import CustomHooks from "../../../../@components/hooks";
import daoLauncherERC20Artifact from "../../../../abis/contracts/qtech/DaoLauncherERC20.sol/DaoLauncherERC20.json";

import { useSnackbar } from "notistack";
import { useMenu } from "../../../context";
import { useParams } from "react-router-dom";
import useVote from "../useVote";

const VotingPanel = ({ DAO, proposal, onChange }) => {
  const signer = CustomHooks.useEthersSigner();
  const { id } = useParams();
  const daoService = useDaoService();
  const proposalService = useProposalService();
  const { updateMenu } = useMenu();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [eventListeners, setEventListeners] = React.useState([]);
  const [daoDeployerData, setDaoDeployerData] = React.useState(null);
  const { vote } = useVote(DAO);
  const { queue } = useQueue(DAO);
  const { execute } = useExecute(DAO);

  const { votingPower } = useBlockchainState(DAO, proposal);

  const { data: daoDeployer, refetch } = CustomHooks.useFetch(
    ["daoLauncher"],
    proposalService.getDAOLauncher
  );

  React.useEffect(() => {
    if (daoDeployer) {
      setDaoDeployerData(daoDeployer.data);
    }
  }, [daoDeployer]);

  const saveDAOtoDatabase = async (contractData) => {
    removeEventListeners();
    const data = prepareDaoData(id, proposal, contractData);
    await daoService
      .save(data)
      .then((response) => {
        enqueueSnackbar("Brand Successfully Created", { variant: "success" });
        updateMenu();
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(error?.message, { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addEventListeners = () => {
    if (eventListeners.length === 0) {
      const daoLauncher = new ethers.Contract(
        daoDeployerData.address,
        daoLauncherERC20Artifact.abi,
        signer
      );
      daoLauncher.on("DaoCreatedEvent", async (data) => {
        if (data.name === proposal.data.name) {
          await saveDAOtoDatabase(data);
          removeEventListeners();
        }
      });
      setEventListeners((prevValue) => [...prevValue, "DaoCreatedEvent"]);
    }
  };

  const removeEventListeners = () => {
    refetch().then((response) => {
      const daoLauncher = new ethers.Contract(
        response.data.data.address,
        daoLauncherERC20Artifact.abi,
        signer
      );
      daoLauncher.removeAllListeners();
    });
  };

  React.useEffect(() => {
    return () => {
      removeEventListeners();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <UI.Busy.FullscreenIndicator show={isLoading} />
      <UI.VotingPoll
        DAO={DAO}
        proposal={proposal}
        onVote={async (option) => {
          setIsLoading(true);
          await handleVote(option, { voteFunc: vote, proposal, votingPower })
            .then(async (response) => {
              setIsLoading(false);
              onChange(response);
            })
            .catch((reason) => {
              setIsLoading(false);
            });
        }}
        onQueue={async () => {
          setIsLoading(true);
          queue(proposal)
            .then((response) => {
              onChange(response);
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        }}
        onExecute={async () => {
          addEventListeners();
          setIsLoading(true);
          execute(proposal)
            .then((response) => {
              onChange(response);
            })
            .catch(() => {
              removeEventListeners();
              setIsLoading(false);
            });
        }}
      />
    </React.Fragment>
  );
};

export default VotingPanel;
