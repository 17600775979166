import React from "react";
import Button from "./Button";

const Index = ({ title }) => {
  return <Button title={title} />;
};

Index.Button = Button;

export default Index;
