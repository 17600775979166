import React, { useState } from "react";
import EnglishFlag from "../../../../static/uk-flag.png";
import SlovenianFlag from "../../../../static/sl-flag.png";
import { useTranslation } from "react-i18next";
import { Typography, Button } from "@mui/material";
import useService from "../../../../profile/useService";
import { useUserContext } from "../../../userContext";

const lngs = {
  en: { nativeName: "English", flag: EnglishFlag, Name: "SI" },
  sl: { nativeName: "Slovensko", flag: SlovenianFlag, Name: "EN" },
};

const LanguageSwitcher = (props) => {
  const { i18n } = useTranslation();
  const userService = useService();
  const { user: loggedInUser } = useUserContext();
  const [currentLanguage, setCurrentLanguage] = useState(
    i18n.language.toLowerCase()
  );

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "sl" : "en";
    setCurrentLanguage(newLanguage);

    if (loggedInUser) {
      userService.changeLanguage(newLanguage);
    }
    i18n.changeLanguage(newLanguage);
  };

  return (
    <Button
      onClick={toggleLanguage}
      sx={{
        justifyContent: "center",
        textTransform: "none",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
        minWidth: "34px",
      }}
    >
      <Typography
        variant={"subtitle3"}
        sx={{
          color: (theme) => theme.palette.text.secondary,
          fontWeight: "bold",
          textAlign: "center",
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            color: (theme) => theme.palette.text.primary,
          }
        }}
      >
        {lngs[currentLanguage]?.Name}
      </Typography>
    </Button>
  );
};

export default LanguageSwitcher;
