import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ThemeSwitcher from "./ThemeSwitcher";
import LanguageSwitcher from "./LanguageSwitcher";

const Settings = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <SettingsIcon
        sx={{
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
        ref={anchorEl}
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            backgroundColor: (theme) => theme.palette.dialog.background,
            backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
            "& .MuiAvatar-root": {
              width: 24,
              height: 24,
              ml: "8px",
              mr: 1,
            },
          },
        }}
      >
        <MenuItem
          sx={{
            minHeight: "42px",
            mx: "8px",
            p: "0 10px",
            borderRadius: (theme) => theme.borderRadius.secondary,
            m: "5px 0px",
            transition: "all 0.2s ease-in-out",
            "&:hover": {
              color: (theme) => theme.palette.text.primary,
              background: "transparent"
            },
            "&:focus": {
              background: "transparent"
            },
          }}
        >
          <ThemeSwitcher />
        </MenuItem>
        <MenuItem
          sx={{
            minHeight: "42px",
            mx: "8px",
            p: "0 10px",
            borderRadius: (theme) => theme.borderRadius.secondary,
            m: "5px 0px",
            transition: "all 0.2s ease-in-out",
            "&:hover": {
              color: (theme) => theme.palette.text.primary,
              background: "transparent"
            },
            "&:focus": {
              background: "transparent"
            },
          }}
        >
          <LanguageSwitcher />
        </MenuItem>
      </Menu>
    </>
  );
};

export default Settings;
