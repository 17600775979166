import React from "react";
import Box from "@mui/material/Box";
import UI from "../../../../../../@components/UI";
import imageIcon from "../../../../../../static/image-icon.png";

const Card = ({ item, isEditing }) => {
  const spaceIsConfirmed = item.album_card.length !== 0;
  const isLocked = item.is_locked;

  return (
    <Box
      key={item.id}
      sx={{
        borderRadius: (theme) => theme.borderRadius.secondary,
        backgroundColor: (theme) => theme.palette.bgTransparent.quaternary,
        position: "relative",
        cursor: "pointer",
        width: { md: "155px", mobile: "102px" },
        height: { md: "232px", mobile: "153px" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          backgroundColor: (theme) => theme.palette.bgTransparent.secondary,
          transform: "scale(1.02)",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "155px",
          width: "100%",
          overflow: "hidden",
          borderRadius: (theme) => theme.borderRadius.secondary,
        }}
      >
        <UI.ImageWithSkeleton
          sx={{
            width: { md: "100px", mobile: "70px" },
            height: { md: "100px", mobile: "70px" },
            transition: "all 0.3s ease-in-out",
            mb: "5px",
          }}
          src={imageIcon}
        />
      </Box>

      {!spaceIsConfirmed && !isLocked && !isEditing && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 1000,
            borderRadius: (theme) => theme.borderRadius.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "2px solid #944dff",
            boxShadow: "#944dff 0px 0px 5px 0px",
            animation: "pulse 3s infinite",
            "@keyframes pulse": {
              "0%": {
                transform: "scale(1)",
              },
              "50%": {
                transform: "scale(1.02)",
                backgroundColor: "rgba(177, 133, 242, 0.3)",
                transition: "all ease-in-out",
              },
              "100%": {
                transform: "scale(1)",
              },
            },
          }}
        >
        </Box>
      )}

    </Box>
  );
};

export default Card;
