import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const Item = () => {
  return (
    <Box
      sx={{
        width: "150px",
        mr: { md: "30px", mobile: "20px" },
        mt: "30px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        transition: "all  0.3s ease-in-out",
        "&:hover": {
          transform: "scale(1.1)",
        },
        "&:hover .removeIcon": {
          display: "block",
          transition: "all 0.1s ease 0s",
        },
      }}
    >
      <Skeleton variant={"rectangular"} width={145} height={145} />
    </Box>
  );
};

const BrandSkeletonList = () => {
  const items = [1, 2, 3, 4];
  return (
    <>
      {items.map((index) => (
        <Item key={index} />
      ))}
    </>
  );
};

export default BrandSkeletonList;
