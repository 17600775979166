import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UI from "../../../../../../../@components/UI";
import { Controller, useForm } from "react-hook-form";
import Paper from "@mui/material/Paper";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import { useUserContext } from "../../../../../../../@components/userContext";
import useService from "../../../../useService";
import CustomHooks from "../../../../../../../@components/hooks";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";


const Video = ({ DAO }) => {
  const { albumId } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { user: loggedInUser } = useUserContext();
  const service = useService(DAO?.id);

  const { mutate: createVideo } = CustomHooks.usePost(service.newCard, {
    onSuccess: () => {
      enqueueSnackbar(t("brands.albums.cards.snackbar"), {
        variant: "success",
      });
      history.push({
        pathname: `/brand/${DAO?.id}/album/${albumId}/edit`,
        state: { scrollToTop: true },
      });
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      video: "",
    },
  });

  const onSubmit = async (form) => {
    const data = {
      name: "Video",
      album_space_type: "Video",
      data_json: {
        video_url: form.video.ipfs,
      },
    };

    createVideo({ albumId: albumId, data: data });
  };
  return (
    <Paper
      sx={{ width: "100%", backgroundColor: (theme) => theme.palette.bgTransparent.primary, }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: "100%",
            px: { md: "80px", mobile: "10px" },
            pb: "35px",
            pt: { md: "55px", mobile: "30px" },
          }}
        >
          <UI.BackButton
            title={t("brands.albums.backToAlbum")}
            onClick={() => history.go(-1)}
          />

          <Typography
            variant={"h3"}
            sx={{
              mb: "25px",
              mt: "30px",
            }}
          >
            {t("brands.albums.createCard.video.createVideoCard")}
          </Typography>
          <Controller
            name={"video"}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <UI.Upload
                noHeight
                noPadding
                {...field}
                id={"video"}
                title={t("brands.albums.createCard.video.upload.title")}
                subtitle={t("brands.albums.createCard.video.upload.subtitle")}
                acceptFiles={"video/mp4"}
              />
            )}
          />
          <Box sx={{ mt: "15px" }}>
            {errors.video?.type === "required" && (
              <Alert
                variant="outlined"
                severity="error"
              >
                {t("brands.albums.createCard.error.video")}
              </Alert>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            mb: "60px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "210px" }}>
            <UI.Button
              disabled={
                !!Object.keys(errors).length || !loggedInUser?.email_verified
              }
              title={t("brands.albums.createCard.button.createCard")}
              type={"primary"}
            />
          </Box>
        </Box>
      </form>
    </Paper>
  );
};

export default Video;
