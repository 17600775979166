import React from "react";
import { useHistory } from "react-router-dom";

const useProtectedRoute = (DAO, isUserValid) => {
  const history = useHistory();

  const checkAndRedirect = () => {
    if (DAO) {
      if (isUserValid != null && !isUserValid) {
        return history.push({
          pathname: `/brand/${DAO?.id}`,
          state: { scrollToTop: true },
        });
      }
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(checkAndRedirect, [DAO, isUserValid]);

  return {};
};

export default useProtectedRoute;
