import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "./TextField";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import InputAdornment from "@mui/material/InputAdornment";

const Time = React.forwardRef((props, ref) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileTimePicker
        {...props}
        ref={ref}
        ampm={false}
        inputFormat="HH:mm"
        renderInput={(params) => (
          <TextField
            {...params}
            endAdornment={
              <InputAdornment position="start">
                <AccessTimeIcon sx={{ color: (theme) => theme.palette.text.primary, }} />
              </InputAdornment>
            }
          />
        )}
      />
    </LocalizationProvider>
  );
});

export default Time;
