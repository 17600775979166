import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../@components/UI";
import truncateEthAddress from "truncate-eth-address";
import useService from "./useService";
import moment from "moment";
import SkeletonList from "./SkeletonList";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import cryptoLogo from "../../../static/crypto.png";
import qvrseLogo from "../../../static/qvrse-logo.png";
import maticLogo from "../../../static/matic-logo.png";
import usdtLogo from "../../../static/usdt-logo.png";
import usdcLogo from "../../../static/usdc-icon.png";
import euroLogo from "../../../static/euroLogo.png";
import dollarLogo from "../../../static/dollarLogo.png";
import Tooltip from "@mui/material/Tooltip";

function getLogoSrc(symbol) {
  // Define the mappings between symbols and logo paths
  const logoMappings = {
    USDT: usdtLogo,
    USDC: usdcLogo,
    MATIC: maticLogo,
    QVRS: qvrseLogo,
    EUR: euroLogo,
    USD: dollarLogo,
  };

  // Check if the symbol exists in the mappings
  if (symbol in logoMappings) {
    const logoPath = logoMappings[symbol];
    return logoPath; // or any other appropriate method to load the image
  }

  // Return a default logo image or handle error
  return maticLogo; // or return a default logo image path or handle error case
}

const Item = ({ dateTime, from, to, amount, symbol }) => {
  return (
    <Box
      sx={{
        cursor: "pointer",
        padding: { md: "10px 30px", mobile: "10px 15px" },
        mb: "15px",
        backgroundColor: (theme) => theme.palette.bgTransparent.primary,
        borderRadius: (theme) => theme.borderRadius.primary,
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid transparent",
        transition: "all 0.3s ese-in-out",
        "&:hover": {
          boxShadow: "#944dff 0px 0px 10px 0px",
          border: "1px solid #944dff",
          backgroundColor: (theme) => theme.palette.bgTransparent.secondary,
        },
      }}
    >
      <Box sx={{ display: "flex" }}>
      <Box
          display={"none"}
          component={"img"}
          src={cryptoLogo}
          sx={{ width: "45px", height: "30px", marginRight: "10px" }}
        />
        <Typography
          variant={"h6"}
          sx={{
            mr: { md: "0px", mobile: "0px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: "0px",
            width: "170px",
          }}
        >
          {dateTime}
        </Typography>
        <Box
          sx={{
            ml: "20px",
            display: { md: "flex", mobile: "none" },
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <UI.CopyToClipboard
            showIcon={false}
            label={truncateEthAddress(from || "")}
            value={from}
          />
        </Box>
        <Box
          sx={{
            display: { md: "flex", mobile: "none" },
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <TrendingFlatIcon sx={{ fontSize: "15px" }} />
        </Box>
        <Box
          sx={{
            display: { md: "flex", mobile: "none" },
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <UI.CopyToClipboard
            showIcon={false}
            label={truncateEthAddress(to || "")}
            value={to}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={symbol} arrow>
          <Box
            component={"img"}
            src={getLogoSrc(symbol)}
            sx={{ width: "22px", height: "22px", marginRight: "10px" }}
          />
        </Tooltip>
        <Typography
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontWeight: "bold",
            minWidth: "15px",
          }}
        >
          {amount}
        </Typography>
      </Box>
    </Box>
  );
};

const List = () => {
  const [items, setItems] = React.useState(null);
  const service = useService();

  const fetchData = async () => {
    await service
      .list()
      .then((response) => {
        setItems(response.data.transactions);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      {!items && <SkeletonList />}
      {items &&
        items?.length > 0 &&
        items.map((item, index) => (
          <Item
            key={index}
            proposalId={item.proposal_id}
            dateTime={moment(item?.datetime_created).format(
              "DD MMM YYYY HH:mm"
            )}
            from={truncateEthAddress(item.from_address || "")}
            to={truncateEthAddress(item.to_address || "")}
            amount={item.amount}
            symbol={item.token_symbol}
          />
        ))}
      {items && items.length === 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <UI.NoResults text={"No transactions found :("} />
        </Box>
      )}
    </Box>
  );
};

export default List;
