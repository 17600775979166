import Gateway from "../../../@components/gateway";

const useService = (daoId) => {
  const gateway = Gateway.useGateway();

  const list = async () => {
    const response = await gateway.get(`dao/${daoId}/community_page/`);
    return response.data.results;
  };

  return {
    list,
  };
};

export default useService;
