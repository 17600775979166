import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import UI from "../@components/UI";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MaterialDialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const RedeemDialog = ({ toggleOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <MaterialDialog
      PaperProps={{
        sx: {
          position: "relative",
          padding: { md: "30px 10px", mobile: "20px 0px" },
          width: "550px",
          margin: { mobile: "15px" },
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
        },
      }}
      open={!!toggleOpen}
      onClose={onClose}
    >
      <CloseIcon
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 30,
          top: 30,
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <DialogTitle sx={{ p: "8px 24px" }}>
        <Typography variant={"h3"}>{t("profile.qrTicketScan.redeemDialog.title")}</Typography>
      </DialogTitle>
      <DialogContent sx={{ p: "0px 24px 5px 24px" }}>
        <Box sx={{ mt: "10px" }}>
          <Typography variant={"subtitle2"}>
            {t("profile.qrTicketScan.redeemDialog.subtitle")}
          </Typography>
        </Box>
        <Box sx={{ mt: "10px" }}></Box>
      </DialogContent>
      <Box
        sx={{
          mt: "20px",
          px: "15px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <UI.Button
          sx={{ width: "150px" }}
          title={t("profile.qrTicketScan.redeemDialog.redeem")}
          type={"primary"}
          onClick={onConfirm}
        />
      </Box>
    </MaterialDialog>
  );
};

const Action = ({ element: Component, onConfirm, disabled }) => {
  const [open, setOpen] = React.useState(null);

  return (
    <>
      <Box component={"span"} onClick={() => !disabled && setOpen(!open)}>
        {Component}
      </Box>

      <RedeemDialog
        toggleOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={() => {
          setOpen(false);
          onConfirm();
        }}
      />
    </>
  );
};

RedeemDialog.Action = Action;

export default RedeemDialog;
