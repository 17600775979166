import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import UI from "../../../../../@components/UI";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AdditionalInfoDialog from "../AdditionalInfoDialog";
import AddField from "../AddField";
import { makeStyles } from "@mui/styles";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  inputOuter: {
    marginTop: "10px",
    marginBottom: "25px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

const AdditionalInfo = ({ utilityIndex }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: `extra_utilities.${utilityIndex}.custom_info_fields`,
  });

  return (
    <>
      {fields.length > 0 &&
        fields.map((item, index) => {
          if (item?.type === "button") {
            //setCustomActionButton(item);
            // Skip the rest of this iteration if type is 'button'
            return null;
          }

          return (
            <Grid item md={12} mobile={12} key={item.id}>
              <Box className={classes.inputOuter}>
                <Box sx={{ display: "flex", mb: "10px" }}>
                  <Typography variant={"h4"}>{item.name}</Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Controller
                    name={`extra_utilities.${utilityIndex}.custom_info_fields.${index}.value`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <UI.Inputs.GetInputByType
                        type={item.type}
                        {...field}
                        placeholder={item?.placeholder}
                      />
                    )}
                  />
                  {item.canRemove !== false && (
                    <Box
                      onClick={() => remove(index)}
                      sx={{
                        cursor: "pointer",
                        ml: "8px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <DeleteForeverIcon
                        sx={{
                          color: (theme) => theme.palette.colors.darkRed,
                          opacity: "0.8",
                          "&:hover": {
                            opacity: 1,
                          },
                        }}
                      />
                    </Box>
                  )}
                </Box>
                {Object.keys(errors).length > 0 &&
                  errors?.extra_utilities[utilityIndex]?.custom_info_fields &&
                  errors?.extra_utilities[utilityIndex]?.custom_info_fields[
                    index
                  ]?.value?.type === "required" && (
                    <Box sx={{ mt: "15px" }}>
                      <Alert
                        variant="outlined"
                        severity="error"
                      >
                        {`${item?.name} is required`}
                      </Alert>
                    </Box>
                  )}
              </Box>
            </Grid>
          );
        })}
      <Box
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <AdditionalInfoDialog.Action
          title={t(
            "brands.collection.create.extraUtilities.additionalInfo.title"
          )}
          onAdd={(newFields) => append(newFields)}
          element={
            <AddField
              title={t(
                "brands.collection.create.extraUtilities.additionalInfo.action.title"
              )}
              subtitle={t(
                "brands.collection.create.extraUtilities.additionalInfo.action.subtitle"
              )}
            />
          }
        />
      </Box>
    </>
  );
};

export default AdditionalInfo;
