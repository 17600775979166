import React, { useState } from "react";
import EnglishFlag from "../../../static/uk-flag.png";
import SlovenianFlag from "../../../static/sl-flag.png";
import { useTranslation } from "react-i18next";
import useService from "../../../profile/useService";
import { useUserContext } from "../../userContext";
import Box from "@mui/material/Box";

const lngs = {
  en: { nativeName: "English", flag: EnglishFlag, Name: "SI" },
  sl: { nativeName: "Slovensko", flag: SlovenianFlag, Name: "EN" },
};

const LanguageSwitcher = (props) => {
  const { selectedTheme } = useUserContext();
  const { i18n } = useTranslation();
  const userService = useService();
  const { user: loggedInUser } = useUserContext();
  const [currentLanguage, setCurrentLanguage] = useState(
    i18n.language.toLowerCase()
  );

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "sl" : "en";
    setCurrentLanguage(newLanguage);

    if (loggedInUser) {
      userService.changeLanguage(newLanguage);
    }
    i18n.changeLanguage(newLanguage);
  };

  return (
    <Box
      onClick={toggleLanguage}
      sx={{
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor:
          selectedTheme === "dark"
            ? "rgba(255,255,255,0.06)"
            : "rgba(0,0,0,0.09)",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        fontSize: "15px",
        fontWeight: "bold",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          backgroundColor:
            selectedTheme === "dark"
              ? "rgba(255,255,255,0.14)"
              : "rgba(0,0,0,0.16)",
          color: selectedTheme === "dark" ? "#fff" : "#000",
        },
      }}
    >
      {lngs[currentLanguage]?.Name}
    </Box>
  );
};

export default LanguageSwitcher;
