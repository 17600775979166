import React, { useEffect } from "react";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3003"
    : window.env.PAYMENT_URL;

const baseQvrseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : window.env.CHAIN === "AMOY"
    ? "https://mumbai.qvrse.io"
    : "https://qvrse.io";

const QvrsePayment = ({
  loggedInQvrseUser,
  open,
  daoId,
  collectionId,
  onSuccess,
}) => {
  useEffect(() => {
    if (open) openPaymentWindow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const openPaymentWindow = () => {
    if (loggedInQvrseUser) {
      //LoggedIn Qvrse user
      window.location.href = `${baseUrl}/brand/${daoId}/collection/${collectionId}?email=${encodeURIComponent(
        loggedInQvrseUser.email
      )}&source=qvrse&redirectUrl=${baseQvrseUrl}/brand/${daoId}/collection/${collectionId}`;
    } else {
      //Not logged in Qvrse user
      window.location.href = `${baseUrl}/brand/${daoId}/collection/${collectionId}?source=qvrse&redirectUrl=${baseQvrseUrl}/brand/${daoId}/collection/${collectionId}`;
    }
  };

  return <></>;
};

export default QvrsePayment;
