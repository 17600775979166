import React from "react";
import { AlchemyAccountProvider } from "@alchemy/aa-alchemy/react";
import { polygonAmoy, polygon } from "@alchemy/aa-core";
import { createConfig } from "@alchemy/aa-alchemy/config";

const AlchemyWrapper = ({ children, queryClient }) => {
  const config = createConfig({
    rpcUrl: window.env.GATEWAY + "/api/rpc",
    chain: window.env.CHAIN === "AMOY" ? polygonAmoy : polygon,
    rpId: process.env.NODE_ENV === "development" ? "localhost" : "qvrse.io",
  });

  return (
    <AlchemyAccountProvider config={config} queryClient={queryClient}>
      {children}
    </AlchemyAccountProvider>
  );
};

export default AlchemyWrapper;
