import Gateway from "../../../@components/gateway";

const useService = (daoId) => {
  const gateway = Gateway.useGateway();

  const info = () => {
    return gateway.get(`dao/${daoId}/treasury/info/`);
  };

  return {
    info,
  };
};

export default useService;
