import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Controller, useFieldArray } from "react-hook-form";
import UI from "../../../../../@components/UI";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

const ChooseYourNFT = ({ control, errors, setValue, data }) => {
  const { t } = useTranslation();

  const {
    append,
    fields: collectionImages,
    remove: removeCollectionImages,
  } = useFieldArray({
    control: control,
    name: `basic_info.collection_images`,
  });

  const updateCollectionImages = (isDefaultValue, newFiles) => {
    if (!newFiles) return;
    //remove all previous files
    removeCollectionImages();

    //add new files
    newFiles.forEach((file) => {
      append({
        name: isDefaultValue ? file.name : "",
        image_url: file.ipfs,
        file_type: file.type,
      });
    });
  };

  return (
    <>
      <Grid item md={12} mobile={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "15px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <UI.Dropzone
            onChange={(files) => {
              updateCollectionImages(false, files);
            }}
            onDefaultValueSet={(files) => {
              updateCollectionImages(true, files);
            }}
            defaultValue={data?.basic_info?.collection_images}
            id={"files"}
            title={t("brands.collection.create.basicInfo.uploadMultiple.title")}
            subtitle={t("brands.collection.create.basicInfo.uploadMultiple.subtitle")}
            acceptFiles={{
              "image/*": [".jpg", ".jpeg", ".png", ".gif", ".webp"],
              "video/*": [".mp4"],
            }}
          />

          {errors.basic_info?.files?.type === "required" && (
            <Box sx={{ mt: "15px" }}>
              <Alert
                variant="outlined"
                severity="error"
              >
                {t(
                  "brands.collection.create.basicInfo.uploadMultiple.error.required"
                )}
              </Alert>
            </Box>
          )}
        </Box>
        {collectionImages?.length > 0 && (
          <Box
            sx={{
              mb: "0px !important",
              marginBottom: "15px",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                mb: "10px",
                mt: "23px !important",
                flexDirection: "column",
              }}
            >
              {collectionImages.map((file, index) => {
                setValue(
                  `basic_info.collection_images.${index}.file_type`,
                  file.file_type
                );
                return (
                  <Grid item md={12} mobile={12} key={index}>
                    <Box
                      sx={{
                        marginBottom: "15px",
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            flex: 4,
                          }}
                        >
                          <Box sx={{ display: "flex", mb: "10px" }}>
                            <Typography variant={"h6"}>
                              {t(
                                "brands.collection.create.basicInfo.uploadMultiple.name.title"
                              )}
                            </Typography>
                            <UI.Tooltip>
                              {t(
                                "brands.collection.create.basicInfo.uploadMultiple.name.tooltip"
                              )}
                            </UI.Tooltip>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {file?.file_type?.startsWith("image") && (
                              <Controller
                                name={`basic_info.collection_images.${index}.image_url`}
                                control={control}
                                defaultValue={file.image_url}
                                rules={{
                                  required: true,
                                }}
                                render={({ field }) => (
                                  <Box
                                    component={"img"}
                                    sx={{
                                      marginRight: "10px",
                                      width: "50px",
                                      height: "50px",
                                      objectFit: "contain",
                                      borderRadius: "5px",
                                    }}
                                    src={file.image_url}
                                    alt="Collection"
                                  />
                                )}
                              />
                            )}
                            {file?.file_type?.startsWith("video") && (
                              <Controller
                                name={`basic_info.collection_images.${index}.image_url`}
                                control={control}
                                defaultValue={file.image_url}
                                rules={{
                                  required: true,
                                }}
                                render={({ field }) => (
                                  <Box
                                    component={"video"}
                                    autoPlay="autoplay"
                                    playsInline
                                    loop
                                    muted
                                    sx={{
                                      marginRight: "10px",
                                      width: "50px",
                                      height: "50px",
                                      objectFit: "contain",
                                      borderRadius: "5px",
                                    }}
                                    src={file.image_url}
                                    alt="Collection"
                                  />
                                )}
                              />
                            )}
                            <Controller
                              name={`basic_info.collection_images.${index}.name`}
                              control={control}
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => (
                                <UI.TextField
                                  {...field}
                                  placeholder={`NFT ${index}`}
                                />
                              )}
                            />
                          </Box>
                        </Box>
                      </Box>

                      {Object.keys(errors).length > 0 &&
                        errors.basic_info?.collection_images &&
                        errors.basic_info.collection_images[index]
                          ?.limit_to_mint?.type === "required" && (
                              <Box
                                  sx={{ mt: "15px" }}
                              >
                            <Alert
                              sx={{
                                color: (theme) => theme.palette.colors.darkRed,
                              }}
                              variant="outlined"
                              severity="error"
                            >
                              {t(
                                "brands.collection.create.basicInfo.uploadMultiple.amount.error.required"
                              )}
                            </Alert>
                              </Box>
                        )}
                      {Object.keys(errors).length > 0 &&
                        errors.basic_info?.collection_images &&
                        errors.basic_info.collection_images[index]?.name
                          ?.type === "required" && (
                              <Box
                                  sx={{ mt: "15px" }}
                              >
                                <Alert
                                  sx={{
                                    color: (theme) => theme.palette.colors.darkRed,
                                  }}
                                  variant="outlined"
                                  severity="error"
                                >
                                  {t(
                                    "brands.collection.create.basicInfo.uploadMultiple.name.error.required"
                                  )}
                                </Alert>
                              </Box>
                        )}
                    </Box>
                  </Grid>
                );
              })}
            </Box>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default ChooseYourNFT;
