import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UI from "../../../@components/UI";
import truncateEthAddress from "truncate-eth-address";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { useSnackbar } from "notistack";
import { useAccount } from "wagmi";
import erc20TokenArtifact from "../../../abis/contracts/modules/token/ERC20Token.sol/ERC20Token.json";
import { ethers } from "ethers";
import Skeleton from "@mui/material/Skeleton";
import { useUserContext } from "../../../@components/userContext";
import { useUserRole } from "../context";
import CustomHooks from "../../../@components/hooks";
import customHooks from "../../../@components/hooks";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "300px",
    height: "260px",
    border: "1px solid #944DFF",
    opacity: 1,
    filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5))",
  },
  cardMedia: {
    opacity: 1,
    height: "auto",
    width: "75px !important",
    margin: "0 auto",
    objectFit: "unset !important",
    marginTop: "20px",
    textAlign: "center",
  },
}));

const Index = ({ DAO }) => {
  const classes = useStyles();
  const { user: loggedInUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const provider = customHooks.useEthersProvider();
  const { isConnected } = useAccount();
  const [tokenName, setTokenName] = React.useState(null);
  const [tokenSymbol, setTokenSymbol] = React.useState(null);
  const [tokenDecimals, setTokenDecimals] = React.useState(null);
  const { isBrandMember } = useUserRole();
  CustomHooks.useProtectedRoute(DAO, isBrandMember);

  React.useEffect(() => {
    const fetchData = async () => {
      if (DAO) {
        const token = new ethers.Contract(
          DAO.token_contract.address,
          erc20TokenArtifact.abi,
          provider
        );

        const name = await token.name();
        const symbol = await token.symbol();
        const decimals = await token.decimals();

        setTokenName(name);
        setTokenSymbol(symbol);
        setTokenDecimals(decimals);
      }
    };
    DAO && fetchData();
    // eslint-disable-next-line
  }, [DAO]);

  const addToWallet = async () => {
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: DAO.token_contract.address, // The address that the tokens is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the tokens
            image: DAO?.dao_token?.icon_url, // A string url of the tokens logo
          },
        },
      });

      if (wasAdded) {
        enqueueSnackbar("Token succesfully added to Metamask", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Token was not added", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message || "Something went wrong", {
        variant: "error",
      });
    }
  };

  return (
    <Paper
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          textAlign: { md: "left", mobile: "center" },
          display: "flex",
          justifyContent: { md: "space-between", mobile: "center" },
          flexDirection: { md: "row", mobile: "column" },
          px: { md: "70px", mobile: "10px" },
          pb: "20px",
          pt: { md: "70px", mobile: "30px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Typography variant={"h1"}>Token</Typography>
          <Box
            sx={{
              ml: "15px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <UI.CopyToClipboard
              label={truncateEthAddress(DAO?.token_contract.address || "")}
              value={DAO?.token_contract.address}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              width: "300px",
              mt: { md: "0px", mobile: "20px" },
              mr: { md: "20px", mobile: "0px" },
            }}
          >
            <UI.Button
              disabled={!isConnected || !loggedInUser?.email_verified}
              onClick={() => addToWallet()}
              title={"Import token to Wallet"}
              type={"primary"}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { md: "70px 70px", mobile: "40px 10px" },
        }}
      >
        <Box sx={{ textAlign: { md: "left", mobile: "center" } }}>
          <Typography variant={"h2"}>DAO is connected with</Typography>
        </Box>
        <Box
          sx={{
            mt: "20px",
            mb: "50px",
            display: "flex",
            justifyContent: { md: "flex-start", mobile: "center" },
          }}
        >
          <Card className={classes.card}>
            {DAO?.dao_token?.icon_url ? (
              <CardMedia
                className={classes.cardMedia}
                component="img"
                image={DAO?.dao_token?.icon_url}
                alt="logo"
              />
            ) : (
              <Skeleton
                className={classes.cardMedia}
                sx={{ height: "75px" }}
                variant={"circular"}
              />
            )}
            <CardContent>
              {tokenName ? (
                <Typography
                  variant={"h2"}
                  sx={{ textAlign: "center", lineHeight: "30px" }}
                >
                  {`${tokenName}`}
                </Typography>
              ) : (
                <Skeleton height={"30px"} variant={"rectangular"} />
              )}
              {tokenSymbol ? (
                <Typography
                  variant={"h2"}
                  sx={{
                    mt: "20px",
                    textAlign: "center",
                    color: (theme) => theme.palette.colors.primary,
                  }}
                >
                  {`(${tokenSymbol})`}
                </Typography>
              ) : (
                <Skeleton
                  sx={{
                    mt: "20px",
                    textAlign: "center",
                  }}
                  height={"30px"}
                  variant={"rectangular"}
                />
              )}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Paper>
  );
};

export default Index;
